import { List } from '@Components/ui/List';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { type DeliveryAddress } from '@Src/types/deliveryAddressTypes';
import { AddressListItem } from './AddressListItem';
import { setSelectedDeliveryAddress } from '@Src/slices/deliveryAddressesSlice';
import { fetchDeliveryStoreSummaries } from '@Src/thunks/deliveryStoresThunks';
import { ReactComponent as SearchIcon } from '@Src/assets/images/search.svg';
import { useConfig } from '@Src/providers/ConfigProvider';
import { AddressAutocomplete } from './AddressAutocomplete';
import { useState } from 'react';
import { Typography, styled } from '@mui/material';
import { TextField } from '@Components/ui/TextField';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { logger } from '@Src/utility/loggerService';
import { useNavigateToWebOrder } from '@Src/utility/orderUtils';
import { DeliveryConfirmLocation } from '../../ConfirmAddress';
import { UseCurrentLocationButton } from './UseCurrentLocationButton';
import { type Geo } from '@Src/types/locationTypes';
import { DeliveryUnavailableModal } from '../../ConfirmAddress/Components/DeliveryUnavailableModal';
import { setGeolocation } from '@Src/slices/locationSlice';
import { useTracking } from '@Src/hooks/useTracking';
import {
  type SupportedCountries,
  getPostCodeExample,
  getPostCodeLabel,
} from '@Src/utility/locationUtils';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { selectIsServiceUnavailable } from '@Src/selectors/stores.selectors';
import useApi from '@Src/hooks/useApi';

const StyledFixedContainer = styled('div')`
  position: fixed;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 2;
  width: 100%;
  border-radius: 4px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 480px;
  }
`;

const StyledSearch = styled(TextField)<{
  ownerState: { isServiceUnavailable?: boolean };
}>(({ ownerState, theme }) => ({
  marginTop: ownerState.isServiceUnavailable
    ? theme.spacing(19)
    : theme.spacing(14),
  paddingTop: theme.spacing(3.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  width: '100%',
}));

const StyledListTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1.5),
}));

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const FullDialogViewContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  .MuiAutocomplete-root {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export type HandleNavConfirmLocationProps = {
  autoFill?: boolean;
  geo: Geo;
};

export type DeliveryFlowStepType =
  | 'addressList'
  | 'addressSearch'
  | 'addressConfirm';

export const DeliverySettings: React.FC<{
  header: JSX.Element;
  startStep?: DeliveryFlowStepType;
  onClose?: () => void;
}> = ({ header, startStep = 'addressList', onClose }) => {
  const { appId } = useConfig();
  const { countryCode } = useDesignSchema('v2');
  const navigateToWebOrder = useNavigateToWebOrder();
  const { translate } = useTranslation();
  const { trackOrderEvent } = useTracking();
  const { get } = useApi();

  const dispatch = useAppDispatch();
  const deliveryAddresses = useAppSelector(
    (state) => state.deliveryAddresses.deliveryAddressList,
  );
  const selectedDeliveryAddress = useAppSelector(
    (state) => state.deliveryAddresses.selectedDeliveryAddress,
  );
  const coordinates = useAppSelector((state) => state.location.geo);
  const userCountryCode = useAppSelector(
    (state) => state.location.ipInfo?.country,
  ) as SupportedCountries;
  const isServiceUnavailable = useAppSelector(selectIsServiceUnavailable);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryFlowStep, setDeliveryFlowStep] =
    useState<DeliveryFlowStepType>(startStep);

  const [autoFillFields, setAutoFillFields] = useState<boolean>(true);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const handleDeliveryAddressSelected = (
    deliveryLocationId: number,
    index: number,
  ): void => {
    const selectedAddress = deliveryAddresses.find(
      (address) => address.DeliveryLocationId === deliveryLocationId,
    );
    if (selectedAddress) {
      trackOrderEvent('Select Delivery Address', {
        order: (index + 1).toString(),
      });
      dispatch(setSelectedDeliveryAddress(selectedAddress));
      dispatch(fetchDeliveryStoreSummaries({ appId, get }))
        .unwrap()
        .then((deliveryStoreSummaries) => {
          const selectedStore = deliveryStoreSummaries.find(
            (store) => !store.UserOutsideDeliveryZone,
          );
          if (selectedStore) {
            navigateToWebOrder({
              isCollection: false,
              storeSummary: selectedStore,
              deliveryLocationId: selectedAddress.DeliveryLocationId,
            });
          } else {
            setIsModalOpen(true);
          }
        })
        .catch((error) => logger.log(error));
    }
  };

  const deliveryAddressItems = deliveryAddresses
    ?.filter((address) => address.IsDisplayed)
    .map((deliveryAddress: DeliveryAddress, index: number) => ({
      key: `${deliveryAddress.DeliveryLocationId}`,
      content: (
        <AddressListItem
          firstLine={deliveryAddress.TwoLinesDisplay[0] || ''}
          secondLine={deliveryAddress.TwoLinesDisplay[1]}
          isSelected={
            selectedDeliveryAddress?.DeliveryLocationId ===
            deliveryAddress.DeliveryLocationId
          }
          isHovered={hoveredItem === deliveryAddress.DeliveryLocationId}
        />
      ),
      id: deliveryAddress.DeliveryLocationId,
      onClick: () =>
        handleDeliveryAddressSelected(
          deliveryAddress.DeliveryLocationId,
          index,
        ),
      isSelected:
        selectedDeliveryAddress?.DeliveryLocationId ===
        deliveryAddress.DeliveryLocationId,
    }));

  const handleNavigateToConfirmLocation = ({
    autoFill = true,
    geo,
  }: HandleNavConfirmLocationProps): void => {
    setAutoFillFields(autoFill);
    dispatch(setGeolocation(geo));
    setDeliveryFlowStep('addressConfirm');
  };

  const handleBackClick = (previousStep: DeliveryFlowStepType): void => {
    if (deliveryFlowStep === startStep && onClose) {
      onClose();
    } else {
      setDeliveryFlowStep(previousStep);
    }
  };

  const renderDeliveryFlowStep = (): JSX.Element => {
    switch (deliveryFlowStep) {
      case 'addressList':
        return (
          <>
            <StyledFixedContainer>{header}</StyledFixedContainer>
            <StyledSearch
              ownerState={{ isServiceUnavailable }}
              label={translate(
                getPostCodeLabel(
                  (countryCode as SupportedCountries) || userCountryCode,
                ),
              )}
              placeholder={
                userCountryCode &&
                `${translate('e.g.')} ${getPostCodeExample(
                  (countryCode as SupportedCountries) || userCountryCode,
                )}`
              }
              onFocus={() => setDeliveryFlowStep('addressSearch')}
              endIcon={<StyledSearchIcon data-fd="search-icon" />}
            />
            <UseCurrentLocationButton
              onLocationRetrieved={handleNavigateToConfirmLocation}
            />
            {deliveryAddressItems?.length > 0 && (
              <>
                <StyledListTitle variant="body2Medium" paragraph>
                  {translate('Recent_locations')}
                </StyledListTitle>
                <List
                  items={deliveryAddressItems || []}
                  setHoveredItem={setHoveredItem}
                />
              </>
            )}
            <DeliveryUnavailableModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </>
        );
      case 'addressSearch':
        return (
          <FullDialogViewContainer>
            <AddressAutocomplete
              onAddressSuggestionClicked={handleNavigateToConfirmLocation}
              onBackClick={() => {
                handleBackClick('addressList');
              }}
            />
            <UseCurrentLocationButton
              onLocationRetrieved={handleNavigateToConfirmLocation}
            />
          </FullDialogViewContainer>
        );
      case 'addressConfirm':
        return (
          <FullDialogViewContainer>
            <DeliveryConfirmLocation
              autoFillFields={autoFillFields}
              coordinates={coordinates}
              onBackClick={() => setDeliveryFlowStep('addressSearch')}
            />
          </FullDialogViewContainer>
        );
      default:
        return <></>;
    }
  };

  return renderDeliveryFlowStep();
};
