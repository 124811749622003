import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import MuiSelect from '@mui/material/Select';
import type React from 'react';

const StyledSelect = styled(MuiSelect)`
  & legend {
    display: none;
  }
  & fieldset {
    border-color: ${({ theme }) => theme.palette.secondary.subtle};
  }
  & .MuiInputBase-input {
    ${({ theme }) => ({
      fontSize: theme.typography.body1Medium?.fontSize,
      fontWeight: theme.typography.body1Medium?.fontWeight,
      lineHeight: theme.typography.body1Medium?.lineHeight,
    })};
  }
`;

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.caption.fontWeight,
  lineHeight: theme.typography.caption.lineHeight,
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  color: theme.palette.text.primary,
}));

type Props = {
  defaultValue: string;
  identifier: string;
  label: string;
  options: Array<Record<string, any>>;
  placeholder: string;
};

export const Select: React.FC<Props> = ({
  label,
  identifier,
  defaultValue,
  options,
  placeholder,
}) => (
  <>
    <StyledInputLabel shrink={false}>{label}</StyledInputLabel>
    <StyledSelect
      aria-label={label}
      data-fd={identifier}
      defaultValue={defaultValue}
      fullWidth
      id={identifier}
      inputProps={{
        'aria-label': label,
        'aria-required': 'true',
        id: `select-${identifier}`,
      }}
      key={identifier}
      label={label}
      name={identifier}
      placeholder={placeholder}
      size="small"
    >
      {options?.map((option) => (
        <MenuItem
          key={option.key}
          disabled={option.disabled}
          value={option.label}
        >
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  </>
);
