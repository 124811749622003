import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Providers/TranslationProvider';
import {
  type TestimonialEntryType,
  type TestimonialsType,
} from '@Src/middlewares/CustomPageTypes';
import { PageTemplates } from '@Src/middlewares/CustomPageTypes';

const StyledHeading = styled.h2<{ primaryColor: string }>`
  color: ${(props) => props.primaryColor};
  text-align: left !important;
  font-family: 'BitterRegular', sans-serif !important;
  font-weight: 300 !important;
  font-size: 2em !important;
  text-transform: uppercase !important;
`;

const StyledSection = styled.section<{ image?: string; primaryColor: string }>`
  background-image: url('${(props) => props.image}') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 505px;
  //parallax
  background-attachment: fixed !important;
  background-position: 50% 50% !important;

  .carousel .control-dots .dot {
    background: transparent !important;
    border: 3px solid ${(props) => props.primaryColor} !important;
    opacity: 1;
    width: 16px;
    height: 16px;
  }

  .testimonials-item {
    padding-bottom: 2rem;
  }

  .carousel .control-dots .dot.selected {
    background: ${(props) => props.primaryColor} !important;
  }

  h2 span {
    position: absolute;
    font-family: Bitter;
    right: 0;
    font-weight: 700;
    font-size: 72px;
  }

  h2:after {
    content: '';
    width: 40px;
    height: 3px;
    background: #e2e9ed;
    position: absolute;
    bottom: -5px;
    left: 36px;
  }
`;

const StyledTestimonialsImage = styled.div<{ logo: string }>`
  background: none;
  background-image: url('${(props) => props.logo}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
`;

const StyledTestimonialsWrapper = styled.div`
  pre {
    white-space: pre-wrap;
    text-wrap: inherit;
    font: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
`;

const getCarouselTestimonials = (
  testimonials?: TestimonialEntryType[],
): JSX.Element => (
  <Carousel swipeable showThumbs={false} showArrows={false} showStatus={false}>
    {(testimonials ?? []).map((t) => (
      <div key={t?.id} className="item testimonials-item">
        <StyledTestimonialsWrapper
          dangerouslySetInnerHTML={{
            __html: t?.testimonial,
          }}
        />
      </div>
    ))}
  </Carousel>
);

const getLinearTestimonials = (
  testimonials?: TestimonialEntryType[],
): JSX.Element[] =>
  (testimonials ?? [])?.map((t) => (
    <div
      key={t?.id}
      className="owl-item"
      style={{ width: '65%', maxWidth: '1500px' }}
    >
      <div className="item">
        <StyledTestimonialsWrapper
          dangerouslySetInnerHTML={{
            __html: t?.testimonial,
          }}
        />
        {t?.author && <span className="author">{t.author}</span>}
      </div>
    </div>
  ));

export const Testimonials = ({
  testimonials,
}: {
  testimonials: TestimonialsType;
}): JSX.Element => {
  const { translate } = useTranslation();
  const { logo, appDesignScheme, template } = useDesignSchema('v1');
  const { Primary: primary } = appDesignScheme;

  const getContent = (): JSX.Element | JSX.Element[] => {
    switch (template) {
      case PageTemplates.LosPollosHermanos:
      case PageTemplates.PizzaCafe:
        return getLinearTestimonials(testimonials?.data);
      default:
        return getCarouselTestimonials(testimonials?.data);
    }
  };

  return (
    <StyledSection
      id="testimonials"
      className="section testimonials parallax voffset100 clearfix"
      image={testimonials?.image}
      primaryColor={primary}
    >
      <div className="container">
        <div className="jt_row-fluid row">
          <div className="voffset20" />
          <div className="col-md-12 jt_col column_container">
            <StyledTestimonialsImage className="testimonials_img" logo={logo} />
            <StyledHeading primaryColor={primary}>
              {translate('Testimonials')}
              <span>”</span>
            </StyledHeading>
          </div>
          <div className="col-md-12 jt_col column_container">
            {getContent()}
          </div>
        </div>
        <div className="voffset80" />
      </div>
    </StyledSection>
  );
};
