import { getComponentWithWidget } from '@Components/LegacyElixir/WidgetComponent';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import styled from 'styled-components';

export type EmptyPageProps = {
  html?: string;
};

const StyledSection = styled.section<{ primaryColor: string }>`
  a {
    color: ${(props) => props.primaryColor};
    &:hover {
      color: ${(props) => props.primaryColor};
      opacity: 0.85;
    }
    &:focus {
      text-decoration: none;
      color: ${(props) => props.primaryColor};
    }
  }
`;

export const EmptyPage = (props: EmptyPageProps): JSX.Element => {
  const { html = '' } = props;
  const { appDesignScheme } = useDesignSchema('v1');
  const { Primary: primary } = appDesignScheme;

  return (
    <StyledSection id="empty" className="section" primaryColor={primary}>
      <div>{getComponentWithWidget(html)}</div>
    </StyledSection>
  );
};

export default EmptyPage;
