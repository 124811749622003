import { type IpInfo } from '@Src/types/locationTypes';
import { logger } from '@Src/utility/loggerService';

const getIpInfo = async (): Promise<IpInfo | undefined> => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await fetch(`https://ipinfo.io/json`, {
    method: 'GET',
    headers,
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 429) {
    logger.error(`getIpInfo`, {
      errorMessage: 'Too many requests',
    });
  }
  return undefined;
};

export { getIpInfo };
