import {
  V2Templates,
  type MobileAppImageType,
} from '@Src/middlewares/CustomPageTypes';
import { styled } from '@mui/material/styles';
import { Grid, Typography, type TypographyProps } from '@mui/material';
import { type CSSProperties } from 'react';
import { ImgixImage } from '../ImgixImage';
import MuiButton from '../MuiButton';

export const StyleContentContainer = styled(Grid)<{
  height: CSSProperties['height'];
  ownerState: {
    hasBackground: boolean;
    backgroundColor?: CSSProperties['backgroundColor'];
    textColor?: CSSProperties['color'];
  };
}>(({ ownerState, height, theme }) => ({
  color: ownerState?.hasBackground
    ? ownerState.textColor
    : theme.palette.text.primary,
  flexDirection: 'row',
  backgroundColor:
    ownerState.hasBackground && ownerState.backgroundColor
      ? ownerState.backgroundColor
      : theme.palette.background.default,
  height,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: '100%',
  },
  ...(theme.template === V2Templates.boxedUp && {
    borderRadius: theme.boxedUp.borderRadius.default,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.boxedUp.borderRadius.mobile,
    },
  }),
}));

export const StyledImageContainer = styled(Grid)<{
  ownerstate: { orientation: 'left' | 'right' };
}>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '340px',
    paddingBottom: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    ...(theme.template === V2Templates.boxedUp && {
      paddingBottom: 0,
    }),
  },
}));

export const StyledImgixImage = styled(ImgixImage)<{
  ownerstate: {
    imageOnly: boolean;
    orientation: 'left' | 'right';
    isPlainSection?: boolean;
  };
}>(
  ({
    theme,
    height,
    ownerstate: { imageOnly, orientation, isPlainSection = false },
  }) => ({
    height,
    width: '100%', // 100% + marginLeft
    objectFit: 'cover',
    transform: isPlainSection
      ? ''
      : `translateX(${
          orientation === 'left' ? `-${theme.spacing(6)}` : theme.spacing(6)
        }) translateY(${theme.spacing(6)})`,
    paddingRight: imageOnly
      ? orientation === 'left'
        ? theme.spacing(4.5)
        : 0
      : 0,
    paddingLeft: imageOnly
      ? orientation === 'right'
        ? theme.spacing(4.5)
        : 0
      : 0,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '340px',
      margin: 0,
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      transform: 'none',
    },
    ...(theme.template === V2Templates.boxedUp && {
      borderRadius: theme.boxedUp.borderRadius.default,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.boxedUp.borderRadius.mobile,
      },
      transform: 'none',
    }),
  }),
);

export const StyledScreenshotContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  paddingRight: theme.spacing(9),
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '340px',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: `0 !important`,
    justifyContent: 'flex-end',
  },
}));

export const StyledScreenshotGrid = styled(Grid)(({ theme }) => ({
  height: '440px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const StyledScreenshotImgixImg = styled(ImgixImage)(({ theme }) => ({
  borderLeft: `8px solid ${theme.palette.common.black}`,
  borderRight: `8px solid ${theme.palette.common.black}`,
  borderTop: `8px solid ${theme.palette.common.black}`,
  borderRadius: '21px 21px 0px 0px;',
  height: '440px',
  [theme.breakpoints.down('md')]: { height: 'auto' },
}));

export const StyledTextContainer = styled(Grid)<{
  ownerstate: {
    isTextGreaterThanMinLength: boolean;
    imageType: MobileAppImageType;
  };
}>(({ theme, ownerstate }) => ({
  display: 'flex',
  justifyContent: 'center',
  // to offset the image margin
  marginBottom:
    ownerstate.isTextGreaterThanMinLength ||
    ownerstate.imageType === 'screenshot'
      ? 0
      : theme.spacing(4.5),
  flexDirection: 'column',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

export const StyledTextGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(9),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4.5),
    paddingTop: theme.spacing(1),
  },
}));

export const StyledHeaderContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

export const StyledSubtitleWrapper = styled(Grid)(() => ({
  paddingTop: `0 !important`,
}));

type TypographyWithComponent = React.ComponentType<
  TypographyProps<'span', { component: 'span' }>
>;

export const StyledText: TypographyWithComponent = styled(Typography)`
  pre,
  p,
  li {
    white-space: pre-wrap;
    text-wrap: inherit;
    font: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    margin: 0;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      color: ${({ theme }) => theme.palette.action.active};
    }
    &:focus {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.action.active};
    }
  }
`;

export const StyledOrderButton = styled(MuiButton)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  textTransform: 'none',
  minWidth: '211px',
  ...(theme.template === V2Templates.boxedUp && {
    borderRadius: theme.boxedUp.buttonBorderRadius,
  }),
}));

export const StyledAppBadgeContainer = styled(Grid)(({ theme }) => ({
  gap: `${theme.spacing(2)} !important`,
  marginTop: `${theme.spacing(4)} !important`,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
}));
