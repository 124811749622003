import React from 'react';
import { getTimeTableEntryRows } from '@Src//utility/openingHourUtils';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useConfig } from '@Src/providers/ConfigProvider';
import styled from 'styled-components';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { type OpeningHoursType } from '@Src/middlewares/DesignConfigTypes';
import { type SupportedLanguage } from '@Src/middlewares/utils/constants';

type TimeTableEntryProps = {
  title: string;
  entries: OpeningHoursType[];
};

type ColumnLayout = {
  columnSize: number;
  dividers: JSX.Element;
};

const StyledDays = styled.div<{ $primaryColor: string }>`
  color: ${(props) => props.$primaryColor};
`;

export const getLocalisedDays = (
  days: string,
  translate: (key: string) => string,
): string => {
  const splitDays = days.split(' - ');
  if (splitDays.length === 1) {
    return translate(days?.toLowerCase());
  } else {
    return `${translate(splitDays[0]?.trim()?.toLowerCase())} - ${translate(
      splitDays[1]?.trim()?.toLowerCase(),
    )}`;
  }
};
const addLeadingZero = (num: number): string =>
  (num < 10 ? '0' : '') + num.toString();

const convertTo24HourFormat = (time: string): string => {
  if (!time) {
    return '';
  }

  const [hours, minutes] = time.split(':');
  const hoursInt = parseInt(hours?.replace(/AM|PM/, ''));
  const minutesInt = parseInt(minutes?.replace(/AM|PM/, '') || '0');
  const isPM = time.includes('PM');
  const hoursWithLeadingZero = addLeadingZero(hoursInt);
  const minutesWithLeadingZero = addLeadingZero(minutesInt);

  if (isPM) {
    if (hoursInt === 12) {
      return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
    } else {
      return `${hoursInt + 12}:${minutesWithLeadingZero}`;
    }
  } else {
    if (hoursInt === 12) {
      return `00:${minutesWithLeadingZero}`;
    } else {
      return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
    }
  }
};

export const formatRangeTo24HourFormat = (time: string): string => {
  // handling potentially early and late ranges 9AM - 11AM, 5PM - 11PM
  const [startRange, endRange] = time.split(', ');
  const [startEarly, endEarly] = startRange.split(' - ');
  let endString = '';
  if (endRange) {
    const [endEarly, endLate] = endRange.split(' - ');
    endString = `, ${convertTo24HourFormat(endEarly)} - ${convertTo24HourFormat(
      endLate,
    )}`;
  }
  return `${convertTo24HourFormat(startEarly)} - ${convertTo24HourFormat(
    endEarly,
  )}${endString}`;
};

export const TWELVE_HOUR_TIME_FORMAT_LANGUAGES: Array<
  Partial<SupportedLanguage>
> = ['en', 'en-US'];

const TimeTableEntry = ({
  title,
  entries,
}: TimeTableEntryProps): JSX.Element => {
  const { language } = useConfig();
  const { translate } = useTranslation();
  const { appDesignScheme } = useDesignSchema('v1');
  const rows = getTimeTableEntryRows(entries);

  const getColumnLayout = (len: number): ColumnLayout => {
    let columnSize = 12;
    let dividers = <></>;
    switch (len) {
      case 1:
        columnSize = 12;
        break;
      case 2:
        columnSize = 6;
        dividers = (
          <span className="column-divider my-column-divider divider-short"></span>
        );
        break;
      case 3:
        columnSize = 4;
        dividers = (
          <>
            <span className="column-divider my-column-divider left-column-divider divider-long"></span>
            <span className="column-divider my-column-divider right-column-divider divider-long"></span>
          </>
        );
        break;
      case 4:
        columnSize = 6;
        dividers = (
          <span className="column-divider my-column-divider divider-long"></span>
        );
        break;
      case 5:
        columnSize = 4;
        dividers = (
          <>
            <span className="column-divider my-column-divider left-column-divider divider-long"></span>
            <span className="column-divider my-column-divider right-column-divider divider-long"></span>
          </>
        );
        break;
      case 6:
        columnSize = 4;
        dividers = (
          <>
            <span className="column-divider my-column-divider left-column-divider divider-long"></span>
            <span className="column-divider my-column-divider right-column-divider divider-long"></span>
          </>
        );
        break;
      case 7:
        columnSize = 4;
        dividers = (
          <>
            <span className="column-divider my-column-divider left-column-divider divider-long"></span>
            <span className="column-divider my-column-divider right-column-divider divider-long"></span>
          </>
        );
        break;
      default:
        console.log('Incorrect number of hours');
    }

    return { columnSize, dividers };
  };
  const { columnSize, dividers } = getColumnLayout(entries.length);
  const renderEntryHours = (
    row: OpeningHoursType[],
    rowIndex: number,
  ): JSX.Element[] => {
    const column = rowIndex === 2 ? 12 : columnSize;
    return row.map((entry, index) => {
      const formattedTimeRange =
        entry?.hours.includes('Closed') ||
        TWELVE_HOUR_TIME_FORMAT_LANGUAGES.includes(language)
          ? entry?.hours
          : formatRangeTo24HourFormat(entry?.hours);

      return (
        <React.Fragment key={entry.days}>
          {rowIndex === 0 && index === 1 && dividers}
          <div
            key={entry.days}
            className={`col-md-${column} jt_col column_container`}
          >
            <StyledDays
              className="section-subtitle days"
              $primaryColor={appDesignScheme?.Primary}
            >
              {getLocalisedDays(entry.days, translate)}&nbsp;
            </StyledDays>
            <div className="section-subtitle hours">
              &nbsp;{translate(formattedTimeRange)}
            </div>
          </div>
          {entries.length === 5 && rowIndex === 1 && index === 0 && (
            <div className={`col-md-${column} jt_col column_container`}></div>
          )}
        </React.Fragment>
      );
    });
  };

  const renderTimeTableEntryRows = (): JSX.Element[] =>
    rows.map((row, index) => (
      <div key={`row-${index}`} className="row my-rowopener">
        {renderEntryHours(row, index)}
      </div>
    ));
  return (
    <div className="jt_row jt_row-fluid row visible">
      <div className="col-md-12 jt_col column_container">
        <h2 className="section-title">
          <span className="timetable-decorator"></span>
          <span className="opening-hours">{title}</span>
          <span className="timetable-decorator2"></span>
        </h2>
      </div>
      {renderTimeTableEntryRows()}
    </div>
  );
};

export default TimeTableEntry;
