import { styled } from '@mui/material/styles';

type StyledListProps = {
  gap: string;
  isHorizontal?: boolean;
  height?: string;
  maxHeight?: string;
};

const StyledList = styled('div')<StyledListProps>`
  height: ${({ height }) => height || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  overflow-x: auto;
  overflow-y: auto;
  gap: ${({ gap }) => gap};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledRoot = styled('div')`
  display: 'flex';
  flex: 1;
  flex-direction: 'column';
  padding: 1.5, 1.875, 1.875, 1.875;
`;

export type Props = {
  action?: () => void;
  actionText?: string;
  dataFd?: string;
  listItems: JSX.Element[];
  listName?: string;
  title?: string;
  gap?: string;
  isHorizontal?: boolean;
  height?: string;
  maxHeight?: string;
};

export const HorizontalList = (props: Props): JSX.Element => {
  const {
    dataFd = 'horizontal-list',
    gap = '10px',
    listItems,
    isHorizontal = true,
    height,
    maxHeight,
  } = props;

  return (
    <StyledRoot data-fd={dataFd}>
      {!!listItems.length && (
        <StyledList
          gap={gap}
          data-fd="horizontal-list-list"
          className="scroll-list"
          isHorizontal={isHorizontal}
          height={height}
          maxHeight={maxHeight}
        >
          {listItems}
        </StyledList>
      )}
    </StyledRoot>
  );
};
