import { formatPrice } from '@Src/utility/priceUtils';
import { type Menu, type MenuItem, type MenuItemHighlight } from './types';

const MAX_ITEMS = 10;
export const PLACEHOLDER_IMG =
  'https://flipdish.imgix.net/byen94KSFwIxpBmvrmhCG1TjTU.svg';

export const formatMenuHighlights = ({
  currency,
  language,
  menu,
}: {
  currency: string;
  language: string;
  menu: Menu;
}): MenuItemHighlight[] => {
  const availableSections = menu.MenuSections.filter(
    (section) => section.IsAvailable && !section.IsHiddenFromUsers,
  ).sort((a, b) => (a.DisplayOrder > b.DisplayOrder ? 1 : -1));

  const itemsToDisplay: MenuItem[] = [];

  availableSections.forEach((section) => {
    section.MenuItems.forEach((item) => {
      if (item.IsAvailable && itemsToDisplay.length !== MAX_ITEMS) {
        let imageUrl = item.ImageUrl;
        if (!item.ImageUrl) {
          imageUrl = PLACEHOLDER_IMG;
        }
        itemsToDisplay.push({ ...item, ImageUrl: imageUrl });
      }
    });
  });

  const formattedItems = itemsToDisplay.map((item) => {
    let price = item.ActualPrice;

    // if item has a master option set, set price to the cheapest option in the master option set
    const masterOptionSet = item.MenuItemOptionSets.find(
      (optionSet) => optionSet.IsMasterOptionSet,
    );

    if (masterOptionSet) {
      const availableMasterOptionSetItems =
        masterOptionSet.MenuItemOptionSetItems.filter(
          (option) => option.IsAvailable,
        );

      if (availableMasterOptionSetItems.length > 0) {
        const cheapestOption = availableMasterOptionSetItems.reduce(
          (prev, curr) => (prev.Price < curr.Price ? prev : curr),
        );
        price = cheapestOption.Price;
      }
    }

    const formattedPrice = formatPrice(
      price,
      currency,
      language,
      true,
      'narrowSymbol',
    );

    return {
      description: item.Description,
      imageUrl: item.ImageUrl,
      isFromPrice: !!item.MenuItemOptionSets?.length,
      itemId: item.MenuItemId,
      name: item.Name,
      price: formattedPrice,
    };
  }) as MenuItemHighlight[];

  return formattedItems;
};
