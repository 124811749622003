import { useState } from 'react';
import styled from 'styled-components';
import { FullscreenGallery } from '@Components/Widgets/Gallery/FullscreenGallery';
import { useTranslation } from '@Providers/TranslationProvider';
import FadeInContainer from '../FadeInContainer';
import { ImgixImage } from '@Components/ui/ImgixImage';

const StyledDiv = styled.div`
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .gallery__item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const StyledArticle = styled.article`
  overflow: hidden;
  & img {
    transition: transform 330ms ease-in-out;
  }
  &:hover img {
    transform: scale(1.2);
  }
`;

export const Gallery = ({ images }: { images: string[] }): JSX.Element => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { translate } = useTranslation();
  if (!images) {
    return <></>;
  }

  const getFormattedImageUrl = (imageUrl: string): string =>
    `${imageUrl}?w=700&h=470&fit=crop&crop=entropy`;

  const buildGalleryImage = (imageUrl: string, index: number): JSX.Element => (
    <StyledArticle
      key={index}
      onClick={() => {
        setIsFullscreenOpen(true);
        setSelectedIndex(index);
      }}
      tabIndex={0}
      className="gallery__item entry burgers swipebox"
    >
      <ImgixImage
        tabIndex={-1}
        src={getFormattedImageUrl(imageUrl)}
        className="img-responsive"
        alt="gallery-images"
        loading="lazy"
      />
      <span className="magnifier" />
    </StyledArticle>
  );

  return (
    <section id="gallery" className="section gallery dark">
      <FadeInContainer className="jt_row jt_row-fluid row visible">
        <div className="col-md-12 jt_col column_container">
          <h2 className="section-title" tabIndex={0}>
            {translate('Gallery')}
          </h2>
          <div className="col-md-6 col-md-offset-3 jt_col column_container">
            <div className="section-subtitle"></div>
          </div>
        </div>
        <div className="col-md-12 jt_col column_container">
          <StyledDiv>
            {images.map((url, index) => buildGalleryImage(url, index))}
          </StyledDiv>
        </div>
      </FadeInContainer>
      {isFullscreenOpen && (
        <FullscreenGallery
          images={images}
          startIndex={selectedIndex}
          onClose={() => {
            setIsFullscreenOpen(false);
          }}
        />
      )}
    </section>
  );
};
