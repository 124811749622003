import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as DeliveryAddressIcon } from '@Src/assets/images/location.svg';
import { ReactComponent as DeliveryAddressIconFilled } from '@Src/assets/images/LocationFilled.svg';

type AddressListItemPropsType = {
  firstLine: string;
  secondLine?: string;
  isSelected?: boolean;
  isHovered?: boolean;
};

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledDeliveryAddressIcon = styled(DeliveryAddressIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const StyledSelectedDeliveryAddressIcon = styled(DeliveryAddressIconFilled)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const StyledSecondaryText = styled(Typography)<{
  ownerState: { isHovered?: boolean };
}>(({ ownerState, theme }) => ({
  color: ownerState.isHovered
    ? theme.palette.getContrastText(
        theme.palette.primary.lightest || theme.palette.primary.main,
      )
    : theme.palette.secondary.body,
}));

export function AddressListItem({
  firstLine,
  secondLine,
  isSelected,
  isHovered,
}: AddressListItemPropsType): JSX.Element {
  return (
    <StyledContainer>
      {isSelected ? (
        <StyledSelectedDeliveryAddressIcon data-fd="address-icon" />
      ) : (
        <StyledDeliveryAddressIcon data-fd="address-icon" />
      )}
      <div>
        <Typography variant="body1Medium">{firstLine}</Typography>
        {secondLine && (
          <StyledSecondaryText
            variant="body2"
            ownerState={{ isHovered: isHovered && !isSelected }}
          >
            {secondLine}
          </StyledSecondaryText>
        )}
      </div>
    </StyledContainer>
  );
}
