import { styled } from '@mui/material/styles';
import { type LandingPageType } from '@Src/middlewares/CustomPageTypes';
import Highlight from '../Highlight';
import { OrderSettings } from '@Components/Widgets/OrderSettings';
import Apps from '@Components/Widgets/Apps';
import MenuHighlights from '../Menu';
import Testimonials from '@Components/Widgets/Testimonials';
import Gallery from '@Components/Widgets/Gallery';
import Reorder from '@Components/Widgets/Reorder';
import LocationsWidget from '@Components/Widgets/Locations';
import { InnerGridPolice, StyledBaseContainer } from '../BaseGridElements';
import { Link } from '@Components/RoutesUtils';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { ImgixImage } from '@Components/ui/ImgixImage';
import MobileAppBadges from '@Components/Widgets/Apps/MobileAppBadges';
import { useTranslation } from '@Src/providers/TranslationProvider';
import LinkButton from '@Components/ui/LinkButton';

const StyledContainer = styled('div')<{
  backgroundImage: string;
  isBannerEnabled: boolean;
}>(({ theme, backgroundImage, isBannerEnabled }) => ({
  // 146 is banner + header
  height: `calc(100vh - ${isBannerEnabled ? '146px' : '88px'})`,
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${backgroundImage}?upscale=true&w=700&h=800)`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${backgroundImage}?upscale=true&w=1400&h=900)`,
  },
}));

const StyledImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  flexBasis: '400px',
}));

const StyledLogoLink = styled(Link)(() => ({
  display: 'block',
  width: '150px',
  height: '150px',
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

const StyledOrderSettings = styled('div')(({ theme }) => ({
  minHeight: '250px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

const StyledButtonLink = styled(LinkButton)(({ theme }) => ({
  display: 'block',
  width: '300px',
  '& span': {
    textTransform: 'uppercase',
  },
  padding: theme.spacing(2, 3),
  border: '2px solid',
  borderColor: theme.palette.primary.contrastText,
  borderWidth: theme.spacing(0.5),
}));

const StyledMobileAppBadges = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: theme.spacing(3),
}));

const OrderNowButton = ({ label }: { label: string }): JSX.Element => (
  <div>
    <StyledButtonLink href="/order" label={label} />
    <StyledMobileAppBadges>
      <MobileAppBadges />
    </StyledMobileAppBadges>
  </div>
);
export const Classic = ({ data }: { data: LandingPageType }): JSX.Element => {
  const backgroundImage = data?.backgroundImages?.[0] || '';
  const { config, banner } = useDesignSchema('v2');
  const { translate } = useTranslation();
  const isBannerEnabled = !!(
    banner?.enabled &&
    (banner?.title || banner?.subTitle)
  );
  const noOderSettings = !!data?.showOrderOnlineButton;

  return (
    <>
      <StyledContainer
        className="item"
        key={`image`}
        backgroundImage={backgroundImage}
        isBannerEnabled={isBannerEnabled}
      >
        <StyledImageContainer>
          {config?.logo && (
            <StyledLogoLink to="/order">
              <ImgixImage src={`${config?.logo}?w=300`} alt="logo" />
            </StyledLogoLink>
          )}
          {noOderSettings ? (
            <OrderNowButton label={translate('Order_Online')} />
          ) : (
            <StyledOrderSettings>
              <OrderSettings mobileLayout />
            </StyledOrderSettings>
          )}
        </StyledImageContainer>
      </StyledContainer>
      <StyledBaseContainer>
        <Reorder />
        <InnerGridPolice shouldOverFlowMobile>
          <MenuHighlights dataFromStoreId />
        </InnerGridPolice>
        {data.highlight?.enabled && (
          <Highlight data={data?.highlight} smallGrid isPlainSection />
        )}
        {data.testimonialsEnabled && (
          <InnerGridPolice shouldOverFlowMobile>
            <Testimonials />
          </InnerGridPolice>
        )}
        {data.mobileAppsEnabled && <Apps smallGrid isPlainSection />}
        {data.galleryEnabled && (
          <InnerGridPolice shouldOverFlowMobile>
            <Gallery />
          </InnerGridPolice>
        )}
        {data.locationsEnabled && (
          <InnerGridPolice>
            <LocationsWidget showSubTitle showTitle />
          </InnerGridPolice>
        )}
      </StyledBaseContainer>
    </>
  );
};
