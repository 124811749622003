import { fetchStoreLeadTimes } from '@Src/thunks/storeLeadTimesThunks';
import { createSlice } from '@Src/utility/reduxUtils';

type StoreLeadTimesState = {
  leadTimes: Record<string, { collection: number; delivery: number }>;
};

export const initialState: StoreLeadTimesState = {
  leadTimes: {},
};

export const storeLeadTimeSlice = createSlice({
  name: 'storeLeadTimes',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoreLeadTimes.fulfilled, (state, response) => {
      state.leadTimes = { ...state.leadTimes, ...response.payload };
      state.fetched = true;
      state.loading = false;
    });
    builder.addCase(fetchStoreLeadTimes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStoreLeadTimes.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});

export default storeLeadTimeSlice.reducer;
