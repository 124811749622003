import { fetchDeliveryStoreSummaries } from '@Src/thunks/deliveryStoresThunks';
import { type DeliveryStoreSummary } from '@Src/types/deliveryStoreTypes';
import { createSlice } from '@Src/utility/reduxUtils';

type DeliveryStoresStateType = {
  selectedDeliveryStore?: DeliveryStoreSummary;
  deliveryStoreList: DeliveryStoreSummary[];
};

export const initialState: DeliveryStoresStateType = {
  deliveryStoreList: [],
  selectedDeliveryStore: undefined,
};
export const deliveryStoresSlice = createSlice({
  name: 'deliveryStores',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDeliveryStoreSummaries.fulfilled,
      (state, response) => {
        state.deliveryStoreList = response.payload;
        state.selectedDeliveryStore = response.payload.find(
          (store) => !store.UserOutsideDeliveryZone,
        );
        state.fetched = true;
        state.loading = false;
      },
    );
    builder.addCase(fetchDeliveryStoreSummaries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeliveryStoreSummaries.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});
export default deliveryStoresSlice.reducer;
