import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { type CSSProperties, useContext } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { type MenuItemHighlight } from '../types';
import MenuItemCard from './MenuItemCard';
import ScrollingMenuHeader from '@Components/ui/ScrollingMenuHeader';
import '@Components/ui/ScrollingMenuHeader/hideScrollBar.css';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Button } from '@mui/material';

type StyledContainerProps = {
  ownerState: {
    minHeight: CSSProperties['minHeight'];
    borderRadius?: string;
    paddingTop?: string;
    paddingLeft?: string;
    paddingBottom?: string;
  };
};

const StyledContainer = styled('div')<StyledContainerProps>(
  ({ ownerState, theme }) => ({
    minHeight: ownerState.minHeight,
    backgroundColor: theme.palette.background.default,
    borderRadius: ownerState.borderRadius,
    [theme.breakpoints.up('sm')]: {
      paddingTop: ownerState.paddingTop || 0,
      paddingLeft: ownerState.paddingLeft,
      paddingBottom: ownerState.paddingBottom || 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: ownerState.paddingTop || 0,
      paddingLeft: ownerState.paddingLeft,
      paddingBottom: ownerState.paddingBottom,
    },
  }),
);

const StyledMenuCardWrapper = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
  height: '100%',
}));

const StyledButton = styled(Button)<{
  ownerState: {
    borderRadius?: string;
    borderWidth?: string;
    textColor?: string;
  };
}>(({ ownerState, theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: ownerState.borderRadius || theme.spacing(1),
  borderWidth: ownerState.borderWidth || '1px',
  borderColor: theme.palette.primary.main,
  color: ownerState.textColor,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    marginBottom: 0,
  },
  '&:hover': {
    borderWidth: ownerState.borderWidth || '1px',
  },
}));

type MenuSliderProps = {
  menuItems: MenuItemHighlight[];
  overrideClickAction?: () => void;
  url: string;
  borderRadius?: string;
  buttonBorderRadius?: string;
  buttonTextColor?: string;
  menuCardBorderRadius?: string;
  menuCardBorderWidth?: string;
  menuCardImageBorderRadius?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingBottom?: string;
};

const MenuSlider: React.FC<MenuSliderProps> = ({
  menuItems,
  overrideClickAction,
  url,
  borderRadius,
  buttonBorderRadius,
  buttonTextColor,
  menuCardBorderRadius,
  menuCardBorderWidth,
  menuCardImageBorderRadius,
  paddingTop,
  paddingLeft,
  paddingBottom,
}): JSX.Element => {
  const { menuHighlights } = useDesignSchema('v2');
  const { translate } = useTranslation();
  const mItems = [...menuItems, {}] as MenuItemHighlight[];

  const handleViewMenuClick = (): void => {
    if (overrideClickAction) {
      overrideClickAction();
    }
  };
  return (
    <StyledContainer
      id="menu-highlights"
      data-fd="menu-highlights"
      ownerState={{
        minHeight: menuHighlights?.showImages ? '355px' : '186px',
        borderRadius,
        paddingTop,
        paddingLeft,
        paddingBottom,
      }}
    >
      {menuItems.length > 0 && (
        <ScrollMenu
          Header={() => {
            const {
              scrollPrev,
              scrollNext,
              isLastItemVisible,
              isFirstItemVisible,
            } = useContext(VisibilityContext);
            return (
              <ScrollingMenuHeader
                title={translate(menuHighlights?.title || '')}
                subTitle={translate(menuHighlights?.subTitle || '')}
                leftButtonDisabled={isFirstItemVisible}
                rightButtonDisabled={isLastItemVisible}
                actionButton={
                  <StyledButton
                    ownerState={{
                      borderRadius: buttonBorderRadius,
                      borderWidth: menuCardBorderWidth,
                      textColor: buttonTextColor,
                    }}
                    variant="outlined"
                    onClick={handleViewMenuClick}
                    href={overrideClickAction ? '' : url}
                  >
                    {translate('View_full_menu')}
                  </StyledButton>
                }
                goBack={() => {
                  scrollPrev('smooth', 'nearest');
                }}
                goForward={() => {
                  scrollNext('smooth', 'nearest');
                }}
              />
            );
          }}
        >
          {mItems.map((item, idx) => (
            <StyledMenuCardWrapper key={idx}>
              <MenuItemCard
                {...item}
                overrideClickAction={overrideClickAction}
                to={`${
                  url.endsWith('order') ? url : `${url}?item=${item.itemId}`
                }`}
                showViewAllCard={idx === mItems.length - 1}
                storeUrl={url}
                showImage={menuHighlights?.showImages}
                borderRadius={menuCardBorderRadius}
                borderWidth={menuCardBorderWidth}
                imageBorderRadius={menuCardImageBorderRadius}
              />
            </StyledMenuCardWrapper>
          ))}
        </ScrollMenu>
      )}
    </StyledContainer>
  );
};

export default MenuSlider;
