import { ReactComponent as ArrowRight } from '@Src/assets/images/ArrowRightSmall.svg';
import { type MenuItemHighlight } from '../types';
import { ImgixImage } from '@Components/ui/ImgixImage';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useTracking } from '@Src/hooks/useTracking';

type StyledContainerProps = {
  ownerState: {
    borderRadius?: string;
    borderWidth?: string;
  };
};
const StyledContainer = styled(Grid)<StyledContainerProps>(
  ({ theme, ownerState }) => ({
    borderColor: theme.palette.secondary.lowContrast,
    borderWidth: ownerState.borderWidth || '1px',
    borderStyle: 'solid',
    borderRadius: ownerState.borderRadius || '8px',
    padding: '12px',
    maxWidth: '226px',
    minWidth: '200px',
  }),
);

type StyledImgixImageProps = {
  ownerState: {
    borderRadius?: string;
  };
};

const StyledImgixImage = styled(ImgixImage)<StyledImgixImageProps>(
  ({ theme, ownerState }) => ({
    borderRadius: ownerState.borderRadius,
    minHeight: '200px',
    minWidth: '200px',
  }),
);

const StyledDetails = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 12px;
`;

const StyledCopyContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 18px;
`;

const StyledName = styled(Typography)`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: 'ellipsis';
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledDescription = styled(Typography)`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.palette.secondary.body};
  display: -webkit-box;
  min-height: 36px;
  overflow: hidden;
  text-overflow: 'ellipsis';
`;

const StyledFooter = styled(Grid)`
  align-items: end;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledPriceContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const StyledArrowRight = styled(ArrowRight)`
  margin-left: 4px;
`;

const StyledViewAllContainer = styled(StyledContainer)<StyledContainerProps>(
  () => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const StyledViewAllText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledViewAllArrowRight = styled(ArrowRight)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(0.5),
}));

const StyledFromText = styled(Typography)<{
  ownerState: { isVisible: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.secondary.subtle,
  visibility: ownerState.isVisible ? 'visible' : 'hidden',
}));

const StyledPrice = styled(Grid)`
  align-items: center;
  display: flex;
`;

export type MenuItemCardProps = {
  overrideClickAction?: () => void;
  to: string;
  showImage?: boolean;
  showViewAllCard?: boolean;
  storeUrl: string;
  borderRadius?: string;
  borderWidth?: string;
  imageBorderRadius?: string;
} & MenuItemHighlight;

const MenuItemCard = ({
  description,
  imageUrl,
  isFromPrice,
  name,
  overrideClickAction,
  price,
  showImage,
  showViewAllCard,
  storeUrl,
  to,
  borderRadius,
  borderWidth,
  imageBorderRadius,
}: MenuItemCardProps): JSX.Element => {
  const { translate } = useTranslation();
  const { trackOrderEvent } = useTracking();

  const handleCardClick = (): void => {
    trackOrderEvent('Menu Item Selected', {
      itemName: name,
      itemPrice: price,
      hasImage: String(imageUrl !== undefined && imageUrl !== null),
      hasDescription: String(description !== undefined && description !== null),
    });

    overrideClickAction?.();
  };
  return (
    <>
      {!showViewAllCard ? (
        <CardActionArea
          onClick={handleCardClick}
          href={overrideClickAction ? '' : to}
          rel="noopener noreferrer"
          style={{
            borderRadius,
          }}
        >
          <StyledContainer ownerState={{ borderRadius, borderWidth }}>
            {showImage && (
              <StyledImgixImage
                ownerState={{ borderRadius: imageBorderRadius }}
                alt={name}
                loading="lazy"
                src={`${imageUrl}?w=200&h=200`}
                tabIndex={-1}
              />
            )}
            <StyledDetails>
              <StyledCopyContainer>
                <StyledName variant="h3xs">{name}</StyledName>
                <StyledDescription variant="body2">
                  {description}
                </StyledDescription>
              </StyledCopyContainer>

              <StyledFooter>
                <StyledPriceContainer>
                  <StyledFromText
                    ownerState={{ isVisible: isFromPrice }}
                    variant="finePrint2Medium"
                  >
                    {translate('from').toUpperCase()}
                  </StyledFromText>

                  <StyledPrice>
                    <Typography variant="h3xs">{price}</Typography>
                    <StyledArrowRight height={12} width={12} />
                  </StyledPrice>
                </StyledPriceContainer>
              </StyledFooter>
            </StyledDetails>
          </StyledContainer>
        </CardActionArea>
      ) : (
        <CardActionArea
          href={overrideClickAction ? '' : storeUrl}
          onClick={() => overrideClickAction?.()}
          rel="noopener noreferrer"
          style={{
            height: '100%',
            width: '200px',
            borderRadius,
          }}
        >
          <StyledViewAllContainer ownerState={{ borderRadius, borderWidth }}>
            <StyledViewAllText variant="buttonSmall">
              {translate('View_full_menu')}
            </StyledViewAllText>
            <StyledViewAllArrowRight height={12} width={12} />
          </StyledViewAllContainer>
        </CardActionArea>
      )}
    </>
  );
};

export default MenuItemCard;
