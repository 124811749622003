import { type LoggerType, useLogger } from '@Src/providers/LoggerProvider';
import React, { type ReactNode } from 'react';

type Props = {
  children: ReactNode;
  identifier: string;
  logger: LoggerType;
};

type State = {
  hasError: boolean;
};

export const WithLoggerHOC =
  // eslint-disable-next-line react/display-name
  (Component: typeof ErrorBoundary) => (props: any) => {
    const logger = useLogger();

    return <Component logger={logger} {...props} />;
  };

WithLoggerHOC.displayName = 'WithLoggerHOC';

export class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: any): void {
    const { identifier } = this.props;
    this.props.logger?.error(`${identifier} Error Boundary`, {
      message: error.message,
      stack: errorInfo.componentStack,
      ...error,
    });
  }

  public render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <div>Something went wrong</div>;
    }

    return children;
  }
}

export default WithLoggerHOC(ErrorBoundary);
