import { createSlice } from '@Src/utility/reduxUtils';

type OrderUrlStateType = {
  path: string;
};

export const initialState: OrderUrlStateType = {
  path: '',
};

export const orderUrlSlice = createSlice({
  name: 'orderUrl',
  initialState,
  reducers: {
    setOrderUrl: (
      state,
      action: {
        type: string;
        payload: string;
      },
    ) => ({
      ...state,
      path: action.payload,
    }),
  },
});

export const { setOrderUrl } = orderUrlSlice.actions;
export default orderUrlSlice.reducer;
