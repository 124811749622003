import { type HqContactPageType } from '@Src/middlewares/CustomPageTypes';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Providers/TranslationProvider';
import styled from 'styled-components';
import { ImgixImage } from '@Components/ui/ImgixImage';

const StyledHqContactWrapper = styled.div`
  max-width: 700px;
  margin: 50px auto 50px auto;
  height: 620px;

  .hqContactContent {
    width: 60%;
    float: left;
    padding-right: 10px;
    padding-left: 15px;
  }

  .hqContactContent p {
    margin-bottom: 10px;
    padding-left: 10px;
  }

  p.hqContactHeading {
    font-weight: bold;
    padding-left: 0px;
  }

  .hqContactImg {
    width: 40%;
    clear: none;
    float: left;
    padding: 0px 15px;
  }

  @media only screen and (max-width: 767px) {
    .hqContactContent p {
      text-align: left !important;
    }
  }

  @media only screen and (max-width: 568px) {
    height: 880px;

    .hqContactContent {
      width: 100%;
      float: left;
      padding-right: 10px;
      padding-left: 10px;
    }

    .hqContactImg {
      width: 100%;
      clear: none;
      float: left;
      padding: 10px 40px;
    }
  }
`;

export type EmptyPageProps = {
  data: HqContactPageType;
};
export const HqContactPage = (props: EmptyPageProps): JSX.Element => {
  const { data } = props;
  const { translate } = useTranslation();
  const { logo } = useDesignSchema('v1');

  return (
    <section id="empty" className="section">
      <StyledHqContactWrapper id="hqContactWrapper">
        <h1>{translate('Legal_information')}</h1>
        <div className="hqContactContent">
          {data.CompanyName && (
            <p className="hqContactHeading">{data.CompanyName}</p>
          )}
          {data.AddressLine1 && <p>{data.AddressLine1}</p>}
          {data.AddressLine2 && <p>{data.AddressLine2}</p>}
          {data.Phone && <p>{`${translate('Phone')}: ${data.Phone}`}</p>}
          {data.Email && <p>{`${translate('Email')}: ${data.Email}`}</p>}
          {data.CeoFullName && (
            <>
              <p className="hqContactHeading">{translate('Business_owner')}</p>
              <p>{data.CeoFullName}</p>
            </>
          )}
          {data.RegisterCourt && (
            <>
              <p className="hqContactHeading">{translate('Register_court')}</p>
              <p>{data.RegisterCourt}</p>
            </>
          )}
          {data.VatNumber && (
            <>
              <p className="hqContactHeading">{translate('Vat_number')}</p>
              <p>{data.VatNumber}</p>
            </>
          )}
          {data.TaxNumber && (
            <>
              <p className="hqContactHeading">{translate('Tax_number')}</p>
              <p>{data.TaxNumber}</p>
            </>
          )}
          {data.CompanyRegistrationNumber && (
            <>
              <p className="hqContactHeading">
                {translate('Company_registration_number')}
              </p>
              <p>{data.CompanyRegistrationNumber}</p>
            </>
          )}
        </div>
        <div className="hqContactImg">
          <ImgixImage src={logo} alt={translate('This_image_is_our_logo')} />
        </div>
      </StyledHqContactWrapper>
    </section>
  );
};

export default HqContactPage;
