import { createSlice } from '@Src/utility/reduxUtils';

type UserStateType = {
  isLoggedIn: boolean;
};

const initialState: UserStateType = {
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (
      state,
      action: {
        type: string;
        payload: boolean;
      },
    ) => ({
      ...state,
      isLoggedIn: action.payload,
    }),
  },
});

export const { setIsLoggedIn } = userSlice.actions;
export default userSlice.reducer;
