import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { ReactComponent as HamburgerMenuIcon } from '@Src/assets/images/elixir/hamburger-menu.svg';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Providers/TranslationProvider';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import { buildSocialMediaIconList } from '../SocialMedia/SocialMedia';
import { useLocation } from 'react-router';
import {
  useCustomPageSchema,
  useEnabledIndexSections,
  usePageLinkSchema,
} from '@Providers/CustomPageSchemaProvider';
import {
  PageType,
  type V1PageLinkType,
} from '@Src/middlewares/CustomPageTypes';
import { Link } from '@Components/RoutesUtils';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useTracking } from '@Src/hooks/useTracking';

const StyledSkipNavigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;

const StyledLogo = styled.div<{ logo: string }>`
  background: url('${(props) => props.logo}');
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledPageLink = styled(Link)`
  text-transform: uppercase !important;
  font-size: 14px;
  font-family: 'Bitter', sans-serif;
  line-height: 6px;
  border-bottom: 3px solid transparent;
  text-shadow: 0px 0px 1px transparent;
  //double ampersand to override hover styles from bootstrap
  &&:hover {
    text-shadow: 0px 0px 1px transparent;
    background-color: transparent;
  }
  &&:focus {
    text-shadow: 0px 0px 1px transparent;
    background-color: transparent;
  }
`;

const StyledHeader = styled.header`
  z-index: 1002 !important;
  box-shadow: 1px 1px #0000001f;
`;

export const Header = (): JSX.Element => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [iconColor, setIconColor] = useState<string>('');
  const { logo, headerLinks, socialMedia, locationPageEnabled } =
    useDesignSchema('v1');
  const pageHeaderLinks = usePageLinkSchema('header') as V1PageLinkType[];
  const pageData = useCustomPageSchema('v1');
  const {
    language,
    supportedLanguages = [],
    isCustomDomain,
    appId,
    vanityUrlSuffix,
  } = useConfig();
  const [currentLanguage, setCurrentLanguage] = useState<string>(language);
  const { gallery: gallerySectionEnabled, contactForm: contactSectionEnabled } =
    useEnabledIndexSections();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { translate } = useTranslation();
  const { trackEvent } = useTracking();

  const toggleMobileNavVisibility = (): void => {
    setIsNavOpen((prevIsNavOpen) => !prevIsNavOpen);
  };

  const handleLanguageChange = (value: string): void => {
    let cookiePath = '/';
    // Need to set cookie path with appId in case of panacea URLs
    if (!isCustomDomain) {
      if (vanityUrlSuffix) {
        cookiePath = `/${vanityUrlSuffix}`;
      } else {
        cookiePath = `/${appId}`;
      }
    }
    const sixMonthsDate = new Date();
    sixMonthsDate.setMonth(sixMonthsDate.getMonth() + 6); // Expires in 6 months
    Cookies.set('flipdish-customer-language', value, {
      expires: sixMonthsDate,
      path: cookiePath,
    });
    setCurrentLanguage(value);
    // Reload in next tick.
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  useEffect(() => {
    const handleHashChange = (): void => {
      try {
        if (!location.pathname.includes('/order')) {
          const hashFromUrl = window.location.hash.split('?')[0];
          const activeElement = document.querySelector(
            `[href="/${hashFromUrl}"]`,
          );
          document
            .querySelector(`#main-menu .current`)
            ?.classList.remove('current');
          activeElement?.closest('li')?.classList.add('current');
        }
      } catch (e) {
        console.log('Issue in header class.');
      }
    };
    window.addEventListener('hashchange', () =>
      setTimeout(handleHashChange, 50),
    );
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    const firstNotSelectedHeaderTextElement = Array.from(
      document.querySelectorAll('.header-text'),
    )?.filter(
      // @ts-expect-error ClassList is not defined on PraentNode but is for the header elements
      (element) => !element?.parentNode?.classList?.contains('current'),
    )?.[0];
    const color =
      firstNotSelectedHeaderTextElement &&
      getComputedStyle(firstNotSelectedHeaderTextElement)?.color;

    color && setIconColor(color);
  }, []);

  const orderClasses = `page-scroll menu-item ${
    location.pathname === '/order' ? 'current' : ''
  }`;

  const locationsClasses = `page-scroll menu-item ${
    location.pathname === '/contact' ? 'current' : ''
  }`;
  useEffect(() => {
    isMobile && isNavOpen && toggleMobileNavVisibility();
  }, [location]);

  const isIndexPageIndexType = pageData.index?.PageType === PageType.IndexPage;
  // @ts-expect-error Doing this only for index page so aboutUs is defined
  const hasAboutUsSection = pageData.index?.aboutUs;

  const trackClick = (): void => {
    trackEvent('Click to order', {
      category: 'Header',
      type: 'Start Order',
    });
  };

  return (
    <StyledHeader id="header" role="banner" className="">
      <div className="jt_row container visible">
        <div className="navbar-header page-scroll">
          <StyledSkipNavigation>
            <Link to="#skip-to-content">Skip navigation</Link>
          </StyledSkipNavigation>
          <button
            type="button"
            className="navbar-toggle"
            onClick={toggleMobileNavVisibility}
            aria-haspopup="true"
            aria-label="Access mobile menu"
          >
            <HamburgerMenuIcon />
          </button>
          <Link
            className="navbar-brand normal logo"
            to="/"
            aria-label="Our logo, click here to go to home page"
          >
            <StyledLogo className="logo_elixir" logo={logo} />
          </Link>
        </div>
        <nav
          className={`collapse navbar-collapse navbar-right navbar-main-collapse ${
            isNavOpen ? 'in' : ''
          }`}
          role="navigation"
          id="main-menu"
        >
          <ul id="nav" className="nav navbar-nav navigation">
            {pageHeaderLinks.map(({ path, title, pageType }) => {
              const isCurrent =
                location.pathname === path ||
                (pageType === PageType.IndexPage && location.pathname === '/');
              const linkClasses = `page-scroll menu-item ${
                isCurrent ? 'current' : ''
              }`;
              return (
                <li key={path} className={linkClasses}>
                  <StyledPageLink className="header-text" to={path}>
                    {translate(title)}
                  </StyledPageLink>
                </li>
              );
            })}
            {headerLinks?.aboutUs &&
              hasAboutUsSection &&
              isIndexPageIndexType && (
                <li className="page-scroll menu-item">
                  <StyledPageLink className="header-text" to="/#aboutSection">
                    {translate('About')}
                  </StyledPageLink>
                </li>
              )}
            {headerLinks?.gallery &&
              gallerySectionEnabled &&
              isIndexPageIndexType && (
                <li className="page-scroll menu-item">
                  <StyledPageLink className="header-text" to="/#gallery">
                    {translate('Gallery')}
                  </StyledPageLink>
                </li>
              )}
            {headerLinks?.reservations && isIndexPageIndexType && (
              <li className="page-scroll menu-item">
                <StyledPageLink className="header-text" to="/#reservations">
                  {translate('Reservations')}
                </StyledPageLink>
              </li>
            )}
            {headerLinks?.contactForm &&
              contactSectionEnabled &&
              isIndexPageIndexType && (
                <li className="page-scroll menu-item">
                  <StyledPageLink className="header-text" to="/#contact">
                    {translate('Contact')}
                  </StyledPageLink>
                </li>
              )}
            {locationPageEnabled && (
              <li className={locationsClasses}>
                <StyledPageLink className="header-text" to="/contact">
                  {translate('Contact_location_page')}
                </StyledPageLink>
              </li>
            )}
            <li className={orderClasses}>
              <StyledPageLink
                className="header-text"
                to="/order"
                onClick={trackClick}
              >
                {translate('Order_Online')}
              </StyledPageLink>
            </li>
            {buildSocialMediaIconList(socialMedia, false, isMobile, iconColor)}
            {supportedLanguages.length > 1 && (
              <li className={locationsClasses}>
                <select
                  id="languageSelector"
                  aria-label={translate('Select_language_from_dropdown_menu')}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                  value={currentLanguage}
                >
                  {supportedLanguages.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}{' '}
                    </option>
                  ))}
                </select>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </StyledHeader>
  );
};

export default Header;
