import {
  initializeAmplitude,
  optOutAmplitude,
  useTracking,
} from '@Src/hooks/useTracking';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useEffect } from 'react';

export const CookieConsent = (): JSX.Element => {
  const { language, isEditorPreview, isPreview } = useConfig();
  const designSchema = useDesignSchema() || {};
  const { identifyUser } = useTracking();

  const name = designSchema.name;
  const isV2Schema = designSchema.schemaVersion === 'v2';
  const logo = isV2Schema ? designSchema?.config?.logo : designSchema.logo;
  const policyUrls = isV2Schema
    ? designSchema?.config?.policyUrls
    : designSchema.policyUrls;
  const cookieConsentGDPREnabled = isV2Schema
    ? designSchema?.config?.cookieConsentGDPREnabled
    : designSchema.cookieConsent?.cookieConsentGDPREnabled;
  const cookieConsentPromptEnabled = isV2Schema
    ? designSchema?.config?.cookieConsentPromptEnabled
    : designSchema.cookieConsent?.cookieConsentPromptEnabled;

  const { translate } = useTranslation();

  const handleCookieConsentChange = (): void => {
    if (isEditorPreview || isPreview) {
      return;
    }
    const permittedCookies = window.fdCc?.getConsentCookies() || [];
    const isAdvertisingEnabled = permittedCookies.includes('advertising');
    const isAnalyticsEnabled = permittedCookies.includes('performance');
    window.updateFacebookConsent?.(isAdvertisingEnabled);
    window.updateGtagConsent?.(isAnalyticsEnabled, isAdvertisingEnabled);
    if (isAnalyticsEnabled) {
      initializeAmplitude();
      identifyUser();
    } else {
      optOutAmplitude();
    }
  };

  useEffect(() => {
    if (cookieConsentGDPREnabled) {
      window.fdCc?.init(
        {
          lang: language,
          restaurantName: name,
          logoUrl: logo,
          cookiePolicyUrl: policyUrls?.cookie || 'cookiepolicy',
        },
        handleCookieConsentChange,
      );

      // Subscribe to cookie consent changes
      // Generally occured by cookie settings modal.
      window.fdCc?.subscribe(handleCookieConsentChange);
    } else if (!cookieConsentGDPREnabled && cookieConsentPromptEnabled) {
      const handleConsent = (): void => {
        if (
          document.cookie.match('cookieconsent') === null &&
          !(document.getElementsByClassName('fd-cc-root').length > 0)
        ) {
          window?.cookieconsent.initialise({
            palette: {
              popup: {
                background: '#000',
              },
              button: {
                background: '#f1d600',
              },
            },
            content: {
              href: policyUrls?.privacy || 'privacy',
              message: translate('Cookie_consent_This_website_uses_cookies'),
              dismiss: translate('Cookie_consent_Got_it'),
              link: translate('Cookie_consent_Learn_more'),
            },
            elements: {
              dismiss: `<button aria-label="${translate(
                'Cookie_consent_Got_it',
              )}" class="cc-btn cc-dismiss">${translate(
                'Cookie_consent_Got_it',
              )}</button>`,
            },
            cookie: {
              expiryDays: '180',
            },
          });
        }
      };

      let timeOut: ReturnType<typeof setTimeout>;
      const delayedHandleConsent = (): void => {
        timeOut = setTimeout(handleConsent, 1000);
      };

      window.addEventListener('load', delayedHandleConsent);

      return () => {
        clearTimeout(timeOut);
        window.removeEventListener('load', delayedHandleConsent);
      };
    }
  }, []);
  return <></>;
};

export default CookieConsent;
