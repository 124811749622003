import { createContext, useContext } from 'react';

export type LoggerType = {
  debug: (message: string, data?: object) => void;
  error: (message: string, data?: object) => void;
  info: (message: string, data?: object) => void;
  log: (message: string, data?: object) => void;
  warn: (message: string, data?: object) => void;
};

interface LoggerBase {
  logger: LoggerType;
}

const LoggerContext = createContext<LoggerBase>({ logger: console });

type LoggerProp = {
  children: JSX.Element;
} & LoggerBase;

export const LoggerProvider = ({
  children,
  logger,
}: LoggerProp): JSX.Element => {
  const value = {
    logger,
  };
  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  );
};

export const useLogger = (): LoggerType => {
  const { logger } = useContext(LoggerContext);
  return logger;
};
