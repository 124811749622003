import { styled } from '@mui/material/styles';
import { ReactComponent as VoucherIcon } from '@Src/assets/images/Redeem.svg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { formatPrice } from '@Src/utility/priceUtils';
import { useConfig } from '@Src/providers/ConfigProvider';

type StyledContainerProps = {
  ownerState: {
    borderRadius?: string;
  };
};
const StyledContainer = styled(Grid)<StyledContainerProps>(
  ({ theme, ownerState }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: ownerState.borderRadius || '8px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '72px',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    width: '263px',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  }),
);

const StyledIconContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledContentContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleContainer = styled(Grid)<{
  ownerState: { hasDescription: boolean };
}>(({ ownerState, theme }) => ({
  justifyContent: ownerState.hasDescription ? 'initial' : 'center',
  color: theme.palette.text.primary,
}));

const StyledDescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.body,
  margin: 0,
}));

type VoucherProps = {
  borderRadius?: string;
};
const Voucher = ({ borderRadius }: VoucherProps): JSX.Element => {
  const { translate } = useTranslation();
  const { language } = useConfig();
  const { currencies, voucher } = useDesignSchema('v2');

  const showVoucher = voucher?.enabled;

  if (!showVoucher) {
    return <></>;
  }

  const title = translate(voucher?.title || '');
  const description = translate(voucher?.description || '');
  const amount = voucher?.amount || 0;
  const voucherAmount = voucher?.isPercent
    ? `${amount || ''}%`
    : formatPrice(
        amount,
        currencies?.[0] || '',
        language,
        amount % 1 !== 0,
        'narrowSymbol',
      );

  return (
    <StyledContainer
      ownerState={{ borderRadius }}
      id="voucher"
      container
      data-fd="voucher-component"
    >
      <StyledIconContainer item xs={2}>
        <VoucherIcon />
      </StyledIconContainer>
      <StyledContentContainer item xs={10}>
        <StyledTitleContainer ownerState={{ hasDescription: !!description }}>
          <Typography variant="body2Medium">
            {title || `${voucherAmount} ${translate('Off_first_online_order')}`}
          </Typography>
        </StyledTitleContainer>
        {description && (
          <Grid>
            <StyledDescriptionText variant="caption" paragraph>
              {description}
            </StyledDescriptionText>
          </Grid>
        )}
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default Voucher;
