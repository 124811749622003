import { styled } from '@mui/material/styles';
import { SteppedProgressBar } from './SteppedProgressBar';
import { ReactComponent as DeliveryIcon } from '@Src/assets/images/delivery_icon.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Link } from '@Components/RoutesUtils';
import {
  activeOrderStatuses,
  useFulfillmentStatusEvents,
} from '@Src/hooks/useFulfillmentStatus';

import { useConfig } from '@Src/providers/ConfigProvider';
import Typography from '@mui/material/Typography';
import { useTracking } from '@Src/hooks/useTracking';
import { useAppSelector } from '@Src/hooks/useAppSelector';

// #region styles
const StyledContainer = styled('div')`
  border-radius: 8px;
  padding: 8px 12px 6px 12px;
  ${({ theme }) => ({
    backgroundColor: theme.palette.background.default,
  })};
  box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.15);
  min-height: 72px;
`;

const StyledOrderInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledOrderStatusTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.subtle};
  margin: 0;
`;

const StyledLiveIndicator = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: 'center';
  padding: 8px;
`;

const StyledDeliveryIcon = styled(DeliveryIcon)`
  margin-right: 8px;
`;

const StyledLink = styled(Link)`
  width: 300px;
  z-index: 1;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
  }
`;

// #endregion

type OrderStatusProps = {
  style?: React.CSSProperties;
  progressBarStyle?: React.CSSProperties;
};

export const OrderStatus = ({
  style,
  progressBarStyle,
}: OrderStatusProps): JSX.Element => {
  const { language } = useConfig();
  const { translate } = useTranslation();
  const { fulfillmentStatus, orderCreated } = useFulfillmentStatusEvents();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { trackOrderEvent } = useTracking();

  const showOrderStatus = isLoggedIn;

  const handleOrderStatusClick = (): void => {
    trackOrderEvent('Order Status', {
      state: fulfillmentStatus?.StatusName || '',
      hasTracking: String(fulfillmentStatus?.DeliveryTrackingUrl !== null),
    });
  };

  const getCompletionTimeFromMsDate = (msDate: string): string => {
    const timeStamp = Number(msDate.slice(6, -5));
    // const utcDate = dayjs.unix(timeStamp).toDate();
    const date = new Date(timeStamp * 1000);
    const formattedDate = date.toLocaleString([language], {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    });
    const timePlusTenMins = new Date(timeStamp * 1000);
    timePlusTenMins.setMinutes(timePlusTenMins.getMinutes() + 10);
    const formattedtimePlusTen = timePlusTenMins.toLocaleString([language], {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    });
    return `${translate(
      'Get_your_order_at',
    )} ${formattedDate} - ${formattedtimePlusTen}`;
  };

  const getOrderStatusDescription = (): string => {
    if (
      fulfillmentStatus?.EstimatedCompletionTime &&
      activeOrderStatuses.includes(fulfillmentStatus?.StatusId)
    ) {
      return getCompletionTimeFromMsDate(
        fulfillmentStatus?.EstimatedCompletionTime,
      );
    }
    return fulfillmentStatus?.Description || '';
  };

  if (!fulfillmentStatus || !showOrderStatus) {
    return <></>;
  }

  return (
    <StyledLink
      onClick={handleOrderStatusClick}
      to={`/order#/history/${orderCreated?.orderId || ''}`}
      aria-label={translate('View_Order_Details')}
    >
      <StyledContainer style={style}>
        <StyledOrderInfoContainer>
          <div>
            <StyledOrderStatusTitle variant="finePrint1" paragraph>
              {fulfillmentStatus?.StatusName}
            </StyledOrderStatusTitle>
            <Typography variant="body2Medium">
              {getOrderStatusDescription()}
            </Typography>
          </div>
          {fulfillmentStatus?.DeliveryTrackingUrl && (
            <StyledLiveIndicator>
              <>
                <StyledDeliveryIcon />
                {translate('Live')}
              </>
            </StyledLiveIndicator>
          )}
        </StyledOrderInfoContainer>
        {!fulfillmentStatus?.IsError && fulfillmentStatus?.TotalStatuses && (
          <SteppedProgressBar
            dataFd="progress-bar"
            style={progressBarStyle}
            currentStep={fulfillmentStatus?.StatusPosition}
            totalSteps={fulfillmentStatus?.TotalStatuses}
          />
        )}
      </StyledContainer>
    </StyledLink>
  );
};

export default OrderStatus;
