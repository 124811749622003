import { Grid, Typography } from '@mui/material';

type LocationSummaryTextProps = {
  name: string;
  address: string;
};

const LocationSummaryText = ({
  name,
  address,
}: LocationSummaryTextProps): JSX.Element => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="body1">{name}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="finePrint1">{address}</Typography>
    </Grid>
  </Grid>
);

export default LocationSummaryText;
