import { useConfig } from '@Src/providers/ConfigProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useState } from 'react';
import styled from 'styled-components';

// #region styled-components
const StyledContainer = styled.div`
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(255, 188, 44);
  display: flex;
  padding: 6px 16px;
  color: rgba(0, 0, 0, 0.87);
  position: absolute;
  left: calc(50% - 200px);
  top: 20px;
  z-index: 1101 !important;
  min-width: 400px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
  }
`;

const StyledIcon = styled.div`
  margin-right: 12px;
  padding: 7px 0;
  display: flex;
  font-size: 22px;
  opacity: 0.9;
`;

const StyledAlertSVG = styled.svg`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: inherit;
`;

const StyledTextContainer = styled.div`
  padding: 8px 0;
  min-width: 0;
  overflow: auto;
`;
const StyledBtnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 2px 0px 0px 16px;
  margin-left: auto;
  margin-right: -8px;
`;
const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  color: inherit;
  padding: 5px;
  font-size: 1.125rem;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

const StyledActionSVG = styled.svg`
  user-select: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 1.25rem;
  cursor: pointer;
  text-align: center;
  color: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
`;
// #endregion

export const PreviewBanner = (): JSX.Element => {
  const { translate } = useTranslation();
  const { isEditorPreview } = useConfig();

  const [isVisible, setIsVisible] = useState<boolean>(!isEditorPreview);

  const handleClick = (): void => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    isVisible && (
      <StyledContainer role="alert">
        <StyledIcon>
          <StyledAlertSVG
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
          >
            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
          </StyledAlertSVG>
        </StyledIcon>
        <StyledTextContainer>
          {translate('Preview_mode_warning')}
        </StyledTextContainer>
        <StyledBtnContainer>
          <StyledButton onClick={handleClick}>
            <StyledActionSVG
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </StyledActionSVG>
          </StyledButton>
        </StyledBtnContainer>
      </StyledContainer>
    )
  );
};
