import { styled } from '@mui/material/styles';
import { Landing } from '../Landing/index';
import { type LandingPageType } from '@Src/middlewares/CustomPageTypes';
import Highlight from '../Highlight';
import { OrderSettings } from '@Components/Widgets/OrderSettings';
import Apps from '@Components/Widgets/Apps';
import MenuHighlights from '../Menu';
import Testimonials from '@Components/Widgets/Testimonials';
import Gallery from '@Components/Widgets/Gallery';
import OrderStatus from '@Components/Widgets/OrderStatus';
import Reorder from '@Components/Widgets/Reorder';
import { LogInTextButton } from '../LogIn';
import LocationsWidget from '@Components/Widgets/Locations';
import Voucher from '@Components/Widgets/Voucher';
import Grid from '@mui/material/Grid';
import { InnerGridPolice } from '../BaseGridElements';
import LoyaltyProgress from '@Components/Widgets/LoyaltyProgress';
import { useAppSelector } from '@Src/hooks/useAppSelector';

const StyledLandingChildrenWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const StyledOrderSettingsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '156px',
  position: 'relative',
  marginTop: theme.spacing(-3),
  [theme.breakpoints.up('sm')]: {
    minHeight: '120px',
    marginTop: theme.spacing(-9.5),
  },
}));

const StyledLogInContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
}));

export const OrderSettingsGrid = styled('div')(({ theme }) => ({
  margin: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.up('sm')]: {
    margin: `0 ${theme.spacing(5)}`,
  },
}));

export const Dishy = ({ data }: { data: LandingPageType }): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  let headingText = '';
  if (isLoggedIn) {
    headingText = data?.loggedInHeading || '';
  } else {
    headingText = data?.loggedOutHeading || '';
  }
  const hideWelcomeMessage = !data.brandNameHeadingEnabled && !headingText;
  return (
    <>
      <Landing
        backgroundImages={data?.backgroundImages}
        hideWelcomeMessage={hideWelcomeMessage}
      >
        <StyledLandingChildrenWrapper>
          <OrderStatus />
          <Voucher />
          <LoyaltyProgress />
        </StyledLandingChildrenWrapper>
      </Landing>
      <OrderSettingsGrid>
        <StyledOrderSettingsContainer>
          <OrderSettings orderButtonSize="lg" />
          {!isLoggedIn && (
            <StyledLogInContainer>
              <LogInTextButton />
            </StyledLogInContainer>
          )}
        </StyledOrderSettingsContainer>
      </OrderSettingsGrid>
      <Reorder />
      <InnerGridPolice shouldOverFlowMobile>
        <MenuHighlights />
      </InnerGridPolice>
      {data.highlight?.enabled && <Highlight data={data?.highlight} />}
      {data.testimonialsEnabled && (
        <InnerGridPolice shouldOverFlowMobile>
          <Testimonials />
        </InnerGridPolice>
      )}
      {data.mobileAppsEnabled && <Apps />}
      {data.galleryEnabled && (
        <InnerGridPolice shouldOverFlowMobile>
          <Gallery />
        </InnerGridPolice>
      )}
      {data.locationsEnabled && (
        <InnerGridPolice>
          <LocationsWidget showSubTitle showTitle />
        </InnerGridPolice>
      )}
    </>
  );
};

export default Dishy;
