import { Fragment } from 'react';
import { useTranslation } from '@Providers/TranslationProvider';
import styled from 'styled-components';
import { buildSocialMediaIconList } from '../SocialMedia/SocialMedia';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { usePageLinkSchema } from '@Providers/CustomPageSchemaProvider';
import { Link } from '@Components/RoutesUtils';
import { useConfig } from '@Src/providers/ConfigProvider';
import { type V1PageLinkType } from '@Src/middlewares/CustomPageTypes';

const StyledFooterLink = styled.div`
  margin-top: 15px;
`;

const StyledSublinkContainer = styled.div`
  margin-top: 15px;
  font-size: small;
`;

const StyledSublink = styled(Link)`
  color: #ccc;
  &:hover {
    color: #ccc;
    opacity: 0.85;
  }
  &:focus {
    color: #ccc;
  }
`;

const StyledSublinkanchor = styled.a`
  color: #ccc;
  cursor: pointer;
  &:hover {
    color: #ccc;
    opacity: 0.85;
  }
  &:focus {
    color: #ccc;
  }
`;

const Footer = (): JSX.Element => {
  const { socialMedia, policyUrls, name, logo, template } =
    useDesignSchema('v1');
  const footerLinks = usePageLinkSchema('footer') as V1PageLinkType[];
  const { translate } = useTranslation();
  const { language } = useConfig();

  const handleCookieSettingsClick = (): void => {
    window.fdCc?.edit({
      lang: language,
      restaurantName: name,
      logoUrl: logo,
      cookiePolicyUrl: policyUrls?.cookie || 'cookiepolicy',
    });
  };

  return (
    <footer id="footer" className="section" role="contentinfo">
      <div className="container">
        <ul className="social">
          {buildSocialMediaIconList(socialMedia, true)}
        </ul>

        <StyledFooterLink className="my-link-color my-font-size">
          <Link
            to="https://www.flipdish.com"
            aria-label="Flipdish link, access our website for more information"
          >
            {template === 'cafeShirley'
              ? translate('Powered_by_flipdish')
              : translate('Online_ordering_by_Flipdish')}
          </Link>
        </StyledFooterLink>
        <StyledSublinkContainer className="footerlink">
          <StyledSublink
            to={policyUrls?.terms ?? '/terms'}
            target="_blank"
            rel="noreferrer"
          >
            {translate('Terms')}
          </StyledSublink>{' '}
          |{' '}
          {policyUrls?.privacy ? (
            <>
              <StyledSublink
                to={policyUrls.privacy}
                target="_blank"
                rel="noreferrer"
              >
                {translate('Privacy')}
              </StyledSublink>
            </>
          ) : (
            <StyledSublink to="/privacy" target="_blank" rel="noreferrer">
              {translate('Privacy')}
            </StyledSublink>
          )}{' '}
          |{' '}
          {policyUrls?.cookie ? (
            <StyledSublink
              to={policyUrls.cookie}
              target="_blank"
              rel="noreferrer"
            >
              {translate('Cookie_Policy')}
            </StyledSublink>
          ) : (
            <StyledSublink
              to={'/cookiepolicy'}
              target="_blank"
              rel="noreferrer"
            >
              {translate('Cookie_Policy')}
            </StyledSublink>
          )}{' '}
          |{' '}
          <StyledSublinkanchor onClick={handleCookieSettingsClick}>
            {translate('Cookie_Settings')}
          </StyledSublinkanchor>
          {footerLinks.map(({ path, title }) => (
            <Fragment key={path}>
              {' '}
              | <StyledSublink to={path}>{title}</StyledSublink>
            </Fragment>
          ))}
        </StyledSublinkContainer>
      </div>
    </footer>
  );
};

export default Footer;
