import type React from 'react';
import { ReactComponent as ArrowLeft } from '@Src/assets/images/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '@Src/assets/images/ArrowRight.svg';
import { styled } from '@mui/material/styles';
import { Grid, type Theme, Typography, useMediaQuery } from '@mui/material';

const StyledContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
  },
}));

const StyledTitleContainer = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center',
  },
}));

const StyledButton = styled('button')`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 24px;
  height: 48px;
  width: 48px;
  &:disabled {
    opacity: 0.1;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

type ScrollingListHeaderProps = {
  goBack: () => void;
  goForward: () => void;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  title?: string;
  subTitle?: string;
  maxContainerWidth?: string;
  actionButton?: JSX.Element;
};

const ScrollingMenuHeader: React.FC<ScrollingListHeaderProps> = ({
  actionButton,
  title,
  subTitle,
  leftButtonDisabled,
  rightButtonDisabled,
  goBack,
  goForward,
}): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  return (
    <StyledContainer item>
      <StyledTitleContainer>
        {title && <Typography variant="body1Medium">{title}</Typography>}
        {subTitle && <Typography variant="h2Display">{subTitle}</Typography>}
      </StyledTitleContainer>
      <StyledButtonContainer sx={{ display: 'flex' }}>
        {actionButton && actionButton}
        {!isMobile && (!leftButtonDisabled || !rightButtonDisabled) && (
          <>
            <StyledButton onClick={goBack} disabled={leftButtonDisabled}>
              <StyledArrowLeft />
            </StyledButton>
            <StyledButton onClick={goForward} disabled={rightButtonDisabled}>
              <StyledArrowRight />
            </StyledButton>
          </>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default ScrollingMenuHeader;
