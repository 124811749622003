import { styled } from '@mui/material/styles';

type StyledCardProps = {
  width?: string;
  maxWidth?: string;
  borderRadius?: string;
  backgroundColor?: string;
  fontFamily?: string;
  textColor?: string;
};

const StyledCard = styled('div')<StyledCardProps>`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  //border-radius: ${({ borderRadius }) => borderRadius ?? '8px'};
  background: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};
  overflow: hidden;
  width: ${({ width }) => width || 'inherit'};
  min-width: ${({ width }) => width || 'inherit'};
  max-width: ${({ maxWidth }) => maxWidth || 'inherit'};
  div > p:first-of-type {
    color: ${({ textColor }) => textColor || 'inherit'};
  }
`;

const StyledCardContent = styled('div')`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Card = ({
  children,
  width,
  className = '',
  fdKey,
  maxWidth,
  cardStyles,
}: {
  children: JSX.Element | JSX.Element[];
  width?: string;
  className?: string;
  fdKey: string;
  maxWidth?: string;
  cardStyles?: {
    borderRadius?: string;
    borderWidth?: string;
    backgroundColor?: string;
    fontFamily?: string;
    textColor?: string;
    border?: string;
    padding?: string;
  };
}): JSX.Element => (
  <StyledCard
    style={cardStyles}
    textColor={cardStyles?.textColor}
    fontFamily={cardStyles?.fontFamily}
    borderRadius={cardStyles?.borderRadius}
    backgroundColor={cardStyles?.backgroundColor}
    maxWidth={maxWidth}
    className={className}
    width={width}
  >
    <StyledCardContent className={`${fdKey}-card-content`}>
      {children}
    </StyledCardContent>
  </StyledCard>
);

export default Card;
