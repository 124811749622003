import { type V2CustomPageType } from '@Src/middlewares/CustomPageTypes';
import { useCustomPageSchema } from '@Src/providers/CustomPageSchemaProvider';
import { useLocation } from 'react-router-dom';

export const useCurrentPage = (): V2CustomPageType | undefined => {
  const pagesSchema = useCustomPageSchema();
  const location = useLocation();
  const path = location.pathname.substring(1);
  const pathKey = path === '' ? 'index' : path;
  const pageData = pagesSchema[pathKey];

  return pageData;
};
