import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { useContext, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ReactComponent as ExpandIcon } from '@Src/assets/images/Expand.svg';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import '@Components/ui/ScrollingMenuHeader/hideScrollBar.css';
import { useTranslation } from '@Src/providers/TranslationProvider';
import ScrollingMenuHeader from '@Components/ui/ScrollingMenuHeader';
import { ImgixImage } from '@Components/ui/ImgixImage';
import { FullscreenGallery } from '@Components/Widgets/Gallery/FullscreenGallery';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTracking } from '@Src/hooks/useTracking';

type StyledContainerProps = {
  ownerState: {
    borderRadius?: string;
  };
};

const StyledContainer = styled('div')<StyledContainerProps>(
  ({ theme, ownerState }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: ownerState.borderRadius,
  }),
);

const StyledGalleryWrapper = styled(Grid)`
  height: 100%;
  ${({ theme }) => ({
    marginRight: theme.spacing(2),
  })};
`;

type StyledImgWrapperProps = {
  ownerState: {
    borderRadius?: string;
  };
};
const StyledImgWrapper = styled('div')<StyledImgWrapperProps>`
  border-radius: ${({ ownerState }) => ownerState.borderRadius};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 240px;
    width: 220px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 240px;
    width: 240px;
  }
  cursor: pointer;
  position: relative;
  width: 100%;
  overflow: hidden;
  & img {
    transition: transform 330ms ease-in-out;
  }
  &:hover img {
    transform: scale(1.2);
  }
  &:hover .magnifier {
    opacity: 1;
    transform: scale(1.2);
  }
`;

const StyledMagnifier = styled('div')`
  opacity: 0;
  z-index: 1;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  transform: scale(1.2, 1.2);
  position: absolute;
  bottom: 0px;
  right: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  height: 48px;
  width: 48px;
`;

type GalleryProps = {
  className?: string;
  borderRadius?: string;
  imageBorderRadius?: string;
};

/**
 * A component for a gallery of images scrollable horizontally
 * @param {string} className - classname for the gallery
 * @param {string} borderRadius - border radius for the gallery container
 * @param {string} imageBorderRadius - border radius for the images in the gallery
 */
const Gallery: React.FC<GalleryProps> = ({
  className,
  borderRadius,
  imageBorderRadius,
}): JSX.Element => {
  const { gallery, imageAttributes } = useDesignSchema('v2');
  const { translate } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const { trackNavigationEvent } = useTracking();

  if (!((gallery?.images?.length || 0) > 0)) {
    return <></>;
  }

  const handleOpenFullScreen = (index: number): void => {
    setSelectedIndex(index);
    setIsFullscreenOpen(true);
    trackNavigationEvent('Gallery View');
  };

  return (
    <StyledContainer
      id="gallery"
      className={className}
      data-fd="gallery"
      ownerState={{ borderRadius }}
    >
      <ScrollMenu
        Header={() => {
          const {
            isFirstItemVisible,
            isLastItemVisible,
            scrollPrev,
            scrollNext,
          } = useContext(VisibilityContext);
          return (
            <ScrollingMenuHeader
              title={translate(gallery?.title || '')}
              subTitle={translate(gallery?.subTitle || '')}
              leftButtonDisabled={isFirstItemVisible}
              rightButtonDisabled={isLastItemVisible}
              goBack={() => {
                scrollPrev('smooth', 'nearest');
              }}
              goForward={() => {
                scrollNext('smooth', 'nearest');
              }}
              maxContainerWidth="none"
            />
          );
        }}
      >
        {gallery?.images?.map((img, idx: number) => (
          <StyledGalleryWrapper key={idx}>
            <StyledImgWrapper
              onClick={() => handleOpenFullScreen(idx)}
              ownerState={{ borderRadius: imageBorderRadius }}
            >
              <ImgixImage
                data-fd={`gallery-image-${idx}`}
                key={`gallery-image-${idx}`}
                src={`${img}?w=240&ar=1:1&fit=crop&crop=entropy`}
                alt={imageAttributes?.[img]?.alt || `gallery image$ ${idx}`}
                loading="lazy"
              />
              <StyledMagnifier className="magnifier">
                <StyledExpandIcon />
              </StyledMagnifier>
            </StyledImgWrapper>
          </StyledGalleryWrapper>
        )) || []}
      </ScrollMenu>
      {isFullscreenOpen && (
        <FullscreenGallery
          images={gallery?.images || []}
          startIndex={selectedIndex}
          onClose={() => {
            setIsFullscreenOpen(false);
          }}
        />
      )}
    </StyledContainer>
  );
};

export default Gallery;
