import { useEffect, useRef, useState } from 'react';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import styled from 'styled-components';
import { useGoogleMap } from '@Src/hooks/useGoogleMap';

const StyledMapDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
`;
type MapSectionProps = {
  onClick: (storeId: number) => void;
  isMobile: boolean;
  selectedStore: number | null;
};

const Map = ({
  onClick,
  isMobile,
  selectedStore,
}: MapSectionProps): JSX.Element => {
  const mapRef = useRef(null);
  const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[]>([]);

  const { storeMapData } = useDesignSchema();
  if (!storeMapData) {
    return <></>;
  }

  const [map] = useGoogleMap(
    {
      center: {
        lat: storeMapData[0]?.Coordinates?.Latitude,
        lng: storeMapData[0]?.Coordinates?.Longitude,
      },
      mapTypeControl: false,
      scrollwheel: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoom: 15,
    },
    mapRef,
  );

  useEffect(() => {
    if (map) {
      const markers = storeMapData.map((store) => {
        // Add marker
        const markerPosition = new google.maps.LatLng(
          store.Coordinates?.Latitude,
          store.Coordinates?.Longitude,
        );
        const markerIcon = {
          url: 'https://static.web.flipdish.com/public/images/elixir/pointer-gray.png',
          scaledSize: new google.maps.Size(50, 68),
        };

        const marker = new google.maps.Marker({
          map,
          position: markerPosition,
          icon: markerIcon,
          // @ts-expect-error id works here
          id: store.storeId,
        });

        marker.addListener('click', () => {
          onClick?.(store.storeId);
        });
        return marker;
      });

      const offsetLng = isMobile ? 0 : -0.01;
      const offsetLat = isMobile ? -0.006 : -0.0;

      const marker = markers?.find(
        // @ts-expect-error id works here
        (marker) => marker.id === selectedStore,
        // @ts-expect-error ts is fun
      )?.position;

      const markerLat: number = marker?.lat();
      const markerLng: number = marker?.lng();

      if (markerLat && markerLng) {
        // Calculate the new center with the right offset due to details card
        const newCenter = new google.maps.LatLng(
          markerLat + offsetLat,
          markerLng + offsetLng,
        );

        // Pan the map to the new center
        map.panTo(newCenter);
      }
      setMapMarkers(markers);
    }

    return () => {
      mapMarkers.forEach((marker) => {
        google.maps.event.clearInstanceListeners(marker);
      });
    };
  }, [map, selectedStore, isMobile]);
  return <StyledMapDiv id="map" ref={mapRef} />;
};
export default Map;
