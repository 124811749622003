import styled, { css } from 'styled-components';
import { ReactComponent as FacebookLogoIcon } from '@Src/assets/images/elixir/facebook-logo.svg';
import { ReactComponent as TikTokLogoIcon } from '@Src/assets/images/tiktok.svg';
import { ReactComponent as TwitterLogoIcon } from '@Src/assets/images/twitter-x.svg';
import { ReactComponent as InstagramLogoIcon } from '@Src/assets/images/elixir/instagram-logo.svg';
import { ReactComponent as TripAdvisorLogoIcon } from '@Src/assets/images/elixir/tripadvisor-logo.svg';
import { ReactComponent as YelpLogoIcon } from '@Src/assets/images/elixir/yelp-logo.svg';
import { ReactComponent as FoursquareLogoIcon } from '@Src/assets/images/elixir/four-square-logo.svg';
import { ReactComponent as PintrestLogoIcon } from '@Src/assets/images/elixir/pintrest-logo.svg';
import { type SocialMediaType } from '@Src/middlewares/DesignConfigTypes';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import {
  PageTemplates,
  type PageTemplatesType,
} from '@Src/middlewares/CustomPageTypes';

type StyledAProps = {
  fullSize?: boolean;
  primaryColor: string;
  template?: PageTemplatesType;
  iconColor?: string;
};
const StyledA = styled.a<StyledAProps>`
  display: ${(props) => (props.fullSize ? 'inherit' : 'contents !important')};
  margin-left: 4px;
  margin-right: 4px;
  padding-top: ${(props) => (props.fullSize ? '0' : '12px')};
  &:hover {
    path {
      fill: ${(props) =>
        props.fullSize
          ? 'default'
          : props.template === PageTemplates.CafeShirley
          ? `var(--header-colour-highlight) !important;`
          : `${props.primaryColor}`};
    }
  }
  svg {
    width: ${(props) => (props.fullSize ? '100%' : '24px')};
    height: ${(props) => (props.fullSize ? '100%' : '24px')};
    @media (min-width: 992px) {
      margin-top: ${(props) =>
        !props.fullSize && props.template === PageTemplates.LosPollosHermanos
          ? '32px'
          : 'default'};
    }
  }
  path {
    fill: ${(props) => (!props.fullSize ? props.iconColor : '#ffffff')};
    ${(props) =>
      !props.fullSize &&
      props.template === PageTemplates.CafeShirley &&
      css`
        path {
          fill: #595959;
        }
      `};
  }
`;

type StyledListItemProps = {
  isFooter?: boolean;
  isMobile?: boolean;
  primaryColor: string;
};
const StyledListItem = styled.li<StyledListItemProps>`
  ${(props) =>
    !props.isFooter &&
    !props.isMobile &&
    css`
      display: flex !important;
      align-items: center;
      line-height: 16px;
    `}
  // for some reason nesting these two together breaks important 
  ${(props) =>
    !props.isFooter &&
    props.isMobile &&
    css`
      display: flex !important;
      align-items: center;
      line-height: 16px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 9px !important;
      padding-left: 10px !important;
    `}
  .icon:hover {
    background: ${(props) => `${props.primaryColor}!important`};
  }
`;

type StyledSocialLabelProps = {
  color?: string;
};

const StyledSocialLabel = styled.span<StyledSocialLabelProps>`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.color ?? 'inherit'};
  text-transform: uppercase;
  font-size: inherit;
  font-family: inherit !important;
  font-style: normal;
  border-bottom: inherit;
  text-shadow: inherit;
  font-weight: inherit;
`;

const buildLogoListItem = (
  pageName: string,
  url: string,
  icon: any,
  isFooter?: boolean,
  isMobile?: boolean,
  iconColor?: string,
): JSX.Element => {
  const { appDesignScheme, template } = useDesignSchema('v1');
  const { Primary: primary } = appDesignScheme || {};
  return (
    <StyledListItem
      isFooter={isFooter}
      isMobile={isMobile}
      key={isFooter ? `footer-${pageName}` : `header-${pageName}`}
      primaryColor={primary}
      className="menu-item"
    >
      <StyledA
        fullSize={isFooter}
        className="icon header-text"
        href={url.startsWith('www') ? `//${url}` : url}
        target="_blank"
        aria-label={`Access our ${pageName} page`}
        rel="noreferrer noopener"
        primaryColor={primary}
        template={template}
        iconColor={iconColor}
      >
        {icon}
        {!isFooter && isMobile && (
          <StyledSocialLabel color={iconColor}>{pageName}</StyledSocialLabel>
        )}
      </StyledA>
    </StyledListItem>
  );
};

export const buildSocialMediaIconList = (
  socialMedia?: SocialMediaType,
  isFooter?: boolean,
  isMobile?: boolean,
  color?: string,
): JSX.Element[] => {
  const items = [];
  if (socialMedia?.FacebookUrl) {
    items.push(
      buildLogoListItem(
        'Facebook',
        socialMedia.FacebookUrl,
        <FacebookLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.FourSquareUrl) {
    items.push(
      buildLogoListItem(
        'FourSquare',
        socialMedia.FourSquareUrl,
        <FoursquareLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.InstagramUrl) {
    items.push(
      buildLogoListItem(
        'Instagram',
        socialMedia.InstagramUrl,
        <InstagramLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.PintrestUrl) {
    items.push(
      buildLogoListItem(
        'Pintrest',
        socialMedia?.PintrestUrl,
        <PintrestLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }

  if (socialMedia?.TripAdvisorUrl) {
    items.push(
      buildLogoListItem(
        'TripAdvisor',
        socialMedia.TripAdvisorUrl,
        <TripAdvisorLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.TwitterUrl) {
    items.push(
      buildLogoListItem(
        'Twitter',
        socialMedia.TwitterUrl,
        <TwitterLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.TikTokUrl) {
    items.push(
      buildLogoListItem(
        'Tiktok',
        socialMedia.TikTokUrl,
        <TikTokLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  if (socialMedia?.YelpUrl) {
    items.push(
      buildLogoListItem(
        'Yelp',
        socialMedia.YelpUrl,
        <YelpLogoIcon />,
        isFooter,
        isMobile,
        color,
      ),
    );
  }
  return items;
};
