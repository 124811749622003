import styled from 'styled-components';

const StyledActionText = styled.p`
  margin: 0;
`;

const StyledButtonContents = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

type StyledButtonPropsType = {
  borderRadius?: string | number;
};

const StyledButton = styled.button<StyledButtonPropsType>`
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 10px;
  padding: 4px 12px;
  border-radius: ${(props) =>
    props.borderRadius || props.borderRadius?.toString() === '0'
      ? props.borderRadius
      : '4px'};
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

type ButtonPropsType = {
  action: () => void;
  actionText: string;
  ariaLabel: string;
  className?: string;
  icon?: JSX.Element;
  fdKey: string;
  styles?: React.CSSProperties;
};

const Button = ({
  action,
  actionText,
  ariaLabel,
  className = '',
  icon,
  fdKey,
  styles = {},
}: ButtonPropsType): JSX.Element => (
  <StyledButton
    style={styles}
    data-fd={fdKey}
    type="button"
    onClick={action}
    aria-label={ariaLabel}
    className={className}
    borderRadius={styles?.borderRadius}
  >
    <StyledButtonContents>
      <StyledActionText>{actionText}</StyledActionText>
      {icon}
    </StyledButtonContents>
  </StyledButton>
);

export default Button;
