import { useState } from 'react';
import { RECAPTHCA_KEY } from '@Src/BuildConstants';

export const useRecaptcha = (): {
  isRecaptchaValid: boolean;
  handleRecaptchaExpired: () => void;
  handleRecaptchaTest: () => void;
  recaptchaSiteKey: string;
} => {
  const [isRecaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
  const handleRecaptchaTest = (): void => {
    setIsReCaptchaValid(true);
  };

  const handleRecaptchaExpired = (): void => {
    setIsReCaptchaValid(false);
  };

  return {
    isRecaptchaValid,
    handleRecaptchaExpired,
    handleRecaptchaTest,
    recaptchaSiteKey: RECAPTHCA_KEY,
  };
};
