import { fetchLoyaltyCampaignProgress } from '@Src/thunks/loyaltyCampaignsThunks';
import { type Campaign } from '@Src/types/loyaltyCampaignTypes';
import { createSlice } from '@Src/utility/reduxUtils';

type LoyaltyCampaignsStateType = {
  loyaltyCampaigns?: Campaign[];
};

export const initialState: LoyaltyCampaignsStateType = {
  loyaltyCampaigns: undefined,
};
export const loyaltyCampaignsSlice = createSlice({
  name: 'loyaltyCampaigns',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchLoyaltyCampaignProgress.fulfilled,
      (state, response) => {
        state.loyaltyCampaigns = response.payload;
        state.fetched = true;
        state.loading = false;
      },
    );
    builder.addCase(fetchLoyaltyCampaignProgress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLoyaltyCampaignProgress.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});
export default loyaltyCampaignsSlice.reducer;
