import MuiList from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { ListItem } from '../ListItem';

type ListItemType = {
  key: string;
  content: JSX.Element | JSX.Element[];
  onClick: () => void;
  isSelected?: boolean;
  id: number;
};
export type ListPropsType = {
  items?: ListItemType[];
  setHoveredItem: (key: number | null) => void;
};

const StyledList = styled(MuiList)`
  padding-top: 0;
` as typeof MuiList;

export function List(props: ListPropsType): JSX.Element {
  const { items, setHoveredItem } = props;

  const handleMouseEnter = (id: number): void => {
    setHoveredItem(id);
  };

  const handleMouseLeave = (): void => {
    setHoveredItem(null);
  };

  return (
    <StyledList className="fd-list" component="ul">
      {items?.map((item: ListItemType) => (
        <ListItem
          key={`${item.key}-list-item`}
          onClick={item.onClick}
          isSelected={item.isSelected}
          onMouseEnter={() => handleMouseEnter(item.id)}
          onMouseLeave={handleMouseLeave}
        >
          {item.content}
        </ListItem>
      ))}
    </StyledList>
  );
}
