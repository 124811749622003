/* eslint-disable @typescript-eslint/promise-function-async */
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Providers/TranslationProvider';
import {
  type IndexPageType,
  PageTemplates,
  type PageTemplatesType,
} from '@Src/middlewares/CustomPageTypes';
import { Link } from '@Components/RoutesUtils';
import { useEffect, useRef, useState } from 'react';
import { formatPrice } from '@Src/utility/priceUtils';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import { useConfig } from '@Src/providers/ConfigProvider';

import { ImgixImage } from '@Components/ui/ImgixImage';
import { getMobileAppIcons } from '@Src/utility/mobileAppsUtils';
import { useTracking } from '@Src/hooks/useTracking';

type StyledSectionProps = { largeLayout: boolean; template: PageTemplatesType };

const LosPollosCss = css<StyledSectionProps>`
  .slider {
    width: ${(props) =>
      props.template !== PageTemplates.LosPollosHermanos
        ? 'max-content !important'
        : 'inherit'};
  }
  #home-slider .my-app-logos {
    opacity: 1;
    margin: 0;
    padding: 0;
    overflow: visible !important;
  }

  @media (max-width: 991px) {
    height: ${(props) => (props.largeLayout ? '100vh' : '84vh')};
  }

  @media (max-width: 375px) {
    height: ${(props) => (props.largeLayout ? '107vh' : '89vh')};
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    height: ${(props) => (props.largeLayout ? '62vh' : '55vh')};
    .slide-content {
      height: 52vh !important;
    }
    .owl-carousel .item,
    #home-slider .owl-carousel img {
      height: ${(props) => (props.largeLayout ? '55vh' : '48vh')};
    }
  }
`;

const StyledLink = styled(Link)`
  :hover {
    opacity: 0.85;
  }
`;

const StyledSection = styled.section<StyledSectionProps>`
  .my-app-logo-v2 {
    margin: ${(props) => (props.template === 'cafeShirley' ? '5px' : '0 5px')};
  }
  ${(props) =>
    props.template === PageTemplates.LosPollosHermanos && LosPollosCss}

  @media only screen and (max-width: 1024px) {
    .my-app-logos {
      margin-top: -85px;
    }
  }

  @media only screen and (min-width: 1024px) {
    #owl-main-text h1 {
      margin-top: 0;
    }

    #owl-main-text {
      margin-top: 50px;
    }
    .slide-content {
      bottom: ${(props) =>
        props.template !== PageTemplates.LosPollosHermanos &&
        props.template !== PageTemplates.Default
          ? 'auto !important'
          : ''};
      top: ${(props) =>
        props.template !== PageTemplates.LosPollosHermanos &&
        props.template !== PageTemplates.Default
          ? '80px !important'
          : ''};
    }

    .my-app-logos {
      margin-top: 15px;
    }
  }
`;

const StyledLogoInto = styled.div<{ logo: string }>`
  background: url('${(props) => props.logo}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  margin: auto;
`;

type StyledDiscountBadgeProps = {
  anchorTop: number;
  offset: number;
};

const StyledDiscountBadge = styled.div<StyledDiscountBadgeProps>`
  top: ${(props) => props.anchorTop - props.offset}px;
  z-index: 1001 !important;
`;

type Props = {
  mainSliderImages: string[];
  showAddressMainSlider: boolean;
  voucher: IndexPageType['voucher'];
};

const getOffset = (selector: string): { top: number; left: number } => {
  const element = document.querySelector(selector);
  if (!element) {
    console.error('Element not found.');
    return { top: 0, left: 0 };
  }
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
};

export const Landing = (props: Props): JSX.Element => {
  const { mainSliderImages, voucher, showAddressMainSlider } = props;
  const { language } = useConfig();
  const sliderDivRef = useRef<HTMLElement>(null);
  const [discountElementAnchorTop, setDiscountElementAnchorTop] = useState(0);
  const isMobile = useIsMobile();
  const { trackEvent } = useTracking();
  const {
    appStoreUrl,
    playStoreUrl,
    storeAddress,
    logo,
    template,
    currencies,
    mobileApps,
  } = useDesignSchema('v1');

  const { AppStoreBadge, GooglePlayBadge } = getMobileAppIcons(language);

  useEffect(() => {
    const { top } = getOffset(
      isMobile ? '#home-slider .logointro' : '.order-online-h2',
    );
    setDiscountElementAnchorTop(top);
  }, [isMobile]);

  const { translate } = useTranslation();
  if (!mainSliderImages || mainSliderImages.length === 0) {
    return <></>;
  }

  const hasApp = !!(appStoreUrl || playStoreUrl);

  const hasMoreThanTwoAddresses = !!(
    storeAddress?.length && storeAddress?.length > 2
  );

  const calculateDiscountBadgeOffsetAdjustment = (): number => {
    if ((template === 'default' || template === 'pizzaCafe') && isMobile) {
      return -10;
    } else if (isMobile) {
      return 80;
    } else {
      return 100;
    }
  };

  const trackClick = (identifier: string) => (): void => {
    trackEvent('Click to order', {
      category: 'Main Slider',
      identifier,
      type: 'Start Order',
    });
  };

  const imageSize = template === PageTemplates.LosPollosHermanos ? 700 : 1440;

  return (
    <>
      <StyledSection
        template={template || 'default'}
        largeLayout={hasMoreThanTwoAddresses}
        id="home-slider"
        ref={sliderDivRef}
      >
        <div className="overlay" />
        <div className="owl-carousel owl-theme">
          <div className="owl-wrapper-outer">
            <div className="owl-wrapper">
              <Carousel
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop
                autoPlay
                interval={4000}
                animationHandler="fade"
                swipeable={false}
              >
                {mainSliderImages.map((img, index) => (
                  <div className="item" key={`image-${index}`}>
                    <ImgixImage
                      style={{ height: '100vh', objectFit: 'cover' }}
                      src={`${img}?w=${imageSize}`}
                      alt={`main image$ ${index}`}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            {voucher?.amount && (
              <StyledDiscountBadge
                anchorTop={discountElementAnchorTop}
                id="discount-badge"
                className="popup medium"
                offset={calculateDiscountBadgeOffsetAdjustment()}
              >
                <Link
                  to={`/order`}
                  aria-label={translate('Discount_image_label')}
                  onClick={trackClick('Voucher')}
                >
                  <>
                    <div className="discount">
                      {voucher?.isPercent
                        ? `${voucher.amount}%`
                        : formatPrice(
                            voucher.amount,
                            currencies?.[0] || '',
                            language,
                            (voucher.amount || 0) % 1 !== 0,
                            'narrowSymbol',
                          )}
                    </div>
                    <div className="title" id="SaveUpTo">
                      {translate('Off_first_online_order')}
                    </div>
                  </>
                </Link>
              </StyledDiscountBadge>
            )}
          </div>
        </div>
        {/* SLIDER CONTENT */}
        <div className="slide-content">
          <div className="voffset100" />
          <Link
            to="/order"
            aria-label="Our logo, click here to start your order"
            onClick={trackClick('Logo')}
          >
            <StyledLogoInto className="styled-logo logointro" logo={logo} />
          </Link>
          <div id="owl-main-text" tabIndex={-1}>
            <div className="item">
              <StyledLink
                to="/order"
                id="skip-to-content"
                onClick={trackClick('H1')}
              >
                <h1 className="order-online-h2" aria-label="Start your order">
                  {translate('Order_Online')}
                </h1>
              </StyledLink>
            </div>
          </div>
          <div className="slide-sep" />
          {showAddressMainSlider && (
            <div className="restAddresses">
              {(storeAddress ?? []).map((address) => (
                <p key={address}>{address}</p>
              ))}
            </div>
          )}
          {hasApp && (
            <div className="my-app-logos">
              {mobileApps?.hasApple && appStoreUrl && (
                <Link to={appStoreUrl} className="my-app-logo-v2">
                  <AppStoreBadge className="my-app-logo-v2" />
                </Link>
              )}

              {mobileApps?.hasAndroid && playStoreUrl && (
                <Link to={playStoreUrl} className="my-app-logo-v2">
                  <GooglePlayBadge className="my-app-logo-v2" />
                </Link>
              )}
            </div>
          )}
        </div>
        <div className="my-powerby my-link-color my-font-size">
          <Link
            to="https://www.flipdish.com/"
            target="_blank"
            aria-label="Flipdish link, access our website for more information"
            rel="noreferrer"
          >
            {translate('Online_ordering_by_Flipdish')}
          </Link>
        </div>
      </StyledSection>
    </>
  );
};
