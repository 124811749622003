import Button from '@mui/material/Button';
import { HorizontalList } from '../HorizontalList.tsx';
import { styled } from '@mui/material/styles';
import { Fragment } from 'react';

type LoadMoreVerticalProps = {
  onClick: () => void;
  loadMoreText: string;
  showLoadMore: boolean;
  listItems: JSX.Element[];
  height?: string;
  maxHeight?: string;
  buttonBorderRadius?: string;
  buttonTextColor?: string;
};

const StyledButtonContainer = styled('div')`
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type StyledButtonContainerProps = {
  ownerState: {
    borderRadius?: string;
    textColor?: string;
  };
};

const StyledButton = styled(Button)<StyledButtonContainerProps>(
  ({ ownerState }) => ({
    borderRadius: ownerState.borderRadius,
    color: ownerState.textColor,
    textTransform: 'none',
    width: '80%',
  }),
);

const LoadMoreVertical = (props: LoadMoreVerticalProps): JSX.Element => {
  const {
    buttonBorderRadius,
    buttonTextColor,
    onClick,
    listItems,
    loadMoreText,
    showLoadMore,
    height,
    maxHeight,
  } = props;
  return (
    <HorizontalList
      height={height}
      maxHeight={maxHeight}
      gap={'none'}
      isHorizontal={false}
      listItems={[
        ...(listItems || [<Fragment key="no-items-passed"></Fragment>]),
        showLoadMore ? (
          <StyledButtonContainer key="load-more-button">
            <StyledButton
              ownerState={{
                borderRadius: buttonBorderRadius,
                textColor: buttonTextColor,
              }}
              onClick={onClick}
              variant="outlined"
            >
              {loadMoreText}
            </StyledButton>
          </StyledButtonContainer>
        ) : (
          <Fragment key="no-button"></Fragment>
        ),
      ]}
    />
  );
};

export default LoadMoreVertical;
