import { type V2HighlightType } from '@Src/middlewares/CustomPageTypes';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { Section } from '@Components/ui/Section';
import { useTheme } from '@mui/material/styles';
import { InnerGridPolice, InnerGridWithImagePolice } from '../BaseGridElements';

export const Highlight = ({
  className = '',
  data,
  orientation = 'left',
  hasBackground = true,
  isPlainSection = false,
  smallGrid = false,
}: {
  className?: string;
  data: V2HighlightType;
  orientation?: 'left' | 'right';
  hasBackground?: boolean;
  isPlainSection?: boolean;
  smallGrid?: boolean;
}): JSX.Element => {
  const { imageAttributes } = useDesignSchema('v2');
  const theme = useTheme();

  const hasImage = !!data?.images?.[0];
  const showTextSection = !!(data?.description || data?.actionButton?.url);
  const imageSrc = data?.images?.[0];

  const GridComponent = smallGrid ? InnerGridPolice : InnerGridWithImagePolice;
  return (
    <GridComponent isShort={!hasImage}>
      <Section
        isPlainSection={isPlainSection}
        backgroundColor={theme.palette.secondary.main}
        textColor={theme.palette.secondary.contrastText}
        className={className}
        data={{
          actionButton: data?.actionButton,
          description: data?.description,
          tag: data?.tag,
          title: data?.title,
        }}
        dataFd="highlight"
        descriptionClassName={'rich-text'}
        hasBackground={hasBackground}
        hasImage={hasImage}
        id="highlight"
        imageAttributes={{
          alt: imageAttributes?.[imageSrc]?.alt || `main highlight image`,
          dataFd: 'main-image',
          src: `${imageSrc}?w=${showTextSection ? 570 : 802}`,
        }}
        orientation={orientation}
        shouldSetInnerHTML
        showTextSection={showTextSection}
      />
    </GridComponent>
  );
};

export default Highlight;
