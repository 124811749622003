import { styled, useTheme } from '@mui/material/styles';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { CollectionStoreList } from '../CollectionStoreList';
import Dialog from '@Components/ui/Dialog';
import { OrderTypeToggle } from '../OrderTypeToggle';
import { ReactComponent as TimeIcon } from '@Src/assets/images/time.svg';
import {
  type DeliveryFlowStepType,
  DeliverySettings,
} from './DeliverySettings';
import { useTranslation } from '@Src/providers/TranslationProvider';
import type React from 'react';
import { ReactComponent as CloseIcon } from '@Src/assets/images/Close.svg';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useConfig } from '@Src/providers/ConfigProvider';
import { extractDateTime } from '../openingTimeUtil';
import { ServiceUnavailable } from '../ServiceUnavailable';
import {
  selectHasSingleStoreTypeOnly,
  selectIsCollectionUnavailable,
  selectIsPreOrderOnly,
} from '@Src/selectors/stores.selectors';

const StyledDialog = styled(Dialog)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    & .MuiPaper-root {
      width: 480px;
      height: 580px;
      overflow-x: hidden;
    }
  }
`;

const StyledFixedContainer = styled('div')`
  position: fixed;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 1;
  width: 100%;
  border-radius: 4px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 480px;
  }
`;

const StyledHeaderControlsContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const StyledOrderTypeToggle = styled(OrderTypeToggle)`
  align-self: center;
`;

const StyledContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledContentHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: '5px solid',
  borderColor: theme.palette.secondary.lowContrast,
  display: 'flex',
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
}));

const StyledTimeIcon = styled(TimeIcon)`
  margin: ${({ theme }) => `0 ${theme.spacing(2)}`};
`;

const StyledHeaderSubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.body,
  margin: 0,
}));

const StyledHeaderText = styled(Typography)`
  margin: 0;
`;

const StyledCloseIcon = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0;
`;

const StyledServiceUnavailableWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}));

type EditOrderSettingDialogPropsType = {
  isOpen: boolean;
  onClose: () => void;
  startStep?: DeliveryFlowStepType;
};

export function EditOrderSettingsDialog({
  isOpen,
  onClose,
  startStep,
}: EditOrderSettingDialogPropsType): JSX.Element {
  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );
  const selectedCollectionStore = useAppSelector(
    (state) => state.collectionStores.selectedCollectionStore,
  );
  const selectedDeliveryStore = useAppSelector(
    (state) => state.deliveryStores.selectedDeliveryStore,
  );
  const isPreOrderOnly = useAppSelector(selectIsPreOrderOnly);
  const isCollectionUnavailable = useAppSelector(selectIsCollectionUnavailable);
  const isToggleHidden = useAppSelector(selectHasSingleStoreTypeOnly);

  const { translate } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledDialog
      open={isOpen}
      onCloseDialog={onClose}
      ariaLabel={translate('Edit_order_settings_dialog')}
      fullScreen={isSmallScreen}
      fullWidth={isSmallScreen}
    >
      {isCollection ? (
        <>
          <StyledFixedContainer>
            <HeaderControls
              isCollectionUnavailable={isCollectionUnavailable}
              isToggleHidden={isToggleHidden}
              onClose={onClose}
            />
            <StyledServiceUnavailableWrapper>
              <ServiceUnavailable
                marginBottom="0"
                width="initial"
                marginLeft="0"
              />
            </StyledServiceUnavailableWrapper>
            <HeaderText
              isCollection={isCollection}
              isPreOrderOnly={isPreOrderOnly}
              openTimeMessage={selectedCollectionStore?.OpenTimeMessage || ''}
            />
          </StyledFixedContainer>
          <CollectionStoreList />
        </>
      ) : (
        <DeliverySettings
          header={
            <>
              <HeaderControls
                isCollectionUnavailable={isCollectionUnavailable}
                isToggleHidden={isToggleHidden}
                onClose={onClose}
              />
              <StyledServiceUnavailableWrapper>
                <ServiceUnavailable
                  marginBottom="0"
                  width="initial"
                  marginLeft="0"
                />
              </StyledServiceUnavailableWrapper>
              <HeaderText
                isCollection={isCollection}
                isPreOrderOnly={isPreOrderOnly}
                openTimeMessage={selectedDeliveryStore?.OpenTimeMessage || ''}
              />
            </>
          }
          startStep={startStep}
          onClose={onClose}
        />
      )}
    </StyledDialog>
  );
}

const HeaderControls: React.FC<{
  isCollectionUnavailable?: boolean;
  isToggleHidden: boolean;
  onClose: () => void;
}> = ({ isCollectionUnavailable, isToggleHidden, onClose }) => (
  <StyledHeaderControlsContainer container>
    <Grid item xs={3}>
      <StyledCloseIcon
        aria-label="Close dialog"
        className="closeIcon"
        data-fd="close-dialog-icon"
        disableRipple
        onClick={onClose}
      >
        <CloseIcon />
      </StyledCloseIcon>
    </Grid>
    <Grid item xs={6}>
      {!isToggleHidden && (
        <StyledOrderTypeToggle collectionDisabled={isCollectionUnavailable} />
      )}
    </Grid>
  </StyledHeaderControlsContainer>
);

const HeaderText: React.FC<{
  isCollection: boolean;
  isPreOrderOnly?: boolean;
  openTimeMessage: string;
}> = ({ isCollection, isPreOrderOnly, openTimeMessage }) => {
  const { translate } = useTranslation();
  const { language } = useConfig();

  let orderType = translate('Deliver');
  let subText = translate('Edit_delivery_time_at_checkout');
  let preOrderText = translate('Preorder_for_delivery');

  if (isCollection) {
    orderType = translate('Collect');
    subText = translate('Edit_collection_time_at_checkout');
    preOrderText = translate('Preorder_for_collection');
  }

  let preOrderSubText = '';
  if (isPreOrderOnly) {
    const preOrderTime = extractDateTime(openTimeMessage, language);
    const date = preOrderTime?.date || '';
    const time = preOrderTime?.time || '';

    preOrderSubText = `${orderType} ${translate(
      'from',
    )} ${date} ${time} - ${translate(
      `edit_${
        orderType === 'Deliver' ? 'delivery' : 'collection'
      }_time_at_checkout`,
    )}`;
  }

  const headerText = isPreOrderOnly ? preOrderText : `${orderType} ASAP`;
  subText = isPreOrderOnly ? preOrderSubText : subText;

  return (
    <StyledContentContainer>
      <StyledContentHeader>
        <StyledTimeIcon />
        <div>
          <StyledHeaderText variant="body1xs" paragraph>
            {headerText}
          </StyledHeaderText>

          <StyledHeaderSubText variant="finePrint2" paragraph>
            {subText}
          </StyledHeaderSubText>
        </div>
      </StyledContentHeader>
    </StyledContentContainer>
  );
};
