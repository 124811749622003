import { type ReactElement } from 'react';

const AppStoreBadge = (): ReactElement => (
  <svg
    width="127"
    height="40"
    viewBox="0 0 127 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4846_2943)">
      <path
        d="M116.978 7.92969e-06H9.53468C9.16798 7.92969e-06 8.80568 7.92975e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.17961 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47235 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.304641 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H116.978C117.338 40 117.703 40 118.062 39.998C118.367 39.998 118.679 39.9941 118.984 39.9873C119.654 39.9789 120.323 39.9198 120.984 39.8105C121.648 39.6959 122.291 39.4833 122.892 39.1797C123.491 38.8758 124.037 38.4779 124.51 38.001C124.986 37.5282 125.384 36.9835 125.691 36.3867C125.993 35.7871 126.202 35.145 126.31 34.4824C126.422 33.8206 126.484 33.1515 126.496 32.4805C126.5 32.1699 126.5 31.8691 126.5 31.5586C126.508 31.1953 126.508 30.834 126.508 30.4648V9.53614C126.508 9.16993 126.508 8.80665 126.5 8.44435C126.5 8.13771 126.5 7.83009 126.496 7.52345C126.484 6.85243 126.422 6.18324 126.31 5.52145C126.202 4.85929 125.993 4.21751 125.691 3.61813C125.074 2.4152 124.095 1.43603 122.892 0.818328C122.291 0.515575 121.648 0.304248 120.984 0.191328C120.323 0.0811107 119.654 0.0220157 118.984 0.0145679C118.679 0.00968793 118.367 0.00382793 118.062 0.00187793C117.703 -0.00012207 117.338 -0.00012207 116.978 -0.00012207V7.92969e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.4448 39.125C8.14013 39.125 7.8428 39.1211 7.54052 39.1143C6.9143 39.1061 6.28954 39.0516 5.67138 38.9512C5.09497 38.8519 4.53658 38.6673 4.01464 38.4033C3.49748 38.1415 3.02579 37.7983 2.61764 37.3867C2.20358 36.98 1.85885 36.5082 1.59713 35.9902C1.33252 35.4688 1.14939 34.9099 1.05413 34.333C0.95125 33.7131 0.89559 33.0863 0.887626 32.458C0.881286 32.2471 0.872986 31.5449 0.872986 31.5449V8.44434C0.872986 8.44434 0.881826 7.75293 0.887676 7.5498C0.895302 6.92248 0.950635 6.29665 1.0532 5.67773C1.14864 5.09925 1.33191 4.53875 1.59667 4.01563C1.85743 3.49795 2.20024 3.02586 2.6118 2.61768C3.0229 2.20562 3.49611 1.86061 4.01415 1.59521C4.53489 1.33209 5.09222 1.14873 5.66747 1.05127C6.28766 0.949836 6.91459 0.894996 7.54298 0.88721L8.4453 0.875H118.057L118.97 0.8877C119.593 0.895099 120.214 0.94945 120.829 1.05029C121.41 1.14898 121.973 1.33362 122.5 1.59814C123.538 2.13299 124.382 2.97916 124.915 4.01807C125.175 4.53758 125.356 5.09351 125.45 5.66699C125.554 6.29099 125.612 6.92174 125.624 7.5542C125.627 7.8374 125.627 8.1416 125.627 8.44434C125.634 8.81934 125.634 9.17627 125.634 9.53613V30.4648C125.634 30.8281 125.634 31.1826 125.627 31.54C125.627 31.8652 125.627 32.1631 125.623 32.4697C125.611 33.0909 125.554 33.7104 125.452 34.3232C125.358 34.9043 125.176 35.4675 124.912 35.9932C124.648 36.5056 124.305 36.9733 123.896 37.3789C123.488 37.7927 123.015 38.1379 122.497 38.4014C121.972 38.6674 121.409 38.8527 120.829 38.9512C120.211 39.0522 119.586 39.1067 118.96 39.1143C118.667 39.1211 118.36 39.125 118.062 39.125L116.978 39.127L8.4448 39.125Z"
        fill="black"
      />
      <path
        d="M24.7718 20.3007C24.7826 19.466 25.0043 18.6477 25.4163 17.9217C25.8283 17.1957 26.4173 16.5858 27.1284 16.1486C26.6766 15.5035 26.0807 14.9725 25.3878 14.598C24.695 14.2234 23.9244 14.0156 23.1372 13.991C21.458 13.8147 19.83 14.9958 18.9743 14.9958C18.102 14.9958 16.7845 14.0085 15.3658 14.0376C14.4481 14.0673 13.5538 14.3341 12.7699 14.8122C11.986 15.2903 11.3393 15.9632 10.8929 16.7655C8.95888 20.1139 10.4015 25.035 12.2541 27.7416C13.181 29.0669 14.2643 30.5474 15.6817 30.4949C17.0688 30.4374 17.5868 29.6104 19.2611 29.6104C20.9199 29.6104 21.4059 30.4949 22.8521 30.4615C24.3405 30.4374 25.2782 29.1303 26.1726 27.7924C26.8386 26.848 27.351 25.8043 27.691 24.6999C26.8263 24.3341 26.0884 23.7219 25.5693 22.9397C25.0502 22.1574 24.7728 21.2396 24.7718 20.3007Z"
        fill="white"
      />
      <path
        d="M22.0402 12.2109C22.8517 11.2367 23.2515 9.98451 23.1547 8.72028C21.9149 8.8505 20.7696 9.44306 19.9471 10.3799C19.545 10.8376 19.237 11.37 19.0408 11.9468C18.8445 12.5235 18.7639 13.1333 18.8034 13.7413C19.4236 13.7477 20.0371 13.6132 20.5977 13.3482C21.1584 13.0831 21.6516 12.6942 22.0402 12.2109Z"
        fill="white"
      />
      <path
        d="M35.6553 14.7017V9.57813H33.7783V8.73486H38.4551V9.57812H36.582V14.7017H35.6553Z"
        fill="white"
      />
      <path
        d="M42.7647 13.4858C42.6439 13.8974 42.3824 14.2534 42.0257 14.4915C41.669 14.7297 41.2399 14.8348 40.8135 14.7886C40.5169 14.7964 40.222 14.7396 39.9495 14.622C39.677 14.5045 39.4334 14.3291 39.2355 14.108C39.0376 13.8868 38.8901 13.6253 38.8034 13.3414C38.7168 13.0576 38.6929 12.7583 38.7334 12.4643C38.6939 12.1695 38.7182 11.8695 38.8047 11.5849C38.8912 11.3002 39.0379 11.0374 39.2347 10.8143C39.4316 10.5913 39.6741 10.4131 39.9458 10.2919C40.2175 10.1707 40.5121 10.1093 40.8096 10.1118C42.0625 10.1118 42.8184 10.9678 42.8184 12.3818V12.6919H39.6387V12.7417C39.6248 12.9069 39.6456 13.0732 39.6997 13.23C39.7538 13.3867 39.8401 13.5305 39.953 13.6519C40.0659 13.7734 40.2029 13.8699 40.3553 13.9353C40.5077 14.0007 40.6721 14.0335 40.8379 14.0317C41.0505 14.0572 41.2659 14.0189 41.4566 13.9217C41.6474 13.8245 41.8049 13.6728 41.9092 13.4858L42.7647 13.4858ZM39.6387 12.0347H41.9131C41.9243 11.8835 41.9038 11.7317 41.8528 11.589C41.8019 11.4463 41.7216 11.3158 41.6172 11.206C41.5129 11.0961 41.3866 11.0093 41.2467 10.9511C41.1068 10.893 40.9562 10.8647 40.8047 10.8682C40.651 10.8662 40.4985 10.8951 40.3561 10.953C40.2137 11.011 40.0844 11.0968 39.9757 11.2055C39.8671 11.3143 39.7813 11.4436 39.7234 11.586C39.6655 11.7284 39.6367 11.881 39.6387 12.0347H39.6387ZM40.2754 9.4458L41.3135 8.02344H42.3555L41.1934 9.4458H40.2754Z"
        fill="white"
      />
      <path
        d="M44.0527 8.44092H44.9414V14.7017H44.0527V8.44092Z"
        fill="white"
      />
      <path
        d="M50.208 13.4858C50.0873 13.8974 49.8257 14.2534 49.469 14.4915C49.1123 14.7297 48.6832 14.8348 48.2568 14.7886C47.9602 14.7964 47.6654 14.7396 47.3929 14.622C47.1204 14.5045 46.8767 14.3291 46.6788 14.108C46.4809 13.8868 46.3335 13.6253 46.2468 13.3414C46.1601 13.0576 46.1362 12.7583 46.1768 12.4643C46.1373 12.1695 46.1616 11.8695 46.2481 11.5849C46.3346 11.3002 46.4812 11.0374 46.6781 10.8143C46.8749 10.5913 47.1174 10.4131 47.3891 10.2919C47.6608 10.1707 47.9554 10.1093 48.2529 10.1118C49.5059 10.1118 50.2617 10.9678 50.2617 12.3818V12.6919H47.082V12.7417C47.0681 12.9069 47.0889 13.0732 47.143 13.23C47.1971 13.3867 47.2834 13.5305 47.3963 13.6519C47.5092 13.7734 47.6463 13.8699 47.7987 13.9353C47.951 14.0007 48.1154 14.0335 48.2812 14.0317C48.4938 14.0572 48.7092 14.0189 48.8999 13.9217C49.0907 13.8245 49.2482 13.6728 49.3525 13.4858L50.208 13.4858ZM47.082 12.0347H49.3564C49.3676 11.8835 49.3471 11.7317 49.2961 11.589C49.2452 11.4463 49.1649 11.3158 49.0605 11.206C48.9562 11.0961 48.8299 11.0093 48.69 10.9511C48.5501 10.893 48.3995 10.8647 48.248 10.8682C48.0943 10.8662 47.9418 10.8951 47.7994 10.953C47.657 11.011 47.5277 11.0968 47.419 11.2055C47.3104 11.3143 47.2246 11.4436 47.1667 11.586C47.1088 11.7284 47.08 11.881 47.082 12.0347ZM47.7187 9.4458L48.7568 8.02344H49.7988L48.6367 9.4458H47.7187Z"
        fill="white"
      />
      <path
        d="M54.4033 11.6704C54.3467 11.4369 54.2083 11.2314 54.0132 11.0912C53.8181 10.9509 53.5792 10.8851 53.3399 10.9058C52.5957 10.9058 52.1406 11.4761 52.1406 12.4356C52.1406 13.4116 52.5996 13.9946 53.3399 13.9946C53.5769 14.0221 53.8158 13.962 54.0116 13.8257C54.2075 13.6894 54.3468 13.4862 54.4033 13.2544H55.2676C55.2104 13.7104 54.9774 14.126 54.6182 14.4126C54.259 14.6992 53.802 14.8341 53.3447 14.7886C53.0437 14.7981 52.7441 14.7417 52.4671 14.6234C52.1901 14.505 51.9423 14.3276 51.741 14.1035C51.5397 13.8794 51.3898 13.614 51.3018 13.3259C51.2138 13.0378 51.1898 12.7339 51.2315 12.4356C51.1919 12.1395 51.2174 11.8382 51.3063 11.553C51.3951 11.2677 51.5452 11.0053 51.746 10.784C51.9467 10.5628 52.1934 10.388 52.4687 10.2719C52.744 10.1558 53.0413 10.1012 53.3399 10.1119C53.8009 10.0694 54.2603 10.2085 54.6203 10.4996C54.9803 10.7907 55.2126 11.2108 55.2676 11.6705L54.4033 11.6704Z"
        fill="white"
      />
      <path
        d="M56.4473 8.44092H57.3281V10.9224H57.3985C57.5166 10.663 57.7118 10.4465 57.9575 10.3021C58.2032 10.1578 58.4874 10.0927 58.7715 10.1157C58.9894 10.1039 59.2073 10.1403 59.4096 10.2223C59.6119 10.3043 59.7935 10.43 59.9417 10.5904C60.0898 10.7507 60.2007 10.9417 60.2665 11.1499C60.3323 11.358 60.3513 11.5781 60.3222 11.7944V14.7017H59.4326V12.0137C59.4326 11.2944 59.0976 10.9302 58.4697 10.9302C58.317 10.9176 58.1633 10.9386 58.0195 10.9917C57.8758 11.0447 57.7453 11.1286 57.6373 11.2373C57.5293 11.346 57.4464 11.4771 57.3943 11.6212C57.3423 11.7653 57.3223 11.9191 57.3359 12.0718V14.7016H56.4472L56.4473 8.44092Z"
        fill="white"
      />
      <path
        d="M61.4395 13.4282C61.4395 12.6177 62.043 12.1504 63.1143 12.084L64.334 12.0137V11.625C64.334 11.1494 64.0196 10.8809 63.4121 10.8809C62.916 10.8809 62.5723 11.063 62.4737 11.3813H61.6133C61.7041 10.6079 62.4317 10.1118 63.4531 10.1118C64.5821 10.1118 65.2183 10.6738 65.2183 11.625V14.7017H64.3633V14.0688H64.293C64.1503 14.2958 63.9499 14.4809 63.7123 14.6051C63.4747 14.7293 63.2083 14.7882 62.9405 14.7758C62.7514 14.7955 62.5604 14.7753 62.3796 14.7166C62.1989 14.658 62.0324 14.562 61.891 14.4351C61.7496 14.3081 61.6363 14.1529 61.5586 13.9795C61.4808 13.8061 61.4402 13.6183 61.4395 13.4282ZM64.334 13.0434V12.667L63.2344 12.7373C62.6143 12.7788 62.333 12.9897 62.333 13.3867C62.333 13.792 62.6846 14.0278 63.168 14.0278C63.3097 14.0422 63.4528 14.0279 63.5888 13.9858C63.7248 13.9437 63.8509 13.8747 63.9597 13.7828C64.0685 13.691 64.1577 13.5782 64.222 13.4512C64.2863 13.3242 64.3244 13.1855 64.334 13.0435L64.334 13.0434Z"
        fill="white"
      />
      <path
        d="M66.6099 10.1987H67.4654V10.8892H67.5318C67.6275 10.6495 67.7968 10.4463 68.0152 10.3089C68.2337 10.1714 68.4901 10.1067 68.7476 10.124C68.8807 10.1222 69.0136 10.1347 69.1441 10.1611V11.0381C68.981 11.0033 68.8147 10.9853 68.648 10.9844C68.5014 10.9713 68.3537 10.9889 68.2144 11.0362C68.075 11.0836 67.9471 11.1595 67.8389 11.2592C67.7306 11.3589 67.6444 11.4801 67.5858 11.6151C67.5272 11.7501 67.4975 11.8959 67.4986 12.043V14.7017H66.6099L66.6099 10.1987Z"
        fill="white"
      />
      <path
        d="M69.9615 15.1523H70.8706C70.9458 15.479 71.3218 15.6899 71.9214 15.6899C72.6617 15.6899 73.1001 15.3384 73.1001 14.7431V13.8789H73.0337C72.8943 14.1208 72.6908 14.3195 72.4455 14.453C72.2003 14.5864 71.9229 14.6495 71.6441 14.6352C70.4947 14.6352 69.7837 13.7466 69.7837 12.3984C69.7837 11.0254 70.5025 10.124 71.6529 10.124C71.9387 10.114 72.2218 10.1828 72.4711 10.3228C72.7205 10.4629 72.9266 10.6687 73.0669 10.9179H73.1372V10.1987H73.9888V14.7387C73.9888 15.7685 73.1822 16.4218 71.9087 16.4218C70.7837 16.4219 70.0562 15.9175 69.9615 15.1523ZM73.1167 12.394C73.1167 11.497 72.6529 10.9223 71.896 10.9223C71.1314 10.9223 70.7017 11.497 70.7017 12.394C70.7017 13.2915 71.1314 13.8662 71.896 13.8662C72.6578 13.8662 73.1167 13.2959 73.1167 12.394V12.394Z"
        fill="white"
      />
      <path
        d="M79.2124 13.4859C79.0917 13.8974 78.8301 14.2534 78.4734 14.4915C78.1167 14.7297 77.6876 14.8348 77.2612 14.7886C76.9646 14.7964 76.6698 14.7396 76.3973 14.6221C76.1248 14.5045 75.8811 14.3291 75.6832 14.108C75.4853 13.8868 75.3379 13.6253 75.2512 13.3415C75.1645 13.0576 75.1406 12.7584 75.1812 12.4644C75.1417 12.1695 75.166 11.8696 75.2525 11.5849C75.3389 11.3002 75.4856 11.0374 75.6825 10.8144C75.8793 10.5913 76.1218 10.4131 76.3935 10.2919C76.6652 10.1707 76.9598 10.1093 77.2573 10.1118C78.5103 10.1118 79.2661 10.9678 79.2661 12.3818V12.6919H76.0864V12.7417C76.0725 12.9069 76.0933 13.0733 76.1474 13.23C76.2015 13.3868 76.2878 13.5305 76.4007 13.6519C76.5136 13.7734 76.6507 13.8699 76.8031 13.9353C76.9555 14.0007 77.1198 14.0335 77.2857 14.0317C77.4982 14.0572 77.7136 14.0189 77.9044 13.9217C78.0951 13.8245 78.2527 13.6728 78.357 13.4858L79.2124 13.4859ZM76.0864 12.0347H78.3608C78.372 11.8836 78.3515 11.7318 78.3005 11.589C78.2496 11.4463 78.1693 11.3158 78.0649 11.206C77.9605 11.0961 77.8343 11.0093 77.6944 10.9512C77.5545 10.893 77.4039 10.8647 77.2524 10.8682C77.0987 10.8663 76.9462 10.8951 76.8038 10.9531C76.6615 11.011 76.5321 11.0969 76.4235 11.2056C76.3148 11.3143 76.229 11.4437 76.1711 11.5861C76.1132 11.7285 76.0844 11.881 76.0864 12.0347H76.0864Z"
        fill="white"
      />
      <path
        d="M80.4595 10.1987H81.315V10.8892H81.3814C81.4772 10.6495 81.6465 10.4463 81.8649 10.3089C82.0833 10.1714 82.3397 10.1067 82.5973 10.124C82.7304 10.1222 82.8633 10.1347 82.9937 10.1611V11.0381C82.8306 11.0033 82.6644 10.9853 82.4976 10.9844C82.3511 10.9713 82.2034 10.9889 82.064 11.0362C81.9246 11.0835 81.7967 11.1595 81.6885 11.2592C81.5802 11.3589 81.494 11.4801 81.4354 11.6151C81.3768 11.7501 81.3471 11.8959 81.3482 12.043V14.7017H80.4595V10.1987Z"
        fill="white"
      />
      <path
        d="M86.1958 12.4482C86.1958 11.0254 86.9273 10.124 88.065 10.124C88.3464 10.1111 88.6257 10.1785 88.8702 10.3183C89.1147 10.4582 89.3144 10.6648 89.4458 10.914H89.5122V8.44092H90.4009V14.7017H89.5494V13.9902H89.479C89.3374 14.2377 89.1308 14.4418 88.8815 14.5802C88.6323 14.7187 88.3499 14.7864 88.065 14.7759C86.9195 14.7759 86.1958 13.8745 86.1958 12.4482ZM87.1138 12.4482C87.1138 13.4033 87.564 13.978 88.317 13.978C89.066 13.978 89.5289 13.395 89.5289 12.4521C89.5289 11.5137 89.0611 10.9224 88.317 10.9224C87.5689 10.9224 87.1138 11.501 87.1138 12.4482H87.1138Z"
        fill="white"
      />
      <path
        d="M91.6021 13.4282C91.6021 12.6177 92.2056 12.1504 93.2769 12.084L94.4966 12.0137V11.625C94.4966 11.1494 94.1822 10.8809 93.5747 10.8809C93.0787 10.8809 92.7349 11.063 92.6363 11.3813H91.7759C91.8667 10.6079 92.5943 10.1118 93.6158 10.1118C94.7447 10.1118 95.3814 10.6738 95.3814 11.625V14.7017H94.5259V14.0688H94.4556C94.3129 14.2958 94.1125 14.4809 93.8749 14.6051C93.6372 14.7293 93.3709 14.7882 93.1031 14.7758C92.914 14.7955 92.723 14.7753 92.5422 14.7166C92.3615 14.6579 92.195 14.562 92.0536 14.435C91.9122 14.3081 91.7989 14.1529 91.7212 13.9795C91.6434 13.8061 91.6028 13.6183 91.6021 13.4282ZM94.4966 13.0434V12.667L93.397 12.7373C92.7769 12.7788 92.4957 12.9897 92.4957 13.3867C92.4957 13.792 92.8472 14.0278 93.3307 14.0278C93.4723 14.0422 93.6154 14.0278 93.7514 13.9858C93.8874 13.9437 94.0135 13.8746 94.1223 13.7828C94.2311 13.691 94.3203 13.5782 94.3846 13.4512C94.4489 13.3242 94.487 13.1855 94.4966 13.0435V13.0434Z"
        fill="white"
      />
      <path
        d="M96.773 10.1987H97.6285V10.914H97.6949C97.8075 10.6571 97.9975 10.4418 98.2384 10.2979C98.4793 10.1541 98.759 10.0891 99.0386 10.1118C99.2577 10.0953 99.4777 10.1284 99.6823 10.2085C99.8869 10.2885 100.071 10.4137 100.221 10.5745C100.37 10.7353 100.482 10.9278 100.547 11.1376C100.612 11.3474 100.629 11.5692 100.597 11.7866V14.7016H99.7086V12.0098C99.7086 11.2861 99.3941 10.9263 98.7369 10.9263C98.5881 10.9193 98.4396 10.9446 98.3016 11.0005C98.1635 11.0563 98.0392 11.1413 97.937 11.2497C97.8349 11.3581 97.7574 11.4873 97.7099 11.6284C97.6624 11.7695 97.6459 11.9193 97.6617 12.0674V14.7016H96.773V10.1987Z"
        fill="white"
      />
      <path
        d="M103.618 10.1118C104.63 10.1118 105.293 10.583 105.379 11.377H104.527C104.445 11.0464 104.122 10.8355 103.618 10.8355C103.122 10.8355 102.745 11.0708 102.745 11.4224C102.745 11.6914 102.972 11.8608 103.461 11.9727L104.209 12.146C105.065 12.3447 105.466 12.7129 105.466 13.3745C105.466 14.2222 104.676 14.7886 103.601 14.7886C102.53 14.7886 101.832 14.3047 101.752 13.5068H102.642C102.719 13.6967 102.857 13.8554 103.035 13.9573C103.212 14.0592 103.419 14.0985 103.622 14.0688C104.175 14.0688 104.569 13.8208 104.569 13.4609C104.569 13.1924 104.358 13.0185 103.907 12.9111L103.122 12.729C102.265 12.5264 101.869 12.1421 101.869 11.4722C101.869 10.6738 102.6 10.1118 103.618 10.1118Z"
        fill="white"
      />
      <path
        d="M35.1982 18.0669H37.0576V30.4854H35.1982V18.0669Z"
        fill="white"
      />
      <path
        d="M39.2979 22.6108L40.3135 18.0669H42.1201L40.8897 22.6108H39.2979Z"
        fill="white"
      />
      <path
        d="M49.1465 27.1289H44.4131L43.2764 30.4854H41.2715L45.7549 18.0669H47.8379L52.3213 30.4854H50.2822L49.1465 27.1289ZM44.9033 25.5801H48.6553L46.8057 20.1323H46.754L44.9033 25.5801Z"
        fill="white"
      />
      <path
        d="M62.0029 25.959C62.0029 28.7725 60.4971 30.5801 58.2246 30.5801C57.649 30.6102 57.0765 30.4776 56.5727 30.1974C56.0689 29.9173 55.6541 29.501 55.376 28.9961H55.333V33.4804H53.4746V21.4311H55.2734V22.937H55.3076C55.5985 22.4345 56.0203 22.0203 56.5279 21.7385C57.0355 21.4566 57.6101 21.3177 58.1904 21.3364C60.4883 21.3364 62.0029 23.1528 62.0029 25.959ZM60.0928 25.959C60.0928 24.1255 59.1455 22.9204 57.7002 22.9204C56.2803 22.9204 55.3252 24.1509 55.3252 25.959C55.3252 27.7832 56.2803 29.0049 57.7002 29.0049C59.1455 29.0049 60.0928 27.8086 60.0928 25.959Z"
        fill="white"
      />
      <path
        d="M71.9673 25.959C71.9673 28.7725 70.4614 30.5801 68.189 30.5801C67.6133 30.6102 67.0408 30.4776 66.537 30.1974C66.0332 29.9173 65.6185 29.501 65.3403 28.9961H65.2973V33.4804H63.4395V21.4311H65.2378V22.937H65.272C65.563 22.4345 65.9847 22.0203 66.4923 21.7385C66.9999 21.4566 67.5745 21.3177 68.1548 21.3364C70.4526 21.3364 71.9673 23.1528 71.9673 25.959ZM70.0571 25.959C70.0571 24.1255 69.1099 22.9204 67.6646 22.9204C66.2446 22.9204 65.2896 24.1509 65.2896 25.959C65.2896 27.7832 66.2446 29.0049 67.6646 29.0049C69.1099 29.0049 70.0571 27.8086 70.0571 25.959Z"
        fill="white"
      />
      <path
        d="M78.5532 27.0254C78.6909 28.2568 79.8872 29.0654 81.522 29.0654C83.0884 29.0654 84.2153 28.2568 84.2153 27.1464C84.2153 26.1826 83.5357 25.6054 81.9263 25.2099L80.3169 24.8217C78.0366 24.271 76.978 23.2046 76.978 21.4741C76.978 19.3315 78.8452 17.8599 81.4966 17.8599C84.1206 17.8599 85.9194 19.3315 85.98 21.4741H84.104C83.9917 20.2349 82.9673 19.4868 81.4702 19.4868C79.9731 19.4868 78.9487 20.2437 78.9487 21.3452C78.9487 22.2232 79.603 22.7398 81.2036 23.1352L82.5718 23.4712C85.1196 24.0737 86.1782 25.0976 86.1782 26.914C86.1782 29.2373 84.3276 30.6924 81.3843 30.6924C78.6304 30.6924 76.771 29.2715 76.6509 27.0254L78.5532 27.0254Z"
        fill="white"
      />
      <path
        d="M90.19 19.2886V21.4312H91.9117V22.9028H90.19V27.8945C90.19 28.6699 90.5347 29.0313 91.2915 29.0313C91.4959 29.0277 91.7 29.0133 91.9029 28.9883V30.4511C91.5626 30.5147 91.2167 30.5435 90.8706 30.5371C89.0376 30.5371 88.3228 29.8486 88.3228 28.0927V22.9028H87.0063V21.4312H88.3228V19.2886H90.19Z"
        fill="white"
      />
      <path
        d="M92.9077 25.959C92.9077 23.1099 94.5854 21.3198 97.2017 21.3198C99.8267 21.3198 101.497 23.1098 101.497 25.959C101.497 28.8154 99.8355 30.5977 97.2017 30.5977C94.5688 30.5976 92.9077 28.8154 92.9077 25.959ZM99.603 25.959C99.603 24.0044 98.7075 22.8511 97.2017 22.8511C95.6958 22.8511 94.8013 24.0132 94.8013 25.959C94.8013 27.9209 95.6958 29.0654 97.2017 29.0654C98.7075 29.0654 99.603 27.9209 99.603 25.959H99.603Z"
        fill="white"
      />
      <path
        d="M103.029 21.4312H104.801V22.9722H104.844C104.964 22.4909 105.246 22.0655 105.643 21.7676C106.039 21.4698 106.526 21.3175 107.022 21.3364C107.236 21.3357 107.45 21.3589 107.659 21.4058V23.144C107.388 23.0614 107.106 23.0235 106.824 23.0317C106.554 23.0208 106.285 23.0684 106.035 23.1713C105.785 23.2742 105.56 23.43 105.376 23.628C105.192 23.8259 105.053 24.0613 104.969 24.318C104.884 24.5748 104.857 24.8467 104.887 25.1152V30.4853H103.029L103.029 21.4312Z"
        fill="white"
      />
      <path
        d="M116.226 27.8262C115.976 29.4697 114.375 30.5977 112.328 30.5977C109.694 30.5977 108.059 28.833 108.059 26.002C108.059 23.1616 109.703 21.3198 112.25 21.3198C114.754 21.3198 116.33 23.0405 116.33 25.7861V26.4229H109.935V26.5352C109.906 26.8684 109.947 27.204 110.057 27.5199C110.168 27.8357 110.344 28.1245 110.574 28.367C110.804 28.6096 111.084 28.8003 111.394 28.9266C111.703 29.053 112.036 29.1119 112.371 29.0996C112.81 29.1408 113.25 29.039 113.627 28.8096C114.004 28.5802 114.296 28.2352 114.461 27.8262L116.226 27.8262ZM109.944 25.124H114.47C114.487 24.8244 114.442 24.5245 114.337 24.2433C114.232 23.962 114.071 23.7054 113.862 23.4896C113.654 23.2738 113.403 23.1034 113.125 22.9891C112.848 22.8749 112.55 22.8192 112.25 22.8257C111.947 22.8239 111.647 22.882 111.367 22.9968C111.087 23.1116 110.832 23.2807 110.618 23.4944C110.403 23.7081 110.233 23.9621 110.118 24.2418C110.002 24.5215 109.943 24.8213 109.944 25.124Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4846_2943">
        <rect width="126.508" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreBadge;
