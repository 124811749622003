import { useMapTools } from '@Src/hooks/useMapTools';
import { useConfig } from '@Src/providers/ConfigProvider';
import {
  Status,
  type AddressI18nFormScheme,
  type Async,
  type Coordinates,
  type GeoCodeResponse,
} from '@Src/types/deliveryLocationFormTypes';
import type React from 'react';
import { useEffect, useState } from 'react';
import { ConfirmAddressForm } from './ConfirmAddressForm';
import useApi from '@Src/hooks/useApi';

const getGeoCodeResults = async (
  location: Coordinates,
  GeoCoder: google.maps.Geocoder,
): Promise<GeoCodeResponse> => {
  let geocodeResults: google.maps.GeocoderResult[] | null = [];
  let geocodeStatus = 'ZERO_RESULTS' as google.maps.GeocoderStatus;

  await GeoCoder.geocode(
    { location: { lat: location.Latitude, lng: location.Longitude } },
    (res: google.maps.GeocoderResult[] | null, status) => {
      geocodeResults = res;
      geocodeStatus = status;
    },
  );

  return { results: geocodeResults, status: geocodeStatus };
};

type DeliveryConfirmLocationProps = {
  autoFillFields: boolean;
  coordinates: Coordinates;
  onBackClick: () => void;
};
export const DeliveryConfirmLocation: React.FC<
  DeliveryConfirmLocationProps
> = ({ autoFillFields, coordinates, onBackClick }): JSX.Element => {
  const { language } = useConfig();
  const [mapTools] = useMapTools();
  const { post } = useApi();

  const [locationFormScheme, setLocationFormScheme] = useState<
    Async<AddressI18nFormScheme | undefined>
  >({ status: Status.LOADING });

  const [googleGeocodeResponse, setGoogleGeocodeResponse] = useState<
    Async<GeoCodeResponse>
  >({ status: Status.LOADING });

  useEffect(() => {
    void (async () => {
      if (mapTools?.GeoCoder) {
        try {
          const geoCodeResponse = await getGeoCodeResults(
            {
              Latitude: coordinates.Latitude,
              Longitude: coordinates.Longitude,
            },
            mapTools?.GeoCoder,
          );
          setGoogleGeocodeResponse({
            data: geoCodeResponse,
            status: Status.SUCCESS,
          });

          if (geoCodeResponse.status === 'OK') {
            try {
              const { data: formScheme } = await post<
                GeoCodeResponse,
                AddressI18nFormScheme
              >({
                path: `/address/formatGoogleAddress?language=${language}`,
                payload: geoCodeResponse,
                appType: 'Web',
              });

              setLocationFormScheme({
                data: formScheme?.Data,
                status: Status.SUCCESS,
              });
            } catch (e) {
              setLocationFormScheme({ status: Status.ERROR });
            }
          }
        } catch (e) {
          setGoogleGeocodeResponse({ status: Status.ERROR });
        }
      }
    })();
  }, [mapTools?.GeoCoder]);

  const isError =
    locationFormScheme.status === Status.ERROR ||
    googleGeocodeResponse.status === Status.ERROR ||
    (googleGeocodeResponse.status === Status.SUCCESS &&
      googleGeocodeResponse.data.status !== 'OK');

  if (isError) {
    // TODO: show error screen
    return <></>;
  }

  if (
    locationFormScheme.status === Status.LOADING ||
    googleGeocodeResponse.status === Status.LOADING
  ) {
    // TODO: show loading screen
    return <></>;
  }

  return (
    <ConfirmAddressForm
      autoFillFields={autoFillFields}
      coordinates={coordinates}
      locationFormScheme={locationFormScheme.data}
      onBackClick={onBackClick}
    />
  );
};
