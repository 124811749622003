import { useDesignSchema } from '@Providers/DesignSchemaProvider';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useCurrentPage } from '@Src/hooks/useCurrentPage';
import { V2PageType } from '@Src/middlewares/CustomPageTypes';

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1DisplayXs,

  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h1,
  },
}));

export const WelcomeText = (): JSX.Element => {
  const { translate } = useTranslation();
  const { name } = useDesignSchema('v2');
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const currentPageData = useCurrentPage();

  if (currentPageData?.pageType !== V2PageType.LandingPage) {
    return <></>;
  }

  const { loggedInHeading, loggedOutHeading, brandNameHeadingEnabled } =
    currentPageData;

  let headingText = '';
  if (isLoggedIn) {
    headingText = loggedInHeading || '';
  } else {
    headingText = loggedOutHeading || '';
  }

  return (
    <>
      {headingText || brandNameHeadingEnabled ? (
        <div>
          {brandNameHeadingEnabled && (
            <Typography variant="body1Medium">{name}</Typography>
          )}
          {headingText && (
            <StyledTypography>{translate(headingText)}</StyledTypography>
          )}
        </div>
      ) : null}
    </>
  );
};
