import { type MenuPreviewSection } from '@Components/LegacyElixir/MenuPreview/MenuAPITypes';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useEffect, useState } from 'react';
import useApi from './useApi';
import { useLogger } from '@Src/providers/LoggerProvider';
import { type MenuItemHighlight } from '@Components/WebsiteV2/Menu/types';

export const useGetMenuDataFromStoreId = (
  formattingFunction: any,
  options: Record<string, unknown> = {},
): MenuPreviewSection[] | MenuItemHighlight[] => {
  const [menuData, setMenuData] = useState<
    MenuPreviewSection[] | MenuItemHighlight[]
  >([]);
  const { appId, pickupStoreId } = useDesignSchema();
  const { get } = useApi();
  const logger = useLogger();

  useEffect(() => {
    try {
      if (pickupStoreId) {
        const sessionStorageKey = `menuData-${appId}`;
        const menuFromSession =
          window.sessionStorage.getItem(sessionStorageKey);
        if (menuFromSession) {
          const menuData = JSON.parse(menuFromSession);
          setMenuData(menuData);
        } else {
          const fetchMenuData = async (): Promise<void> => {
            let formattedMenuPreview;
            const response = await get<{ MenuUrl?: string }>({
              path: `/Restaurant/PickupRestaurantDetails/${pickupStoreId}`,
            });
            const menuUrl = response?.data?.Data?.MenuUrl;
            if (menuUrl) {
              const menuData = await fetch(menuUrl);
              const menuJson = await menuData.json();
              formattedMenuPreview = formattingFunction({
                menu: menuJson,
                ...options,
              });

              window.sessionStorage.setItem(
                sessionStorageKey,
                JSON.stringify(formattedMenuPreview),
              );
            }
            setMenuData(formattedMenuPreview);
          };
          void (async () => await fetchMenuData())();
        }
      }
    } catch (e) {
      logger.warn('Error fetching menu data', { error: e, pickupStoreId });
    }
  }, [pickupStoreId]);

  return menuData;
};
