import {
  V2PageType,
  type V2ContactPageType,
  type V2ReservationsPageType,
  V2Templates,
} from '@Src/middlewares/CustomPageTypes';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import ContactFormWidget from '@Components/Widgets/ContactForms/ContactFormWidget';
import ReservationsFormWidget from '@Components/Widgets/ContactForms/ReservationsFormWidget';
import LocationsWidget from '@Components/Widgets/Locations';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { InnerGridPolice, InnerGridWithImagePolice } from '../BaseGridElements';
import { useTranslation } from '@Src/providers/TranslationProvider';

const StyledTitleGrid = styled(Grid)`
  display: ${({ theme }) =>
    theme.template === V2Templates.boxedUp ? 'none' : 'block'};
`;

const NoPaddingInnerGridPolice = styled(InnerGridPolice)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
`;

export const Contact = ({
  data,
}: {
  data: V2ContactPageType | V2ReservationsPageType;
}): JSX.Element => {
  const isMobile = useIsMobile();
  const { template } = useDesignSchema('v2');
  const theme = useTheme();
  const { translate } = useTranslation();
  const themePropKey = isMobile ? 'mobile' : 'default';
  const formPaddingLeft = `${theme.boxedUp.contact.formPadding.left[themePropKey]}px`;
  const formPaddingRight = `${theme.boxedUp.contact.formPadding.right[themePropKey]}px`;
  const formPaddingTop = `${theme.boxedUp.contact.formPadding.top[themePropKey]}px`;
  const formPaddingBottom = `${theme.boxedUp.contact.formPadding.bottom[themePropKey]}px`;
  const buttonBorderRadius = `${theme.boxedUp.buttonBorderRadius}px`;
  const contactWidgetParams =
    template === V2Templates.boxedUp
      ? {
          buttonBorderRadius,
          formPaddingLeft,
          formPaddingRight,
          formPaddingBottom,
          formPaddingTop,
        }
      : {};

  const InnerHolder =
    template === V2Templates.boxedUp ? 'div' : NoPaddingInnerGridPolice;

  return (
    <>
      <StyledTitleGrid item xs={12}>
        {data?.title && (
          <NoPaddingInnerGridPolice>
            <Typography variant={isMobile ? 'h2Display' : 'h1'}>
              {translate(data.title)}
            </Typography>
          </NoPaddingInnerGridPolice>
        )}
      </StyledTitleGrid>
      <InnerGridWithImagePolice isShort>
        <InnerHolder>
          {data.pageType === V2PageType.ContactPage && (
            <ContactFormWidget
              contactFormData={data}
              {...contactWidgetParams}
            />
          )}
          {data.pageType === V2PageType.ReservationsPage && (
            <ReservationsFormWidget
              reservationsData={data}
              {...contactWidgetParams}
            />
          )}
        </InnerHolder>
      </InnerGridWithImagePolice>
      {data.locationsEnabled && (
        <InnerGridPolice>
          <LocationsWidget />
        </InnerGridPolice>
      )}
    </>
  );
};

export default Contact;
