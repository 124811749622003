import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ReactComponent as Receipt } from '@Src/assets/images/Receipt.svg';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useConfig } from '@Src/providers/ConfigProvider';

type StyledContainerProps = {
  ownerState: {
    borderWidth?: string;
    borderRadius?: string;
    isVertical?: boolean;
  };
};

const StyledContainer = styled('div')<StyledContainerProps>(
  ({ ownerState, theme }) => ({
    alignItems: 'center',
    borderColor: theme.palette.secondary.lowContrast,
    borderRadius: ownerState.borderRadius,
    borderWidth: ownerState.borderWidth || '1px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1.5),
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    ...(ownerState.isVertical && {
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: `${theme.spacing(9)} ${theme.spacing(4.5)}`,
    }),
  }),
);

const StyledDescription = styled(Typography)`
  text-align: center;
`;

type StyledActionButtonProps = {
  ownerState: {
    borderRadius?: string;
    borderWidth?: string;
    primaryColor?: string;
    textColor?: string;
    isVertical?: boolean;
  };
};

const StyledOrderButton = styled(Button)<StyledActionButtonProps>(
  ({ theme, ownerState }) => ({
    borderColor: ownerState.primaryColor,
    borderRadius: ownerState.borderRadius,
    borderWidth: ownerState.borderWidth || '1px',
    backgroundColor: 'transparent',
    color: ownerState.textColor,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 0,
    marginTop: theme.spacing(1.5),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    textTransform: 'none',

    '&:hover': {
      borderWidth: ownerState.borderWidth,
    },
    ...(!ownerState.isVertical && {
      marginTop: 0,
    }),
  }),
);

const StyleReceipt = styled(Receipt)<{ ownerState: { isVertical?: boolean } }>(
  ({ ownerState, theme }) => ({
    color: theme.palette.primary.contrastText,
    ...(ownerState.isVertical
      ? {
          marginBottom: theme.spacing(1.5),
        }
      : {
          marginRight: theme.spacing(1),
        }),
  }),
);

const StyledOrderNowIconContainer = styled('div')<{
  ownerState: { isVertical?: boolean };
}>(({ ownerState, theme }) => ({
  ...(ownerState.isVertical
    ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5),
      }),
}));

const StyledTextContainer = styled('div')<{
  ownerState: { isVertical?: boolean };
}>(({ ownerState }) => ({
  ...(ownerState.isVertical
    ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    : {
        maxWidth: '60%',
      }),
}));

export type Props = {
  borderRadius?: string;
  borderWidth?: string;
  primaryColor: string;
  textColor?: string;
  isVertical?: boolean;
  translate: (key: string) => string;
};

export function NoOrders(props: Props): JSX.Element {
  const {
    borderRadius,
    borderWidth,
    isVertical,
    primaryColor,
    textColor,
    translate,
  } = props;

  const { appId } = useDesignSchema();
  const { isCustomDomain } = useConfig();

  const baseUrl = isCustomDomain ? '' : `/${appId}`;
  const orderUrl = useAppSelector((state) => state.orderUrl.path);

  return (
    <StyledContainer
      ownerState={{
        borderRadius,
        borderWidth,
        isVertical,
      }}
    >
      <StyledTextContainer ownerState={{ isVertical }}>
        <StyledOrderNowIconContainer ownerState={{ isVertical }}>
          <StyleReceipt
            ownerState={{
              isVertical,
            }}
          />
          <Typography variant="h3">{translate('No_orders_yet')}</Typography>
        </StyledOrderNowIconContainer>
        <StyledDescription variant="caption">
          {translate('When_you_place_an_order')}
        </StyledDescription>
      </StyledTextContainer>
      <StyledOrderButton
        ownerState={{
          borderRadius,
          borderWidth,
          isVertical,
          primaryColor,
          textColor,
        }}
        href={`${baseUrl}${orderUrl}`}
        variant="outlined"
      >
        {translate('Order_now_lower')}
      </StyledOrderButton>
    </StyledContainer>
  );
}
