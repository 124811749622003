import Dialog from '@Components/ui/Dialog';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { setIsCollection } from '@Src/slices/orderSettingSlice';
import { type ButtonBaseProps } from '@mui/material';
import Button, { type ButtonTypeMap } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type React from 'react';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 3,
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '328px',
  },
  '& .MuiDialogActions-root': {
    padding: '0',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '> :not(:first-of-type)': {
      marginLeft: 0,
    },
  },
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const StyledBodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.body,
  marginBottom: theme.spacing(3),
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledActionBtnText = styled(Typography)`
  text-transform: none;
`;

type ActionButtonProps = {
  buttonText: string;
} & ButtonBaseProps &
  ButtonTypeMap['props'];

const ActionButton: React.FC<ActionButtonProps> = ({ buttonText, ...rest }) => (
  <StyledActionButton fullWidth size="large" {...rest}>
    <StyledActionBtnText variant="button">{buttonText}</StyledActionBtnText>
  </StyledActionButton>
);

const Actions: React.FC<{
  actionConfig: ActionButtonProps[];
  showCollection: boolean;
}> = ({ actionConfig, showCollection }) => (
  <>
    {showCollection ? (
      actionConfig.map((a) => (
        <ActionButton
          key={a.key}
          buttonText={a.buttonText}
          onClick={a.onClick}
          variant={a.variant}
        />
      ))
    ) : (
      <ActionButton
        key={actionConfig[0].key}
        buttonText={actionConfig[0].buttonText}
        onClick={actionConfig[0].onClick}
        variant="contained"
      />
    )}
  </>
);

export type DeliveryUnavailableProps = {
  goToFirstStep?: () => void;
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeliveryUnavailableModal: React.FC<DeliveryUnavailableProps> = ({
  goToFirstStep,
  isOpen,
  setIsModalOpen,
}) => {
  const { translate } = useTranslation();
  const { name: whitelabelName } = useDesignSchema();
  const dispatch = useAppDispatch();

  const collectionStores = useAppSelector(
    (state) => state.collectionStores.collectionStoreList,
  );

  const handleClick = ({ isCollection }: { isCollection: boolean }): void => {
    setIsModalOpen(false);
    dispatch(setIsCollection(isCollection));
    goToFirstStep?.();
  };

  const actionConfig: ActionButtonProps[] = [
    {
      key: 'Try_another_address',
      onClick: () => handleClick({ isCollection: false }),
      buttonText: translate('Try_another_address'),
      variant: 'outlined',
    },
    {
      key: 'Order_for_collection',
      onClick: () => handleClick({ isCollection: true }),
      buttonText: translate('Order_for_collection'),
      variant: 'contained',
    },
  ];

  return (
    <StyledDialog
      actions={
        <Actions
          actionConfig={actionConfig}
          showCollection={!!collectionStores?.length}
        />
      }
      ariaLabel={translate('Sorry_{restaurant}_does_not_deliver_to_address')}
      open={isOpen}
    >
      <StyledHeader variant="h2xs">
        {translate('Sorry_{restaurant}_does_not_deliver_to_address', {
          restaurant: whitelabelName,
        })}
      </StyledHeader>
      <StyledBodyText variant="body1xs" paragraph>
        {translate('Try_another_address_or_collection')}
      </StyledBodyText>
    </StyledDialog>
  );
};
