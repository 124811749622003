import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Reservations from './ReservationForm';
import ContactForm from './ContactForm';
import { useState } from 'react';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { ImgixImage } from '@Components/ui/ImgixImage';
import {
  type V2ContactPageType,
  type V2ReservationsPageType,
} from '@Src/middlewares/CustomPageTypes';
import StyledAlert from '@Components/ui/StyledAlert';
import { type SubmissionStatus } from './types';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

const StyledContainer = styled(Grid)<{
  ownerState: {
    borderRadius?: string;
    formPaddingTop?: string;
    buttonBorderRadius?: string;
  };
}>`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ ownerState }) => ownerState.borderRadius};
  #v2-contact,
  #v2-reservations {
    padding-top: ${({ ownerState }) => ownerState.formPaddingTop};
    button {
      border-radius: ${({ ownerState }) => ownerState.buttonBorderRadius};
    }
  }
`;

const StyledImgixImage = styled(ImgixImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledImageGrid = styled(Grid)<{
  ownerState: { paddingLeft?: string; paddingTop?: string };
}>`
  padding-top: ${({ theme, ownerState }) => ownerState?.paddingTop || 0};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme, ownerState }) => ownerState?.paddingLeft || 0};
  }
`;

const StyledGrid = styled(Grid)<{
  ownerState: {
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
}>(({ ownerState, theme }) => ({
  position: 'relative',
  paddingLeft: ownerState.paddingLeft && `${ownerState.paddingLeft} !important`,
  paddingRight: ownerState.paddingRight,
  paddingBottom: ownerState.paddingBottom,
  [theme.breakpoints.down('sm')]: {
    paddingLeft:
      ownerState.paddingLeft && `${ownerState.paddingLeft} !important`,
  },
}));

const StyledAlertPlaceholder = styled('div')`
  height: 52px;
`;

export const ContactForms = ({
  hasContactForm,
  hasReservations,
  reservationsFormDefaultSelected,
  contactFormData,
  reservationsData,
  borderRadius,
  buttonBorderRadius,
  formPaddingBottom,
  formPaddingLeft,
  formPaddingRight,
  formPaddingTop,
  imagePaddingLeft,
  imagePaddingTop,
}: {
  hasContactForm?: boolean;
  hasReservations?: boolean;
  reservationsFormDefaultSelected?: boolean;
  contactFormData?: V2ContactPageType;
  reservationsData?: V2ReservationsPageType;
  borderRadius?: string;
  buttonBorderRadius?: string;
  formPaddingTop?: string;
  formPaddingLeft?: string;
  formPaddingRight?: string;
  formPaddingBottom?: string;
  imagePaddingLeft?: string;
  imagePaddingTop?: string;
}): JSX.Element => {
  const { translate } = useTranslation();
  const { imageAttributes } = useDesignSchema('v2');
  const reservationFormSelected =
    !!reservationsFormDefaultSelected && !!hasReservations;

  const [contactFormSubmissionStatus, setContactFormSubmissionStatus] =
    useState<SubmissionStatus>('none');
  const [
    reservationsFormSubmissionStatus,
    setReservationsFormSubmissionStatus,
  ] = useState<SubmissionStatus>('none');

  let image = '';
  if (hasReservations && hasContactForm) {
    image = contactFormData?.image || reservationsData?.image || '';
  } else if (hasReservations) {
    image = reservationsData?.image || '';
  } else if (hasContactForm) {
    image = contactFormData?.image || '';
  }

  const getAlert = (): JSX.Element => {
    let severity: 'success' | 'error' | undefined, message;
    if (
      reservationsFormSubmissionStatus === 'success' &&
      reservationFormSelected
    ) {
      severity = 'success';
      message = translate('Reservation_submitted');
    } else if (
      reservationsFormSubmissionStatus === 'error' &&
      reservationFormSelected
    ) {
      severity = 'error';
      message = translate('There_has_been_issue');
    } else if (
      contactFormSubmissionStatus === 'success' &&
      !reservationFormSelected
    ) {
      severity = 'success';
      message = translate('Your_message_has_been_successfully_sent');
    } else if (
      contactFormSubmissionStatus === 'error' &&
      !reservationFormSelected
    ) {
      severity = 'error';
      message = translate('Sending_message_failed_try_again_later');
    }

    if (severity && message) {
      return <StyledAlert severity={severity} message={message} />;
    } else {
      return <StyledAlertPlaceholder />;
    }
  };

  return (
    <StyledContainer
      ownerState={{
        borderRadius,
        formPaddingTop,
        buttonBorderRadius,
      }}
      container
      spacing={2}
    >
      <StyledGrid
        item
        sm={12}
        md={6}
        ownerState={{
          paddingBottom: formPaddingBottom,
          paddingLeft: formPaddingLeft,
          paddingRight: formPaddingRight,
        }}
      >
        <Grid container>
          {!reservationFormSelected && (
            <Grid item xs={12}>
              <ContactForm
                formSubmissionStatus={contactFormSubmissionStatus}
                setFormSubmissionStatus={setContactFormSubmissionStatus}
              />
            </Grid>
          )}
          {reservationFormSelected && (
            <Grid item xs={12}>
              <Reservations
                daysAhead={reservationsData?.daysAhead}
                setFormSubmissionStatus={setReservationsFormSubmissionStatus}
                submissionStatus={reservationsFormSubmissionStatus}
              />
            </Grid>
          )}
        </Grid>
        {getAlert()}
      </StyledGrid>
      {image && (
        <StyledImageGrid
          ownerState={{
            paddingLeft: imagePaddingLeft,
            paddingTop: imagePaddingTop,
          }}
          item
          sm={12}
          md={6}
        >
          <StyledImgixImage
            loading="lazy"
            src={`${image || ''}?w=$600&ar=3:2&fit=crop&crop=entropy`}
            alt={imageAttributes?.[image]?.alt || ''}
          />
        </StyledImageGrid>
      )}
    </StyledContainer>
  );
};

export default ContactForms;
