import { type SupportedMethodsType } from '@Src/hooks/useApi';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';

type Props = {
  storeId: number;
  get: SupportedMethodsType['get'];
};
export const fetchStoreLeadTimes = createAsyncThunk(
  'storeLeadTimes/fetchStoreLeadTimes',
  async ({ storeId, get }: Props, thunkApi) => {
    try {
      thunkApi.dispatch({
        type: 'storeLeadTimes/fetchStoreLeadTimesStart',
      });

      const { data } = await get<{
        LeadTimeMinutes: {
          Collection: number;
          Delivery: number;
        };
      }>({ path: `/restaurant/leadtimes/${storeId}`, customHeaders: {} });

      return {
        [storeId]: {
          collection: data?.Data?.LeadTimeMinutes?.Collection,
          delivery: data?.Data?.LeadTimeMinutes?.Delivery,
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(`fetchStoreLeadTimes for ${storeId || 'undefined'}`, {
        message: e.message,
      });
      console.log('error', e);
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
