import { Alert, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledErrorAlert = styled(Alert)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
  path {
    fill: ${({ theme }) => theme.palette.error.contrastText};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledSuccessAlert = styled(Alert)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.success.main};
  // designs use background too
  color: ${({ theme }) => theme.palette.background.default};
  path {
    fill: ${({ theme }) => theme.palette.background.default};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

type StyledAlertProps = {
  severity: 'error' | 'success';
  message: string;
};

const StyledAlert = ({ severity, message }: StyledAlertProps): JSX.Element => (
  <>
    {severity === 'success' && (
      <StyledSuccessAlert severity="success">
        <Typography variant="body1xs">{message}</Typography>
      </StyledSuccessAlert>
    )}
    {severity === 'error' && (
      <StyledErrorAlert severity="error">
        <Typography variant="body1xs">{message}</Typography>
      </StyledErrorAlert>
    )}
  </>
);

export default StyledAlert;
