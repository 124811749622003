import { AMPLITUDE_API_KEY } from '@Src/BuildConstants';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import * as amplitude from '@amplitude/analytics-browser';

export const initializeAmplitude = (): void => {
  amplitude?.init(AMPLITUDE_API_KEY, {
    serverZone: 'EU',
    defaultTracking: true,
  });
  optInAmplitude();
};

export const optInAmplitude = (): void => {
  amplitude?.setOptOut(false);
};

export const optOutAmplitude = (): void => {
  amplitude?.setOptOut(true);
};

export const useTracking = (): {
  trackEvent: (eventName: string, options?: Record<string, string>) => void;
  identifyUser: () => void;
  trackNavigationEvent: (
    eventName: string,
    options?: Record<string, string>,
  ) => void;
  trackOrderEvent: (
    eventName: string,
    options?: Record<string, string>,
  ) => void;
} => {
  const { appId } = useConfig();
  const { schemaVersion, template = '' } = useDesignSchema();

  const trackEvent = (
    eventName: string,
    options?: Record<string, string>,
  ): void => {
    amplitude?.track(eventName, {
      ...(options || {}),
      appId,
      schemaVersion,
    });
  };

  const trackNavigationEvent = (
    eventName: string,
    options: Record<string, string> = {},
  ): void => {
    trackEvent(eventName, { ...options, category: 'Navigation' });
  };

  const trackOrderEvent = (
    eventName: string,
    options: Record<string, string> = {},
  ): void => {
    trackEvent(eventName, { ...options, category: 'Order' });
  };

  const identifyUser = (): void => {
    try {
      if (amplitude) {
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set('schemaVersion', schemaVersion);
        identifyEvent.set('template', template);

        amplitude.identify(identifyEvent);
      }
    } catch (error) {
      // Do nothing, amplitude is not initialized.
    }
  };

  return {
    identifyUser,
    trackEvent,
    trackNavigationEvent,
    trackOrderEvent,
  };
};
