import { styled, useTheme } from '@mui/material/styles';
import { type LandingPageType } from '@Src/middlewares/CustomPageTypes';
import Highlight from '../Highlight';
import Apps from '@Components/Widgets/Apps';
import MenuHighlights from '../Menu';
import Testimonials from '@Components/Widgets/Testimonials';
import Gallery from '@Components/Widgets/Gallery';
import LocationsWidget from '@Components/Widgets/Locations';
import { InnerGridPolice, InnerGridWithImagePolice } from '../BaseGridElements';
import { BoxedUpLanding } from '../Landing/boxedUp';

const StyledLocationsContainer = styled(InnerGridPolice)(({ theme }) => ({
  borderRadius: theme.boxedUp.borderRadius.default,
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.boxedUp.borderRadius.mobile,
  },
}));

const StyledInnerGridWithImagePolice = styled(InnerGridWithImagePolice)(
  ({ theme }) => ({
    background: 'transparent',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }),
);

export const BoxedUp = ({ data }: { data: LandingPageType }): JSX.Element => {
  const theme = useTheme();
  const cardBorderRadius = '24px';
  const cardBorderWidth = `${theme.boxedUp.borderWidth}px`;
  const buttonBorderRadius = `${theme.boxedUp.buttonBorderRadius}px`;

  return (
    <>
      <StyledInnerGridWithImagePolice>
        <BoxedUpLanding
          backgroundImages={data?.backgroundImages}
          borderWidth={cardBorderWidth}
          secondaryImage={data?.secondaryImage}
        />
      </StyledInnerGridWithImagePolice>
      <InnerGridPolice>
        <MenuHighlights
          menuCardBorderRadius={cardBorderRadius}
          buttonBorderRadius={buttonBorderRadius}
          buttonTextColor={theme.palette.text.primary}
          menuCardImageBorderRadius="16px"
          menuCardBorderWidth={cardBorderWidth}
        />
      </InnerGridPolice>
      {data.highlight?.enabled && <Highlight data={data?.highlight} />}
      {data.testimonialsEnabled && (
        <InnerGridPolice>
          <Testimonials
            testimonialBorderRadius={cardBorderRadius}
            testimonialBorderWidth={cardBorderWidth}
          />
        </InnerGridPolice>
      )}
      {data.mobileAppsEnabled && <Apps />}
      {data.galleryEnabled && (
        <InnerGridPolice>
          <Gallery imageBorderRadius="24px" />
        </InnerGridPolice>
      )}
      {data.locationsEnabled && (
        <StyledLocationsContainer>
          <LocationsWidget
            listBorderRadius={`${theme.boxedUp.locationWidget.listBorderRadius}px`}
            buttonBorderRadius={`${theme.boxedUp.locationWidget.buttonBorderRadius}px`}
            buttonTextColor={theme.palette.text.primary}
            cardBorderRadius={`${theme.boxedUp.locationWidget.cardBorderRadius}px`}
            showSubTitle
            showTitle
          />
        </StyledLocationsContainer>
      )}
    </>
  );
};

export default BoxedUp;
