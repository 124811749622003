import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from '@Components/RoutesUtils';
import { ReactComponent as FlipdishPoweredByIcon } from '@Src/assets/images/flipdish_powered_by.svg';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';
import { InnerGridPolice, StyledBaseContainer } from '../BaseGridElements';

export const StyledSublinkContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const StyledSublink = styled(Link)`
  text-decoration: none !important;
  color: inherit;
  &:hover,
  &:focus {
    opacity: 0.5;
  }
`;

export const StyledCookieSettingsButton = styled('button')`
  text-decoration: none !important;
  color: inherit;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.5;
  }
`;

const StyledFooterBase = styled('footer')`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    padding-top: ${({ theme }) => theme.spacing(7.5)};
    padding-bottom: ${({ theme }) => theme.spacing(7.5)};
  }

  background: ${({ theme }) => theme.palette.secondary.main};

  color: ${(props) => props.theme.palette.secondary.contrastText};

  p {
    color: ${(props) => props.theme.palette.secondary.contrastText};
  }
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledFooter = styled(StyledFooterBase)(({ theme }) => ({
  ...(theme.template === V2Templates.boxedUp && {
    borderRadius: theme.boxedUp.borderRadius.default,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.boxedUp.borderRadius.mobile,
    },
  }),
}));

export const StyledBoxedUpFooter = styled(StyledBaseContainer)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    padding: `0 ${theme.spacing(3)}`,
  },
}));

export const StyledFooterInnerGridPolice = styled(InnerGridPolice)(
  ({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }),
);

export const StyledMobileBadgeAndSocialMediaContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: flex-end;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: ${({ theme }) => theme.spacing(2.5)};
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledSocialMediaList = styled('ul')`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: ${({ theme }) => theme.spacing(1.5)};
  a {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.secondary.contrastText)};
    border-radius: 100px !important;
    &:hover {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.secondary.contrastText};
        }
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.palette.secondary.contrastText};
    }
  }
`;

export const StyledTopLineFooter = styled(Grid)(({ theme }) => ({
  paddingRight: V2Templates.boxedUp === theme.template ? 0 : theme.spacing(4),
  paddingLeft: V2Templates.boxedUp === theme.template ? 0 : theme.spacing(3),
}));

export const StyledPoweredByIcon = styled(FlipdishPoweredByIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  path {
    fill: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const SyledLogoSublinkGridItem = styled(Grid)`
  align-items: center;
  width: 50%;
  display: flex;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing(2.75)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: left;
    align-items: start;
    width: 100%;
  }
`;

export const StyledBadgesContainer = styled('div')`
  display: flex;
  flex-direction: row;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: ${({ theme }) => theme.spacing(2)}!important;
  }
`;

export const StyledLogo = styled('img')`
  height: 64px;
  border-radius: 20%;
`;

export const StyledMobileAndSocialMediaItem = styled(Grid)`
  width: 50%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const StyledBottomLineFooter = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(3.25)};
`;

export const StyledFlipdishContainer = styled(Grid)`
  display: flex;
`;

export const FlipdishIconTextContainer = styled(Grid)`
  align-items: end;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const StyledReturnLink = styled(Link)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }) => theme.spacing(3.5)};
  }
`;
