import { Link } from '@Components/RoutesUtils';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  color: `${theme.palette.primary.contrastText} !important`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textAlign: 'center',
  textDecoration: 'none',
  width: '100%',
}));

const StyledLabel = styled(Typography)`
  text-transform: none;
`;

type LinkButtonPropsType = {
  href: string;
  onClick?: () => void;
  label: string;
  ariaLabel?: string;
  dataFd?: string;
  textVariant?: 'button' | 'buttonSmall';
  border?: string;
  className?: string;
};

const LinkButton = ({
  href,
  label,
  ariaLabel,
  dataFd,
  onClick,
  textVariant = 'button',
  className,
}: LinkButtonPropsType): JSX.Element => (
  <StyledLink
    className={className}
    onClick={onClick}
    data-fd={dataFd}
    to={href}
    aria-label={ariaLabel}
  >
    <StyledLabel variant={textVariant}>{label}</StyledLabel>
  </StyledLink>
);

export default LinkButton;
