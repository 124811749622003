import { useTranslation } from '@Providers/TranslationProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import NotFoundSvg from '@Src/assets/images/not-found.svg';
import { styled } from '@mui/material/styles';

const StyledTitle = styled('h1')<{ $primary: string }>`
  font-size: 20px;
  font-family: 'Oswald', sans-serif !important;
  font-size: 52px;
  font-weight: 300;
  text-align: center;
  color: #3743b1;
  text-align: center;
  padding: 10%;
`;

const StyledImage = styled('img')`
  max-width: 80%;
  margin: 0 auto;
`;

export const NotFound = (): JSX.Element => {
  const { translate } = useTranslation();
  const designSchema = useDesignSchema();
  const primaryColor =
    designSchema.schemaVersion === 'v1'
      ? designSchema.appDesignScheme?.Primary
      : designSchema.globalStyle?.colors?.primary;
  return (
    <div id="no-page-found">
      <StyledTitle $primary={primaryColor || '#000000'}>
        <StyledImage src={NotFoundSvg} />
        {translate('Not_Found_page')}
      </StyledTitle>
    </div>
  );
};

export default NotFound;
