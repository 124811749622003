import { List } from '@Components/ui/List';

import { useAppSelector } from '@Src/hooks/useAppSelector';
import { StoreListItem } from '../StoreListItem';
import { setSelectedStore } from '@Src/slices/collectionStoresSlice';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { type CollectionStoreSummary } from '@Src/types/collectionStoreTypes';
import { useNavigateToWebOrder } from '@Src/utility/orderUtils';
import { Typography, styled } from '@mui/material';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useTracking } from '@Src/hooks/useTracking';
import { selectIsServiceUnavailable } from '@Src/selectors/stores.selectors';
import { useState } from 'react';

const StyledContainer = styled('div')<{
  ownerState: { isServiceUnavailable?: boolean };
}>(({ ownerState, theme }) => ({
  paddingTop: ownerState.isServiceUnavailable
    ? theme.spacing(20)
    : theme.spacing(14.5),
}));

const StyledMainText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1.5, 2),
}));

export function CollectionStoreList(): JSX.Element {
  const { translate } = useTranslation();
  const navigateToWebOrder = useNavigateToWebOrder();
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const collectionStores = useAppSelector(
    (state) => state.collectionStores.collectionStoreList,
  );
  const selectedStore = useAppSelector(
    (state) => state.collectionStores.selectedCollectionStore,
  );
  const isServiceUnavailable = useAppSelector(selectIsServiceUnavailable);

  const dispatch = useAppDispatch();
  const { trackOrderEvent } = useTracking();

  const handleCollectionStoreSelected = (
    storeId: number,
    index: number,
  ): void => {
    const selectedStore = collectionStores.find(
      (store) => store.PhysicalRestaurantId === storeId,
    );
    if (selectedStore) {
      trackOrderEvent('Select Collection Store', {
        status: selectedStore.IsOpen ? 'Open' : 'Closed',
        order: (index + 1).toString(),
      });
      dispatch(setSelectedStore(selectedStore));
      navigateToWebOrder({ isCollection: true, storeSummary: selectedStore });
    }
  };

  const storeListItemElements = collectionStores?.map(
    (collectionStore: CollectionStoreSummary, index: number) => ({
      key: `${collectionStore.PhysicalRestaurantId}`,
      content: (
        <StoreListItem
          address={collectionStore.PhysicalRestaurantAddress}
          acceptPreorders={collectionStore.AcceptPreOrders}
          distanceKm={collectionStore.DistanceKm}
          isOpen={collectionStore.IsOpen}
          openTimeMessage={collectionStore.OpenTimeMessage}
          storeName={collectionStore.PhysicalRestaurantName}
          storeId={collectionStore.PhysicalRestaurantId}
          isHovered={
            hoveredItem === collectionStore.PhysicalRestaurantId &&
            selectedStore?.PhysicalRestaurantId !==
              collectionStore.PhysicalRestaurantId
          }
        />
      ),
      id: collectionStore.PhysicalRestaurantId,
      onClick: () =>
        handleCollectionStoreSelected(
          collectionStore.PhysicalRestaurantId,
          index,
        ),
      isSelected:
        selectedStore?.PhysicalRestaurantId ===
        collectionStore.PhysicalRestaurantId,
    }),
  );

  return (
    <StyledContainer ownerState={{ isServiceUnavailable }}>
      <StyledMainText variant="body2Medium" paragraph>
        {translate('Showing_stores_near')}
      </StyledMainText>
      <List
        items={storeListItemElements || []}
        setHoveredItem={setHoveredItem}
      />
    </StyledContainer>
  );
}
