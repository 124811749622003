import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import AboutImage from './AboutImage';
import FadeInContainer from '../FadeInContainer';
import styled from 'styled-components';
import { useTranslation } from '@Providers/TranslationProvider';
import { type AboutUsPageType } from '@Src/middlewares/CustomPageTypes';
import { Link } from '@Components/RoutesUtils';
import { useTracking } from '@Src/hooks/useTracking';

type Props = {
  isPage?: boolean;
  data?: AboutUsPageType;
};
const StyledHeading = styled.h2<{ primaryColor: string }>`
  color: ${(props) => props.primaryColor};
`;

const StyledText = styled.div<{ primaryColor: string }>`
  pre {
    white-space: pre-wrap;
    text-wrap: inherit;
    font: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
  a {
    color: ${(props) => props.primaryColor};
    &:hover {
      color: ${(props) => props.primaryColor};
      opacity: 0.85;
    }
    &:focus {
      text-decoration: none;
      color: ${(props) => props.primaryColor};
    }
  }
`;
export const About = (props: Props): JSX.Element => {
  const { isPage, data } = props;
  const { appDesignScheme, template } = useDesignSchema('v1');
  const { Primary: primary } = appDesignScheme;
  const { translate } = useTranslation();
  const { trackEvent } = useTracking();
  if (!data) {
    return <></>;
  }

  // about left content shows right images and vice versa
  const leftImages = data?.right?.images || [];
  const rightImages = data?.left?.images || [];

  const trackClick = (): void => {
    trackEvent('Click to order', {
      category: 'About Us Section',
      type: 'Start Order',
    });
  };

  const getLeftContent = (): JSX.Element => {
    if (
      data?.left?.description === null &&
      data?.left?.title === null &&
      leftImages?.length === 0
    ) {
      return <></>;
    }
    return (
      <>
        <div className="col-md-6 jt_col column_container">
          {data?.left?.title && (
            <StyledHeading
              className="heading font-smoothing"
              primaryColor={primary}
            >
              {data.left.title}
            </StyledHeading>
          )}
          {data?.left?.description && (
            <StyledText
              className="text"
              primaryColor={primary}
              dangerouslySetInnerHTML={{
                __html: data.left.description,
              }}
            />
          )}
          {template === 'losPollosHermanos' && (
            <>
              <br />
              <br />
              <Link to="/order" onClick={trackClick}>
                <button className="ctabutton" style={{ color: 'white' }}>
                  {translate('Order_now')}
                </button>
              </Link>
            </>
          )}
        </div>
        <div className="col-md-6 jt_col column_container">
          <div className="voffset40"></div>
          <AboutImage id="owl-about" images={leftImages} />
        </div>
      </>
    );
  };

  return (
    <section id="aboutSection" className="section about">
      <div className="container">
        <FadeInContainer className="jt_row jt_row-fluid row visible">
          <div className="col-md-12 jt_col column_container">
            {isPage ? (
              <>
                <div className="voffset100" />
                <h1 className="section-title" tabIndex={0}>
                  {data.title}
                </h1>
              </>
            ) : (
              <h2 className="section-title" tabIndex={0}>
                {data.title}
              </h2>
            )}
          </div>
          <div className="col-md-6 col-md-offset-3 jt_col column_container">
            <div className="section-subtitle">{data.subtitle}</div>
          </div>
          {getLeftContent()}
        </FadeInContainer>
        <FadeInContainer className="jt_row jt_row-fluid row visible">
          <div className="col-md-6 jt_col column_container">
            <div className="voffset10"></div>
            <AboutImage id="owl-about2" images={rightImages} />
          </div>

          <div className="col-md-6 jt_col column_container">
            {data?.right?.title && (
              <StyledHeading
                className="heading font-smoothing"
                primaryColor={primary}
              >
                {data.right.title}
              </StyledHeading>
            )}
            {data?.right?.description && (
              <StyledText
                className="text"
                primaryColor={primary}
                dangerouslySetInnerHTML={{
                  __html: data.right.description,
                }}
              />
            )}
          </div>
        </FadeInContainer>
      </div>
    </section>
  );
};

export default About;
