import { useTranslation } from '@Src/providers/TranslationProvider';
import { useRecaptcha } from '@Src/hooks/useRecaptcha';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useApi } from '@Src/hooks/useApi';
import { TextField } from '@Components/ui/TextField';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConfig } from '@Src/providers/ConfigProvider';
import { validEmailRegex } from '@Src/utility/regex';
import {
  maxEmailLength,
  maxMessageLength,
  maxNameLength,
  maxPhoneLength,
} from '../utils';
import MuiButton from '@Components/ui/MuiButton';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { type SubmissionStatus } from '../types';

type ErrorsType = {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
  captchaToken?: string;
};

const StyledRecaptchaContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const StyledSubmitButton = styled(MuiButton)`
  width: fit-content;
  padding: 20px 32px;
`;

const StyledRecaptchaErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

const StyledButtonContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledButtonText = styled(Typography)`
  text-transform: none;
`;

const StyledTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

type ContactFormProps = {
  setFormSubmissionStatus: (status: SubmissionStatus) => void;
  formSubmissionStatus: SubmissionStatus;
};

export const ContactForm = ({
  setFormSubmissionStatus,
  formSubmissionStatus,
}: ContactFormProps): JSX.Element => {
  const { translate } = useTranslation();
  const { language, appId } = useConfig();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const [formErrors, setFormErrors] = useState<ErrorsType>({});
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const {
    isRecaptchaValid,
    handleRecaptchaExpired,
    handleRecaptchaTest,
    recaptchaSiteKey,
  } = useRecaptcha();

  useEffect(() => {
    if (isRecaptchaValid) {
      setCaptchaToken(recaptchaRef.current?.getValue() || '');
    } else {
      setCaptchaToken('');
    }
  }, [isRecaptchaValid]);

  useEffect(() => {
    setIsBrowser(true);
    const customerString = isLoggedIn
      ? localStorage.getItem('flipdish-customer')
      : '';
    const parsedCustomer = customerString ? JSON.parse(customerString) : {};
    setFormValues({
      name: parsedCustomer?.CustomerName || '',
      email: parsedCustomer?.EmailAddress || '',
      phone: parsedCustomer?.CustomerPhoneNumberLocalFormatString || '',
      message: '',
    });
  }, []);

  useEffect(() => {
    formSubmissionStatus === 'success' &&
      setFormValues({ ...formValues, message: '' });
  }, [formSubmissionStatus]);

  const { post } = useApi();

  const validateForm = (): boolean => {
    const errors: ErrorsType = {};

    if (!formValues?.name) {
      errors.name = translate('The_Name_field_is_required');
    } else if (formValues?.name?.length > maxNameLength) {
      errors.name = translate('Value_more_than_maximum_length_of', {
        maximum: maxNameLength,
      });
    }
    if (!formValues?.email) {
      errors.email = translate('The_Email_field_is_required');
    } else if (!validEmailRegex.test(formValues?.email || '')) {
      errors.email = translate('Invalid_email');
    } else if (formValues?.email?.length > maxEmailLength) {
      errors.email = translate('Value_more_than_maximum_length_of', {
        maximum: maxEmailLength,
      });
    }
    if (!formValues?.phone) {
      errors.phone = translate('The_Phone_Number_field_is_required');
    } else if (formValues?.phone.length > maxPhoneLength) {
      errors.phone = translate('Value_more_than_maximum_length_of', {
        maximum: maxPhoneLength,
      });
    }
    if (!captchaToken) {
      errors.captchaToken = translate('This_field_is_required');
    }
    if (!formValues?.message) {
      errors.message = translate('The_Message_field_is_required');
    } else if (formValues?.message.length > maxMessageLength) {
      errors.message = translate('Value_more_than_maximum_length_of', {
        maximum: maxMessageLength,
      });
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormFieldChange = (event: {
    target: { name: any; value: string };
  }): void => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value?.trimStart() || '',
    });
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
  }): Promise<void> => {
    event.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      const formData = {
        Name: formValues?.name,
        Phone: formValues?.phone,
        Email: formValues?.email,
        Message: formValues?.message,
        ContactGoogleRecaptchaResponse: captchaToken,
      };

      try {
        setSubmitting(true);
        if (appId) {
          const response = await post<typeof formData, string>({
            path: `/app/contact?panaceaAppNameId=${appId}`,
            payload: formData,
          });
          if (response?.data?.Success === true) {
            setFormSubmissionStatus('success');
          } else {
            setFormSubmissionStatus('error');
          }
        }
        setSubmitting(false);
      } catch {
        setSubmitting(false);
        setFormSubmissionStatus('error');
      }
    }
  };

  return (
    <section id="v2-contact">
      <form
        className="contact-form"
        noValidate
        // form doesn't expect promise returned but doesn't use it anyway
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit}
      >
        <Grid container columnSpacing={1.5}>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              name="name"
              data-fd="contact-name"
              error={!!formErrors?.name}
              helperText={formErrors?.name || ''}
              value={formValues?.name}
              label={translate('Name')}
              onChange={handleFormFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              name="phone"
              data-fd="contact-phone"
              error={!!formErrors?.phone}
              helperText={formErrors?.phone || ''}
              value={formValues?.phone}
              label={translate('Phone_Number')}
              onChange={handleFormFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              name="email"
              data-fd="contact-email"
              onChange={handleFormFieldChange}
              error={!!formErrors?.email}
              helperText={formErrors?.email || ''}
              value={formValues.email}
              label={translate('Email')}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              multiline
              minRows={3}
              name="message"
              data-fd="contact-message"
              onChange={handleFormFieldChange}
              value={formValues.message}
              label={translate('Your_Message')}
              error={!!formErrors?.message}
              helperText={formErrors?.message || ''}
            />
          </Grid>
          <Grid item xs={12}>
            {isBrowser && recaptchaSiteKey && (
              <StyledRecaptchaContainer>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  data-fd="contact-recaptcha"
                  hl={language}
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaTest}
                  onExpired={handleRecaptchaExpired}
                />
                {formErrors.captchaToken && (
                  <StyledRecaptchaErrorText variant="caption">
                    {formErrors.captchaToken}
                  </StyledRecaptchaErrorText>
                )}
              </StyledRecaptchaContainer>
            )}
          </Grid>
        </Grid>
        <StyledButtonContainer>
          <StyledSubmitButton
            loading={isSubmitting}
            disabled={isSubmitting}
            variant="contained"
            type="submit"
          >
            <StyledButtonText variant="button">
              {translate('Send')}
            </StyledButtonText>
          </StyledSubmitButton>
        </StyledButtonContainer>
      </form>
    </section>
  );
};

export default ContactForm;
