import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from '@Components/App';
import { type DesignSchemaType } from './middlewares/DesignConfigTypes';
import { type PageSchemaType } from './middlewares/CustomPageTypes';
import { LoggerProvider } from './providers/LoggerProvider';
import * as clientLogger from './utility/loggerService';
import { type ConfigType } from './providers/ConfigProvider';
import ErrorBoundary from '@Components/ErorrBoundary';
import createEmotionCache from './createEmotionCache';
import { CacheProvider } from '@emotion/react';

const cache = createEmotionCache();
// Adding extra types to window object for data variables from server and scripts.
declare global {
  interface Window {
    __FF__: SplitIO.Treatments;
    __DS__: DesignSchemaType;
    __CPS__: PageSchemaType;
    __APP__: ConfigType;
    __TLS__: Record<string, string>;
    fdCc: {
      init: (...args: any) => void;
      edit: (...args: any) => void;
      getConsentCookies: (...args: any) => string[];
      subscribe: (...args: any) => void;
    };
    cookieconsent: {
      initialise: (...args: any) => void;
    };
    updateFacebookConsent: (...args: any) => void;
    updateGtagConsent: (...args: any) => void;
  }
}

const featureFlags = window.__FF__;
const designSchema = window.__DS__;
const customPageSchema = window.__CPS__;
const appConfig = window.__APP__;
const translations = window.__TLS__;
clientLogger.initialise(appConfig.env);
// @ts-expect-error Delete data variables from window scope.
delete window.__FF__;
// @ts-expect-error Delete data variables from window scope.
delete window.__DS__;
// @ts-expect-error Delete data variables from window scope.
delete window.__CPS__;
// @ts-expect-error Delete data variables from window scope.
delete window.__APP__;
// @ts-expect-error Delete data variables from window scope.
delete window.__TLS__;

const logger = clientLogger.logger;

let baseName = appConfig.appId;

if (appConfig.isCustomDomain) {
  baseName = '';
} else if (appConfig.vanityUrlSuffix) {
  baseName = appConfig.vanityUrlSuffix;
}
ReactDOM.hydrateRoot(
  document.getElementById('root') as HTMLElement,
  <React.StrictMode>
    <CacheProvider value={cache}>
      <LoggerProvider logger={logger}>
        <ErrorBoundary identifier="root app">
          <BrowserRouter basename={`/${baseName}`}>
            <App
              featureFlags={featureFlags}
              designSchema={designSchema}
              customPageSchema={customPageSchema}
              appConfig={appConfig}
              translations={translations}
            />
          </BrowserRouter>
        </ErrorBoundary>
      </LoggerProvider>
    </CacheProvider>
  </React.StrictMode>,
);
