import { lazy, Suspense } from 'react';
import parse, { type DOMNode } from 'html-react-parser';
import { OrderSettings } from '@Components/Widgets/OrderSettings';

const Reorder = lazy(async () => await import('@Components/Widgets/Reorder'));
const OrderStatus = lazy(
  async () => await import('@Components/Widgets/OrderStatus'),
);
const Testimonials = lazy(
  async () => await import('@Components/Widgets/Testimonials'),
);
const Gallery = lazy(async () => await import('@Components/Widgets/Gallery'));
const MobileApps = lazy(async () => await import('@Components/Widgets/Apps'));
const ContactForm = lazy(
  async () =>
    await import('@Components/Widgets/ContactForms/ContactFormWidget'),
);
const ReservationsForm = lazy(
  async () =>
    await import('@Components/Widgets/ContactForms/ReservationsFormWidget'),
);

const widgets = {
  'fd-gallery': Gallery,
  'fd-mobile-apps': MobileApps,
  'fd-order-status': OrderStatus,
  'fd-reorder': Reorder,
  'fd-testimonials': Testimonials,
  'fd-contact-form': ContactForm,
  'fd-reservations-form': ReservationsForm,
  'fd-order-settings': OrderSettings,
};

export const getComponentWithWidget = (
  htmlString: string,
): string | JSX.Element | JSX.Element[] => {
  const elements = parse(htmlString, {
    replace: (domNode: DOMNode) => {
      // @ts-expect-error name prop exists.
      if (widgets[domNode.name]) {
        // @ts-expect-error attribs prop exists.
        const props = domNode.attribs || {};
        const style = props.style ? cssToJSObject(props.style) : {};
        // @ts-expect-error name prop exists.
        const Component = widgets[domNode.name];
        return (
          <Suspense fallback={<div></div>}>
            <Component {...props} style={style} />
          </Suspense>
        );
      }
    },
  });
  return elements;
};

const cssToJSObject = (cssString: string): Record<string, string | number> => {
  try {
    return cssString
      .split(';')
      .reduce<Record<string, string | number>>((styleObject, rule) => {
        const [property, value] = rule.split(':');
        if (property && value) {
          const camelCaseProperty = property.replace(/-./g, (match) =>
            match.charAt(1).toUpperCase(),
          );
          styleObject[camelCaseProperty.trim()] = value.trim();
        }
        return styleObject;
      }, {});
  } catch (e) {
    return {};
  }
};
