import { type ThemeOptions } from '@mui/material/styles';
import { ColorSchemeEnum, type ColorSchemeExtended } from './schemes';
import { type V2Templates } from '@Src/middlewares/CustomPageTypes';

type CreateThemeProps = {
  colorScheme: ColorSchemeExtended;
  lightestColor: string;
  primaryColor: string;
  primaryFont: string;
  secondaryColor: string;
  secondaryFont: string;
  template: V2Templates | '';
  tertiaryColor: string;
};

export const createThemeOptions = ({
  colorScheme,
  lightestColor,
  primaryColor,
  primaryFont,
  secondaryColor,
  secondaryFont,
  template = '',
  tertiaryColor,
}: CreateThemeProps): ThemeOptions => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1441,
    },
  },
  template,
  boxedUp: {
    borderRadius: {
      default: 64,
      mobile: 32,
    },
    borderWidth: 2,
    contact: {
      formPadding: {
        left: {
          default: 72,
          mobile: 40,
        },
        right: {
          default: 72,
          mobile: 24,
        },
        bottom: {
          default: 72,
          mobile: 24,
        },
        top: {
          default: 48,
          mobile: 30,
        },
      },
      imagePadding: {
        left: 0,
        top: 0,
      },
    },
    locationWidget: {
      listBorderRadius: 24,
      buttonBorderRadius: 20,
      cardBorderRadius: 24,
    },
    widgetBorderRadius: 12,
    buttonBorderRadius: 32,
    orderSettings: {
      borderRadius: {
        default: 56,
        mobile: 32,
      },
      boxShadow: 'none',
      padding: {
        bottom: {
          default: 32,
          mobile: 16,
        },
      },
    },
    sectionPadding: {
      top: {
        default: 24,
        mobile: 16,
      },
      left: {
        default: 48,
        mobile: 24,
      },
      bottom: {
        default: 48,
        mobile: 24,
      },
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      lightest: lightestColor,
    },
    secondary: {
      main: secondaryColor,
      ...colorScheme,
    },
    tertiary: {
      main: tertiaryColor,
    },
    text: {
      disabled:
        colorScheme.type === ColorSchemeEnum.light
          ? 'rgba(0, 0, 0, 0.38)'
          : 'rgba(255, 255, 255, 0.5)',
      primary: colorScheme.foreground,
      secondary: colorScheme.body,
    },
    background: {
      default: colorScheme.background,
      paper: colorScheme.background,
    },
    error: {
      main: '#d80034',
    },
    success: {
      main: '#20c26e',
    },
    warning: {
      main: '#ff6624',
    },
  },
  typography: {
    /** Not cookbook standard - taken from designs */
    body1xs: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    /** Not cookbook standard - taken from designs */
    body1: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    body1Medium: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    body1Bold: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    body2: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    body2Medium: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    body2Bold: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '18px',
    },
    button: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    /** Not cookbook standard - taken from designs */
    buttonSmall: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    caption: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    /** Not cookbook standard - taken from designs */
    finePrint1: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    /** Not cookbook standard - taken from designs */
    finePrint1Medium: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    /** Not cookbook standard - taken from designs */
    finePrint2: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12px',
    },
    /** Not cookbook standard - taken from designs */
    finePrint2Medium: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '12px',
    },
    /** Not cookbook standard - taken from designs */
    finePrint3: {
      fontFamily: `${secondaryFont},Roboto,sans-serif`,
      fontSize: '8px',
      fontWeight: 400,
      lineHeight: '10px',
    },
    /** Not cookbook standard - taken from designs */
    h1: {
      fontSize: '48px',
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontWeight: 600,
      lineHeight: '64px',
    },
    h1DisplayXs: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '36px',
      fontWeight: 600,
      lineHeight: '40px',
    },
    h1xs: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '36px',
    },
    h2: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    /** Not cookbook standard - taken from designs */
    h2Display: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '36px',
      fontWeight: 600,
      lineHeight: '40px',
    },
    h2xs: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '21px',
      fontWeight: 500,
      lineHeight: '28px',
    },
    h3: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    /** Not cookbook standard - taken from designs */
    h3xs: {
      fontFamily: `${primaryFont},Roboto,sans-serif`,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '21px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1xs: 'h1',
          h1DisplayXs: 'h1',
          h2xs: 'h2',
          h2Display: 'h2',
          h3xs: 'h3',
        },
      },
    },
  },
});
