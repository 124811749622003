import { type MenuType, type MenuPreviewSection } from './MenuAPITypes';

export const formatMenuApiResponse = ({
  menu,
}: {
  menu: MenuType;
}): MenuPreviewSection[] => {
  // format the first three available menu sections
  const visibleSections = menu.MenuSections.filter(
    (section) => section.IsAvailable && !section.IsHiddenFromCustomers,
  ).sort((a, b) => (a.DisplayOrder > b.DisplayOrder ? 1 : -1));
  const sectionsToDisplay = visibleSections.slice(0, 3);

  // get first five available menu items for each section
  const formattedSections = sectionsToDisplay.map((section) => {
    const visibleItems = section.MenuItems.filter(
      (item) => item.IsAvailable,
    ).sort((a, b) => (a.DisplayOrder > b.DisplayOrder ? 1 : -1));
    const itemsToDisplay = visibleItems.slice(0, 5);
    const formattedItems = itemsToDisplay.map((item) => {
      let price = item.ActualPrice;
      // if item has a master option set, set price to the cheapest option in the master option set
      const masterOptionSet = item.MenuItemOptionSets.find(
        (optionSet) => optionSet.IsMasterOptionSet,
      );
      if (masterOptionSet) {
        const availableMasterOptionSetItems =
          masterOptionSet.MenuItemOptionSetItems.filter(
            (option) => option.IsAvailable,
          );
        if (availableMasterOptionSetItems.length > 0) {
          const cheapestOption = availableMasterOptionSetItems.reduce(
            (prev, curr) => (prev.Price < curr.Price ? prev : curr),
          );
          price = cheapestOption.Price;
        }
      }
      return {
        itemId: item.MenuItemId.toString(),
        price,
        title: item.Name,
        description: item.Description,
        isFromPrice: true,
      };
    });

    return {
      sectionId: section.MenuSectionId.toString(),
      title: section.Name,
      items: formattedItems,
    };
  });
  return formattedSections || [];
};
