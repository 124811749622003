import { FeatureFlagProvider } from '@Providers/FeatureFlagsProvider';
import { DesignSchemaProvider } from '@Providers/DesignSchemaProvider';
import { TranslationProvider } from '@Providers/TranslationProvider';
import { Normalizer } from '../styles/Normalizer';
import { Router } from './Routes';
import { type DesignSchemaType } from '../middlewares/DesignConfigTypes';
import { type PageSchemaType } from '@Src/middlewares/CustomPageTypes';
import { CustomPageSchemaProvider } from '@Providers/CustomPageSchemaProvider';
import ErrorBoundary from './ErorrBoundary';
import CookieConsent from './CookieConsent';
import { ConfigProvider, type ConfigType } from '@Src/providers/ConfigProvider';
import { Provider } from 'react-redux';
import { store } from '@Src/store';
import ThemeProvider from '@Src/providers/ThemeProvider';
import { initialState } from '@Src/slices/collectionStoresSlice';
import { initialState as deliveryAddInitialState } from '@Src/slices/deliveryAddressesSlice';
import { initialState as deliveryStoreInitialState } from '@Src/slices/deliveryStoresSlice';
import { initialState as locationInitialState } from '@Src/slices/locationSlice';
import { initialState as loyaltyCampaignsInitialState } from '@Src/slices/loyaltyCampaignsSlice';
import { initialState as orderSetingsInitialState } from '@Src/slices/orderSettingSlice';
import { initialState as urlInitialState } from '@Src/slices/orderUrlSlice';
import { initialState as storeLeadTimesInitialState } from '@Src/slices/storeLeadTimesSlice';
import { initializeTheme } from '@Src/services/themeService';

const initialLoadingState = { fetched: false, loading: false };
interface AppProps {
  featureFlags?: SplitIO.Treatments;
  designSchema: DesignSchemaType;
  customPageSchema: PageSchemaType;
  appConfig: ConfigType;
  translations: Record<string, string>;
}

const App = (props: AppProps): JSX.Element => {
  const { designSchema } = props;
  const theme = initializeTheme(designSchema);

  return (
    <ErrorBoundary identifier="base app">
      <ConfigProvider config={props.appConfig}>
        <Provider
          store={store}
          serverState={{
            collectionStores: {
              ...initialState,
              ...initialLoadingState,
            },
            deliveryAddresses: {
              ...deliveryAddInitialState,
              ...initialLoadingState,
            },
            deliveryStores: {
              ...deliveryStoreInitialState,
              ...initialLoadingState,
            },
            location: {
              ...locationInitialState,
              ...initialLoadingState,
            },
            loyaltyCampaigns: {
              ...loyaltyCampaignsInitialState,
              ...initialLoadingState,
            },
            orderSettings: {
              ...orderSetingsInitialState,
            },
            orderUrl: {
              ...urlInitialState,
            },
            storeLeadTimes: {
              ...storeLeadTimesInitialState,
              ...initialLoadingState,
            },
            user: { isLoggedIn: props.appConfig?.isLoggedIn || false },
          }}
        >
          <TranslationProvider translations={props.translations}>
            <DesignSchemaProvider schema={props.designSchema}>
              <CustomPageSchemaProvider schema={props.customPageSchema}>
                <ThemeProvider theme={theme}>
                  <FeatureFlagProvider features={props.featureFlags}>
                    <>
                      <Normalizer />
                      <CookieConsent />
                      <Router />
                    </>
                  </FeatureFlagProvider>
                </ThemeProvider>
              </CustomPageSchemaProvider>
            </DesignSchemaProvider>
          </TranslationProvider>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
