import { StarFilled } from './StarFilled';
import { StarOutlined } from './StarOutlined';
import { StarPending } from './StarPending';

export type StarVariant = 'filled' | 'outlined' | 'pending';
export type Props = {
  className?: string;
  variant: StarVariant;
  filledColor?: string;
  pendingColor?: string;
};

export function Star(props: Props): JSX.Element {
  const {
    className,
    filledColor = '#20C26E',
    pendingColor = '#bcedd4',
    variant,
  } = props;

  const getStarIcon = (): JSX.Element => {
    if (variant === 'filled') {
      return (
        <StarFilled
          dataFd="star-filled"
          className={className}
          color={filledColor}
        />
      );
    }

    if (variant === 'pending') {
      return (
        <StarPending
          dataFd="star-pending"
          className={className}
          color={pendingColor}
        />
      );
    }

    return <StarOutlined dataFd="star-outlined" className={className} />;
  };

  return getStarIcon();
}
