import { type SupportedMethodsType } from '@Src/hooks/useApi';
import { type RootState } from '@Src/store';
import { type CollectionStoreSummary } from '@Src/types/collectionStoreTypes';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';

export type FetchDataParamsType = {
  appId: string | null;
  get: SupportedMethodsType['get'];
};
export const fetchCollectionStoreSummaries = createAsyncThunk(
  'collectionStores/fetchCollectionStoreSummaries',
  async ({ appId, get }: FetchDataParamsType, thunkApi) => {
    try {
      if (!appId) {
        throw new Error('appId is required');
      }

      const state = thunkApi.getState() as RootState;
      thunkApi.dispatch({
        type: 'collectionStores/fetchCollectionStoreSummariesStart',
      });
      const { geo } = state.location;

      const { data } = await get<CollectionStoreSummary[]>({
        path: '/Restaurant/PickupPhysicalRestaurantSummariesFromCoordinates',
        params: {
          latitude: geo.Latitude?.toString(),
          longitude: geo.Longitude?.toString(),
          count: '50',
          skip: '0',
        },
        appType: 'Web',
      });

      return data?.Data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(
        `fetchCollectionStoreSummaries for ${appId || 'undefined'}`,
        {
          message: e.message,
        },
      );
      console.log('error', e);
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
