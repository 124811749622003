import { styled, keyframes } from '@mui/material/styles';
import { useMemo } from 'react';

const StyledProgressBar = styled('div')`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px 0 4px 0;
  width: 100%;

  & span:first-child {
    margin-left: 0;
  }

  & span:last-child {
    margin-right: 0;
  }
`;

type StyledStepProps = {
  ownerState: {
    isActive: boolean;
  };
};
const StyledStep = styled('span')<StyledStepProps>`
  ${({ ownerState, theme }) => ({
    backgroundColor: ownerState.isActive
      ? theme.palette.success.main
      : theme.palette.secondary.lowContrast,
  })};
  display: inline-flex;
  flex-grow: 1;
  height: 4px;
  margin: 0 2px;
  position: relative;
`;

const stepAnimation = keyframes`
    0% { left: -80px }
    35% { left: 100% }
    100% { left: 100% }
`;

type StyledStepAnimationProps = {
  ownerState: {
    isWarning: boolean;
  };
};
const StyledStepAnimation = styled('div')<StyledStepAnimationProps>`
  animation: ${stepAnimation} 2800ms linear infinite;
  ${({ ownerState, theme }) => ({
    background: ownerState.isWarning
      ? `linear-gradient(
    45deg,
    ${theme.palette.warning.main}00 10%,
    ${theme.palette.warning.main}80 50%,
    ${theme.palette.warning.main}00 90%
    )`
      : `linear-gradient(
    45deg,
    ${theme.palette.success.main}00 10%,
    ${theme.palette.success.main}80 50%,
    ${theme.palette.success.main}00 90%
  )`,
  })};
  bottom: 0;
  position: absolute;
  top: 0;
  width: 80px;
`;

const StyledStepAnimationContainer = styled('div')`
  overflow: hidden;
  position: relative;
  width: 100%;
`;
type StyledPointerContainerProps = { ownerState: { isFirst: boolean } };
const StyledStepPointerContainer = styled('div')<StyledPointerContainerProps>`
  display: flex;
  height: 20px;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 20px;
  z-index: 9999;
  ${({ ownerState }) => ownerState.isFirst && 'left: -8.8px'}
`;

const pulsingPointerCircle = keyframes`
     0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
`;

const StyledPointerCircle = styled('div')`
  animation: ${pulsingPointerCircle} 1400ms infinite;
  background-color: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;
  bottom: 0;
  height: 40%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
`;

const pulsingPointerOuterCircle = keyframes`
    0% {
      transform: scale(.4);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 0.3;
    }
    100% {
      transform: scale(.4);
      opacity: 1,
    }
`;
const StyledPointerOuterCircle = styled('div')`
  animation: ${pulsingPointerOuterCircle} 1400ms infinite;
  background-color: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export type SteppedProgressBarProps = {
  currentStep: number;
  dataFd?: string;
  isWarning?: boolean;
  totalSteps: number;
  style?: React.CSSProperties;
};

export function SteppedProgressBar(
  props: SteppedProgressBarProps,
): JSX.Element {
  const {
    currentStep,
    dataFd = 'stepped-progress-bar',
    isWarning = false,
    totalSteps,
    style,
  } = props;

  const renderSteps = useMemo((): JSX.Element[] => {
    const steps = [] as JSX.Element[];

    for (let i = 0; i < totalSteps; i += 1) {
      const stepNumber = i + 1;
      const isActive = stepNumber <= currentStep;
      const isFirst = i === 0 && currentStep === 0;
      const showPointer = isFirst || currentStep === stepNumber;
      const isPending = stepNumber === currentStep + 1;

      steps.push(
        <StyledStep ownerState={{ isActive }} key={`step-${stepNumber}`}>
          {isPending && (
            <StyledStepAnimationContainer data-fd="pending-step-animation">
              <StyledStepAnimation ownerState={{ isWarning }} />
            </StyledStepAnimationContainer>
          )}
          {showPointer && (
            <StyledStepPointerContainer
              data-fd="pointer-container"
              ownerState={{ isFirst }}
            >
              <StyledPointerCircle />
              <StyledPointerOuterCircle />
            </StyledStepPointerContainer>
          )}
        </StyledStep>,
      );
    }

    return steps;
  }, [currentStep, totalSteps]);

  return (
    <StyledProgressBar style={style} data-fd={dataFd}>
      {renderSteps}
    </StyledProgressBar>
  );
}
