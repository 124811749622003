import { styled } from '@mui/material/styles';
import { ReactComponent as VoucherIcon } from '@Src/assets/images/Redeem.svg';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import { InnerGridPolice } from '../BaseGridElements';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@Src/providers/TranslationProvider';
import Button from '@mui/material/Button';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';
import { useState } from 'react';
import LoginDialog from '../LogIn/LoginDialog';
import { LoyaltyProgressBar } from '@Components/Widgets/LoyaltyProgress/LoyaltyProgressBar';

const StyledPlaceHolder = styled('div')<{
  dark?: boolean;
  width: string;
  noMargin?: boolean;
}>(({ theme, dark, noMargin, width }) => ({
  width,
  maxWidth: '100%',
  height: theme.spacing(1),
  backgroundColor: dark
    ? theme.palette.grey[500]
    : theme.palette.secondary.extraLowContrast,
  marginBottom: noMargin ? 0 : theme.spacing(1),
}));

const StyledCard = styled(Card)<{ width?: string }>(({ theme, width }) => ({
  boxShadow: `0px 12px 36px 0px rgba(0, 0, 0, 0.15)`,
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  width: width || '100%',
  maxWidth: '85%',
  overflow: 'hidden',
  border: `1px solid`,
  borderColor: theme.palette.secondary.lowContrast,
}));

const StyledVoucherCard = styled(StyledCard)(() => ({
  display: 'flex',
}));

const StyledReorderCard = styled('div')(({ theme }) => ({
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  marginTop: `-${theme.spacing(2)}`,
}));

const StyledLoyaltyCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: `-${theme.spacing(2)}`,
}));

const StyledLoyaltyContainer = styled('div')(({ theme }) => ({
  zIndex: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledFractionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  borderRadius: '8px',
  height: '48px',
  justifyContent: 'center',
  marginLeft: theme.spacing(0.5),
  minWidth: '48px',
  paddingTop: theme.spacing(1.5),
}));

const StyledNumberLeft = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(1.5),
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '36px',
}));

const StyledNumberRight = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 700,
  lineHeight: '36px',
}));

const StyledReorderBottom = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}));

const StyledFakeBtn = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor: theme.palette.secondary.body,
  borderRadius: '3px',
  color: theme.palette.background.default,
}));

const StyledhelperText = styled('div')(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
}));

const StyledContainer = styled(InnerGridPolice)<{ image?: string }>(
  ({ theme }) => ({
    '.loyalty-bar-row': {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      '.loyalty-bar-row': {
        fontSize: '12px',
      },
    },
  }),
);

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  textTransform: 'none',
  ...(theme.template === V2Templates.boxedUp && {
    borderRadius: theme.boxedUp.buttonBorderRadius,
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderWidth: theme.boxedUp.borderWidth,
    '&:hover': {
      borderWidth: theme.boxedUp.borderWidth,
    },
  }),
}));

export const NotLoggedInTile = (): JSX.Element => {
  const isMobile = useIsMobile();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const { translate } = useTranslation();

  return (
    <StyledContainer>
      <StyledVoucherCard>
        <div style={{ alignSelf: 'center' }}>
          <VoucherIcon />
        </div>
        <div style={{ minWidth: 0 }}>
          <StyledPlaceHolder dark width="100px" />
          <StyledPlaceHolder width="200px" />
          <StyledPlaceHolder width="200px" noMargin />
        </div>
      </StyledVoucherCard>
      <StyledReorderCard>
        <StyledCard>
          <div>
            <StyledPlaceHolder width="200px" />
            <StyledPlaceHolder width="50%" />
          </div>
          <StyledReorderBottom>
            <StyledPlaceHolder dark width="100px" noMargin />
            <StyledFakeBtn>{translate('Reorder')}</StyledFakeBtn>
          </StyledReorderBottom>
        </StyledCard>
      </StyledReorderCard>
      <StyledLoyaltyCard>
        <StyledCard>
          <StyledLoyaltyContainer>
            <LoyaltyProgressBar currentStar={7} totalStars={8} />
            <StyledFractionContainer>
              <StyledNumberLeft variant="body2">7</StyledNumberLeft>
              <StyledNumberRight variant="body2">/8</StyledNumberRight>
            </StyledFractionContainer>
          </StyledLoyaltyContainer>
        </StyledCard>
      </StyledLoyaltyCard>
      <StyledhelperText>
        <StyledTypography variant="body1Medium">
          {translate('Login_Helper_Text')}
        </StyledTypography>
      </StyledhelperText>
      <center>
        <StyledLoginButton
          onClick={() => setIsLoginOpen(true)}
          variant="outlined"
          fullWidth={isMobile}
        >
          {translate('Log_in')}
        </StyledLoginButton>
      </center>
      {isLoginOpen && (
        <LoginDialog
          onClose={() => setIsLoginOpen(false)}
          page="Home Reorder Tile"
        />
      )}
    </StyledContainer>
  );
};
