import { StoreStatusIcon } from '@Components/Widgets/OrderSettings/StoreStatusIcon';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import LinkButton from '@Components/ui/LinkButton';

const StyledPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  border-radius: 8px;
  width: 300px;
  height: auto;
  border: ${({ theme }) => `1px solid ${theme.palette.background.paper}`};
  background: ${({ theme }) => theme.palette.background.default};
  word-wrap: break-word;

  /* Shadow - Medium - Light - 0 4 8 12% */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;

const StyledBodyTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.body};
`;

const StyledTitleGrid = styled(Grid)`
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;

const StyledOpeningHoursGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.secondary.body};
  text-decoration: none;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.primary.main};
`;

const StyledDirectionsLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledButtonContainer = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(2)};
  span {
    display: flex;
    align-items: center;
  }
`;

const StyledButtonWrapper = styled('div')`
  max-width: 50%;
  a {
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing(1.5)};
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: ${({ theme }) => theme.typography.body2.fontSize}!important;
    }
  }
`;

const StyledButton = styled(LinkButton)`
  display: flex !important;
  span {
    text-transform: capitalize !important;
  }
`;

type LocationCardProps = {
  name: string;
  address?: string;
  phone?: string;
  email?: string;
  isOpen?: boolean;
  isOpenText?: string;
  deliveryHours?: string[];
  collectionHours?: string[];
  contactUsUrl?: string;
  reservationsUrl?: string;
};
const LocationCard = ({
  name,
  address,
  email,
  phone,
  isOpen = false,
  isOpenText,
  contactUsUrl,
  reservationsUrl,
  deliveryHours = [],
  collectionHours = [],
}: LocationCardProps): JSX.Element => {
  const { translate } = useTranslation();
  const hasDeliveryHours = deliveryHours && deliveryHours.length > 0;
  const hasCollectionHours = collectionHours && collectionHours.length > 0;

  const location = useLocation();
  const showContactButton = contactUsUrl && location?.pathname !== contactUsUrl;
  const showReservationsButton =
    !contactUsUrl && reservationsUrl && location?.pathname !== reservationsUrl;

  return (
    <StyledPaper id="locations">
      <Grid container>
        <StyledTitleGrid gap={0.5} container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h2xs">{name}</Typography>
          </Grid>
          <Grid item xs={12} display={'flex'}>
            <StoreStatusIcon acceptPreorders={false} isOpen={isOpen} />
            <Typography variant="body2">{isOpenText} </Typography>
          </Grid>
        </StyledTitleGrid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <StyledBodyTypography variant="body2">
              {address}
            </StyledBodyTypography>
          </Grid>
        </Grid>

        {phone && (
          <Grid item xs={12}>
            <StyledBodyTypography variant="body2">
              {`${translate('Phone')}: `}
              <StyledLink data-fd={'phone-link'} to={`tel:${phone}`}>
                {phone}
              </StyledLink>
            </StyledBodyTypography>
          </Grid>
        )}
        {email && (
          <Grid item xs={12}>
            <StyledBodyTypography variant="body2">
              {`${translate('Email')}: `}
              <StyledLink data-fd={'email-link'} to={`mailTo:${email}`}>
                {email}
              </StyledLink>
            </StyledBodyTypography>
          </Grid>
        )}
        {
          <StyledOpeningHoursGrid container gap={2} item xs={12}>
            {hasDeliveryHours ? (
              <Grid item xs={12}>
                <Typography variant="body2Medium">
                  {translate('Delivery_Hours')}
                </Typography>
                <Grid item xs={12}>
                  {deliveryHours.map((openingHours) => (
                    <StyledBodyTypography key={openingHours} variant="body2">
                      {openingHours}
                    </StyledBodyTypography>
                  ))}
                </Grid>
              </Grid>
            ) : null}
            {hasCollectionHours ? (
              <Grid item xs={12}>
                <Typography variant="body2Medium">
                  {translate('Collection_Hours')}
                </Typography>
                <Grid item xs={12}>
                  {collectionHours.map((openingHours) => (
                    <StyledBodyTypography key={openingHours} variant="body2">
                      {openingHours}
                    </StyledBodyTypography>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </StyledOpeningHoursGrid>
        }
        <StyledButtonContainer container item xs={12} gap={2}>
          {showContactButton && (
            <StyledButtonWrapper>
              <StyledButton
                href={contactUsUrl}
                dataFd={'contact-us-button'}
                label={translate('Contact_us')}
              />
            </StyledButtonWrapper>
          )}
          {showReservationsButton && (
            <StyledButtonWrapper>
              <StyledButton
                href={reservationsUrl}
                dataFd={'reservations-button'}
                label={translate('Book_now')}
              />
            </StyledButtonWrapper>
          )}
          {address && (
            <Typography variant="finePrint1">
              <StyledDirectionsLink
                data-fd={'get-directions-button'}
                target="_blank"
                rel="noreferrer noopener"
                to={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
              >
                {translate('Get_directions')}
              </StyledDirectionsLink>
            </Typography>
          )}
        </StyledButtonContainer>
      </Grid>
    </StyledPaper>
  );
};

export default LocationCard;
