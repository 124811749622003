import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { StoreStatusIcon } from '../StoreStatusIcon';
import { useConfig } from '@Src/providers/ConfigProvider';
import { getOpeningTimeMsgTranslation } from '../openingTimeUtil';

type StoreListItemPropsType = {
  address: string;
  storeName: string;
  isOpen: boolean;
  acceptPreorders: boolean;
  openTimeMessage: string | null;
  distanceKm: number;
  storeId: number;
  isHovered: boolean;
};

const StyledNameContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledSecondaryText = styled(Typography)<{
  ownerState: { isHovered?: boolean };
}>(({ ownerState, theme }) => ({
  color: ownerState.isHovered
    ? theme.palette.getContrastText(
        theme.palette.primary.lightest || theme.palette.primary.main,
      )
    : theme.palette.secondary.body,
  margin: 0,
}));

const StyledStoreName = styled(Typography)`
  margin: 0;
`;

const StyledPreorderNow = styled('span')`
  color: ${({ theme }) => theme.palette.warning.main};
`;

export function StoreListItem({
  address,
  storeName,
  isOpen,
  acceptPreorders,
  openTimeMessage,
  distanceKm,
  isHovered,
}: StoreListItemPropsType): JSX.Element {
  const { translate } = useTranslation();
  const { language } = useConfig();

  const renderOpeningTimeMessage = (): JSX.Element | null => {
    if (isOpen) {
      return null;
    }
    const timeMessage = getOpeningTimeMsgTranslation(
      openTimeMessage || '',
      language,
    );

    return (
      <StyledSecondaryText
        data-fd="opening-time-message"
        variant="caption"
        ownerState={{ isHovered }}
      >
        {acceptPreorders && (
          <StyledPreorderNow>{`${translate(
            'Preorder_now',
          )} `}</StyledPreorderNow>
        )}
        {timeMessage}
      </StyledSecondaryText>
    );
  };

  return (
    <div>
      <StyledNameContainer>
        <StoreStatusIcon isOpen={isOpen} acceptPreorders={acceptPreorders} />
        <StyledStoreName variant="body1Medium" paragraph>
          {storeName}
        </StyledStoreName>
      </StyledNameContainer>

      {address && (
        <>
          <StyledSecondaryText
            ownerState={{ isHovered }}
            variant="finePrint1"
            paragraph
          >{`${distanceKm}km • ${address}`}</StyledSecondaryText>
          {renderOpeningTimeMessage()}
        </>
      )}
    </div>
  );
}
