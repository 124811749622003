import { Grid, type Theme, Typography, useMediaQuery } from '@mui/material';
import { type CSSProperties } from 'react';
import { type MobileAppImageType } from '@Src/middlewares/CustomPageTypes';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Link } from '@Components/RoutesUtils';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import MobileAppBadges from '@Components/Widgets/Apps/MobileAppBadges';
import {
  StyleContentContainer,
  StyledAppBadgeContainer,
  StyledHeaderContainer,
  StyledImageContainer,
  StyledImgixImage,
  StyledOrderButton,
  StyledScreenshotContainer,
  StyledScreenshotGrid,
  StyledScreenshotImgixImg,
  StyledSubtitleWrapper,
  StyledText,
  StyledTextContainer,
  StyledTextGrid,
} from './styles';

type Props = {
  backgroundColor?: CSSProperties['backgroundColor'];
  textColor?: CSSProperties['color'];
  className?: string;
  descriptionClassName?: string;
  data: {
    actionButton?: { label?: string; url?: string };
    description?: string;
    tag?: string;
    title?: string;
  };
  dataFd: string;
  hasBackground?: boolean;
  hasImage?: boolean;
  id: string;
  imageAttributes?: { alt: string; dataFd: string; src: string };
  imageType?: MobileAppImageType;
  orientation?: 'left' | 'right';
  shouldSetInnerHTML?: boolean;
  showAppBadges?: boolean;
  showTextSection?: boolean;
  isPlainSection?: boolean;
};
export const Section = ({
  backgroundColor,
  className,
  descriptionClassName,
  data,
  dataFd,
  hasBackground = true,
  hasImage,
  id,
  imageType = 'image',
  imageAttributes,
  orientation = 'left',
  shouldSetInnerHTML,
  showAppBadges,
  showTextSection,
  isPlainSection,
  textColor,
}: Props): JSX.Element => {
  const { translate } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const orderUrl = useAppSelector((state) => state.orderUrl.path) || '/order';
  const translatedButtonLabel = translate(
    data.actionButton?.label || 'Start_Your_Order',
  );
  const buttonLabel = translatedButtonLabel || translate('Start_Your_Order');
  const description = translate(data?.description || '').replaceAll(
    '\n',
    '<br />',
  );

  const MIN_STR_LENGTH = isLg ? 450 : data.actionButton ? 200 : 350;
  const isTextGreaterThanMinLength = description.length > MIN_STR_LENGTH;
  const containsBreak = /<br\/?>/.test(description);
  let IMG_HEIGHT = '500px'; // Default value

  if (isTextGreaterThanMinLength || isMd || !hasImage || containsBreak) {
    IMG_HEIGHT = '100%';
  } else if (isLg) {
    IMG_HEIGHT = '504px';
  }
  const centered = !hasImage || !showTextSection;

  let imageContainerOrder;
  let textContainerOrder;

  if (orientation === 'left' || isMobile) {
    imageContainerOrder = { xs: 1, sm: 0 };
    textContainerOrder = { xs: 0, sm: 1 };
  } else {
    imageContainerOrder = { xs: 0, sm: 1 };
    textContainerOrder = { xs: 1, sm: 0 };
  }

  return (
    <div id={id} className={className} data-fd={`${dataFd}-section`}>
      <StyleContentContainer
        container
        height={IMG_HEIGHT}
        ownerState={{ hasBackground, backgroundColor, textColor }}
        overflow={imageType === 'screenshot' ? 'hidden' : 'visible'}
      >
        {hasImage && imageType === 'image' && (
          <StyledImageContainer
            item
            order={imageContainerOrder}
            ownerstate={{ orientation }}
            sm={!showTextSection || centered ? 12 : 6}
            xs={12}
          >
            <StyledImgixImage
              alt={imageAttributes?.alt || 'main image'}
              data-fd={imageAttributes?.dataFd}
              src={imageAttributes?.src}
              height={IMG_HEIGHT}
              ownerstate={{
                orientation,
                imageOnly: !showTextSection,
                isPlainSection,
              }}
            />
          </StyledImageContainer>
        )}

        {hasImage && imageType === 'screenshot' && (
          <StyledScreenshotContainer
            item
            order={imageContainerOrder}
            sm={6}
            xs={12}
          >
            <StyledScreenshotGrid item>
              <StyledScreenshotImgixImg
                data-fd={imageAttributes?.dataFd}
                alt={imageAttributes?.alt || 'screenshot image'}
                src={imageAttributes?.src}
              />
            </StyledScreenshotGrid>
          </StyledScreenshotContainer>
        )}

        {showTextSection && (
          <StyledTextContainer
            item
            order={textContainerOrder}
            ownerstate={{ isTextGreaterThanMinLength, imageType }}
            sm={centered ? 12 : 6}
            xs={12}
          >
            <StyledTextGrid item>
              <StyledHeaderContainer item xs={12}>
                {data?.tag && (
                  <Grid item xs={12}>
                    <Typography variant="body1Medium">
                      {translate(data?.tag)}
                    </Typography>
                  </Grid>
                )}
                {data?.title && (
                  <StyledSubtitleWrapper item xs={12}>
                    <Typography variant="h2Display">
                      {translate(data.title)}
                    </Typography>
                  </StyledSubtitleWrapper>
                )}
              </StyledHeaderContainer>
              {description && (
                <Grid item xs={12}>
                  <StyledText
                    className={descriptionClassName}
                    component="span"
                    data-fd={`${dataFd}-description`}
                    dangerouslySetInnerHTML={
                      shouldSetInnerHTML ? { __html: description } : undefined
                    }
                  >
                    {!shouldSetInnerHTML ? description : null}
                  </StyledText>
                </Grid>
              )}
              {data?.actionButton?.url && (
                <Grid item xs={12}>
                  <Link to={data.actionButton?.url || orderUrl}>
                    <StyledOrderButton variant="contained">
                      {buttonLabel}
                    </StyledOrderButton>
                  </Link>
                </Grid>
              )}

              {showAppBadges && (
                <Grid item xs={12}>
                  <StyledAppBadgeContainer item xs={12}>
                    <MobileAppBadges />
                  </StyledAppBadgeContainer>
                </Grid>
              )}
            </StyledTextGrid>
          </StyledTextContainer>
        )}
      </StyleContentContainer>
    </div>
  );
};
