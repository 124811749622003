import { Link } from '@Components/RoutesUtils';
import { useTracking } from '@Src/hooks/useTracking';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { getMobileAppIcons } from '@Src/utility/mobileAppsUtils';

const MobileAppBadges = (): JSX.Element => {
  const { language, appId } = useConfig();
  const { AppStoreBadge, GooglePlayBadge } = getMobileAppIcons(language);
  const { mobileApps } = useDesignSchema('v2');
  const { trackEvent } = useTracking();
  const { translate } = useTranslation();

  const { appleStoreUrl, playStoreUrl: playStoreUrlFromSchema } =
    mobileApps || {};
  const playStoreUrl =
    playStoreUrlFromSchema ||
    `https://play.google.com/store/apps/details?id=ie.flipdish.${appId}`;

  const handleAppBadgeClick = (isPlayStore: boolean): void => {
    const store = isPlayStore ? 'Google' : 'Apple';
    trackEvent('App Download', {
      category: 'Engagement',
      store,
    });
  };

  return (
    <>
      {mobileApps?.hasApple && appleStoreUrl && (
        <Link
          onClick={() => handleAppBadgeClick(false)}
          to={appleStoreUrl || ''}
          data-fd="app-store-badge"
          aria-label={translate('Link_to_app_on_apple_app_store')}
        >
          <AppStoreBadge />
        </Link>
      )}
      {mobileApps?.hasAndroid && (playStoreUrl || playStoreUrl) && (
        <Link
          onClick={() => handleAppBadgeClick(true)}
          to={playStoreUrl || playStoreUrl || ''}
          data-fd="play-store-badge"
          aria-label={translate('Link_to_app_on_google_play_store')}
        >
          <GooglePlayBadge />
        </Link>
      )}
    </>
  );
};

export default MobileAppBadges;
