import { ImgixImage } from '@Components/ui/ImgixImage';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

const StyledOwlCarousel = styled.div`
  opacity: 1;
  display: 'block';
`;
const StyledOwlWrapper = styled.div`
  width: '1084px';
  left: '0px';
  display: 'block';
`;
const StyledOwlItem = styled.div`
  width: '542px';
`;

interface AboutImageProps {
  id: string;
  images: string[];
}
const AboutImage = ({ id, images }: AboutImageProps): JSX.Element => (
  <StyledOwlCarousel id={id} className="owl-carousel owl-theme">
    <div className="owl-wrapper-outer">
      <StyledOwlWrapper className="owl-wrapper">
        <StyledOwlItem className="owl-item">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            autoPlay
            interval={4000}
            animationHandler="fade"
            dynamicHeight={false}
            swipeable={false}
          >
            {(images || []).map((image, index) => (
              <div key={`about-image-${index}`} className="item">
                <ImgixImage
                  loading="lazy"
                  src={`${image}?w=630&h=420`}
                  alt={`about-image-${index}`}
                />
              </div>
            ))}
          </Carousel>
        </StyledOwlItem>
      </StyledOwlWrapper>
    </div>
  </StyledOwlCarousel>
);

export default AboutImage;
