import { type RootState } from '@Src/store';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';
import { type SupportedMethodsType } from '@Src/hooks/useApi';
import { type Campaign } from '@Src/types/loyaltyCampaignTypes';

export type FetchDataParamsType = {
  get: SupportedMethodsType['get'];
  appId: string | null;
};
export const fetchLoyaltyCampaignProgress = createAsyncThunk(
  'loyaltyCampaigns/loyaltyProgress',
  async ({ get, appId }: FetchDataParamsType, thunkApi) => {
    try {
      if (!appId) {
        throw new Error('appId is required');
      }

      const state = thunkApi.getState() as RootState;
      thunkApi.dispatch({
        type: 'loyaltyCampaigns/loyaltyProgressStart',
      });
      const { selectedDeliveryStore } = state.deliveryStores;
      const { selectedCollectionStore } = state.collectionStores;
      const { isCollection } = state.orderSettings;
      const storeId = isCollection
        ? selectedCollectionStore?.PhysicalRestaurantId
        : selectedDeliveryStore?.PhysicalRestaurantId;

      if (!storeId) {
        return undefined;
      }

      const url = '/marketing/GetUserStatusOnLoyaltyCampaign';
      const queryParams = { appId, storeIDs: storeId.toString() };
      const response = await get<Campaign[]>({
        path: url,
        params: { appId, storeIDs: storeId.toString() },
      });
      const { data } = response;
      if (!data?.Data) {
        logger.error(
          '[fetchLoyaltyCampaignProgress]: did not return any data for {AppId}',
          {
            appId,
            url,
            params: queryParams,
            code: data?.Code,
            developerMessage: data?.DeveloperMessage,
            success: data?.Success,
            userMessage: data?.UserMessage,
          },
        );
        return thunkApi.rejectWithValue({
          error: `fetchLoyaltyCampaignProgress did not return any data: ${
            data?.UserMessage || 'undefined'
          }`,
        });
      }
      return data.Data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(
        '[fetchLoyaltyCampaignProgress]: fetching failed for {AppId}',
        {
          appId,
          errorMessage: e.message,
        },
      );
      console.log('error', e);
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
