import { useTranslation } from '@Src/providers/TranslationProvider';
import { useState } from 'react';
import { isLocationServiceSupported } from '@Src/utility/locationUtils';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { setGeolocation } from '@Src/slices/locationSlice';
import { logger } from '@Src/utility/loggerService';
import { ReactComponent as LocationIcon } from '@Src/assets/images/user-location.svg';
import { Button, Typography, styled } from '@mui/material';
import { type HandleNavConfirmLocationProps } from '..';
import Dialog from '@Components/ui/Dialog';
import { useTracking } from '@Src/hooks/useTracking';
import { useAppSelector } from '@Src/hooks/useAppSelector';

const StyledContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1.75),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1.25),
  paddingLeft: theme.spacing(2),
}));

const StyledButton = styled(Button)`
  color: inherit;
  text-transform: none;
  width: fit-content;
  padding: 0;
`;

const StyledIcon = styled(LocationIcon)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 3,
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '328px',
  },
  '& .MuiDialogActions-root': {
    padding: '0',
  },
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const StyledBodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.body,
  marginBottom: theme.spacing(3),
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledActionBtnText = styled(Typography)`
  text-transform: none;
`;

type UseCurrentLocationButtonPropsType = {
  onLocationRetrieved: ({ geo }: HandleNavConfirmLocationProps) => void;
};

export function UseCurrentLocationButton({
  onLocationRetrieved,
}: UseCurrentLocationButtonPropsType): JSX.Element {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackOrderEvent } = useTracking();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [geoLocationInProgress, setGeolocationInProgress] = useState<boolean>();

  const buttonTitle = geoLocationInProgress
    ? translate('Finding_location')
    : translate('Use_current_location');

  const handleUseLocationClick = (): void => {
    trackOrderEvent('Add Address', {
      isLoggedIn: String(isLoggedIn),
      type: 'Current Location',
    });
    if (!isLocationServiceSupported()) {
      setIsModalOpen(true);
      return;
    }
    setGeolocationInProgress(true);
    navigator.geolocation.getCurrentPosition(
      (p) => handleGeolocationSuccess(p),
      handleGeolocationError,
      {
        enableHighAccuracy: true,
      },
    );
  };

  const handleGeolocationError = (error: GeolocationPositionError): void => {
    setIsModalOpen(true);
    logger.log(error.message);
    setGeolocationInProgress(false);
  };

  const handleGeolocationSuccess = (position: GeolocationPosition): void => {
    const { coords } = position;
    setGeolocationInProgress(false);
    const geo = {
      Latitude: coords.latitude,
      Longitude: coords.longitude,
    };
    dispatch(setGeolocation(geo));
    onLocationRetrieved({ geo });
  };

  return (
    <StyledContainer>
      <StyledButton onClick={handleUseLocationClick} variant="text">
        <StyledIcon height={20} width={20} />
        <Typography variant="buttonSmall">{buttonTitle}</Typography>
      </StyledButton>

      <StyledDialog
        actions={
          <StyledActionButton
            fullWidth
            onClick={() => {
              setIsModalOpen(false);
            }}
            size="large"
            variant="contained"
          >
            <StyledActionBtnText variant="button">
              {translate('Ok')}
            </StyledActionBtnText>
          </StyledActionButton>
        }
        ariaLabel={translate('Edit_order_settings_dialog')}
        open={isModalOpen}
      >
        <StyledHeader variant="h2xs">
          {translate('Sharing_location_disabled')}
        </StyledHeader>
        <StyledBodyText variant="body1xs" paragraph>
          {translate('Sharing_location_disabled_info')}
        </StyledBodyText>
      </StyledDialog>
    </StyledContainer>
  );
}
