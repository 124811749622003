import { Select } from '@Components/ui/Select';
import { TextField } from '@Components/ui/TextField';
import { ReactComponent as Warning } from '@Src/assets/images/Warning.svg';
import { ReactComponent as LeftArrowIcon } from '@Src/assets/images/arrow-left-long.svg';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { useMapTools } from '@Src/hooks/useMapTools';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { fetchDeliveryAddresses } from '@Src/thunks/deliveryAddressesThunks';
import {
  type AddressI18nFormScheme,
  type Coordinates,
} from '@Src/types/deliveryLocationFormTypes';
import { DeviceId } from '@Src/utility/deviceUtils';
import { useNavigateToWebOrder } from '@Src/utility/orderUtils';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DeliveryUnavailableModal } from './Components/DeliveryUnavailableModal';
import { setMap } from './utils';
import useApi from '@Src/hooks/useApi';
import { type DeliveryStoreSummary } from '@Src/types/deliveryStoreTypes';

type UserAddressDetailsType = {
  addressFields: any;
  autoSelectThisLocation: boolean;
  coordinates: Coordinates;
  countryCode?: string;
  deliveryInstructions?: string;
  deviceId?: string;
  isCustomAddress: boolean;
  isDisplayed: boolean;
};

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(6),
  },
}));

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => ({
    color: theme.palette.text.primary,
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
  })};
`;

const StyledHeader = styled(Typography)`
  padding: ${({ theme }) =>
    `0 ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2)}`};
`;

const StyledMapContainer = styled('div')`
  box-sizing: border-box;
  height: 160px;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

const StyledForm = styled('form')`
  ${({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  })};
`;

const StyledFieldContainer = styled('div')`
  margin: ${({ theme }) => `0 0 ${theme.spacing(3.25)}`};
`;

const StyledSelectContainer = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(-2)} 0 ${theme.spacing(3.25)} 0`};
`;

const StyledWarning = styled(Warning)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const StyledSubmitContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
  bottom: 0,
  position: 'sticky',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    left: 0,
    position: 'fixed',
    width: '100%',
  },
}));

const StyledSubmitText = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

export type ConfirmAddressFormProps = {
  autoFillFields?: boolean;
  coordinates: Coordinates;
  locationFormScheme?: AddressI18nFormScheme;
  onBackClick: () => void;
};

export const ConfirmAddressForm: React.FC<ConfirmAddressFormProps> = ({
  autoFillFields = true,
  coordinates,
  locationFormScheme,
  onBackClick,
}): JSX.Element => {
  const { appId, language } = useConfig();
  const { translate } = useTranslation();
  const navigateToWebOrder = useNavigateToWebOrder();
  const dispatch = useAppDispatch();
  const [mapTools] = useMapTools();
  const { get, post } = useApi();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: {},
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    autoFillFields && setMap(coordinates);
  }, [autoFillFields, coordinates]);

  const getCoordinates = async (): Promise<Coordinates> => {
    const hasAddressBeenUpdated = isDirty;
    if (hasAddressBeenUpdated) {
      const fieldValues = { ...getValues(), instructions: '' };
      const address = Object.values(fieldValues).join(' ');

      const geoCodeResponse = await mapTools?.GeoCoder.geocode({ address });
      const coords = geoCodeResponse?.results[0]?.geometry?.location;

      if (coords) {
        return { Latitude: coords.lat(), Longitude: coords.lng() };
      }
    }
    return coordinates;
  };

  const onSubmit = (): void => {
    void (async (): Promise<void> => {
      const coordinates = await getCoordinates();
      const fieldValues = getValues();
      const addressDetails = {
        ...fieldValues,
      };
      delete addressDetails.instructions;

      const requestPayload = {
        addressFields: getValues(),
        autoSelectThisLocation: false,
        coordinates,
        countryCode: locationFormScheme?.CountryCode,
        deliveryInstructions: fieldValues.instructions,
        deviceId: DeviceId(),
        isCustomAddress: true,
        isDisplayed: true,
      };

      try {
        const { data } = await post<
          UserAddressDetailsType,
          { DeliveryLocationId: number }
        >({
          path: '/map/DeliveryLocationForm',
          payload: requestPayload,
          suppresCookieHeader: true,
          appType: 'Web',
        });

        const { data: deliveryStoreSummaries } = await get<
          DeliveryStoreSummary[]
        >({
          path: '/Restaurant/DeliveryRestaurantSummaries',
          params: {
            Latitude: coordinates.Latitude.toString(),
            Longitude: coordinates.Longitude.toString(),
            skip: '0',
            count: '1000',
            deviceId: DeviceId(),
          },
          appType: 'Web',
        });

        const selectedStore = deliveryStoreSummaries?.Data?.find(
          (store) => !store.UserOutsideDeliveryZone,
        );

        if (selectedStore) {
          navigateToWebOrder({
            isCollection: false,
            storeSummary: selectedStore,
            deliveryLocationId: data?.Data?.DeliveryLocationId,
          });
        } else {
          setIsModalOpen(true);
          await dispatch(
            fetchDeliveryAddresses({ appId, lang: language, get }),
          );
        }
      } catch (e) {
        console.error(e);
        // TODO: show some kind of error state in the UI
      }
    })();
  };

  const trimFormValue = (identifier: string): void => {
    const formValue = getValues()[identifier];
    setValue(identifier, formValue.trim());
  };

  return (
    <StyledContainer>
      <StyledIconButton disableRipple data-fd="back-icon" onClick={onBackClick}>
        <LeftArrowIcon />
      </StyledIconButton>
      <StyledHeader variant="h1xs">{translate('Confirm_address')}</StyledHeader>
      {locationFormScheme && (
        <article>
          {autoFillFields && (
            <StyledMapContainer aria-hidden="true" id="mapRef" />
          )}
          <StyledForm
            data-fd="confirm-location-form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(() => {
              onSubmit();
            })}
          >
            {locationFormScheme.FormData?.map((field) => {
              const {
                Identifier: identifier,
                Label: label,
                Placeholder: placeholder,
                Rules: rules,
              } = field;

              const fieldValue =
                typeof field.Value === 'string'
                  ? field.Value.trim()
                  : field.Value;

              const validationRule = {
                maxLength: {
                  message: rules?.MaxLength?.Message || '',
                  value: rules?.MaxLength?.Value,
                },
                minLength: {
                  message: rules?.MinLength?.Message || '',
                  value: rules?.MinLength?.Value,
                },
                pattern: {
                  message: rules?.Pattern?.Message || '',
                  // @ts-expect-error TODO: need to revisit this
                  value: new RegExp(rules?.Pattern?.Value),
                },
                required: {
                  message: rules?.Required?.Message || '',
                  value: rules?.Required?.Value || false,
                },
              };

              const isSelectField = Array.isArray(rules?.Predefined);
              if (isSelectField) {
                const options = rules?.Predefined?.map(
                  (option: { Value: any; Display: any }) => ({
                    disabled: false,
                    key: option.Value,
                    label: option.Display,
                  }),
                );

                const defaultValue: string =
                  options?.find((opt) => opt.key === fieldValue)?.label || '';

                return (
                  options &&
                  options?.length > 0 && (
                    <StyledSelectContainer key={identifier}>
                      <Controller
                        control={control}
                        defaultValue={defaultValue}
                        name={identifier}
                        render={() => (
                          <Select
                            defaultValue={defaultValue}
                            identifier={identifier}
                            label={label}
                            options={options}
                            placeholder={placeholder || ''}
                          />
                        )}
                      />
                    </StyledSelectContainer>
                  )
                );
              }

              const getHelperText = (): string => {
                const errMessage = errors[identifier]?.message;
                if (errMessage && typeof errMessage === 'string') {
                  return errMessage;
                }
                return '';
              };

              return (
                <StyledFieldContainer key={identifier}>
                  <TextField
                    data-fd={identifier}
                    defaultValue={autoFillFields ? fieldValue : ''}
                    endIcon={errors[identifier] && <StyledWarning />}
                    error={!!errors[identifier]}
                    helperText={getHelperText()}
                    id={identifier}
                    inputProps={{
                      ...register(identifier, validationRule as any),
                    }}
                    key={identifier}
                    label={label}
                    name={identifier}
                    onBlur={(): void => trimFormValue(identifier)}
                    placeholder={placeholder}
                  />
                </StyledFieldContainer>
              );
            })}
            <StyledFieldContainer>
              <TextField
                data-fd="custom-instructions"
                id="instructions"
                inputProps={{ ...register('instructions') }}
                key="instructions"
                label={translate('Delivery_instructions_optional')}
                minRows={3}
                multiline
                name="instructions"
                placeholder={translate(
                  'DeliveryConfirmLocation_SpecialDeliveryInstructions',
                )}
              />
            </StyledFieldContainer>

            <StyledSubmitContainer>
              <Button type="submit" variant="contained" size="large" fullWidth>
                <StyledSubmitText variant="button">
                  {translate('Confirm_address')}
                </StyledSubmitText>
              </Button>
            </StyledSubmitContainer>
          </StyledForm>
        </article>
      )}
      <DeliveryUnavailableModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        goToFirstStep={onBackClick}
      />
    </StyledContainer>
  );
};
