import { type RootState } from '@Src/store';
import { type CollectionStoreSummary } from '@Src/types/collectionStoreTypes';
import { type DeliveryStoreSummary } from '@Src/types/deliveryStoreTypes';
import { createSelector } from '@Src/utility/reduxUtils';

const selectIsCollection = (state: RootState): boolean =>
  state.orderSettings.isCollection;

export const selectIsDeliveryUnavailable = (
  state: RootState,
): boolean | undefined => {
  if (state.deliveryStores?.selectedDeliveryStore) {
    return (
      !state.deliveryStores?.selectedDeliveryStore.IsOpen &&
      !state.deliveryStores?.selectedDeliveryStore.AcceptPreOrders
    );
  }
};

export const selectIsCollectionUnavailable = (
  state: RootState,
): boolean | undefined => {
  if (state?.collectionStores?.selectedCollectionStore) {
    return (
      !state.collectionStores?.selectedCollectionStore.IsOpen &&
      !state.collectionStores?.selectedCollectionStore.AcceptPreOrders
    );
  }
};

const hasDeliveryStores = (state: RootState): boolean =>
  !!state.deliveryStores?.deliveryStoreList?.length;

const hasCollectionStores = (state: RootState): boolean =>
  !!state.collectionStores?.collectionStoreList?.length;

export const selectServiceUnavailableText = createSelector(
  [
    selectIsCollection,
    hasCollectionStores,
    selectIsCollectionUnavailable,
    hasDeliveryStores,
    selectIsDeliveryUnavailable,
  ],
  (
    isCollection,
    hasCollectionStores,
    isCollectionUnavailable,
    hasDeliveryStores,
    isDeliveryUnavailable,
  ) => {
    if (isCollection && isDeliveryUnavailable && hasCollectionStores) {
      return {
        headerText: 'Delivery_currently_unavailable',
        message: 'Check_back_later_or_order_for_collection',
      };
    }
    if (!isCollection && isCollectionUnavailable && hasDeliveryStores) {
      return {
        headerText: 'Collection_currently_unavailable',
        message: 'Check_back_later_or_order_for_delivery',
      };
    }
  },
);

export const selectIsPreOrderOnly = (state: RootState): boolean | undefined => {
  if (state.orderSettings.isCollection) {
    return (
      !state.collectionStores?.selectedCollectionStore?.IsOpen &&
      state.collectionStores?.selectedCollectionStore?.AcceptPreOrders &&
      state.collectionStores?.selectedCollectionStore
        ?.SecondsUntilRestaurantOpens !== null
    );
  }
  if (!state.orderSettings.isCollection) {
    return (
      !state.deliveryStores?.selectedDeliveryStore?.IsOpen &&
      state.deliveryStores?.selectedDeliveryStore?.AcceptPreOrders &&
      state.deliveryStores?.selectedDeliveryStore
        ?.SecondsUntilRestaurantOpens !== null
    );
  }
};

export const selectSelectedStore = (
  state: RootState,
): DeliveryStoreSummary | CollectionStoreSummary | undefined =>
  state.orderSettings.isCollection
    ? state.collectionStores?.selectedCollectionStore
    : state.deliveryStores?.selectedDeliveryStore;

export const selectSelectedStoreId = (state: RootState): number | undefined =>
  state.orderSettings.isCollection
    ? state.collectionStores?.selectedCollectionStore?.PhysicalRestaurantId
    : state.deliveryStores?.selectedDeliveryStore?.PhysicalRestaurantId;

export const selectHasStores = (state: RootState): boolean =>
  !!state.deliveryStores?.deliveryStoreList?.length ||
  !!state.collectionStores?.collectionStoreList?.length;

export const selectHasSingleStoreTypeOnly = (state: RootState): boolean =>
  !state.deliveryStores?.deliveryStoreList?.length ||
  !state.collectionStores?.collectionStoreList?.length;

export const selectShouldShowLeadTime = (state: RootState): boolean =>
  (!state.orderSettings.isCollection &&
    !!state.deliveryAddresses?.selectedDeliveryAddress) ||
  state.orderSettings.isCollection;

export const selectIsServiceUnavailable = createSelector(
  [
    selectIsDeliveryUnavailable,
    selectIsCollectionUnavailable,
    selectIsCollection,
  ],
  (isDeliveryUnavailable, isCollectionUnavailable, isCollection) =>
    (isCollection && isDeliveryUnavailable) ||
    (!isCollection && isCollectionUnavailable),
);

export const selectShouldForceCollectionMenuUrl = (state: RootState): boolean =>
  !state.orderSettings.isCollection &&
  (!state.deliveryStores?.selectedDeliveryStore ||
    !state.deliveryAddresses?.selectedDeliveryAddress?.DeliveryLocationId);

export const selectHasMoreThanOneCollectionStore = (
  state: RootState,
): boolean => state.collectionStores?.collectionStoreList?.length > 1;

export const selectIsCollectionWithStores = createSelector(
  [
    selectIsCollection,
    selectShouldForceCollectionMenuUrl,
    selectHasMoreThanOneCollectionStore,
  ],
  (isCollection, shouldForceCollectionMenuUrl, hasMoreThanOneCollectionStore) =>
    (isCollection || shouldForceCollectionMenuUrl) &&
    hasMoreThanOneCollectionStore,
);
