import { type Coordinates } from '@Src/types/deliveryLocationFormTypes';
import CustomMarker from '@Src/assets/images/Marker.svg';

export const setMap = (locationGeo: Coordinates): void => {
  const options: google.maps.MapOptions = {
    center: { lat: locationGeo.Latitude, lng: locationGeo.Longitude },
    clickableIcons: false,
    draggable: false,
    fullscreenControl: false,
    keyboardShortcuts: false,
    mapTypeControl: false, // No map type (terrain/satellite, etc)s
    streetViewControl: false, // No street view ControlLabel
    zoom: 17,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    styles: [
      {
        stylers: [
          {
            saturation: -100,
          },
        ],
      },
    ],
  };

  const map = new google.maps.Map(
    document.getElementById('mapRef') as HTMLElement,
    options,
  );

  const customIcon = {
    scaledSize: new google.maps.Size(48, 48),
    url: CustomMarker,
  };

  // eslint-disable-next-line no-new
  new google.maps.Marker({
    icon: customIcon,
    map,
    position: { lat: locationGeo.Latitude, lng: locationGeo.Longitude },
  });
};
