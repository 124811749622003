import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { Grid, Typography, styled } from '@mui/material';
import LinkButton from '@Components/ui/LinkButton';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { InnerGridPolice, StyledBaseContainer } from '../BaseGridElements';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';

const StyledBoxedUpBanner = styled(StyledBaseContainer)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    padding: `0 ${theme.spacing(3)}`,
  },
}));

const StyledBoxedUpInnerGridPolice = styled(InnerGridPolice)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.contrastText,
  padding: `${theme.spacing(1)} !important`,
  paddingLeft: `${theme.spacing(4)} !important`,
}));

const StyledBannerContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => `${theme.spacing(1)} 5em`};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  }
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const StyledButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  a: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    height: 'fit-content',
    span: {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },
  },
  ...(theme.template === V2Templates.boxedUp && {
    justifyContent: 'center',
    a: {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      borderRadius: theme.boxedUp.buttonBorderRadius,
      width: 'auto',
      alignSelf: 'center',
    },
  }),
}));

type StyledBannerGridProps = {
  ownerState: {
    centerAlign: boolean;
  };
};

const StyledBannerGrid = styled(Grid)<StyledBannerGridProps>`
  display: flex;
  justify-content: space-between;
  align-content: center;
  ${({ ownerState }) =>
    ownerState.centerAlign &&
    `
    justify-content: center;
    text-align: center;`}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;

const BoxedUpBanner = ({
  children,
  ...props
}: {
  children: JSX.Element;
}): JSX.Element => (
  <StyledBoxedUpBanner {...props}>
    <StyledBoxedUpInnerGridPolice>{children}</StyledBoxedUpInnerGridPolice>
  </StyledBoxedUpBanner>
);

const Banner = (): JSX.Element => {
  const { translate } = useTranslation();
  const { banner, template } = useDesignSchema('v2');

  const centerAlignBannerContent =
    (!banner?.actionButtonText && !banner?.actionButtonUrl) ||
    (!banner?.subTitle && !banner?.title);

  const Banner =
    template === V2Templates.boxedUp ? BoxedUpBanner : StyledBannerContainer;

  return (
    <Banner id="information-banner">
      <StyledBannerGrid
        ownerState={{ centerAlign: centerAlignBannerContent }}
        container
      >
        <Grid item>
          {banner?.title && (
            <Grid item xs={12}>
              <StyledText variant="h3">{translate(banner.title)}</StyledText>
            </Grid>
          )}
          {banner?.subTitle && (
            <Grid item xs={12}>
              <StyledText variant="body2">
                {translate(banner.subTitle)}
              </StyledText>
            </Grid>
          )}
        </Grid>
        {banner?.actionButtonText && banner?.actionButtonUrl && (
          <StyledButtonGrid item>
            <LinkButton
              textVariant="buttonSmall"
              label={translate(banner.actionButtonText)}
              href={banner.actionButtonUrl}
            />
          </StyledButtonGrid>
        )}
      </StyledBannerGrid>
    </Banner>
  );
};

export default Banner;
