import { type SupportedLanguage } from './utils/constants';

export type SocialMediaType = {
  AppId: string;
  TwitterUrl?: string;
  FacebookUrl?: string;
  InstagramUrl?: string;
  PintrestUrl?: string;
  TripAdvisorUrl?: string;
  FourSquareUrl?: string;
  YelpUrl?: string;
};

export type ConfigDataType = {
  appDesignScheme: {
    Font?: string;
    Primary: string;
    Secondary: string;
  };
  logo: string;
  appStoreUrl?: string;
  playStoreUrl?: string;
  map?: MapObjectType;
  policyUrls?: PolicyUrlsType;
  cookieConsentGDPREnabled?: boolean;
  cookieConsentPromptEnabled?: boolean;
  hostName?: string;
  languages?: Array<{
    id?: SupportedLanguage;
    name?: string;
  }>;
};

export type MobileAppImageType = 'image' | 'screenshot';

export type MobileAppMetaDataType = {
  appleStoreId?: string;
  hasApple?: boolean;
  hasAndroid?: boolean;
  appStoreDescription?: string;
  appStoreKeywords?: string;
  appleStoreUrl?: string;
  appStoreUrl?: string;
  playStoreUrl?: string;
  images?: string[];
  title?: string;
  description?: string;
  imageType?: MobileAppImageType;
};

export type LegalDataType = {
  registrationNumber: string;
  tradingName: string;
  companyName: string;
  companyAddress: string;
};

export type AnalyticsType = {
  fbIds?: string;
  gaIds?: string;
};

export type HeaderLinkType = {
  aboutUs?: boolean;
  gallery?: boolean;
  contactForm?: boolean;
  reservations?: boolean;
};

export type PolicyUrlsType = {
  privacy?: string;
  cookie?: string;
  terms?: string;
};

export type AboutUsType = {
  title: string;
  subtitle?: string;
  left: {
    title: string;
    description: string;
    images: string[];
  };
  right: {
    title: string;
    description: string;
    images: string[];
  };
};

export type MapObjectType = {
  googleMapApiKey?: string;
  mapCenter?: {
    latitude?: number;
    longitude?: number;
  };
};

export type PageSchemaType = Record<string, CustomPageType | V2CustomPageType>;

export type PageTemplatesType =
  | 'losPollosHermanos'
  | 'cafeShirley'
  | 'pizzaCafe'
  | 'default';

export enum PageTemplates {
  LosPollosHermanos = 'losPollosHermanos',
  CafeShirley = 'cafeShirley',
  PizzaCafe = 'pizzaCafe',
  Default = 'default',
}

export enum V2Templates {
  dishy = 'dishy',
  boxedUp = 'boxedUp',
  classic = 'classic',
}

export type RedirectType = { from: string; to: string; status: 301 | 302 };

export type MenuLinkType = {
  NavigationLinkTitle: string;
  NavigationLinkIndex: number;
  LinkDisplaySection: 'header' | 'footer';
  url: string;
};

export type headHTMLObjType = {
  customizer?: Record<string, string>;
  styles?: Array<{
    text?: string;
  }>;
  links?: Array<{ rel?: string; href: string }>;
  scripts?: Array<{ src?: string; text?: string }>;
  meta?: Array<{ name?: string; content?: string }>;
  facebookMeta?: string;
  googleMeta?: string;
  gaId?: string;
  fonts?: string[];
};

export type CustomPageSchemaV1Type = {
  schemaVersion: 'v1';
  locationPageEnabled: boolean;
  name: string;
  pages: Record<string, CustomPageType>;
  headHTMLObj?: headHTMLObjType;
  globalCSS?: string;
  headerLinks?: HeaderLinkType;
  socialMedia?: SocialMediaType;
  template?: PageTemplatesType;
  mobileApps?: MobileAppMetaDataType;
  legal?: LegalDataType;
  analytics?: AnalyticsType;
  config?: ConfigDataType;
  redirects?: RedirectType[];
  menuLinks?: MenuLinkType[];
  isPanaceaEnabled: boolean;
};

export enum PageType {
  AboutUsPage = 'AboutUsPage',
  HQContactPage = 'HQContactPage',
  EmptyPage = 'EmptyPage',
  EmptyNoHeaderPage = 'EmptyNoHeaderPage',
  IndexPage = 'IndexPage',
}

export type CustomPageType =
  | HqContactPageType
  | AboutUsPageType
  | EmptyPageType
  | EmptyNoHeaderPage
  | IndexPageType;

export type CustomPageGenericType = {
  NavigationLinkTitle?: string; // Formerly Title
  NavigationLinkIndex?: number; // Formerly Linkindex
  LinkDisplaySection?: 'header' | 'footer' | 'none';
  pageStyles?: string;
  enabled: boolean;
};

export type HqContactPageType = {
  PageType: PageType.HQContactPage;
  CompanyName?: string;
  Email?: string;
  CompanyRegistrationNumber?: number;
  AddressLine2?: string;
  AddressLine1?: string;
  Phone?: string;
  CeoFullName?: string;
  TaxNumber?: string;
  VatNumber?: string;
  RegisterCourt?: string;
} & CustomPageGenericType;

export type AboutUsPageType = {
  PageType: PageType.AboutUsPage;
  title?: string; // Formally h1
  subtitle?: string;
  left: {
    title: string;
    description: string;
    images: string[];
  };
  right: {
    title: string;
    description: string;
    images: string[];
  };
} & CustomPageGenericType;

export type EmptyPageType = {
  html?: string;
  PageType: PageType.EmptyPage;
} & CustomPageGenericType;

export type EmptyNoHeaderPage = Omit<EmptyPageType, 'PageType'> & {
  header?: boolean;
  PageType: PageType.EmptyNoHeaderPage;
} & CustomPageGenericType;

export type IndexPageType = {
  PageType: PageType.IndexPage;
  testimonials: TestimonialsType;
  aboutUs: AboutUsPageType;
  openingHoursEnabled: boolean;
  openingHoursImage: string;
  showMenu: boolean;
  voucherPopupEnabled: boolean;
  showMap: boolean;
  showGallery: boolean;
  showTestimonials: boolean;
  contactFormEnabled: boolean;
  footer: boolean;
  galleryImages: string[];
  mainSliderImages: string[];
  showAddressMainSlider: boolean;
  voucher?: {
    isPercent: boolean;
    amount: number;
    description?: string;
  };
  reservations?: {
    enabled: boolean;
    daysAhead: number;
  };
} & CustomPageGenericType;

export type V1PageLinkType = {
  path: string;
  title: string;
  pageType?: PageType;
};

export type PageLinkType = V1PageLinkType | V2NavigationMenuLinkType;

export type TestimonialEntryType = {
  id: number;
  author?: string | null;
  testimonial: string;
};

export type V2VoucherType = {
  enabled: boolean;
  amount: number;
  isPercent: boolean;
  description?: string;
  title?: string;
};

export type V2TestimonialsType = {
  title?: string;
  subTitle?: string;
  backgroundImage?: string;
  data: TestimonialEntryType[];
};

export type V2ImageAttributesType = Record<
  string,
  {
    alt?: string;
  }
>;

export type V2GalleryType = {
  images?: string[];
  title?: string;
  subTitle?: string;
};

export type V2BannerType = {
  enabled?: boolean;
  title?: string;
  subTitle?: string;
  actionButtonUrl?: string;
  actionButtonText?: string;
};

export type V2GlobalConfigType = {
  policyUrls?: PolicyUrlsType;
  logo: string;
  countryCode: string;
  hostName: string;
  languages?: Array<{
    id?: SupportedLanguage;
    name?: string;
  }>;
  googleMapApiKey: string;
  cookieConsentGDPREnabled: boolean;
  cookieConsentPromptEnabled: boolean;
};

export type ColorScheme = { background: string; foreground: string };

export type V2GlobalStyleType = {
  fonts?: {
    primary?: string;
    secondary?: string;
  };
  colors?: {
    primary?: string;
    secondary?: string;
    tertiary?: string;
  };
  colorScheme?: ColorScheme;
  css?: string;
};

export type V2MobileAppsType = {
  hasApple: boolean;
  hasAndroid: boolean;
  appleStoreId?: string;
  appleStoreUrl?: string;
  playStoreUrl?: string;
  images?: string[];
  title?: string;
  description?: string;
  imageType?: MobileAppImageType;
};

export type TestimonialsType = {
  image?: string;
  data: TestimonialEntryType[];
};

export type V2TranslationsType = Partial<
  Record<SupportedLanguage, Record<string, string>>
>;

export type CustomPageSchemaV2Type = {
  schemaVersion: 'v2';
  template?: V2Templates;
  name: string;
  socialMedia?: SocialMediaType;
  pages: Record<string, V2CustomPageType>;
  redirects?: RedirectType[];
  menuLinks: V2MenuLinkType[];
  images: string[];
  isPanaceaEnabled: boolean;
  mobileApps: V2MobileAppsType;
  legal?: LegalDataType;
  analytics?: AnalyticsType;
  config: V2GlobalConfigType;
  navigationMenuLinks: V2NavigationMenuLinkType[];
  globalStyle: V2GlobalStyleType;
  headMeta: {
    styles?: Array<{
      text?: string;
    }>;
    links?: Array<{ rel?: string; href: string }>;
    scripts?: Array<{ src?: string; text?: string }>;
    meta?: Array<{ name?: string; content?: string }>;
    facebookVerificationMeta?: string;
    googleVerificationMeta?: string;
    seoDescription?: string;
    seoKeywords?: string;
  };
  gallery: V2GalleryType;
  testimonials: V2TestimonialsType;
  banner?: V2BannerType;
  voucher?: V2VoucherType;
  imageAttributes?: V2ImageAttributesType;
  menuHighlights?: V2MenuHighlightsType;
  showReturnBanner?: boolean;
  orderSettings?: V2OrderSettingsType;
  translations?: V2TranslationsType;
};

export type V2MenuLinkType = {
  title: string;
  url: string;
  displaySection: 'header' | 'footer';
};

export type V2NavigationMenuLinkType = {
  title: string;
  url: string;
  order: number;
};

export enum V2PageType {
  AboutUsPage = 'AboutUsPage',
  HQContactPage = 'HQContactPage',
  EmptyPage = 'EmptyPage',
  EmptyNoHeaderPage = 'EmptyNoHeaderPage',
  LandingPage = 'LandingPage',
  ContactPage = 'ContactPage',
  ReservationsPage = 'ReservationsPage',
}

export type V2CustomPageGenericType = {
  enabled: boolean;
  pageCss?: string;
  title?: string;
};

export type LandingPageType = {
  pageType: V2PageType.LandingPage;
  backgroundImages: string[];
  secondaryImage?: string;
  highlight: V2HighlightType;
  testimonialsEnabled?: boolean;
  showOrderOnlineButton?: boolean;
  galleryEnabled?: boolean;
  mobileAppsEnabled?: boolean;
  locationsEnabled?: boolean;
  brandNameHeadingEnabled?: boolean;
  loggedInHeading?: string;
  loggedOutHeading?: string;
} & V2CustomPageGenericType;

export type V2EmptyPageType = {
  html?: string;
  pageType: V2PageType.EmptyPage;
} & V2CustomPageGenericType;

export type V2EmptyNoHeaderPage = {
  html?: string;
  pageType: V2PageType.EmptyNoHeaderPage;
} & V2CustomPageGenericType;

export type V2AboutUsPageType = {
  pageType: V2PageType.AboutUsPage;
  blocks: V2AboutUsPageBlockType;
  galleryEnabled?: boolean;
} & V2CustomPageGenericType;

export type V2HqContactPageType = {
  pageType: V2PageType.HQContactPage;
  CompanyName?: string;
  Email?: string;
  CompanyRegistrationNumber?: string;
  AddressLine2?: string;
  AddressLine1?: string;
  Phone?: string;
  CeoFullName?: string;
  TaxNumber?: string;
  VatNumber?: string;
  RegisterCourt?: string;
} & V2CustomPageGenericType;

export type V2ContactPageType = {
  pageType?: V2PageType.ContactPage;
  title?: string;
  subTitle?: string;
  image?: string;
  locationsEnabled?: boolean;
} & V2CustomPageGenericType;

export type V2ReservationsPageType = Omit<V2ContactPageType, 'pageType'> & {
  pageType?: V2PageType.ReservationsPage;
  daysAhead?: number;
};

export type V2CustomPageType =
  | V2EmptyPageType
  | V2EmptyNoHeaderPage
  | LandingPageType
  | V2AboutUsPageType
  | V2HqContactPageType
  | V2ContactPageType
  | V2ReservationsPageType;

export type V2AboutUsPageBlockType = Record<
  string,
  V2AboutUsPageBlockEntryType
>;

export type V2AboutUsPageBlockEntryType = {
  tag: string;
  title: string;
  description: string;
  images: string[];
  actionButton?: {
    label?: string;
    url?: string;
  };
  isHidden?: boolean;
  order?: number;
};

export type V2HighlightType = {
  enabled?: boolean;
  title?: string;
  tag?: string;
  description: string;
  actionButton?: {
    label?: string;
    url?: string;
  };
  images: string[];
};

export type V2MenuHighlightsType = {
  showImages?: boolean;
  subTitle?: string;
  title?: string;
};

export enum V2DefaultOrderType {
  delivery = 'delivery',
  collection = 'collection',
}

export type V2OrderSettingsType = {
  defaultOrderType?: V2DefaultOrderType;
};

export type CustomPageSchemaType =
  | CustomPageSchemaV1Type
  | CustomPageSchemaV2Type;

export type APIErrorType = {
  message: string;
  error: boolean;
  stack: string;
};
