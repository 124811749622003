import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Link } from '@Components/RoutesUtils';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useTracking } from '@Src/hooks/useTracking';
import {
  V2Templates,
  type V2NavigationMenuLinkType,
} from '@Src/middlewares/CustomPageTypes';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import Cookies from 'js-cookie';
import { useApi } from '@Src/hooks/useApi';
import { logger } from '@Src/utility/loggerService';
import { CUSTOM_EVENTS } from '@Src/utility/CustomEvents';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { setIsLoggedIn } from '@Src/slices/userSlice';
import { EditOrderSettingsDialog } from '@Components/Widgets/OrderSettings/EditOrderSettingsDialog';
import { selectIsCollectionWithStores } from '@Src/selectors/stores.selectors';
import Dishy from './dishy';
import BoxedUp from './boxedUp';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import { StyledBaseContainer } from '../BaseGridElements';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';

const LoginDialog = lazy(
  async () => await import('@Components/WebsiteV2/LogIn/LoginDialog'),
);

export const StyledLogoLink = styled(Link)`
  justify-content: left;
  overflow: visible;
  display: flex;
`;

export const StyledBoxedUpHeader = styled(StyledBaseContainer)(({ theme }) => ({
  padding: theme.spacing(0),
  marginTop: `0 !important`,
  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(3)}`,
  },
}));

const StyledMenuText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: center;
  text-transform: capitalize;
`;

type StyledMenuLinkProps = {
  isCurrent: boolean;
};

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})<StyledMenuLinkProps>`
  font-size: inherit;
  line-height: inherit;
  &:hover,
  &:focus {
    background-color: transparent !important;
    span {
      color: ${(props) => props.theme.palette.primary.main} !important;
    }
  }
  min-height: 48px !important;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: ${({ theme }) => theme.spacing(3)};
  }
  border-bottom: none !important;
  a {
    text-decoration: none !important;
    span {
      color: ${(props) =>
        props.isCurrent ? props.theme.palette.primary.main : 'inherit'};
    }
  }
`;

const Header = (): JSX.Element => {
  const { template, navigationMenuLinks } = useDesignSchema('v2');
  const { translate } = useTranslation();
  const location = useLocation();
  const { trackOrderEvent, trackNavigationEvent } = useTracking();
  const { appId, isCustomDomain, vanityUrlSuffix } = useConfig();
  const { post } = useApi();
  const isMobile = useIsMobile();
  const enableSafariLogin = useFeatureFlag('enableSafariLogin');
  const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false);
  const [isLoggingOut, setIsLogginOut] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const orderUrl = useAppSelector((state) => state.orderUrl.path) || '/order';
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const dispatch = useAppDispatch();
  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );
  const isCollectionWithStores = useAppSelector(selectIsCollectionWithStores);

  useEffect(() => {
    // edge case in which screen is resized to mobile size
    isDrawerOpen && setIsDrawerOpen(false);
    isDialogOpen && setIsDialogOpen(false);
  }, [location.pathname, isMobile]);

  useEffect(() => {
    const handleWebOrderEvent = (e: Event): void => {
      if (e.type === CUSTOM_EVENTS.USER_LOGIN) {
        dispatch(setIsLoggedIn(true));
      }
      if (e.type === CUSTOM_EVENTS.USER_LOGOUT) {
        dispatch(setIsLoggedIn(false));
      }
    };
    document.addEventListener(CUSTOM_EVENTS.USER_LOGIN, handleWebOrderEvent);
    document.addEventListener(CUSTOM_EVENTS.USER_LOGOUT, handleWebOrderEvent);

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.USER_LOGIN,
        handleWebOrderEvent,
      );
      document.removeEventListener(
        CUSTOM_EVENTS.USER_LOGOUT,
        handleWebOrderEvent,
      );
    };
  }, []);

  const handleNavItemClick = (link: V2NavigationMenuLinkType): void => {
    const originLink = navigationMenuLinks?.find(
      (navLink) => location.pathname === navLink.url,
    );
    trackNavigationEvent('Select Page', {
      name: link.title,
      origin: originLink?.title || '',
    });

    if (link.url.includes('/order')) {
      trackOrderEvent('Start Order', {
        orderType: isCollection ? 'Collection' : 'Delivery',
        section: 'Navigation Header',
      });
    }
    setIsDrawerOpen(false);

    if (link.url.includes('/order') && isCollectionWithStores) {
      setIsDialogOpen(true);
    }
  };

  const handleLoginClick = (): void => {
    setIsLoginOpen(true);
  };

  const handleLogout = (): void => {
    setIsLogginOut(true);
    post({
      path: '/Account/LogOff',
    })
      .then(() => {
        if (!enableSafariLogin) {
          let cookiePath = '/';
          // Need to set cookie path with appId in case of panacea URLs
          if (!isCustomDomain) {
            if (vanityUrlSuffix) {
              cookiePath = `/${vanityUrlSuffix}`;
            } else {
              cookiePath = `/${appId}`;
            }
          }
          Cookies.remove('flipdish-token', { path: cookiePath });
        }
        localStorage.removeItem('flipdish-device-id');
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((e) => {
        logger.error(`logOff for ${appId || 'undefined'}`, {
          message: e.message,
        });
        setIsLogginOut(false);
      });
  };

  const createOrderUrl = (): string => (isCollectionWithStores ? '' : orderUrl);

  const menuItems = navigationMenuLinks
    ?.filter((l) => l.order !== -1)
    ?.sort((a, b) => (a.order || 0) - (b.order || 0))
    ?.map((link) => {
      const pathName = location.pathname === '/' ? '/index' : location.pathname;
      const isCurrent = pathName === link.url;
      const itemUrl =
        link?.url === '/order' ? createOrderUrl() : link?.url || '/';

      return (
        <StyledMenuItem
          data-fd={link?.title || ''}
          isCurrent={isCurrent}
          key={link?.url}
          onClick={() => handleNavItemClick(link)}
        >
          <Link
            data-fd={(link?.title || '') + '-link' || ''}
            to={itemUrl}
            onClick={(e) => {
              !itemUrl && e.preventDefault();
            }}
          >
            <StyledMenuText variant="body1xs">
              {translate(link?.title || '')}
            </StyledMenuText>
          </Link>
        </StyledMenuItem>
      );
    });

  if (!isLoggedIn) {
    if (template !== V2Templates.boxedUp) {
      menuItems?.push(
        <StyledMenuItem
          data-fd={'Login'}
          key="login"
          isCurrent={false}
          className="header-text"
          onClick={handleLoginClick}
        >
          <StyledMenuText variant="body1xs">
            {translate('Login')}
          </StyledMenuText>
        </StyledMenuItem>,
      );
    }
  } else {
    menuItems?.push(
      <StyledMenuItem
        data-fd={'Logout'}
        key="logout"
        isCurrent={false}
        disabled={isLoggingOut}
        className="header-text"
        onClick={() => handleLogout()}
      >
        <StyledMenuText variant="body1xs">
          {translate('Log_out')}
        </StyledMenuText>
      </StyledMenuItem>,
    );
  }

  const renderDialogs = (): JSX.Element => (
    <>
      {isLoginOpen && (
        <Suspense fallback={<div>{translate('loading')}</div>}>
          <LoginDialog onClose={() => setIsLoginOpen(false)} />
        </Suspense>
      )}
      <EditOrderSettingsDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );

  switch (template) {
    case V2Templates.boxedUp:
      return (
        <StyledBoxedUpHeader>
          <BoxedUp
            isDrawerOpen={isDrawerOpen}
            handleDrawerOpenChange={(isOpen) => setIsDrawerOpen(isOpen)}
            menuItems={menuItems}
            onLogin={handleLoginClick}
          >
            {renderDialogs()}
          </BoxedUp>
        </StyledBoxedUpHeader>
      );
    default:
      return (
        <Dishy
          isDrawerOpen={isDrawerOpen}
          handleDrawerOpenChange={(isOpen) => setIsDrawerOpen(isOpen)}
          menuItems={menuItems}
        >
          {renderDialogs()}
        </Dishy>
      );
  }
};
export default Header;
