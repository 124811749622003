import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField, {
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const LABEL_SHRINK_SIZE_OFFSET = 4;

const getLabelSize = (fontSize?: string | number): string | undefined => {
  let fs;
  if (fontSize) {
    if (typeof fontSize === 'string') {
      fs = fontSize.replace('px', '');
    }
    const size = Number(fs) + LABEL_SHRINK_SIZE_OFFSET;
    return `${size}px`;
  }
};

const StyledTextField = styled(MuiTextField)`
  & legend {
    display: none;
  }
  & fieldset {
    border-color: ${({ theme }) => theme.palette.secondary.subtle};
  }
  & .MuiInputBase-input {
    ${({ theme }) => ({
      fontSize: theme.typography.body1Medium?.fontSize,
      fontWeight: theme.typography.body1Medium?.fontWeight,
      lineHeight: theme.typography.body1Medium?.lineHeight,
      paddingBottom: theme.spacing(1.5),
    })};
  }
  & .MuiInputLabel-root {
    ${({ theme }) => ({
      color: theme.palette.text.primary,
      fontSize: getLabelSize(theme.typography.finePrint1?.fontSize),
      fontWeight: theme.typography.finePrint1?.fontWeight,
      lineHeight: getLabelSize(theme.typography.finePrint1?.lineHeight),
      marginLeft: theme.spacing(-1.75),
      marginBottom: theme.spacing(-0.625),
      position: 'relative',
    })}
    & .MuiFormHelperText-root {
      margin-left: 0;
    }
  }
`;

export type TextFieldProps = MuiTextFieldProps & {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
};

const TextField = (props: TextFieldProps): JSX.Element => {
  const {
    endIcon,
    startIcon,
    helperText = '',
    label = '',
    placeholder = '',
    variant = 'outlined',
    ...rest
  } = props;

  const endAdornment = endIcon ? (
    <InputAdornment position="end">{endIcon}</InputAdornment>
  ) : null;

  const startAdornment = startIcon ? (
    <InputAdornment position="start">{startIcon}</InputAdornment>
  ) : null;

  return (
    <StyledTextField
      autoComplete="off"
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
      label={label}
      placeholder={placeholder}
      variant={variant}
      size="small"
      fullWidth
      {...rest}
    />
  );
};

TextField.displayName = 'TextField';
export { TextField };
