import About from '../../About';
import { type AboutUsPageType } from '@Src/middlewares/CustomPageTypes';

export type AboutPageProps = {
  data?: AboutUsPageType;
};
export const AboutPage = ({ data }: AboutPageProps): JSX.Element => (
  <About isPage={true} data={data} />
);

export default AboutPage;
