import { useEffect, useState } from 'react';

export function useCountdown(countdownSec = 30): [number, () => void, number] {
  const [countdown, setCountdown] = useState(countdownSec);
  const [countdownCount, setCountdownCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((current) => {
        if (current <= 0) {
          clearInterval(interval);

          return 0;
        }

        return current - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownCount]);

  const startAgain = (): void => {
    if (countdown <= 0) {
      setCountdown(countdownSec);
      setCountdownCount((current) => current + 1);
    }
  };

  return [countdown, startAgain, countdownCount] as [
    number,
    () => void,
    number,
  ];
}
