import styled from 'styled-components';
import { Link } from '@Components/RoutesUtils';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { PageTemplates } from '@Src/middlewares/CustomPageTypes';
import {
  TWELVE_HOUR_TIME_FORMAT_LANGUAGES,
  formatRangeTo24HourFormat,
  getLocalisedDays,
} from '../OpeningHours/TimeTableEntry';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useTracking } from '@Src/hooks/useTracking';

const StyledContainer = styled.div`
  @media only screen and (min-width: 992px) {
    h2.section-title {
      align-content: center;
      justify-content: center;
    }

    h2.section-title:before,
    h2.section-title:after {
      content: ' ';
      margin: 5px;
    }
  }
`;

const StyledH1 = styled.h1`
  margin-top: 110px;
  text-align: center;
`;

const StyledContactDetails = styled.h3<{ $color: string; $template?: string }>`
  color: ${(props) =>
    props.$template === PageTemplates.CafeShirley
      ? `${props.$color} !important`
      : '#ccc'};
  text-transform: ${(props) =>
    props.$template === PageTemplates.CafeShirley
      ? `uppercase`
      : 'none !important'};
`;

const StyledHoursNTime = styled.p<{ $template?: string }>`
  color: ${(props) =>
    props.$template === PageTemplates.CafeShirley
      ? `#575757 !important`
      : '#7d7a7a !important'};
`;

const StyledPhone = styled.div<{ $color: string }>`
  text-transform: uppercase;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 48px;
  font-weight: 400;

  color: ${(props) => props.$color};

  a {
    color: ${(props) => props.$color};
  }
`;

const StyledLink = styled.a<{ $color: string }>`
  color: ${(props) => props.$color};
  &:hover {
    color: ${(props) => props.$color};
    opacity: 0.85;
  }
  &:focus {
    text-decoration: none;
    color: ${(props) => props.$color};
  }
`;

const StyledMap = styled.div`
  max-width: 400px;
  max-height: 300px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ContactLocations = (): JSX.Element => {
  const {
    storeMapData,
    storeContactDetails,
    openingHours,
    appDesignScheme,
    map,
    template,
  } = useDesignSchema('v1');
  const { language } = useConfig();
  const { trackEvent } = useTracking();
  const { translate } = useTranslation();

  const trackClick = (): void => {
    trackEvent('Click to order', {
      category: 'Locations Page',
      type: 'Start Order',
    });
  };

  const storeTiles = (openingHours || []).map(
    ({ title, pickupHours, deliveryHours }, index) => {
      const isLastRowSingular =
        index === (openingHours?.length || 0) - 1 && index % 2 === 0;
      const columnClasses = isLastRowSingular
        ? 'col-md-12'
        : 'col-lg-6 col-md-6';
      return (
        <div className={columnClasses} key={title}>
          <section id="reservations" className="section reservations">
            <div className="jt_row jt_row-fluid row visible">
              <div className="col-lg-12 col-md-12 jt_col column_container">
                <h2 className="section-title">{title}</h2>
              </div>
              <div className="col-lg-6 col-md-6 col-lg-offset-3 col-md-offset-3 jt_col column_container">
                <div className="section-subtitle">
                  {storeMapData?.[index]?.Address}
                  <br />
                  {storeContactDetails?.[index]?.phone && (
                    <StyledLink
                      href={`tel:${storeContactDetails[index].phone}`}
                      $color={appDesignScheme.Primary}
                    >
                      {storeContactDetails[index].phone}
                    </StyledLink>
                  )}
                  <br />
                  {storeContactDetails?.[index]?.email && (
                    <StyledLink
                      href={`mailto:${storeContactDetails[index].phone}`}
                      $color={appDesignScheme.Primary}
                    >
                      {storeContactDetails[index].email}
                    </StyledLink>
                  )}
                </div>
              </div>
              <div className="col-md-12 jt_col column_container">
                {deliveryHours && (
                  <>
                    <StyledContactDetails
                      $color={appDesignScheme.Primary}
                      $template={template}
                      className="contact-details"
                    >
                      {translate('Delivery_Hours')}
                    </StyledContactDetails>
                    <div className="voffset30"></div>
                    <StyledHoursNTime
                      className="hours_and_time_styles"
                      $template={template}
                    >
                      {deliveryHours?.map(({ days, hours }, index) => (
                        <div key={index}>
                          <b>{getLocalisedDays(days, translate)}</b>{' '}
                          {hours.includes('Closed') ||
                          TWELVE_HOUR_TIME_FORMAT_LANGUAGES.includes(language)
                            ? translate(hours)
                            : formatRangeTo24HourFormat(hours)}
                        </div>
                      ))}
                    </StyledHoursNTime>
                  </>
                )}
                {pickupHours && (
                  <>
                    <div className="voffset70"></div>
                    <StyledContactDetails
                      $color={appDesignScheme.Primary}
                      $template={template}
                      className="contact-details"
                    >
                      {translate('Collection_Hours')}
                    </StyledContactDetails>
                    <div className="voffset30"></div>
                    <StyledHoursNTime
                      className="hours_and_time_styles"
                      $template={template}
                    >
                      {pickupHours?.map(({ days, hours }, index) => (
                        <div key={index}>
                          <b>{getLocalisedDays(days, translate)}</b>{' '}
                          {hours.includes('Closed') ||
                          TWELVE_HOUR_TIME_FORMAT_LANGUAGES.includes(language)
                            ? translate(hours)
                            : formatRangeTo24HourFormat(hours)}
                        </div>
                      ))}
                    </StyledHoursNTime>
                  </>
                )}
                <div className="voffset70"></div>
                <StyledPhone
                  className="reservation-phone"
                  $color={appDesignScheme.Primary}
                >
                  <Link to="/order" onClick={trackClick}>
                    {translate('Order_Online_Now')}
                  </Link>
                </StyledPhone>
                {
                  /* eslint-disable  react/forbid-elements */
                  storeMapData?.[index]?.Coordinates?.Latitude &&
                    storeMapData?.[index]?.Coordinates?.Longitude &&
                    map?.googleMapApiKey && (
                      <StyledMap className="owl-item">
                        <div className="item">
                          <a
                            href={`http://maps.google.com/maps?q=${storeMapData[index].Coordinates.Latitude},${storeMapData[index].Coordinates.Longitude}&z=12`}
                            target="_blank"
                            aria-label="@Resources.Link_to_our_location_on_google_map"
                            rel="noreferrer"
                          >
                            <img
                              src={`https://maps.googleapis.com/maps/api/staticmap?center=${storeMapData[index].Coordinates.Latitude},${storeMapData[index].Coordinates.Longitude}&scale=2&zoom=12&size=400x300&format=png32&scale=1&maptype=roadmap&markers=size:mid|${storeMapData[index].Coordinates.Latitude},${storeMapData[index].Coordinates.Longitude}&key=${map.googleMapApiKey}`}
                              alt="@Resources.Google_map_image_of_our_location"
                            />
                          </a>
                        </div>
                      </StyledMap>
                    )
                  /* eslint-enable  react/forbid-elements */
                }
              </div>

              <div className="col-md-12 jt_col column_container">
                <div className="voffset60"></div>
              </div>
            </div>
          </section>
        </div>
      );
    },
  );

  const getFormattedStoretiles = (): JSX.Element[] => {
    const formattedTiles: JSX.Element[] = [];
    // Add 2 tiles in a row
    for (let i = 0; i < storeTiles.length; i = i + 2) {
      formattedTiles.push(
        <div className="row" key={i}>
          {storeTiles[i]}
          {storeTiles[i + 1] && storeTiles[i + 1]}
        </div>,
      );
    }
    return formattedTiles;
  };
  return (
    <StyledContainer className="container">
      <StyledH1>{translate('Contact_Details')}</StyledH1>
      {getFormattedStoretiles()}
    </StyledContainer>
  );
};

export default ContactLocations;
