import { useConfig } from '@Src/providers/ConfigProvider';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { ReactComponent as LanguageIcon } from '@Src/assets/images/Language.svg';

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.text.primary,
  '& .MuiSelect-select': {
    padding: `${theme.spacing(0)} ${theme.spacing(1)} !important`, // Remove padding added by the default icon
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledValue = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.typography.pxToRem(10),
  fontWeight: theme.typography.fontWeightRegular,
  gap: theme.spacing(0.25),
  letterSpacing: 0.1,
  lineHeight: 1.2,
  paddingTop: theme.spacing(0.25),
  svg: {
    color: 'inherit',
    height: 20,
    width: 20,
  },
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(13),
    letterSpacing: 0.13,
    lineHeight: 1.38,
    svg: {
      height: 24,
      width: 24,
    },
  },
}));

const LanguageSelector = (): JSX.Element => {
  const { language, supportedLanguages = [] } = useConfig();
  const { translate } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(language);

  const handleLanguageChange = (e: SelectChangeEvent<unknown>): void => {
    const value = e.target.value as string;
    const sixMonthsDate = new Date();
    sixMonthsDate.setMonth(sixMonthsDate.getMonth() + 6); // Expires in 6 months
    setCurrentLanguage(value);
    const pageUrl = new URL(window.location.href);
    pageUrl.searchParams.set('lang', value);
    window.location.href = pageUrl.toString();
  };

  const renderValue = (): JSX.Element => (
    <StyledValue>
      <LanguageIcon />
      {currentLanguage}
    </StyledValue>
  );

  return (
    <StyledSelect
      aria-label={translate('Select_language_from_dropdown_menu')}
      className="language-selector"
      displayEmpty
      IconComponent={() => null}
      onChange={handleLanguageChange}
      renderValue={renderValue}
    >
      {supportedLanguages?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.id}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default LanguageSelector;
