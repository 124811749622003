// #region address internationalisation types
export interface AddressI18nFormScheme {
  CountryCode?: string;
  DisplayFormat?: AddressI18nDisplayFormat;
  FormData?: AddressI18nFormData[];
  Language?: string;
  Templates?: AddressI18nTemplates;
}

export interface AddressI18nDisplayFormat {
  OneLine: string;
  TwoLines: string[];
}

export interface AddressI18nFormData {
  Identifier: string;
  Label: string;
  Mapping: string;
  Placeholder?: string;
  Rules?: AddressI18nFormRules;
  Value?: string | number;
}

export interface AddressI18nTemplates {
  building: string;
  line1: string;
  line2: string;
  postcode: string;
  singleLine: string;
  street: string;
  town: string;
}

export interface AddressI18nFormRules {
  Predefined?: AddressI18nPredefinedRule[];
  Required?: {
    Message: string;
    Value: boolean;
  };
  MinLength?: {
    Message: string;
    Value: number;
  };
  MaxLength?: {
    Message: string;
    Value: number;
  };
  Pattern?: {
    Message: string;
    Value: RegExp;
  };
}

export interface AddressI18nPredefinedRule {
  Display: string;
  Value: string;
}
// #endregion

// #region delivery locations types
export interface DeliveryLocationForms {
  SkipToDeliveryRestaurantsScreen: boolean;
  SkipToPickupRestaurantsScreen: boolean;
  DeliveryLocations: DeliveryLocation[];
}

export interface DeliveryLocation {
  AddressFields: any;
  AutoSelectThisLocation: boolean;
  Coordinates: Coordinates;
  CountryCode: string;
  DeliveryInstructions?: string;
  DeliveryLocationId: number;
  IsCustomAddress: boolean; // Can ignore
  IsDisplayed: boolean; // Hide false ones
  SingleLineDisplay: string;
  TwoLinesDisplay: string[];
}
// #endregion

export interface Coordinates {
  Latitude: number;
  Longitude: number;
}

export interface GeoCodeResponse {
  results: google.maps.GeocoderResult[];
  status: google.maps.GeocoderStatus;
}

export enum Status {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type Async<T> = Loading | Failed | Success<T>;
interface Loading {
  status: Status.LOADING;
}

interface Failed {
  status: Status.ERROR;
  error?: string | Error;
}

interface Success<T> {
  status: Status.SUCCESS;
  data: T;
}
