import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ListItemPropsType = {
  className?: string;
  disabled?: boolean;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isSelected?: boolean;
};

const StyledListItem = styled(ListItemButton)<{
  ownerState: { isSelected?: boolean };
}>(({ ownerState, theme }) => ({
  backgroundColor: ownerState.isSelected
    ? theme.palette.primary.lightest
    : 'initial',
  borderTop: ownerState.isSelected ? '1px solid' : 'none',
  borderBottom: '1px solid',
  borderColor: theme.palette.secondary.lowContrast,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.lightest,
    color: !ownerState.isSelected
      ? theme.palette.getContrastText(
          theme.palette.primary.lightest || theme.palette.primary.main,
        )
      : 'inherit',
    cursor: 'pointer',
  },
}));

export function ListItem(props: ListItemPropsType): JSX.Element {
  const { children, onClick, isSelected, onMouseEnter, onMouseLeave } = props;

  return (
    <StyledListItem
      selected={isSelected}
      ownerState={{ isSelected }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </StyledListItem>
  );
}
