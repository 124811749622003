import { type RootState } from '@Src/store';
import { createAsyncThunk } from '@Src/utility/reduxUtils';

import { getStoreOrderUrl } from '@Src/utility/orderUtils';
import { setOrderUrl } from '@Src/slices/orderUrlSlice';

export const listenForSetIsCollection = createAsyncThunk(
  'orderUrl/listenForSetIsCollection',
  async (
    { isCollection }: { isCollection: boolean },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const selectedDeliveryStore = state.deliveryStores.selectedDeliveryStore;
    const selectedCollectionStore =
      state.collectionStores.selectedCollectionStore;
    const deliveryLocationId =
      state.deliveryAddresses.selectedDeliveryAddress?.DeliveryLocationId;

    const selectedStore = isCollection
      ? selectedCollectionStore
      : selectedDeliveryStore;

    const shouldForceCollectionStoreUrl =
      !isCollection && (!selectedDeliveryStore || !deliveryLocationId);

    const url =
      getStoreOrderUrl({
        deliveryLocationId,
        isCollection,
        shouldForceCollectionStoreUrl,
        storeSummary: shouldForceCollectionStoreUrl
          ? selectedCollectionStore
          : selectedStore,
      }) || '/order';

    dispatch(setOrderUrl(url));
  },
);
