import { useState } from 'react';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useMapTools } from '@Src/hooks/useMapTools';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Autocomplete } from '@Components/ui/AutoComplete';
import { AddressSuggestion } from './AddressSuggestion';
import {
  LatLng,
  ToGeo,
  getPostCodeLabel,
  type SupportedCountries,
} from '@Src/utility/locationUtils';
import debounce from 'lodash.debounce';
import { ReactComponent as LeftArrowIcon } from '@Src/assets/images/arrow-left-long.svg';
import { ReactComponent as CancelIcon } from '@Src/assets/images/clear.svg';
import { type AddressAutoCompleteSuggestionDetails } from '@Src/types/googleMapsTypes';
import { IconButton, styled } from '@mui/material';
import { logger } from '@Src/utility/loggerService';
import { type HandleNavConfirmLocationProps } from '..';
import { useTracking } from '@Src/hooks/useTracking';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingRight: theme.spacing(3),
}));

type AddressAutocompletePropsType = {
  onAddressSuggestionClicked: ({
    autoFill,
    geo,
  }: HandleNavConfirmLocationProps) => void;
  onBackClick: () => void;
};
export function AddressAutocomplete({
  onAddressSuggestionClicked,
  onBackClick,
}: AddressAutocompletePropsType): JSX.Element {
  const { translate } = useTranslation();
  const { countryCode } = useDesignSchema('v2');

  const geo = useAppSelector((state) => state.location.geo);
  const [mapTools] = useMapTools();
  const [suggestions, setSuggestions] = useState<
    AddressAutoCompleteSuggestionDetails[]
  >([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { trackOrderEvent } = useTracking();

  const userCountryCode = useAppSelector(
    (state) => state.location.ipInfo?.country,
  ) as SupportedCountries;

  const handleInputChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>, inputValue: string): void => {
      if (!isDirty) {
        trackOrderEvent('Add Address', {
          isLoggedIn: String(isLoggedIn),
          type: 'Input',
        });
      }
      const minGeocodeInputLength = 5;

      if (inputValue?.length < minGeocodeInputLength) {
        return;
      }

      mapTools?.AutoComplete?.getPlacePredictions(
        {
          input: inputValue,
          location: LatLng(geo),
          radius: 200,
        },
        handleAutocompleteResponse,
      ).catch((error) =>
        logger.log('Failed to get address autocomplete suggestions', error),
      );
      setIsDirty(true);
    },
    750,
  );

  const handleAutocompleteResponse = (
    newSuggestions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus,
  ): void => {
    switch (status) {
      case google.maps.places.PlacesServiceStatus.OK:
        break;

      default:
        return;
    }

    const formattedSuggestions:
      | AddressAutoCompleteSuggestionDetails[]
      | undefined = newSuggestions?.map((newSuggestion) => ({
      description: newSuggestion.description,
      placeId: newSuggestion.place_id,
    }));

    if (formattedSuggestions) {
      const addressNotListedSuggestion: AddressAutoCompleteSuggestionDetails = {
        description: translate('My_address_is_not_listed'),
        placeId: 'customAddressRequired',
      };
      formattedSuggestions.push(addressNotListedSuggestion);

      setSuggestions(formattedSuggestions);
    }
  };

  const handlePlaceSelected = (
    _event: React.ChangeEvent<HTMLInputElement>,
    selectedSuggestion: AddressAutoCompleteSuggestionDetails,
  ): void => {
    if (!suggestions || suggestions.length === 0 || !mapTools?.Places) {
      return;
    }
    const placeId = selectedSuggestion?.placeId;
    if (placeId === 'customAddressRequired') {
      onAddressSuggestionClicked({ autoFill: false, geo });
    }

    if (placeId) {
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId,
        fields: ['geometry', 'address_component'],
      };

      mapTools?.Places.getDetails(request, (result, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          result?.geometry?.location
        ) {
          const coords = ToGeo(result?.geometry?.location);
          onAddressSuggestionClicked({ geo: coords });
        }
      });
    }
  };

  return (
    <Autocomplete
      autoFocus
      endIcon={<CancelIcon />}
      startIcon={
        <StyledIconButton
          disableRipple
          data-fd="back-icon"
          onClick={onBackClick}
        >
          <LeftArrowIcon />
        </StyledIconButton>
      }
      placeholder={translate(
        getPostCodeLabel(
          (countryCode as SupportedCountries) || userCountryCode,
        ),
      )}
      filterOptions={(options): google.maps.places.AutocompletePrediction[] =>
        options
      }
      onChange={handlePlaceSelected}
      options={suggestions}
      getOptionLabel={(option): string =>
        typeof option === 'string' ? option : option.description
      }
      onInputChange={handleInputChange}
      renderOption={(optionProps, option): JSX.Element => (
        <AddressSuggestion
          option={option}
          key={optionProps.key}
          optionProps={optionProps}
        />
      )}
    />
  );
}
