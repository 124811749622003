import { fetchCollectionStoreSummaries } from '@Src/thunks/collectionStoresThunks';
import { type CollectionStoreSummary } from '@Src/types/collectionStoreTypes';
import { createSlice } from '@Src/utility/reduxUtils';

type CollectionStoresStateType = {
  selectedCollectionStore?: CollectionStoreSummary;
  collectionStoreList: CollectionStoreSummary[];
};

export const initialState: CollectionStoresStateType = {
  collectionStoreList: [],
  selectedCollectionStore: undefined,
};
export const collectionStoresSlice = createSlice({
  name: 'collectionStores',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {
    setSelectedStore: (
      state,
      action: {
        type: string;
        payload: CollectionStoreSummary;
      },
    ) => ({
      ...state,
      selectedCollectionStore: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCollectionStoreSummaries.fulfilled,
      (state, response) => {
        const storelist: CollectionStoreSummary[] = response.payload || [];

        const sortedList = [...storelist].sort((a, b) => {
          if (a.IsOpen !== b.IsOpen) {
            return a.IsOpen ? -1 : 1;
          }

          if (a.DistanceKm !== b.DistanceKm) {
            return a.DistanceKm - b.DistanceKm;
          }

          if (a.AcceptPreOrders !== b.AcceptPreOrders) {
            return a.AcceptPreOrders ? -1 : 1;
          }

          return 0;
        });

        state.collectionStoreList = sortedList;
        state.selectedCollectionStore = sortedList[0];
        state.fetched = true;
        state.loading = false;
      },
    );
    builder.addCase(fetchCollectionStoreSummaries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCollectionStoreSummaries.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});

export const { setSelectedStore } = collectionStoresSlice.actions;

export default collectionStoresSlice.reducer;
