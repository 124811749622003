import { type RefObject, useEffect, useState } from 'react';

export function useElementInView(
  ref: RefObject<Element>,
  rootMargin = '0px',
): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    try {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
          }
        },
        { rootMargin },
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } catch (e) {
      // Unsupported Browser.
      setIsIntersecting(true);
      return () => {};
    }
  }, []);
  return isIntersecting;
}
