import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { Section } from '@Components/ui/Section';
import { useTheme } from '@mui/material/styles';
import {
  InnerGridPolice,
  InnerGridWithImagePolice,
} from '@Components/WebsiteV2/BaseGridElements';

type AppsProps = {
  className?: string;
  isPlainSection?: boolean;
  smallGrid?: boolean;
};

export const Apps = ({
  className,
  isPlainSection,
  smallGrid,
}: AppsProps): JSX.Element => {
  const { mobileApps, imageAttributes } = useDesignSchema('v2');
  const theme = useTheme();

  const hasImage = (mobileApps?.images?.length || 0) > 0;
  const image = mobileApps?.images?.[0] || '';
  const imageType = mobileApps?.imageType || 'image';
  const width = imageType === 'image' ? '570' : '251';
  const src = image ? `${image}?w=${width}` : '';
  const showTextSection = !!mobileApps?.description;

  const GridComponent = smallGrid ? InnerGridPolice : InnerGridWithImagePolice;

  return (
    <GridComponent isShort={!hasImage}>
      <Section
        backgroundColor={theme.palette?.tertiary?.main}
        textColor={theme.palette?.tertiary?.contrastText}
        className={className}
        data={{
          title: mobileApps?.title,
          description: mobileApps?.description,
        }}
        isPlainSection={isPlainSection}
        dataFd="apps"
        hasImage={hasImage}
        id="appdownloadpromo"
        imageAttributes={{
          alt: imageAttributes?.[src]?.alt || 'app image 0',
          dataFd: 'app-image-0',
          src,
        }}
        imageType={imageType}
        orientation={'right'}
        showAppBadges
        showTextSection={showTextSection}
      />
    </GridComponent>
  );
};

export default Apps;
