import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useRecaptcha } from '@Src/hooks/useRecaptcha';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { useApi } from '@Src/hooks/useApi';
import { ImgixImage } from '@Components/ui/ImgixImage';
type ErrorsType = {
  date?: string;
  name?: string;
  email?: string;
  phone?: string;
  numPeople?: string;
  captchaToken?: string;
};

const StyledError = styled.span`
  color: red;
`;
const StyledInputField = styled.input`
  margin-bottom: 20px;
`;

const StyledHeading = styled.h3<{ $color: string }>`
  &&& {
    color: ${(props) => props.$color};
  }
`;
const StyledColumn = styled.div`
  margin-top: 20px;
`;
const StyledText = styled.p<{ $color: string }>`
  &&& {
    color: ${(props) => props.$color};
  }
`;
export const Reservations = (): JSX.Element => {
  const { translate } = useTranslation();
  const { logo, appDesignScheme, appId } = useDesignSchema('v1');
  const [formErrors, setFormErrors] = useState<ErrorsType>({});
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [submitFailure, setSubmitFailure] = useState<boolean>(false);
  const [failureMsg, setFailureMsg] = useState<string>('');
  const [minDateString, setMinDateString] = useState<string>('');
  const [maxDateString, setMaxDateString] = useState<string>('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const dateFieldRef = useRef<HTMLInputElement>(null);
  const timeFieldRef = useRef<HTMLInputElement>(null);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const phoneFieldRef = useRef<HTMLInputElement>(null);
  const numPeopleFieldRef = useRef<HTMLInputElement>(null);
  const messageFieldRef = useRef<HTMLTextAreaElement>(null);

  const {
    isRecaptchaValid,
    handleRecaptchaExpired,
    handleRecaptchaTest,
    recaptchaSiteKey,
  } = useRecaptcha();

  useEffect(() => {
    setIsBrowser(true);
    const today = new Date();
    setMinDateString(today.toISOString().split('T')[0]);
    const futureDate = new Date();
    // need to read max reservation date from schema
    futureDate.setDate(today.getDate() + 25);
    setMaxDateString(futureDate.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    if (isRecaptchaValid) {
      setCaptchaToken(recaptchaRef.current?.getValue() || '');
    } else {
      setCaptchaToken('');
    }
  }, [isRecaptchaValid]);

  const { post } = useApi();

  const validateForm = (): boolean => {
    const errors: ErrorsType = {};
    const numPeople = parseInt(numPeopleFieldRef?.current?.value || '0');
    if (dateFieldRef?.current?.value.trim() === '') {
      errors.date = translate('The_Date_field_is_required');
    }
    if (nameFieldRef?.current?.value.trim() === '') {
      errors.name = translate('This_field_is_required');
    }
    if (emailFieldRef?.current?.value.trim() === '') {
      errors.email = translate('Please_enter_an_email_address');
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        emailFieldRef?.current?.value || '',
      )
    ) {
      errors.email = translate('Invalid_email');
    }
    if (phoneFieldRef?.current?.value.trim() === '') {
      errors.phone = translate('The_Phone_field_is_required');
    }
    if (numPeopleFieldRef?.current?.value.trim() === '') {
      errors.numPeople = translate('The_Number_of_people_field_is_required');
    }
    if (numPeople < 1) {
      errors.numPeople = translate('The_Number_of_people_field_is_required');
    }
    if (numPeople > 14) {
      errors.numPeople = translate(
        'Please_enter_a_value_less_than_or_equal_to_14',
      );
    }
    if (captchaToken.trim() === '') {
      errors.captchaToken = translate('This_field_is_required');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      const formData = {
        Name: nameFieldRef?.current?.value as string,
        Phone: phoneFieldRef?.current?.value as string,
        Email: emailFieldRef?.current?.value as string,
        Message: messageFieldRef?.current?.value as string,
        NumberOfPeople: parseInt(numPeopleFieldRef?.current?.value as string),
        Date: dateFieldRef?.current?.value as string,
        Time: timeFieldRef?.current?.value as string,
        ReservationGoogleRecaptchaResponse: captchaToken,
      };
      try {
        if (appId) {
          const response = await post<typeof formData, string>({
            path: `/app/makereservation?panaceaAppNameId=${appId}`,
            payload: formData,
          });
          if (response?.data?.Success === true) {
            setSubmitSuccess(true);
            setSubmitFailure(false);
          } else if (response?.data?.UserMessage) {
            setSubmitFailure(true);
            setFailureMsg(response.data.UserMessage);
          } else {
            setSubmitFailure(true);
          }
        }
      } catch {
        setSubmitFailure(true);
      }
    }
  };
  return (
    <section id="reservations" className="section reservations">
      <div className="container">
        <div className="jt_row jt_row-fluid row visible">
          <div className="col-md-12 jt_col column_container">
            <h2 className="section-title">{translate('Reservations')}</h2>
          </div>

          <div className="col-md-12 jt_col column_container">
            <div className="voffset100"></div>

            <ImgixImage
              className="center menu-logo"
              height="100"
              src={logo}
              width={250}
              alt={translate('This_image_is_our_logo')}
              loading="lazy"
            />

            <div className="voffset40"></div>

            <div className="reservationDivider">
              <span className="above"></span>
            </div>
            <div className="voffset50"></div>
            <StyledHeading $color={appDesignScheme.Primary} tabIndex={0}>
              {translate('Make_a_Reservation')}
            </StyledHeading>
            <div className="reservationDivider">
              <span></span>
            </div>
            <div className="voffset50"></div>
          </div>

          <div id="result"></div>
          {submitFailure && (
            <div
              className="col-md-12 jt_col column_container"
              id="contact-success"
            >
              <div className="voffset100"></div>

              <div className="voffset40"></div>
              <span aria-live="assertive" className="field-validation-error">
                <StyledError>
                  {failureMsg || translate('There_has_been_issue')}
                </StyledError>
              </span>
              <div className="voffset50"></div>
            </div>
          )}
          {!submitSuccess && (
            <form
              className="contact-form"
              noValidate
              // form doesn't expect promise returned but doesn't use it anyway
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit}
            >
              {' '}
              <div className="col-md-5 col-md-offset-1 jt_col column_container">
                <StyledText $color={appDesignScheme.Primary}>
                  {translate('Book_a_table')}
                </StyledText>

                <label htmlFor="Date" className="pull-left">
                  {translate('Date')}
                </label>
                {formErrors.date && (
                  <StyledError
                    aria-live="assertive"
                    className="field-validation-valid"
                  >
                    {formErrors.date}
                  </StyledError>
                )}
                <StyledInputField
                  ref={dateFieldRef}
                  className="text date required text-box single-line"
                  id="Date"
                  max={maxDateString}
                  min={minDateString}
                  name="Date"
                  placeholder={translate('Date')}
                  type="date"
                />

                <label htmlFor="Time" className="pull-left">
                  {translate('Time')}
                </label>
                <span
                  aria-live="assertive"
                  className="field-validation-valid"
                ></span>
                <StyledInputField
                  ref={timeFieldRef}
                  type="time"
                  id="Time"
                  name="time"
                  placeholder={translate('Time')}
                  aria-live="polite"
                  className="text time required"
                  step="300"
                />
                <label htmlFor="NumberOfPeople" className="pull-left">
                  {translate('Number_of_people')}
                </label>
                {formErrors.numPeople && (
                  <StyledError
                    aria-live="assertive"
                    className="field-validation-valid"
                  >
                    {formErrors.numPeople}
                  </StyledError>
                )}
                <StyledInputField
                  ref={numPeopleFieldRef}
                  className="text party required text-box single-line"
                  id="NumberOfPeople"
                  max="14"
                  min="1"
                  name="NumberOfPeople"
                  placeholder={translate('Number_of_people')}
                  type="number"
                  defaultValue="0"
                />
              </div>
              <div className="col-md-5 jt_col column_container">
                <StyledText $color={appDesignScheme.Primary}>
                  {translate('Contact_Details')}
                </StyledText>

                <label htmlFor="Name" className="pull-left">
                  {translate('Name')}
                </label>
                {formErrors.name && (
                  <StyledError
                    aria-live="assertive"
                    className="field-validation-valid"
                  >
                    {formErrors.name}
                  </StyledError>
                )}
                <StyledInputField
                  ref={nameFieldRef}
                  className="text reservation_name required text-box single-line"
                  id="Name"
                  name="Name"
                  placeholder={translate('Name')}
                  type="text"
                />

                <label htmlFor="Email" className="pull-left">
                  {translate('Email_Address')}
                </label>
                <span
                  aria-live="assertive"
                  className="field-validation-valid"
                ></span>
                {formErrors.email && (
                  <StyledError>{formErrors.email}</StyledError>
                )}
                <StyledInputField
                  ref={emailFieldRef}
                  className="text email required text-box single-line"
                  id="Email"
                  name="Email"
                  placeholder={translate('Email')}
                  type="email"
                />

                <label htmlFor="Phone" className="pull-left">
                  {translate('Phone')}
                </label>
                {formErrors.phone && (
                  <StyledError
                    aria-live="assertive"
                    className="field-validation-valid"
                  >
                    {formErrors.phone}
                  </StyledError>
                )}
                <StyledInputField
                  ref={phoneFieldRef}
                  className="text reservation_phone required text-box single-line"
                  id="Phone"
                  name="Phone"
                  placeholder={translate('Phone')}
                  type="text"
                />
              </div>
              <div className="col-md-10 col-md-offset-1 jt_col column_container">
                <textarea
                  ref={messageFieldRef}
                  cols={20}
                  id="Message"
                  name="Message"
                  rows={2}
                ></textarea>
              </div>
              <StyledColumn className="col-md-5 col-md-offset-1 jt_col column_container">
                {formErrors.captchaToken && (
                  <StyledError
                    aria-live="assertive"
                    className="field-validation-error"
                  >
                    <span>{formErrors.captchaToken}</span>
                  </StyledError>
                )}
                {isBrowser && recaptchaSiteKey && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    data-fd="reservations-recaptcha"
                    // TODO: Pass language - hl={language}
                    sitekey={recaptchaSiteKey}
                    onChange={handleRecaptchaTest}
                    onExpired={handleRecaptchaExpired}
                  />
                )}
              </StyledColumn>
              <div className="col-md-4 col-md-offset-4 jt_col column_container">
                <input
                  id="reservations_submit"
                  type="submit"
                  className="button center"
                  value={translate('Make_reservation')}
                />
              </div>
            </form>
          )}
          {submitSuccess && (
            <div
              className="col-md-12 jt_col column_container"
              id="reservations-success"
            >
              <div className="voffset100"></div>
              <div className="voffset40"></div>
              <h3>{translate('Reservation_submitted')}</h3>
              <div className="voffset50"></div>
            </div>
          )}
          <div className="col-md-12 jt_col column_container">
            <div className="voffset60"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
