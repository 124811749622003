import {
  type IndexPageType,
  type EmptyNoHeaderPage,
  PageType,
  type PageLinkType,
  type PageSchemaType,
  type CustomPageType,
  type V2CustomPageType,
  type V2EmptyNoHeaderPage,
  V2PageType,
} from '@Src/middlewares/CustomPageTypes';
import { createContext, useContext } from 'react';
import { useDesignSchema } from './DesignSchemaProvider';

interface PageSchemaBase {
  schema: PageSchemaType;
}

const PageSchemaContext = createContext<PageSchemaBase>(
  {} as unknown as PageSchemaBase,
);

type SchemaProp = {
  children: JSX.Element;
} & PageSchemaBase;

export const CustomPageSchemaProvider = ({
  children,
  schema,
}: SchemaProp): JSX.Element => {
  const value = {
    schema,
  };
  return (
    <PageSchemaContext.Provider value={value}>
      {children}
    </PageSchemaContext.Provider>
  );
};

type VersionType = 'v1' | 'v2';

type ReturnObjectType<T> = T extends 'v1'
  ? Record<string, CustomPageType>
  : T extends 'v2'
  ? Record<string, V2CustomPageType>
  : PageSchemaType;

export const useCustomPageSchema = <T extends VersionType>(
  version?: T,
): ReturnObjectType<T> => {
  const { schema } = useContext(PageSchemaContext);
  // @ts-expect-error - this is a hack to make the types work.
  return schema || {};
};

export const usePageLinkSchema = (
  displaySection: 'header' | 'footer',
): PageLinkType[] => {
  const designSchema = useDesignSchema();
  if (designSchema?.schemaVersion === 'v2') {
    return designSchema?.navigationMenuLinks || [];
  } else {
    const pageSchema = useContext(PageSchemaContext);
    const schema = pageSchema.schema as Record<string, CustomPageType>;
    const pageLnks = Object.entries(schema || [])
      .filter(([, value]) => value.LinkDisplaySection === displaySection)
      .map(([key, page]) => ({
        path: key ? `/${key}` : '/',
        title: page.NavigationLinkTitle || '',
        pageType: page.PageType || '',
        index: page.NavigationLinkIndex || 0,
      }));

    const mappedMenuLinks = (designSchema?.menuLinks || [])
      .filter((link) => link.LinkDisplaySection === displaySection)
      .map((link) => ({
        path: link.url || '',
        title: link.NavigationLinkTitle || '',
        index: link.NavigationLinkIndex || 0,
      }));

    const links = [...pageLnks, ...mappedMenuLinks].sort((valueA, valueB) => {
      const linkIndexA = valueA.index || 0;
      const linkIndexB = valueB.index || 0;
      if (linkIndexA === linkIndexB) return 0;
      return linkIndexA >= linkIndexB ? 1 : -1;
    });

    return links;
  }
};

export const useGetHeadernFooterState = (
  path: string,
): { header: boolean; footer: boolean } => {
  const { schema } = useContext(PageSchemaContext);
  let header = true;
  let footer = true;
  const pageData = schema?.[path] as IndexPageType | EmptyNoHeaderPage;
  if (pageData) {
    const pageType = pageData.PageType;
    if (pageType === PageType.EmptyNoHeaderPage) {
      header = false;
      footer = false;
    } else if (pageType === PageType.IndexPage) {
      footer = !!pageData.footer;
    }
  }
  return { header, footer };
};

export const useGetHeaderStateV2 = (
  path: string,
): { header: boolean; footer: boolean } => {
  const { schema } = useContext(PageSchemaContext);
  let header = true;
  let footer = true;
  const pageData = schema?.[path] as V2EmptyNoHeaderPage;
  if (pageData) {
    const pageType = pageData.pageType;
    if (pageType === V2PageType.EmptyNoHeaderPage) {
      header = false;
      footer = false;
    }
  }
  return { header, footer };
};

export const useEnabledIndexSections = (): {
  gallery: boolean;
  contactForm: boolean;
} => {
  const { schema } = useContext(PageSchemaContext);
  if (schema) {
    const indexPage = Object.entries(
      schema as Record<string, CustomPageType>,
    ).find(([, value]) => value.PageType === PageType.IndexPage);
    const data = indexPage?.[1] as IndexPageType;
    return {
      gallery: data?.showGallery,
      contactForm: data?.contactFormEnabled,
    };
  }
  return {
    gallery: false,
    contactForm: false,
  };
};
