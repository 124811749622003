import { SvgIcon } from '@mui/material';

export function StarPending({
  className,
  color,
  dataFd,
}: {
  className?: string;
  color: string;
  dataFd?: string;
}): JSX.Element {
  return (
    <SvgIcon
      className={className}
      data-fd={dataFd}
      name="StarPending"
      fontSize="inherit"
    >
      <path d="M4.59 23L6.54 14.4961L0 8.77632L8.64 8.01974L12 0L15.36 8.01974L24 8.77632L17.46 14.4961L19.41 23L12 18.4908L4.59 23Z">
        <animate
          attributeName="fill"
          values={`${color};white;`}
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54 14.4961L4.59 23L12 18.4908L19.41 23L17.46 14.4961L24 8.77632L15.36 8.01974L12 0L8.64 8.01974L0 8.77632L6.54 14.4961ZM4.84816 10.3594L8.75663 13.7777L7.59857 18.828L12 16.1496L16.4014 18.828L15.2434 13.7777L19.1518 10.3594L13.9822 9.90674L12 5.17569L10.0179 9.90674L4.84816 10.3594Z"
        fill="#B3B3B3"
      />
    </SvgIcon>
  );
}
