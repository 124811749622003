import { About } from './About';
import OpeningHours from './OpeningHours';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { Testimonials } from './Testimonials';
import { Contact } from './Contact';
import { Landing } from './Landing';
import MapSection from './MapSection';
import MenuPreview from './MenuPreview';
import { Gallery } from './Gallery';
import { type IndexPageType } from '@Src/middlewares/CustomPageTypes';
import { Reservations } from './Reservations';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const LegacyElixir = ({
  data,
}: {
  data: IndexPageType;
}): JSX.Element => {
  const schema = useDesignSchema('v1');
  const location = useLocation();

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;
    const { hash } = location;
    try {
      const section = hash && document.querySelector(hash);

      if (section) {
        timeOut = setTimeout(() => {
          section.scrollIntoView();
        }, 100);
      }
    } catch (e) {
      // Nothing to do here, its a malformed URL.
    }

    return () => clearTimeout(timeOut);
  }, [location.hash]);

  const showMap =
    data.showMap && schema.map && (schema.storeMapData?.length || 0) > 0;
  return (
    <>
      <Landing
        mainSliderImages={data.mainSliderImages}
        showAddressMainSlider={data.showAddressMainSlider}
        voucher={data.voucherPopupEnabled ? data.voucher : undefined}
      />
      {data.aboutUs && <About data={data.aboutUs} />}
      {data.openingHoursEnabled && schema.openingHours && (
        <OpeningHours image={data.openingHoursImage} />
      )}
      {data.showMenu && schema.pickupStoreId && <MenuPreview />}
      {data.showGallery && data.galleryImages && (
        <Gallery images={data.galleryImages} />
      )}
      {data.reservations?.enabled && <Reservations />}
      {data.showTestimonials && data.testimonials && (
        <Testimonials testimonials={data.testimonials} />
      )}
      {data.contactFormEnabled && <Contact />}
      {showMap && <MapSection />}
    </>
  );
};

export default LegacyElixir;
