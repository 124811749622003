import { type SupportedLanguage } from '@Src/middlewares/utils/constants';

export function extractDateTime(
  apiTranslation: string,
  languageCode: SupportedLanguage,
): {
  date: string | null;
  time: string | null;
} {
  let dateRegex: RegExp = / /;

  /** date comes before month, e.g. 15 nov. */
  if (languageCode === 'es' || languageCode === 'fr') {
    dateRegex =
      /(\d{1,2}\s(?:enero\.?|feb\.?|marzo\.?|abr\.?|mayo\.?|jun\.?|jul\.?|agosto\.?|set\.?|oct\.?|nov\.?|dic\.?))/i;
  }

  /** date comes after month, e.g. Nov 15 */
  if (
    languageCode === 'en' ||
    languageCode === 'en-US' ||
    languageCode === 'de'
  ) {
    dateRegex =
      /((?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec?)) (\d{1,2})/i;
  }

  const timeRegex = /(\d{1,2}:\d{2}\s?(?:AM|PM)?)/i;

  const dateMatch = apiTranslation.match(dateRegex);
  const timeMatch = apiTranslation.match(timeRegex);

  const extractedDateTime = {
    date: dateMatch ? dateMatch[0] : null,
    time: timeMatch ? timeMatch[0] : null,
  };

  return extractedDateTime;
}

export const getTranslationWithPlaceholders = ({
  apiTranslation,
  date,
  time,
}: {
  apiTranslation: string;
  date?: string | null;
  time?: string | null;
}): string => {
  let t = apiTranslation;

  if (date) {
    t = t.replace(date, '{date}');
  }
  if (time) {
    t = t.replace(time, '{time}');
  }

  return t;
};

/** Same language map of "closed at" message -> "opens at" message for supported languages */
export const mapClosedAtToOpenAtMsg = (
  translationWithPlaceholders: string,
  languageCode: SupportedLanguage,
): string => {
  const mapTranslations: Record<string, Record<string, string>> = {
    de: {
      'Öffnet wieder {time}': 'Abholung ab {time} Uhr',
      'Morgen ab {time} wieder geöffnet': 'Abholung morgen ab {time} Uhr',
      'Öffnet wieder {date} {time}': 'Abholung ab {date} {time}',
    },
    en: {
      'Closed until {time}': 'Opens at {time}',
      'Closed until {time} tomorrow': 'Opens at {time} tomorrow',
      'Closed until {date} {time}': 'Opens {date} at {time}',
    },
    es: {
      'Cerrado hasta las {time}': 'Recoger a las {time}',
      'Cerrado hasta las {time} mañana': 'Retirar mañana a las {time}',
      'Cerrado hasta las {date} {time}': 'Recoger el {date} a las {time}',
    },
    fr: {
      "Fermé jusqu'à {time}": 'Récupération à {time}',
      "Fermé jusqu'à {time} demain": 'Récupération à {time} demain',
      "Fermé jusqu'à {date} {time}": 'Récupération le {date} à {time}',
    },
  };

  if (languageCode && translationWithPlaceholders) {
    if (languageCode === 'en-US') {
      const t = mapTranslations.en[translationWithPlaceholders];
      return t || translationWithPlaceholders;
    }
    const t = mapTranslations[languageCode][translationWithPlaceholders];
    return t || translationWithPlaceholders;
  }

  return translationWithPlaceholders || '';
};

export const replacePlaceHolders = (
  mappedTWithPlaceholders: string,
  dateTime: {
    date: string | null;
    time: string | null;
  },
): string => {
  let translation = mappedTWithPlaceholders;

  Object.keys(dateTime).forEach((key) => {
    const value = dateTime[key as keyof typeof dateTime];
    if (value !== null) {
      translation = translation?.replace(`{${key}}`, `${value}`);
    }
  });

  return translation;
};

export function getOpeningTimeMsgTranslation(
  apiTranslation: string,
  languageCode: SupportedLanguage,
): string {
  const dateTime = extractDateTime(apiTranslation, languageCode);

  const translationWithPlaceholders = getTranslationWithPlaceholders({
    apiTranslation,
    date: dateTime.date,
    time: dateTime.time,
  });

  const mappedTranslationWithPlaceholders = mapClosedAtToOpenAtMsg(
    translationWithPlaceholders,
    languageCode,
  );

  const translation = replacePlaceHolders(mappedTranslationWithPlaceholders, {
    date: dateTime.date,
    time: dateTime.time,
  });

  return translation || apiTranslation;
}
