export const CUSTOM_EVENTS = {
  USER_LOGIN: 'Flipdish-user-logged-in',
  USER_LOGOUT: 'Flipdish-user-logged-out',
};

type Keys = keyof typeof CUSTOM_EVENTS;
type Props = {
  name: (typeof CUSTOM_EVENTS)[Keys];
  data?: object;
};

export const publishCustomEvent = ({ name, data }: Props): void => {
  // Use try catch so as to not block main app functionality.
  try {
    const event = new CustomEvent(name, {
      detail: {
        ...(data || {}),
      },
    });

    window.document.dispatchEvent(event);
  } catch (e) {
    console.error(`Unable to publish custom event ${name}`, e);
  }
};
