import { fetchIpInfo } from '@Src/thunks/locationThunks';
import { type Geo, type IpInfo } from '@Src/types/locationTypes';
import { createSlice } from '@Src/utility/reduxUtils';

type LocationStateType = {
  ipInfo?: IpInfo;
  geo: Geo;
};
export const initialState: LocationStateType = {
  ipInfo: undefined,
  // same initial geo as web order
  geo: { Latitude: 53.3454362, Longitude: -6.2616217 },
};
export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {
    setGeolocation: (
      state,
      action: {
        type: string;
        payload: Geo;
      },
    ) => ({
      ...state,
      geo: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIpInfo.fulfilled, (state, response) => {
      state.ipInfo = response.payload.ipInfo;
      state.geo = response.payload.geo;
      state.fetched = true;
      state.loading = false;
    });
    builder.addCase(fetchIpInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchIpInfo.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});

export const { setGeolocation } = locationSlice.actions;
export default locationSlice.reducer;
