import AppStoreBadgeEn from '@Src/assets/images/elixir/app-store-badge-en';
import GooglePlayStoreBadgeEn from '@Src/assets/images/elixir/google-play-badge-en';
import AppStoreBadgeDe from '@Src/assets/images/elixir/app-store-badge-de';
import GooglePlayStoreBadgeDe from '@Src/assets/images/elixir/google-play-badge-de';
import AppStoreBadgeEs from '@Src/assets/images/elixir/app-store-badge-es';
import GooglePlayStoreBadgeEs from '@Src/assets/images/elixir/google-play-badge-es';
import AppStoreBadgeFr from '@Src/assets/images/elixir/app-store-badge-fr';
import GooglePlayStoreBadgeFr from '@Src/assets/images/elixir/google-play-badge-fr';
import { type FunctionComponent, type SVGProps } from 'react';

type MobileAppIconsType = {
  AppStoreBadge: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  GooglePlayBadge: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
};

export const getMobileAppIcons = (lang: string): MobileAppIconsType => {
  switch (lang) {
    case 'fr':
      return {
        AppStoreBadge: AppStoreBadgeFr,
        GooglePlayBadge: GooglePlayStoreBadgeFr,
      };
    case 'de':
      return {
        AppStoreBadge: AppStoreBadgeDe,
        GooglePlayBadge: GooglePlayStoreBadgeDe,
      };
    case 'es':
      return {
        AppStoreBadge: AppStoreBadgeEs,
        GooglePlayBadge: GooglePlayStoreBadgeEs,
      };
    default:
      return {
        AppStoreBadge: AppStoreBadgeEn,
        GooglePlayBadge: GooglePlayStoreBadgeEn,
      };
  }
};
