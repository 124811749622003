import { styled } from '@mui/material/styles';

const StyledChip = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.extraLowContrast,
  borderRadius: '100px',
  display: 'flex',
  padding: theme.spacing(0.5),
  position: 'relative',
  width: 'fit-content',
  zIndex: 1,
}));

type ChipProps = {
  children: React.ReactNode;
  className?: string;
};
export function Chip({ children, className }: ChipProps): JSX.Element {
  return <StyledChip className={className}>{children}</StyledChip>;
}
