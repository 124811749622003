import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';
import { useConfig } from '@Src/providers/ConfigProvider';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { logger } from '@Src/utility/loggerService';

const StyledDiv = styled('div')(({ theme }) => ({
  ...(theme?.template === V2Templates.boxedUp
    ? {
        '& #flipdish-budapest': {
          borderRadius: theme.boxedUp.borderRadius.mobile,
          marginBottom: theme.spacing(2),
          overflow: 'hidden',
        },
        [theme.breakpoints.up('sm')]: {
          '& #flipdish-budapest': {
            borderRadius: theme.boxedUp.borderRadius.default,
            marginBottom: theme.spacing(3),
            overflow: 'hidden',
          },
        },
      }
    : {
        paddingTop: '25px',
      }),
}));

export const Weborder = (): JSX.Element => {
  const designSchema = useDesignSchema();
  const isV2 = designSchema.schemaVersion === 'v2';
  const policyUrls = isV2
    ? designSchema?.config?.policyUrls
    : designSchema.policyUrls;

  const font = isV2 ? designSchema?.globalStyle?.fonts?.primary : null;
  const primaryColor = isV2 ? designSchema?.globalStyle?.colors?.primary : null;

  const { language, appId } = useConfig();

  useEffect(() => {
    const script = document.createElement('script');
    // Add a unique query string to the script URL to force the browser to fetch the script again (not cached script)
    script.src = `https://web-order.flipdish.co/client/productionwlbuild/latest/static/js/main.js?${Date.now()}`;
    script.async = true; // Script is executed asynchronously as soon as it is available
    script.defer = true; // Defer script execution until the document has been parsed
    script.onerror = () => {
      logger.error(
        `[AppId-${
          appId || 'undefined'
        }][WebOrderEmbed] msg: WebOrder embed script failed to load`,
      );
    };

    document.body.appendChild(script);
    // Cleanup possible memory leak
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const showFullscreenMobile = isV2 ? 'false' : 'true';

  return (
    <StyledDiv
      id="flipdish-menu"
      data-fd="flipdish-menu"
      data-restaurant={appId}
      data-web-order-source="elixir"
      data-initial-language={language}
      data-force-app="true"
      data-initial-screen="delivery-type"
      data-full-screen-on-mobile={showFullscreenMobile}
      data-terms-link={policyUrls?.terms || '/terms'}
      data-privacy-policy-link={policyUrls?.privacy || '/privacy'}
      data-cookie-policy-link={policyUrls?.cookie || '/cookiepolicy'}
      {...(font ? { 'data-font': font } : {})}
      {...(primaryColor ? { 'data-primary-color': primaryColor } : {})}
    />
  );
};

export default Weborder;
