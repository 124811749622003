import { SvgIcon } from '@mui/material';

export function StarOutlined({
  className,
  dataFd,
}: {
  className?: string;
  dataFd?: string;
}): JSX.Element {
  return (
    <SvgIcon
      className={className}
      data-fd={dataFd}
      name="StarOutlined"
      fontSize="inherit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54003 14.4961L4.59003 23L12 18.4908L19.41 23L17.46 14.4961L24 8.77632L15.36 8.01974L12 0L8.64003 8.01974L3.05176e-05 8.77632L6.54003 14.4961ZM4.84819 10.3594L8.75666 13.7777L7.5986 18.828L12 16.1496L16.4015 18.828L15.2434 13.7777L19.1519 10.3594L13.9822 9.90674L12 5.17569L10.0179 9.90674L4.84819 10.3594Z"
        fill="#B3B3B3"
      />
    </SvgIcon>
  );
}
