import { type ColorScheme } from '@Src/middlewares/CustomPageTypes';

export enum ColorSchemeEnum {
  light = 'light',
  dark = 'dark',
}
export type ColorSchemeExtended = {
  background: string;
  body: string;
  disabled: string;
  extraLowContrast: string;
  foreground: string;
  lowContrast: string;
  subtle: string;
  type: ColorSchemeEnum;
};

export const supportedColorSchemes: Record<
  ColorSchemeEnum,
  ColorSchemeExtended
> = {
  light: {
    background: '#ffffff',
    body: '#666666',
    disabled: '#b3b3b3',
    extraLowContrast: '#ededed',
    foreground: '#000000',
    lowContrast: '#cccccc',
    subtle: '#999999',
    type: ColorSchemeEnum.light,
  },
  dark: {
    background: '#000000',
    body: '#c8c8c8',
    disabled: '#7a7a7a',
    extraLowContrast: '#3d3d3d',
    foreground: '#ffffff',
    lowContrast: '#717171',
    subtle: '#999999',
    type: ColorSchemeEnum.dark,
  },
};

/** Creates secondary colors based on color scheme, e.g. contrastText, disabled, etc. */
export function getColorSchemeExtended(
  colorScheme?: ColorScheme,
): ColorSchemeExtended {
  let colorSchemeExtended = supportedColorSchemes.light;

  for (const [, colors] of Object.entries(supportedColorSchemes)) {
    if (colors.background === colorScheme?.background) {
      colorSchemeExtended = colors;
    }
  }

  return colorSchemeExtended;
}
