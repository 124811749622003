import { styled } from '@mui/material/styles';
import { Chip } from '@Components/ui/Chip';
import Typography from '@mui/material/Typography';
import { type FunctionComponent, type SVGProps } from 'react';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';

type StyledProps = { isSelected: boolean };

const StyledToggleContainer = styled('div')<{
  ownerState: {
    borderTop?: string;
    padding?: string;
    marginLeft?: string;
  };
}>(({ ownerState, theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 25,
  borderTop: ownerState.borderTop || 'none',
  padding: ownerState.padding,
  marginLeft: ownerState?.marginLeft || theme.spacing(2),
  width: 'fit-content',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1),
  },
  '.icon-from-parent': {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.body,
    path: {
      fill: theme.palette.secondary.body,
    },
  },
  '.icon-selected': {
    path: {
      fill: theme.palette.primary.contrastText,
    },
    color: theme.palette.primary.contrastText,
  },
}));

const StyledToggleButton = styled('button')<{
  ownerState: StyledProps;
}>(({ ownerState, theme }) => ({
  alignItems: 'center',
  background: ownerState.isSelected ? theme.palette.primary.main : 'initial',
  borderRadius: theme.shape.borderRadius * 25,
  display: 'flex',
  flexDirection: 'row',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
}));

const StyledLabel = styled(Typography)<{ ownerState: StyledProps }>(
  ({ ownerState, theme }) => ({
    color: ownerState.isSelected
      ? theme.palette.primary.contrastText
      : theme.palette.secondary.body,
  }),
);

type ChipToggleProps = {
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  handleLeftOnClick: () => void;
  handleRightOnClick: () => void;
  leftSelected: boolean;
  LeftIcon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  leftLabel: string;
  RightIcon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  rightLabel: string;
  padding?: string;
  marginLeft?: string;
  borderTop?: string;
};

export function ChipToggle(props: ChipToggleProps): JSX.Element {
  const {
    leftDisabled,
    rightDisabled,
    padding,
    handleLeftOnClick,
    handleRightOnClick,
    LeftIcon,
    leftLabel,
    RightIcon,
    rightLabel,
    leftSelected,
    marginLeft,
    borderTop,
  } = props;

  const fulfillmentTypeCopyUpdate = useFeatureFlag(
    'enableFulfillmentTypeCopyUpdate',
  );

  const labelVariant = fulfillmentTypeCopyUpdate
    ? 'buttonSmall'
    : 'finePrint1Medium';

  return (
    <StyledToggleContainer ownerState={{ borderTop, padding, marginLeft }}>
      <Chip>
        <StyledToggleButton
          disabled={leftDisabled}
          ownerState={{ isSelected: leftSelected }}
          onClick={handleLeftOnClick}
        >
          {LeftIcon && (
            <LeftIcon
              className={
                'icon-from-parent' + (leftSelected ? ' icon-selected' : '')
              }
              data-fd="left-icon"
              width={16}
              height={16}
            />
          )}
          <StyledLabel
            variant={labelVariant}
            ownerState={{ isSelected: leftSelected }}
          >
            {leftLabel}
          </StyledLabel>
        </StyledToggleButton>
        <StyledToggleButton
          disabled={rightDisabled}
          ownerState={{ isSelected: !leftSelected }}
          onClick={handleRightOnClick}
        >
          {RightIcon && (
            <RightIcon
              className={
                'icon-from-parent' + (!leftSelected ? ' icon-selected' : '')
              }
              data-fd="right-icon"
              width={16}
              height={16}
            />
          )}
          <StyledLabel
            variant={labelVariant}
            ownerState={{ isSelected: !leftSelected }}
          >
            {rightLabel}
          </StyledLabel>
        </StyledToggleButton>
      </Chip>
    </StyledToggleContainer>
  );
}
