import { V2Templates } from '@Src/middlewares/CustomPageTypes';
import { styled } from '@mui/material/styles';
import { type CSSObject } from '@emotion/react';

export const StyledBaseContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth?: boolean }>(({ theme, isFullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: isFullWidth ? 0 : '0 auto',
  maxWidth: '100%',
  paddingBottom:
    theme.template === V2Templates.boxedUp ? 0 : theme.spacing(4.5),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3),
    paddingBottom:
      theme.template === V2Templates.boxedUp ? 0 : theme.spacing(6),
    maxWidth: isFullWidth ? 'inherit' : '1140px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: isFullWidth ? 'inherit' : '1640px',
  },
}));

export const BaseGridPolice = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth?: boolean }>(({ theme, isFullWidth }) => {
  let smUpStyle: CSSObject = { margin: 0 };

  if (!isFullWidth) {
    if (theme.template === V2Templates.dishy) {
      smUpStyle = { margin: `0 ${theme.spacing(6)}` };
    } else {
      smUpStyle = { margin: `0 ${theme.spacing(3)}`, paddingBottom: 0 };
    }
  }

  return {
    overflow: 'visible',
    margin: theme.template === V2Templates.boxedUp ? theme.spacing(2) : 0,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: smUpStyle,
  };
});

export const InnerGridPolice = styled('div')<{
  shouldOverFlowMobile?: boolean;
}>(({ shouldOverFlowMobile, theme }) => {
  if (theme.template === V2Templates.boxedUp) {
    return {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.boxedUp.borderRadius.mobile,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6),
        marginBottom: theme.spacing(3),
        borderRadius: theme.boxedUp.borderRadius.default,
      },
    };
  }
  return {
    overflow: 'visible',
    margin: `0 ${theme.spacing(3)}`,
    padding: `${theme.spacing(4.5)} 0`,
    width: shouldOverFlowMobile ? `calc(100% - ${theme.spacing(3)})` : 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(6)} 0`,
      margin: `0 ${theme.spacing(5)}`,
      width: 'auto',
    },
  };
});

export const InnerGridWithImagePolice = styled('div')<{ isShort?: boolean }>(
  ({ theme, isShort }) => {
    if (theme.template === V2Templates.boxedUp) {
      return {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.boxedUp.borderRadius.mobile,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(3),
          borderRadius: theme.boxedUp.borderRadius.default,
        },
      };
    }

    return {
      padding: `${theme.spacing(4.5)} 0`,

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(6)} 0 ${
          isShort ? theme.spacing(6) : theme.spacing(12)
        }`,
      },
    };
  },
);
