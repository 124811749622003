import { fetchDeliveryAddresses } from '@Src/thunks/deliveryAddressesThunks';
import { type DeliveryAddress } from '@Src/types/deliveryAddressTypes';
import { createSlice } from '@Src/utility/reduxUtils';

type DeliveryAddressesStateType = {
  selectedDeliveryAddress?: DeliveryAddress;
  deliveryAddressList: DeliveryAddress[];
};

export const initialState: DeliveryAddressesStateType = {
  deliveryAddressList: [],
  selectedDeliveryAddress: undefined,
};
export const deliveryAddressesSlice = createSlice({
  name: 'deliveryAddresses',
  initialState: {
    ...initialState,
    fetched: false,
    loading: false,
  },
  reducers: {
    setSelectedDeliveryAddress: (
      state,
      action: {
        type: string;
        payload: DeliveryAddress;
      },
    ) => ({
      ...state,
      selectedDeliveryAddress: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeliveryAddresses.fulfilled, (state, response) => {
      // Addresses 1-5 are the most recently updated addresses
      // Address 6 is the latest address the user has created - which we want to display first in the list
      const copy = [...response.payload.DeliveryLocations];
      const latestAddress = copy.pop();
      const orderedDeliveryAddresses = latestAddress
        ? [latestAddress, ...copy]
        : response.payload.DeliveryLocations;

      state.deliveryAddressList = orderedDeliveryAddresses;
      state.selectedDeliveryAddress = orderedDeliveryAddresses[0];
      state.fetched = true;
      state.loading = false;
    });
    builder.addCase(fetchDeliveryAddresses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeliveryAddresses.rejected, (state) => {
      state.fetched = true;
      state.loading = false;
    });
  },
});
export const { setSelectedDeliveryAddress } = deliveryAddressesSlice.actions;
export default deliveryAddressesSlice.reducer;
