import Button, {
  type ButtonProps as MuiButtonProps,
} from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

type StyledButtonProps = {
  ownerState: {
    buttonWidth: number | null;
  };
};
const StyledButton = styled(Button)<StyledButtonProps>(({ ownerState }) => ({
  minWidth: ownerState?.buttonWidth ? `${ownerState.buttonWidth}px` : 'auto',
  boxShadow: 'none',
}));

type ButtonProps = MuiButtonProps & {
  loading?: boolean;
};

export const MuiButton = ({
  loading,
  children,
  ...rest
}: ButtonProps): JSX.Element => {
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [children]);

  return (
    <StyledButton ownerState={{ buttonWidth }} {...rest} ref={buttonRef}>
      {loading ? <StyledCircularProgress size={24} /> : children}
    </StyledButton>
  );
};

export default MuiButton;
