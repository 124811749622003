import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@Src/assets/images/Close.svg';
import { ReactComponent as LeftArrowIcon } from '@Src/assets/images/ArrowLeft.svg';
import { ReactComponent as RightArrowIcon } from '@Src/assets/images/ArrowRight.svg';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

const StyledFullscreenContainer = styled.div`
  background-color: #0d0d0d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .fullscreenImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: white;
  height: 34px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 34px;
  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
`;

const StyledFullscreenImage = styled.img`
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  vertical-align: middle;
`;

const StyledLeftArrow = styled.div`
  position: absolute;
  cursor: pointer;
  left: 20px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  border-radius: 24px;
  width: 50px;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledRightArrow = styled(StyledLeftArrow)`
  left: unset;
  right: 20px;
`;

type FullscreenGalleryProps = {
  images: string[];
  onClose: () => void;
  startIndex: number;
};
export const FullscreenGallery = ({
  images,
  onClose,
  startIndex,
}: FullscreenGalleryProps): React.ReactElement => {
  const [selectedImageIndex, setSelectedImageIndex] =
    useState<number>(startIndex);
  const { imageAttributes } = useDesignSchema('v2');
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedImageIndex]);

  const getNextImage = (): void => {
    const nextIndex =
      selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1;
    setSelectedImageIndex(nextIndex);
  };

  const getPrevImage = (): void => {
    const prevIndex =
      selectedImageIndex === 0 ? images.length - 1 : selectedImageIndex - 1;
    setSelectedImageIndex(prevIndex);
  };
  const handleKeyPress = (e: { key: string }): void => {
    switch (e.key) {
      case 'ArrowLeft':
        getNextImage();
        break;
      case 'ArrowRight':
        getPrevImage();
        break;
      case 'Escape':
        onClose();
        break;
    }
  };
  return (
    <StyledFullscreenContainer>
      <StyledCloseIcon onClick={onClose} />
      <div className="fullscreenImageContainer">
        <StyledFullscreenImage
          src={`${images[selectedImageIndex]}?w=1080&h=720`}
          alt={
            imageAttributes?.[selectedImageIndex]?.alt ||
            `fullscreen gallery image ${selectedImageIndex}`
          }
        />
      </div>
      <StyledLeftArrow onClick={getPrevImage}>
        <LeftArrowIcon />
      </StyledLeftArrow>
      <StyledRightArrow onClick={getNextImage}>
        <RightArrowIcon />
      </StyledRightArrow>
    </StyledFullscreenContainer>
  );
};
