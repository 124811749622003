import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useTranslation } from '@Src/providers/TranslationProvider';
import LocationSummaryText from './LocationSummaryText';
import LoadMoreVertical from '@Components/ui/LoadMoreVertical';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import LocationCard from './LocationCard';
import {
  Grid,
  Paper,
  type Theme,
  styled,
  Typography,
  CircularProgress,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useRef, useState } from 'react';
import { getTodaysDay, getOpenTimeDescription } from './util';
import MapSection from './Map';
import { useCustomPageSchema } from '@Src/providers/CustomPageSchemaProvider';
import { V2PageType } from '@Src/middlewares/CustomPageTypes';

type StyledTextContainerProps = {
  ownerState: {
    showBorder: boolean;
    isSelected: boolean;
  };
};

const StyledTextContainer = styled('div')<StyledTextContainerProps>`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme, ownerState }) =>
    ownerState?.isSelected ? theme.palette.secondary.main : 'inherit'};
  color: ${({ ownerState, theme }) =>
    ownerState?.isSelected ? theme.palette.secondary.contrastText : 'inherit'};
  border-bottom: 1px solid
    ${({ theme, ownerState }) =>
      ownerState?.showBorder
        ? theme.palette.secondary.lowContrast
        : 'transparent'};
`;

type StyledLocationCardContainerProps = {
  ownerState: {
    borderRadius?: string;
    cardBorderRadius?: string;
  };
};
const StyledLocationCardContainer = styled(
  'div',
)<StyledLocationCardContainerProps>`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  left: ${({ theme }) => theme.spacing(2)};
  z-index: 99;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 10px;
    top: unset;
  }

  a {
    border-radius: ${({ ownerState }) => ownerState.borderRadius};
  }
  #locations {
    border-radius: ${({ ownerState }) => ownerState.cardBorderRadius};
  }
`;

const StyledListGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    border-right: ${({ theme }) =>
      `1px solid ${theme.palette.secondary.lowContrast || ''}`};
  }
`;

type StyledCardProps = {
  ownerState: {
    borderRadius?: string;
  };
};
const StyledCard = styled(Paper)<StyledCardProps>`
  box-shadow: none;
  border-radius: ${({ ownerState }) => ownerState.borderRadius || '8px'};
  border: ${({ theme }) =>
    `1px solid ${theme.palette.secondary.lowContrast || ''}`};
  background: ${({ theme }) => theme.palette.background.default};
  height: 500px;
  overflow-y: hidden;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-height: 1100px;
    height: fit-content;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Make the scrollbar thumb transparent */
    }
  }
`;

const StyledMapGrid = styled(Grid)`
  position: relative;
`;

const StyledMapDiv = styled('div')`
  height: 500px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 650px;
  }
`;
const StyledTitleContainer = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 8px;
  padding-bottom: 24px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledCircularProgressContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

type LocationsWidgetProps = {
  showTitle?: boolean;
  showSubTitle?: boolean;
  buttonBorderRadius?: string;
  buttonTextColor?: string;
  cardBorderRadius?: string;
  listBorderRadius?: string;
};

const LocationsWidget = ({
  showTitle,
  showSubTitle,
  buttonBorderRadius,
  buttonTextColor,
  listBorderRadius,
  cardBorderRadius,
}: LocationsWidgetProps): JSX.Element => {
  const mapContainerRef = useRef(null);
  const [mapInView, setMapInView] = useState(false);
  const paginationIncrement = 5;
  const pages = useCustomPageSchema() || {};
  let contactUsUrl = '';
  let reservationsUrl = '';
  Object.entries(pages)?.some(([key, value]) => {
    if (value?.pageType === V2PageType.ContactPage) {
      contactUsUrl = `/${key}` || '';
      return true;
    }
    return false;
  });

  if (!contactUsUrl) {
    Object.entries(pages)?.some(([key, value]) => {
      if (value?.pageType === V2PageType.ReservationsPage) {
        reservationsUrl = `/${key}` || '';
        return true;
      }
      return false;
    });
  }

  const [selectedStore, setSelectedStore] = useState<number | null>(null);
  const [rowsToDisplay, setRowsToDisplay] = useState(paginationIncrement);
  const { translate } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const { storeContactDetails, storeMapData, openingHours } = useDesignSchema();

  const subTitle = storeMapData?.length
    ? `${storeMapData?.length} ${translate(
        storeMapData?.length > 1 ? 'stores' : 'store',
      )}`
    : '';

  const detailsToShow = storeContactDetails?.find(
    (store) => store?.storeId === selectedStore,
  );

  const mapDataToShow = storeMapData?.find(
    (store) => store?.storeId === selectedStore,
  );

  const openingHoursToShow = openingHours?.find(
    (store) => store?.storeId === selectedStore,
  );

  const collectionStores =
    useAppSelector((state) => state.collectionStores.collectionStoreList) || [];
  const deliveryStores =
    useAppSelector((state) => state.deliveryStores.deliveryStoreList) || [];

  const storeOpeninghashMap: Record<number, boolean> = {};
  collectionStores.forEach((s) => {
    storeOpeninghashMap[s.PhysicalRestaurantId] = s.IsOpen;
  });
  deliveryStores.forEach((s) => {
    storeOpeninghashMap[s.PhysicalRestaurantId] = s.IsOpen;
  });

  const isOpen =
    !!storeOpeninghashMap[selectedStore || 0] ||
    openingHoursToShow?.openForPickup ||
    openingHoursToShow?.openForDelivery;

  const selectedStoreFromState = useAppSelector(
    (state) =>
      (state?.orderSettings?.isCollection
        ? state?.collectionStores?.selectedCollectionStore?.PhysicalRestaurantId
        : state.deliveryStores?.deliveryStoreList?.[0]?.PhysicalRestaurantId) ||
      null,
  );

  const storesToShow = selectedStoreFromState
    ? storeContactDetails
        ?.sort((a, _b) => {
          if (a?.storeId === selectedStoreFromState) return -1;
          return 1;
        })
        ?.map((store) => store?.storeId)
    : storeContactDetails?.map((store) => store?.storeId);

  const paginatedStores = storesToShow?.slice(0, rowsToDisplay);

  useEffect(() => {
    setSelectedStore(selectedStoreFromState || storesToShow?.[0] || null);
  }, [selectedStoreFromState]);

  if (!storesToShow || storesToShow?.length === 0) {
    return <></>;
  }

  const getStoreList = (): JSX.Element[] | undefined =>
    paginatedStores?.map((storeId, index) => (
      <StyledTextContainer
        className={`locations-widget-store-item-${storeId}`}
        data-fd={`location-text-${storeId}`}
        ownerState={{
          showBorder:
            storesToShow?.length !== index + 1 ||
            (storesToShow?.length < paginationIncrement && !isMobile),
          isSelected: selectedStore === storeId,
        }}
        key={storeId}
        onClick={() => setSelectedStore(storeId)}
      >
        <LocationSummaryText
          name={
            storeContactDetails?.find((store) => store.storeId === storeId)
              ?.name || ''
          }
          address={
            storeMapData?.find((store) => store.storeId === storeId)?.Address ||
            ''
          }
        />
      </StyledTextContainer>
    ));

  const getTranslatedDays = (days?: string): string => {
    const daysArray = days?.split(' - ');
    const translatedDays = daysArray?.map((day) => translate(day));
    return translatedDays?.join(' - ') || '';
  };

  const getTranslatedHours = (hours?: string): string =>
    hours?.replace('Closed', translate('Closed')) || '';

  const deliveryHours = openingHoursToShow?.deliveryHours;

  const formattedDeliveryHours = deliveryHours?.map(
    (hours) =>
      `${getTranslatedDays(hours?.days.toLowerCase())}: ${getTranslatedHours(
        hours?.hours,
      )}`,
  );
  const collectionHours = openingHoursToShow?.pickupHours;
  const formattedCollectionHours = collectionHours?.map(
    (hours) =>
      `${getTranslatedDays(hours?.days.toLowerCase())}: ${getTranslatedHours(
        hours?.hours,
      )}`,
  );

  const todaysDay = getTodaysDay();
  const openUntilTime =
    openingHoursToShow?.pickupClosingHours?.[todaysDay] ||
    openingHoursToShow?.deliveryClosingHours?.[todaysDay];
  const openTimeDescription = getOpenTimeDescription(
    isOpen,
    openUntilTime,
    translate,
  );

  const onlyOneStore = storesToShow?.length === 1;
  const showStoreCard =
    openingHoursToShow?.title ||
    mapDataToShow?.Address ||
    detailsToShow?.email ||
    detailsToShow?.phone;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    let observer: IntersectionObserver;
    try {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setMapInView(true);
          }
        });
      }, options);

      if (mapContainerRef.current) {
        observer.observe(mapContainerRef.current);
      }
    } catch (e) {
      setMapInView(true);
    }

    return () => {
      if (mapContainerRef.current && observer) {
        observer?.unobserve(mapContainerRef.current);
      }
    };
  }, []);

  return (
    <>
      {(showTitle || showSubTitle) && (
        <StyledTitleContainer id="locations" data-fd="store-locations-section">
          {showTitle && (
            <Typography variant="h2Display">
              {translate('Locations')}
            </Typography>
          )}
          {showSubTitle && (
            <Typography
              className="locations-widget-subtitle"
              variant="body1Medium"
            >
              {subTitle}
            </Typography>
          )}
        </StyledTitleContainer>
      )}

      <StyledCard
        ownerState={{
          borderRadius: listBorderRadius,
        }}
      >
        <Grid container>
          {!onlyOneStore && (
            <StyledListGrid
              item
              xs={12}
              sm={12}
              md={4}
              order={{ xs: 2, sm: 2, md: 1 }}
            >
              <LoadMoreVertical
                onClick={() =>
                  setRowsToDisplay(rowsToDisplay + paginationIncrement)
                }
                showLoadMore={(storesToShow?.length || 0) > rowsToDisplay}
                loadMoreText={translate('See_more_locations')}
                listItems={getStoreList() || []}
                height={isMobile ? 'fit-content' : '500px'}
                maxHeight={isMobile ? '425px' : ''}
                buttonBorderRadius={buttonBorderRadius}
                buttonTextColor={buttonTextColor}
              />
            </StyledListGrid>
          )}
          <StyledMapGrid
            item
            xs={12}
            sm={12}
            md={onlyOneStore ? 12 : 8}
            order={{ sx: 1, sm: 1, md: 2 }}
          >
            <StyledMapDiv ref={mapContainerRef}>
              {mapInView ? (
                <MapSection
                  selectedStore={selectedStore}
                  isMobile={isMobile}
                  onClick={setSelectedStore}
                />
              ) : (
                <StyledCircularProgressContainer>
                  <StyledCircularProgress
                    aria-label={translate('Loading')}
                    size={60}
                  />
                </StyledCircularProgressContainer>
              )}
            </StyledMapDiv>
            {showStoreCard && (
              <StyledLocationCardContainer
                className={`locations-widget-store-card-${
                  detailsToShow?.storeId || ''
                }`}
                ownerState={{
                  borderRadius: buttonBorderRadius,
                  cardBorderRadius,
                }}
              >
                <LocationCard
                  contactUsUrl={contactUsUrl}
                  reservationsUrl={reservationsUrl}
                  isOpen={isOpen}
                  isOpenText={openTimeDescription}
                  name={openingHoursToShow?.title || ''}
                  collectionHours={formattedCollectionHours}
                  deliveryHours={formattedDeliveryHours}
                  address={mapDataToShow?.Address}
                  email={detailsToShow?.email}
                  phone={detailsToShow?.phone}
                />
              </StyledLocationCardContainer>
            )}
          </StyledMapGrid>
        </Grid>
      </StyledCard>
    </>
  );
};

export default LocationsWidget;
