import { type LoggerType } from '@Src/providers/LoggerProvider';
import { datadogLogs } from '@datadog/browser-logs';
import * as Constants from '@Src/BuildConstants';

export const initialise = (env: string = 'staging'): void => {
  // Details of implementation: https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
  datadogLogs.init({
    beforeSend: (log) => {
      log.user = {};
    },
    clientToken: Constants.DD_CLIENT_TOKEN || '',
    forwardErrorsToLogs: false,
    sampleRate: 100,
    service: 'photon',
    site: 'us3.datadoghq.com',
    telemetrySampleRate: 0,
    version: Constants.BUILD_NUM,
    env,
  });
};

export type LogTypes = 'debug' | 'error' | 'info' | 'log' | 'warn';

const sendLog = (mode: LogTypes, message: string, meta?: object): void => {
  const payload = {
    version: Constants.BUILD_NUM,
    ...meta,
  };
  datadogLogs.logger[mode](message, payload);
};

export const logger: LoggerType = {
  debug: (message: string, data?: object): void =>
    sendLog('debug', message, data),
  error: (message: string, data?: object): void =>
    sendLog('error', message, data),
  info: (message: string, data?: object): void =>
    sendLog('info', message, data),
  log: (message: string, data?: object): void => sendLog('log', message, data),
  warn: (message: string, data?: object): void =>
    sendLog('warn', message, data),
};
