import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactComponent as TimeIcon } from '@Src/assets/images/time.svg';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import {
  selectHasSingleStoreTypeOnly,
  selectSelectedStoreId,
} from '@Src/selectors/stores.selectors';
import { useEffect, useMemo } from 'react';
import { fetchStoreLeadTimes } from '@Src/thunks/storeLeadTimesThunks';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { useTranslation } from '@Src/providers/TranslationProvider';
import useApi from '@Src/hooks/useApi';

const StyledContainer = styled('div')<{
  ownerState: { isToggleHidden?: boolean };
}>(({ ownerState, theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: ownerState.isToggleHidden
    ? theme.spacing(2.25)
    : theme.spacing(1.25),
  marginLeft: theme.spacing(3),
  borderRadius: '16px',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2),
    marginTop: ownerState.isToggleHidden
      ? theme.spacing(1.5)
      : theme.spacing(1.5),
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.extraLowContrast,
  color: theme.palette.text.primary,
  borderRadius: '12px',
  paddingTop: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

export const LeadTime = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { translate } = useTranslation();
  const { get } = useApi();

  const isToggleHidden = useAppSelector(selectHasSingleStoreTypeOnly);
  const selectedStoreId = useAppSelector(selectSelectedStoreId);
  const storeLeadTimes = useAppSelector(
    (state) => state.storeLeadTimes.leadTimes,
  );
  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );

  useEffect(() => {
    if (selectedStoreId && !storeLeadTimes?.[selectedStoreId]) {
      void dispatch(fetchStoreLeadTimes({ storeId: selectedStoreId, get }));
    }
  }, [dispatch, selectedStoreId, storeLeadTimes]);

  const time = useMemo(() => {
    if (selectedStoreId && storeLeadTimes?.[selectedStoreId]) {
      const leadTime =
        storeLeadTimes[selectedStoreId]?.[
          isCollection ? 'collection' : 'delivery'
        ];
      if (leadTime && !isNaN(leadTime)) {
        const range = `${leadTime}-${leadTime + 10}`;
        return `${range} ${translate('mins')}`;
      }
    }
  }, [isCollection, storeLeadTimes, selectedStoreId, translate]);

  if (time) {
    return (
      <StyledContainer ownerState={{ isToggleHidden }}>
        <StyledDiv>
          <TimeIcon width={16} height={16} />
          <StyledTypography variant="finePrint2Medium">{time}</StyledTypography>
        </StyledDiv>
      </StyledContainer>
    );
  }

  return <></>;
};
