const daysInJsOrder = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getTodaysDay = (): string => {
  const date = new Date();
  const day = date.getDay();
  return daysInJsOrder[day];
};

export const getOpenTimeDescription = (
  isOpen = false,
  openUntilTime = '',
  translate: (key: string) => string,
): string => {
  /* 
    If the store is open and the openUntilTime is Closed, 
    then we should show the store as Open.
    PickupHours and DeliveryHours are not correctly implemented
    in the response GET /v1.0/${appId}/stores to represent the
    store hour overrides on Portal.
    TODO: CG-765 use C-API to get OpeningHours for LocationsWidget
  */
  const isStoreOverrideOpen = isOpen && openUntilTime === 'Closed';
  if (isStoreOverrideOpen) {
    return translate('Open');
  }

  const isStoreOpen = isOpen && openUntilTime !== 'Closed';
  if (isStoreOpen) {
    return `${translate('Open_until')} ${openUntilTime || ''}`;
  }

  return translate('Closed');
};
