import Card from '@Components/ui/Card';
import { styled } from '@mui/material/styles';
import Button from '@Components/ui/Button';
import { Typography } from '@mui/material';

const StyledCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.palette.secondary.lowContrast};

  & > div {
    align-items: stretch;
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;
const StyledTopSection = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledBottomSection = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDescription = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0;
`;

const StyledPrice = styled(Typography)`
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 8px;
`;

type StyledActionButtonProps = {
  primaryColor: string;
  textColor?: string;
};

const StyledActionButton = styled(Button)<StyledActionButtonProps>`
  margin-left: auto;
  color: ${(props) => props.textColor || props.primaryColor};
  border-color: ${(props) => props.primaryColor};
  background-color: transparent;
  margin-left: 8px;
`;

export type Props = {
  action: () => void;
  actionText: string;
  primaryColor: string;
  description: string;
  price: string;
  id: number;
  buttonBorderRadius?: string;
  cardBorderRadius?: string;
  buttonBorderWidth?: string;
  cardBorderWidth?: string;
  cardBackgroundColor?: string;
  reorderTextColor?: string;
  style?: Record<string, string>;
  cardWidth: string;
};

export function ReorderCard(props: Props): JSX.Element {
  const {
    action,
    actionText,
    description,
    price,
    primaryColor,
    id,
    buttonBorderRadius,
    buttonBorderWidth,
    cardBorderRadius,
    cardBorderWidth,
    cardBackgroundColor,
    reorderTextColor,
    style = {},
    cardWidth,
  } = props;
  return (
    <StyledCard
      className="reorder-card"
      cardStyles={{
        ...style,
        borderRadius: style?.borderRadius || cardBorderRadius,
        backgroundColor: style?.backgroundColor || cardBackgroundColor,
        borderWidth: cardBorderWidth || '1px',
        textColor: reorderTextColor,
      }}
      fdKey="reorder"
      width={cardWidth}
    >
      <StyledTopSection>
        {description && (
          <StyledDescription variant="caption">{description}</StyledDescription>
        )}
      </StyledTopSection>

      <StyledBottomSection>
        {price && <StyledPrice variant="caption">{price}</StyledPrice>}
        <StyledActionButton
          styles={{
            ...style,
            borderRadius: buttonBorderRadius,
            borderWidth: buttonBorderWidth || '1px',
          }}
          fdKey={`${id}-reorder-button`}
          primaryColor={primaryColor}
          textColor={reorderTextColor}
          ariaLabel={actionText}
          actionText={actionText}
          action={action}
        />
      </StyledBottomSection>
    </StyledCard>
  );
}
