import { type ReactElement } from 'react';

const AppStoreBadge = (): ReactElement => (
  <svg
    width="120"
    height="40"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4846_2946)">
      <path
        d="M110.135 7.92969e-06H9.53468C9.16798 7.92969e-06 8.80568 7.92975e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.17961 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47235 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.304641 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6959 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.145 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53614C119.664 9.16993 119.664 8.80665 119.656 8.44435C119.656 8.13771 119.656 7.83009 119.652 7.52345C119.64 6.85243 119.578 6.18324 119.467 5.52145C119.358 4.85929 119.149 4.21751 118.848 3.61813C118.23 2.4152 117.252 1.43603 116.049 0.818328C115.448 0.515575 114.804 0.304248 114.141 0.191328C113.48 0.0811107 112.811 0.0220157 112.141 0.0145679C111.836 0.00968793 111.523 0.00382793 111.219 0.00187793C110.859 -0.00012207 110.494 -0.00012207 110.135 -0.00012207V7.92969e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.44481 39.125C8.14013 39.125 7.84281 39.1211 7.54052 39.1143C6.9143 39.1061 6.28954 39.0516 5.67138 38.9512C5.09497 38.8519 4.53658 38.6673 4.01464 38.4033C3.49748 38.1415 3.02579 37.7983 2.61764 37.3867C2.20358 36.98 1.85885 36.5082 1.59713 35.9902C1.33252 35.4688 1.14939 34.9099 1.05413 34.333C0.95125 33.7131 0.89559 33.0863 0.887626 32.458C0.881286 32.2471 0.872986 31.5449 0.872986 31.5449V8.44434C0.872986 8.44434 0.881826 7.75293 0.887676 7.5498C0.895302 6.92248 0.950638 6.29665 1.05321 5.67773C1.14864 5.09925 1.33191 4.53875 1.59667 4.01563C1.85743 3.49794 2.20024 3.02586 2.61181 2.61768C3.02291 2.20562 3.49611 1.8606 4.01415 1.59521C4.53489 1.33209 5.09222 1.14873 5.66747 1.05127C6.28766 0.949836 6.91459 0.894996 7.54298 0.88721L8.4453 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.606 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.71 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44481 39.125Z"
        fill="black"
      />
      <path
        d="M24.7689 20.3007C24.7796 19.466 25.0013 18.6477 25.4134 17.9217C25.8254 17.1957 26.4143 16.5858 27.1254 16.1486C26.6737 15.5035 26.0777 14.9725 25.3849 14.598C24.6921 14.2234 23.9215 14.0156 23.1343 13.991C21.455 13.8147 19.8271 14.9958 18.9714 14.9958C18.0991 14.9958 16.7816 14.0085 15.3629 14.0376C14.4452 14.0673 13.5509 14.3341 12.767 14.8122C11.9831 15.2903 11.3364 15.9632 10.89 16.7655C8.95595 20.1139 10.3985 25.035 12.2511 27.7416C13.178 29.0669 14.2613 30.5474 15.6788 30.4949C17.0658 30.4374 17.5839 29.6104 19.2582 29.6104C20.9169 29.6104 21.403 30.4949 22.8492 30.4615C24.3376 30.4374 25.2753 29.1303 26.1697 27.7924C26.8357 26.848 27.3481 25.8043 27.6881 24.6999C26.8234 24.3341 26.0855 23.722 25.5664 22.9397C25.0472 22.1574 24.7699 21.2396 24.7689 20.3007Z"
        fill="white"
      />
      <path
        d="M22.0373 12.2109C22.8488 11.2367 23.2486 9.98451 23.1518 8.72028C21.912 8.8505 20.7667 9.44306 19.9442 10.3799C19.5421 10.8376 19.2341 11.37 19.0378 11.9468C18.8416 12.5235 18.7609 13.1333 18.8005 13.7413C19.4206 13.7477 20.0341 13.6132 20.5948 13.3482C21.1555 13.0831 21.6487 12.6942 22.0373 12.2109Z"
        fill="white"
      />
      <path
        d="M42.3022 27.1396H37.5688L36.4321 30.4961H34.4272L38.9106 18.0781H40.9936L45.477 30.4961H43.438L42.3022 27.1396ZM38.0591 25.5908H41.8111L39.9615 20.1435H39.9097L38.0591 25.5908Z"
        fill="white"
      />
      <path
        d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3813 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.177 22.0316 49.6847 21.7497C50.1923 21.4679 50.7668 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3022 22.9316 50.8569 22.9316C49.437 22.9316 48.4819 24.1621 48.4819 25.9697C48.4819 27.7939 49.437 29.0156 50.8569 29.0156C52.3022 29.0156 53.2495 27.8193 53.2495 25.9697Z"
        fill="white"
      />
      <path
        d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7705 30.621 60.198 30.4883 59.6942 30.2082C59.1905 29.9281 58.7757 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4424H58.395V22.9483H58.4292C58.7201 22.4458 59.1418 22.0316 59.6495 21.7498C60.1571 21.4679 60.7317 21.329 61.312 21.3477C63.6099 21.3477 65.1245 23.1641 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9317 60.8218 22.9317C59.4018 22.9317 58.4468 24.1621 58.4468 25.9697C58.4468 27.794 59.4018 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8194 63.2144 25.9697H63.2144Z"
        fill="white"
      />
      <path
        d="M71.7104 27.0361C71.8481 28.2676 73.0444 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1572C77.3726 26.1933 76.6929 25.6162 75.0835 25.2207L73.4741 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4853C70.1353 19.3427 72.0024 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4853H77.2612C77.1489 20.246 76.1245 19.498 74.6274 19.498C73.1303 19.498 72.1059 20.2548 72.1059 21.3564C72.1059 22.2343 72.7602 22.7509 74.3608 23.1464L75.729 23.4823C78.2768 24.0849 79.3354 25.1083 79.3354 26.9247C79.3354 29.248 77.4848 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7104 27.0361Z"
        fill="white"
      />
      <path
        d="M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4477 29.042C84.6521 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.3729 30.5543 84.0268 30.5478C82.1938 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z"
        fill="white"
      />
      <path
        d="M86.065 25.9697C86.065 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.7261 30.6084 86.065 28.8262 86.065 25.9697ZM92.7603 25.9697C92.7603 24.0156 91.8648 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8648 29.0762 92.7602 27.9316 92.7602 25.9697H92.7603Z"
        fill="white"
      />
      <path
        d="M96.186 21.4424H97.9585V22.9834H98.0015C98.1214 22.5021 98.4034 22.0768 98.8 21.7789C99.1966 21.481 99.6836 21.3287 100.179 21.3476C100.393 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.981 23.043C99.711 23.032 99.4418 23.0796 99.192 23.1825C98.9421 23.2854 98.7175 23.4411 98.5335 23.639C98.3496 23.8369 98.2106 24.0723 98.1262 24.3289C98.0417 24.5856 98.0139 24.8575 98.0444 25.126V30.4961H96.186L96.186 21.4424Z"
        fill="white"
      />
      <path
        d="M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8933 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348V25.1348Z"
        fill="white"
      />
      <path
        d="M39.3926 14.6978H35.6709V8.73102H36.5977V13.8457H39.3926V14.6978Z"
        fill="white"
      />
      <path
        d="M40.3291 13.4243C40.3291 12.6138 40.9327 12.1465 42.0039 12.0801L43.2237 12.0098V11.6211C43.2237 11.1455 42.9092 10.877 42.3018 10.877C41.8057 10.877 41.462 11.0591 41.3633 11.3774H40.503C40.5938 10.604 41.3213 10.1079 42.3428 10.1079C43.4717 10.1079 44.1084 10.6699 44.1084 11.6211V14.6978H43.253V14.0649H43.1827C43.0399 14.2919 42.8395 14.477 42.6019 14.6012C42.3643 14.7254 42.098 14.7843 41.8301 14.7719C41.6411 14.7916 41.45 14.7714 41.2693 14.7127C41.0885 14.654 40.922 14.5581 40.7806 14.4311C40.6392 14.3042 40.526 14.149 40.4482 13.9756C40.3704 13.8021 40.3299 13.6144 40.3291 13.4243ZM43.2237 13.0395V12.6631L42.124 12.7334C41.5039 12.7749 41.2226 12.9858 41.2226 13.3828C41.2226 13.7881 41.5742 14.0239 42.0576 14.0239C42.1993 14.0383 42.3424 14.024 42.4784 13.9819C42.6144 13.9398 42.7405 13.8708 42.8493 13.7789C42.9581 13.6871 43.0473 13.5743 43.1116 13.4473C43.1759 13.3202 43.214 13.1816 43.2237 13.0395Z"
        fill="white"
      />
      <path
        d="M45.2764 12.4444C45.2764 11.0215 46.0078 10.1201 47.1455 10.1201C47.427 10.1072 47.7062 10.1746 47.9508 10.3145C48.1953 10.4544 48.395 10.661 48.5264 10.9101H48.5928V8.43701H49.4815V14.6978H48.6299V13.9863H48.5596C48.418 14.2338 48.2114 14.4378 47.9621 14.5763C47.7129 14.7148 47.4305 14.7825 47.1455 14.772C46 14.772 45.2764 13.8706 45.2764 12.4444ZM46.1944 12.4444C46.1944 13.3994 46.6446 13.9741 47.3975 13.9741C48.1465 13.9741 48.6094 13.3911 48.6094 12.4483C48.6094 11.5098 48.1417 10.9185 47.3975 10.9185C46.6494 10.9185 46.1944 11.4971 46.1944 12.4444H46.1944Z"
        fill="white"
      />
      <path
        d="M54.7471 13.4819C54.6264 13.8935 54.3648 14.2495 54.0081 14.4876C53.6514 14.7258 53.2223 14.8309 52.7959 14.7847C52.4993 14.7925 52.2045 14.7357 51.932 14.6182C51.6595 14.5006 51.4158 14.3252 51.2179 14.1041C51.02 13.8829 50.8726 13.6214 50.7859 13.3375C50.6992 13.0537 50.6753 12.7544 50.7159 12.4605C50.6764 12.1656 50.7007 11.8656 50.7872 11.581C50.8736 11.2963 51.0203 11.0335 51.2172 10.8105C51.414 10.5874 51.6565 10.4092 51.9282 10.288C52.1999 10.1668 52.4945 10.1054 52.792 10.1079C54.045 10.1079 54.8008 10.9639 54.8008 12.3779V12.688H51.6211V12.7378C51.6072 12.9031 51.628 13.0694 51.6821 13.2261C51.7362 13.3829 51.8225 13.5266 51.9354 13.6481C52.0483 13.7695 52.1854 13.866 52.3378 13.9314C52.4902 13.9968 52.6545 14.0297 52.8204 14.0278C53.0329 14.0533 53.2483 14.0151 53.439 13.9178C53.6298 13.8206 53.7874 13.6689 53.8916 13.4819L54.7471 13.4819ZM51.6211 12.0308H53.8955C53.9067 11.8796 53.8862 11.7278 53.8352 11.5851C53.7843 11.4424 53.704 11.3119 53.5996 11.2021C53.4953 11.0922 53.369 11.0054 53.2291 10.9472C53.0892 10.8891 52.9386 10.8608 52.7871 10.8643C52.6334 10.8623 52.4809 10.8912 52.3385 10.9491C52.1962 11.0071 52.0668 11.0929 51.9582 11.2017C51.8495 11.3104 51.7637 11.4397 51.7058 11.5821C51.6479 11.7245 51.6191 11.8771 51.6211 12.0308H51.6211Z"
        fill="white"
      />
      <path
        d="M55.9942 10.1948H56.8496V10.9101H56.916C57.0287 10.6532 57.2187 10.4378 57.4595 10.294C57.7004 10.1502 57.9802 10.0852 58.2598 10.1079C58.4789 10.0914 58.6989 10.1244 58.9035 10.2045C59.1081 10.2846 59.292 10.4097 59.4417 10.5706C59.5914 10.7314 59.703 10.9239 59.7682 11.1337C59.8334 11.3435 59.8505 11.5653 59.8184 11.7827V14.6977H58.9297V12.0058C58.9297 11.2822 58.6152 10.9223 57.958 10.9223C57.8093 10.9154 57.6608 10.9407 57.5227 10.9966C57.3846 11.0524 57.2603 11.1374 57.1582 11.2458C57.056 11.3542 56.9786 11.4834 56.931 11.6245C56.8835 11.7656 56.8671 11.9154 56.8828 12.0635V14.6977H55.9941L55.9942 10.1948Z"
        fill="white"
      />
      <path
        d="M63.5196 8.8633C63.5127 8.7482 63.5406 8.63369 63.5996 8.53461C63.6585 8.43553 63.7459 8.35644 63.8503 8.30759C63.9548 8.25873 64.0715 8.24237 64.1853 8.26061C64.2992 8.27885 64.405 8.33085 64.4889 8.40989C64.5729 8.48892 64.6311 8.59135 64.6562 8.70389C64.6813 8.81644 64.672 8.93392 64.6295 9.04112C64.5871 9.14832 64.5134 9.24029 64.418 9.30514C64.3227 9.36998 64.2101 9.4047 64.0948 9.4048C64.0211 9.40856 63.9474 9.39737 63.8781 9.37192C63.8088 9.34647 63.7454 9.30727 63.6917 9.25669C63.638 9.20611 63.595 9.14518 63.5655 9.07758C63.5359 9.00998 63.5203 8.93709 63.5196 8.8633ZM63.6524 10.1948H64.5372V14.6978H63.6524V10.1948Z"
        fill="white"
      />
      <path
        d="M65.9712 10.1948H66.8267V10.9185H66.8931C67.0002 10.6772 67.1749 10.4722 67.3962 10.3284C67.6175 10.1845 67.8758 10.1079 68.1397 10.1079C68.4036 10.1079 68.6619 10.1845 68.8832 10.3284C69.1044 10.4722 69.2792 10.6772 69.3863 10.9185H69.4566C69.5823 10.6657 69.7784 10.4547 70.0213 10.3108C70.2643 10.167 70.5436 10.0965 70.8257 10.1079C71.0231 10.0918 71.2215 10.1196 71.4068 10.1894C71.5921 10.2592 71.7596 10.3693 71.8972 10.5116C72.0348 10.654 72.1391 10.8251 72.2025 11.0127C72.266 11.2002 72.2871 11.3995 72.2642 11.5962V14.6978H71.3755V11.8281C71.3755 11.2202 71.0855 10.9224 70.5025 10.9224C70.3769 10.917 70.2516 10.9376 70.1343 10.983C70.0171 11.0284 69.9105 11.0975 69.8213 11.1861C69.732 11.2746 69.6621 11.3807 69.6158 11.4976C69.5695 11.6144 69.5479 11.7396 69.5523 11.8653V14.6978H68.6793V11.7412C68.6849 11.6279 68.6659 11.5148 68.6235 11.4095C68.5812 11.3043 68.5165 11.2095 68.434 11.1317C68.3515 11.0538 68.2531 10.9948 68.1455 10.9587C68.038 10.9226 67.9239 10.9102 67.8112 10.9224C67.6809 10.9245 67.5523 10.9529 67.4333 11.0059C67.3142 11.0589 67.2071 11.1354 67.1183 11.2308C67.0294 11.3261 66.9608 11.4384 66.9164 11.561C66.8721 11.6835 66.8529 11.8137 66.86 11.9439V14.6978H65.9713L65.9712 10.1948Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4846_2946">
        <rect width="119.664" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreBadge;
