/* eslint-disable react/forbid-elements */
type ImgProps = {
  srcDesktop: string;
  srcMobile: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const ImgixResponsiveImage = (props: ImgProps): JSX.Element => {
  const { srcDesktop, srcMobile, ...imgProps } = props;

  const srcSetMobile = [1, 2, 3]
    .map(
      (n) => `${srcMobile}&upscale=true&auto=format&dpr=${n}&q=${90 / n} ${n}x`,
    )
    .join(', ');
  const srcSetDesktop = [1, 2, 3]
    .map(
      (n) =>
        `${srcDesktop}&upscale=true&fit=crop&auto=format&dpr=${n}&q=${
          90 / n
        } ${n}x`,
    )
    .join(', ');

  return (
    <picture>
      <source media="(max-width: 768px)" srcSet={srcSetMobile} />
      <source media="(min-width: 769px)" srcSet={srcSetDesktop} />
      <img {...imgProps} src={srcMobile} />
    </picture>
  );
};
