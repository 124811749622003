import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useTranslation } from '@Providers/TranslationProvider';
import MenuPreviewItem from './MenuPreviewItem';
import styled from 'styled-components';
import FadeInContainer from '../FadeInContainer';
import { formatPrice } from '../../../utility/priceUtils';
import { Link } from '@Components/RoutesUtils';
import { useConfig } from '@Src/providers/ConfigProvider';
import { ImgixImage } from '@Components/ui/ImgixImage';
import { useTracking } from '@Src/hooks/useTracking';
import { useGetMenuDataFromStoreId } from '@Src/hooks/useGetMenuDataFromStoreId';
import { formatMenuApiResponse } from './menuUtil';
import { type MenuPreviewSection } from './MenuAPITypes';

const StyledSectionContainer = styled.div`
  padding-left: 34px;
  padding-right: 34px;
`;

const StyledSectionTitle = styled.h3<{ $primaryColor: string }>`
  color: ${(props) => props.$primaryColor};
`;

const StyledOrderLink = styled(Link)<{ $primaryColor: string }>`
  && {
    color: ${(props) => props.$primaryColor};
    border: 2px solid ${(props) => props.$primaryColor};
    background: transparent;
    &:hover {
      background: ${(props) => props.$primaryColor};
    }
  }
`;

const MenuPreview = (): JSX.Element => {
  const { logo, appDesignScheme, currencies } = useDesignSchema('v1');
  const { language } = useConfig();
  const { translate } = useTranslation();
  const { trackEvent } = useTracking();
  const currency = currencies?.[0] || 'EUR';
  const menuPreviewSections = useGetMenuDataFromStoreId(
    formatMenuApiResponse,
  ) as MenuPreviewSection[];
  const { Primary: primary } = appDesignScheme;

  const trackClick = (): void => {
    trackEvent('Click to order', {
      category: 'Menu',
      type: 'Start Order',
    });
  };

  if (!menuPreviewSections || menuPreviewSections?.length === 0) {
    return <></>;
  }

  const renderSections = (): JSX.Element[] =>
    (menuPreviewSections || []).map((section) => (
      <div key={section.sectionId} className="col-md-4 jt_col column_container">
        <StyledSectionTitle $primaryColor={primary}>
          {section.title}
        </StyledSectionTitle>
        <ul>
          {section.items.map((item) => {
            const formattedPrice = formatPrice(
              item.price,
              currency,
              language,
              true,
              'narrowSymbol',
            );
            return (
              <div key={item.itemId}>
                <MenuPreviewItem
                  description={item.description}
                  isFromPrice={item.isFromPrice}
                  price={formattedPrice}
                  title={item.title}
                />
              </div>
            );
          })}
        </ul>
      </div>
    ));
  const logoUrl = logo.includes('w=')
    ? logo
    : logo.includes('?')
    ? `${logo}&w=250`
    : `${logo}?w=250`;
  return (
    <section id="menu" className="section menu">
      <div className="container">
        <FadeInContainer className="jt_row jt_row-fluid row visible">
          <div className="col-md-12 jt_col column_container">
            <div className="voffset100"></div>
            <Link to="/order" aria-label="Start your order">
              <div className="container menu-logo-container">
                <ImgixImage
                  className="center menu-logo"
                  height="100"
                  src={logoUrl}
                  alt="Our logo, click here to start your order"
                  loading="lazy"
                />
              </div>
            </Link>
            <div className="voffset60"></div>
          </div>

          <StyledSectionContainer>{renderSections()}</StyledSectionContainer>
          <div className="col-md-4 col-md-offset-4 jt_col column_container">
            <StyledOrderLink
              to="/order"
              className="button menu center"
              id="order-online-button"
              onClick={trackClick}
              $primaryColor={primary}
            >
              {translate('Order_Online')}
            </StyledOrderLink>
          </div>
        </FadeInContainer>
      </div>
      <div className="voffset30"></div>
    </section>
  );
};

export default MenuPreview;
