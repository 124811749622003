import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { logger } from '@Src/utility/loggerService';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Button, Typography } from '@mui/material';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useCountdown } from '@Src/hooks/useCountdown';
import useApi from '@Src/hooks/useApi';

const StyledButton = styled(Button)`
  text-transform: none;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type Props = {
  onError?: (error: Error) => void;
  onSubmit?: () => void;
  phoneNumber: string;
};

export function PhoneCallButton(props: Props): JSX.Element {
  const { onError, onSubmit, phoneNumber } = props;
  const { appId, version } = useConfig();
  const { translate } = useTranslation();
  const isCallRequested = useRef(false);
  const isSubmitting = useRef(false);
  const { post } = useApi();
  const [countdown, startCountdownAgain] = useCountdown(30);

  const callRequestMessage = isCallRequested.current
    ? translate('You_will_recieve_your_call_soon')
    : translate('Didnt_get_code');

  const handleRequestPhoneCall = (): void => {
    isSubmitting.current = true;
    isCallRequested.current = true;

    if (onSubmit) {
      onSubmit();
    }
    const payload = {
      Fallback: 'PhoneCall',
      PhoneNumber: phoneNumber,
    };
    post<typeof payload, number>({
      path: '/Account/RequestPhoneLoginCodeSms',
      payload,
      customHeaders: {
        'Content-Type': 'application/json',
        'Flipdish-App-Type': 'Web',
        'Flipdish-App-Version': version || '',
        'Flipdish-White-Label-Id': appId,
      },
    })
      .then(() => {
        startCountdownAgain();
      })
      .catch((error) => {
        console.log('error');
        logger.error(
          'API Request Error - PhoneCallButton RequestPhoneLoginCodeSms',
          {
            ...error,
          },
        );

        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        isSubmitting.current = false;
      });
  };

  const getRequestPhoneCallButtonText = (): string => {
    const callStatusMessage = isCallRequested.current
      ? 'Request_phone_call_again_in'
      : 'Request_phone_call_in';

    if (countdown > 0) {
      return `${translate(callStatusMessage)} ${countdown}s`;
    }

    return `${translate('Request_code_via_phone_call')}`;
  };

  return (
    <>
      <Typography variant="body2">{callRequestMessage}</Typography>
      <StyledButton
        data-fd="request-call"
        disabled={countdown > 0 || isSubmitting.current}
        onClick={handleRequestPhoneCall}
        size="small"
        variant="outlined"
      >
        {getRequestPhoneCallButtonText()}
      </StyledButton>
    </>
  );
}
