import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { selectServiceUnavailableText } from '@Src/selectors/stores.selectors';

const StyledContainer = styled('div')<{
  ownerState: { marginBottom?: string; marginLeft?: string; width?: string };
}>(({ ownerState, theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  marginTop: theme.spacing(0.75),
  marginLeft: ownerState.marginLeft || theme.spacing(3),
  borderRadius: '27px',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: ownerState.marginBottom || theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: ownerState.width || '100%',
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.extraLowContrast,
  borderRadius: '27px',
  paddingTop: theme.spacing(0.5),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
}));

const StyledMessage = styled(Typography)(({ theme }) => ({
  margin: 0,
  color: theme.palette.secondary.body,
}));

type ServiceUnavailableProps = {
  marginBottom?: string;
  marginLeft?: string;
  width?: string;
};

export const ServiceUnavailable = ({
  marginBottom,
  marginLeft,
  width,
}: ServiceUnavailableProps): JSX.Element => {
  const { translate } = useTranslation();
  const text = useAppSelector(selectServiceUnavailableText);

  if (text?.headerText && text?.message) {
    return (
      <StyledContainer ownerState={{ marginBottom, marginLeft, width }}>
        <StyledDiv>
          <StyledHeader variant="finePrint2Medium" paragraph>
            {translate(text.headerText)}
          </StyledHeader>
          <StyledMessage variant="finePrint2" paragraph>
            {translate(text.message)}
          </StyledMessage>
        </StyledDiv>
      </StyledContainer>
    );
  }
  return <></>;
};
