import { useEffect } from 'react';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { fetchCollectionStoreSummaries } from '@Src/thunks/collectionStoresThunks';
import { fetchDeliveryAddresses } from '@Src/thunks/deliveryAddressesThunks';
import { fetchIpInfo } from '@Src/thunks/locationThunks';
import { fetchDeliveryStoreSummaries } from '@Src/thunks/deliveryStoresThunks';
import { useConfig } from '@Src/providers/ConfigProvider';
import useApi from './useApi';

export const useOrderSettingSetup = (): void => {
  const { appId, language, isLocal } = useConfig();
  const { get } = useApi();
  const fetched = useAppSelector((state) => state.collectionStores.fetched);
  const loading = useAppSelector((state) => state.collectionStores.loading);

  const locationFetched = useAppSelector((state) => state.location.fetched);
  const locationLoading = useAppSelector((state) => state.location.loading);

  const deliveryStoresFetched = useAppSelector(
    (state) => state.deliveryStores.fetched,
  );
  const deliveryStoresLoading = useAppSelector(
    (state) => state.deliveryStores.loading,
  );
  const addressesFetched = useAppSelector(
    (state) => state.deliveryAddresses.fetched,
  );
  const addressesLoading = useAppSelector(
    (state) => state.deliveryAddresses.loading,
  );
  const dispatch = useAppDispatch();

  // get location based on ip info, used in both flows
  useEffect(() => {
    if (!locationFetched && !locationLoading) {
      void dispatch(fetchIpInfo(!!isLocal));
    }
  }, [locationFetched, locationLoading]);

  // Get collection stores after location coordinates are fetched and collection is selected
  useEffect(() => {
    if (locationFetched && !fetched && !loading) {
      void dispatch(fetchCollectionStoreSummaries({ appId, get }));
    }
  }, [locationFetched, fetched, loading]);

  // Get delivery addresses if not fetched and delivery is selected
  useEffect(() => {
    if (!addressesFetched && !addressesLoading) {
      void dispatch(
        fetchDeliveryAddresses({
          appId,
          lang: language,
          get,
        }),
      );
    }
  }, [addressesFetched, addressesLoading]);

  // Get delivery stores after location and delivery addresses are fetched and delivery is selected
  useEffect(() => {
    if (
      locationFetched &&
      addressesFetched &&
      !deliveryStoresFetched &&
      !deliveryStoresLoading
    ) {
      void dispatch(fetchDeliveryStoreSummaries({ appId, get }));
    }
  }, [
    locationFetched,
    addressesFetched,
    deliveryStoresFetched,
    deliveryStoresLoading,
  ]);
};
