import { useEffect } from 'react';
import {
  Link as RouterLink,
  Navigate,
  type NavLinkProps,
} from 'react-router-dom';
import { useCustomPageSchema } from '@Providers/CustomPageSchemaProvider';
import { useConfig } from '@Src/providers/ConfigProvider';
import {
  type PageSchemaType,
  PageType,
  type CustomPageType,
  type V2CustomPageType,
  V2PageType,
} from '@Src/middlewares/CustomPageTypes';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

type LinkPropsType = Omit<NavLinkProps, 'className' | 'style' | 'children'> & {
  to: string;
  className?: string;
  children: JSX.Element | string;
};

const isInternalPage = (
  customPageSchemas: PageSchemaType,
  href: string = '',
  schemaVersion: 'v1' | 'v2',
): boolean => {
  // Locations page is not in schema but is internal always.
  if (
    [
      '/contact',
      '/#aboutSection',
      '/#gallery',
      '/#reservations',
      '/#contact',
    ].includes(href)
  ) {
    return true;
  }

  const pageSchema = customPageSchemas[href.substring(1)];
  if (!pageSchema) {
    return false;
  }

  if (schemaVersion === 'v1') {
    const v1PageSchema = pageSchema as CustomPageType;
    const pageType = v1PageSchema.PageType;
    if (pageType === PageType.EmptyNoHeaderPage) {
      return false;
    }
    // We need to do a full server side reload of the page
    // for web order to avoid react version conflicts.
    if (pageType === PageType.EmptyPage) {
      const pageHtml = v1PageSchema?.html || '';
      if (pageHtml.includes('flipdish-menu')) {
        return false;
      }
    }
  } else {
    const v2PageSchema = pageSchema as V2CustomPageType;
    const pageType = v2PageSchema.pageType;
    if (pageType === V2PageType.EmptyNoHeaderPage) {
      return false;
    }
    // We need to do a full server side reload of the page
    // for web order to avoid react version conflicts.
    if (pageType === V2PageType.EmptyPage) {
      const pageHtml = v2PageSchema?.html || '';
      if (pageHtml.includes('flipdish-menu')) {
        return false;
      }
    }
  }
  return true;
};

// This component takes care of internal and external routes
export const Link = ({
  to,
  className = '',
  children,
  ...props
}: LinkPropsType): JSX.Element => {
  let href = to || '';
  const customPageSchemas = useCustomPageSchema();
  const { schemaVersion } = useDesignSchema();
  const { appId, isCustomDomain, vanityUrlSuffix } = useConfig();

  if (isInternalPage(customPageSchemas, href, schemaVersion)) {
    const treatedHref = href === '/index' ? '/' : href;
    return (
      <RouterLink className={className} to={treatedHref} {...props}>
        {children}
      </RouterLink>
    );
  }

  const isExternalPage = href.startsWith('http') || href.startsWith('www.');
  if (isExternalPage) {
    href = href.startsWith('www.') ? `https://${href}` : href;
    return (
      //  This handles external navigation
      // eslint-disable-next-line react/forbid-elements
      <a className={className} href={href} {...props}>
        {children}
      </a>
    );
  }

  let prefix = `/${appId}`;
  if (isCustomDomain) {
    prefix = '';
  } else if (vanityUrlSuffix) {
    prefix = `/${vanityUrlSuffix}`;
  }

  return (
    //  This handles relative static pages navigation
    // eslint-disable-next-line react/forbid-elements
    <a className={className} href={`${prefix}${href}`} {...props}>
      {children}
    </a>
  );
};

export const Redirect = ({ to }: { to?: string }): JSX.Element => {
  let url = to || '';
  const pagesSchema = useCustomPageSchema();
  const isInternalPage =
    Object.keys(pagesSchema).includes(url) || url.startsWith('/order');

  useEffect(() => {
    if (!isInternalPage) {
      url = !/^https?:\/\//i.test(url) ? `https://${url}` : url;
      window.location.replace(url);
    }
  }, []);

  if (isInternalPage) {
    return <Navigate replace to={url} />;
  }
  return <></>;
};
