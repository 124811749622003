import { type SupportedMethodsType } from '@Src/hooks/useApi';
import { type DeliveryAddress } from '@Src/types/deliveryAddressTypes';
import { DeviceId } from '@Src/utility/deviceUtils';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';

export type FetchDataParamsType = {
  appId: string | null;
  lang: string;
  get: SupportedMethodsType['get'];
};
export const fetchDeliveryAddresses = createAsyncThunk(
  'deliveryAddresses/fetchDeliveryAddresses',
  async ({ appId, lang, get }: FetchDataParamsType, thunkApi) => {
    try {
      if (!appId) {
        throw new Error('appId is required');
      }

      thunkApi.dispatch({
        type: 'deliveryAddresses/fetchDeliveryAddressesStart',
      });

      const url = '/map/DeliveryLocationForms';
      const deviceId = DeviceId();
      const queryParams = { deviceId, language: lang };
      const { data } = await get<{
        DeliveryLocations: DeliveryAddress[];
      }>({
        path: url,
        params: { deviceId, language: lang },
        suppresCookieHeader: true,
        appType: 'Web',
      });

      if (!data?.Data) {
        logger.error(
          '[fetchDeliveryAddresses]: did not return any data for {AppId}',
          {
            appId,
            url,
            params: queryParams,
            code: data?.Code,
            developerMessage: data?.DeveloperMessage,
            success: data?.Success,
            userMessage: data?.UserMessage,
          },
        );
        return thunkApi.rejectWithValue({
          error: `fetchDeliveryAddresses did not return any data ${
            data?.UserMessage || 'undefined'
          }`,
        });
      }
      return data.Data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error('[fetchDeliveryAddresses]: fetching failed for {AppId}', {
        appId,
        errorMessage: e.message,
      });
      console.log('error', e);
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
