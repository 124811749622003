import {
  V2Templates,
  type LandingPageType,
} from '@Src/middlewares/CustomPageTypes';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import BoxedUp from './boxedUp';
import Dishy from './dishy';
import { Classic } from './classic';

export const V2Template = ({
  data,
}: {
  data: LandingPageType;
}): JSX.Element => {
  const { template } = useDesignSchema('v2');

  const renderTemplate = (renderTemplate?: V2Templates): JSX.Element => {
    switch (renderTemplate) {
      case V2Templates.boxedUp:
        return <BoxedUp data={data} />;
      case V2Templates.classic:
        return <Classic data={data} />;
      default:
        return <Dishy data={data} />;
    }
  };

  return <>{renderTemplate(template)}</>;
};

export default V2Template;
