import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import Grid from '@mui/material/Grid';
import { styled, type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { ReactComponent as HamburgerMenuIcon } from '@Src/assets/images/elixir/hamburger-menu.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Link } from '@Components/RoutesUtils';
import { Drawer } from '@Components/ui/Drawer';
import { ReactComponent as CloseIcon } from '@Src/assets/images/Close.svg';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { WelcomeText } from '../Landing/WelcomeText';
import { useCurrentPage } from '@Src/hooks/useCurrentPage';
import { V2PageType } from '@Src/middlewares/CustomPageTypes';
import { BaseGridPolice, InnerGridWithImagePolice } from '../BaseGridElements';
import { useConfig } from '@Src/providers/ConfigProvider';
import LanguageSelector from './LanguageSelector';

const StyledBurgerMenuIcon = styled(HamburgerMenuIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  width: '24px',
  height: '24px',
  [theme.breakpoints.up('sm')]: {
    width: '32px',
    height: '32px',
  },
}));

const StyledCloseButton = styled(IconButton)`
  color: inherit;
`;

export const StyledLogoLink = styled(Link)`
  justify-content: left;
  overflow: visible;
  display: flex;
`;

const StyledBaseGridPolice = styled(BaseGridPolice, {
  shouldForwardProp: (prop) => prop !== 'shouldOverlap',
})<{
  shouldOverlap?: boolean;
}>(({ shouldOverlap, theme }) => ({
  marginTop: 0,
  ...(shouldOverlap ? { marginBottom: '-100px' } : {}),
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));

const StyledMenuList = styled(MenuList)(() => ({
  '& a': {
    width: '100%',
  },
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.boxedUp.borderRadius.default,
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledHolderGrid = styled(Grid)(({ theme }) => ({
  minHeight: '128px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.boxedUp.borderRadius.mobile,
    position: 'relative',
  },
}));

const StyledLogoDiv = styled(StyledGridItem)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(2),
  },
}));

const StyledLogo = styled('img')(({ theme }) => ({
  borderRadius: '20%',
  height: '48px',
  [theme.breakpoints.up('sm')]: {
    height: '72px',
  },
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginTop: theme.spacing(3.5),

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    paddingLeft: theme.spacing(3),
  },
}));

const StyledMenuDiv = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.boxedUp.borderRadius.default,
  display: 'flex',
  justifyContent: 'space-around',
  minWidth: '104px',
  padding: theme.spacing(0, 4),
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    border: `${theme.spacing(2)} solid ${
      theme.palette.secondary.extraLowContrast || ''
    }`,
    minHeight: '104px',
    padding: theme.spacing(0, 1),
    position: 'absolute',
    right: `-${theme.spacing(2)}`,
    top: `-${theme.spacing(2)}`,
    width: 'fit-content',
  },
}));

const StyledLoginButton = styled('button')`
  border-radius: ${({ theme }) => `${theme.boxedUp.buttonBorderRadius}px`};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => `${theme.palette.primary.contrastText}`};
  padding: 12px 24px;
  text-transform: none;
  margin-right: ${({ theme }) => theme.spacing(3.25)};
  span {
    text-transform: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledNavSection = styled('div')`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => `${theme.boxedUp.borderRadius.default}px`};
  display: flex;
  justify-content: space-around;
  width: 100%;
  .menu {
    display: flex;
    flex-grow: 0;
    gap: ${({ theme }) => theme.spacing(2)};
  }
  .language-selector {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(1.5)};
    .menu {
      gap: ${({ theme }) => theme.spacing(0)};
    }
  }
`;

export const StyledMobileHeaderContainer = styled(InnerGridWithImagePolice)<{
  ownerState: { isLandingPage?: boolean };
}>(({ ownerState, theme }) => ({
  background: 'transparent',
  borderRadius: 0,
  marginBottom: ownerState.isLandingPage ? '-100px' : theme.spacing(2),
  position: 'relative',
  zIndex: 1,
}));

const BoxedUp = ({
  isDrawerOpen,
  handleDrawerOpenChange,
  menuItems,
  onLogin,
  children,
}: {
  isDrawerOpen: boolean;
  handleDrawerOpenChange: (isOpen: boolean) => void;
  menuItems?: JSX.Element[];
  onLogin: () => void;
  children?: JSX.Element;
}): JSX.Element => {
  const { config, imageAttributes } = useDesignSchema('v2');
  const { logo } = config || {};
  const { translate } = useTranslation();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { supportedLanguages } = useConfig();
  const showLanguageSelector = supportedLanguages.length > 1;
  const currentPageData = useCurrentPage();
  const isLandingPage = currentPageData?.pageType === V2PageType.LandingPage;
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const logoAlt = logo
    ? imageAttributes?.[logo]?.alt || 'Our_logo'
    : 'Our_logo';

  const getLogoSectionWidth = (): number => {
    if (isLg && isLoggedIn) return 10;
    if (isLg || isLoggedIn) return 9;
    return 8;
  };

  const getNavSectionWidth = (): number => {
    if (isLg && isLoggedIn) return 2;
    if (isLg || isLoggedIn) return 3;
    return 4;
  };

  let headingText = '';
  if (isLandingPage) {
    if (isLoggedIn) {
      headingText = currentPageData?.loggedInHeading || '';
    } else {
      headingText = currentPageData?.loggedOutHeading || '';
    }
  }

  const hasTitle =
    (!isLandingPage && !!currentPageData?.title) ||
    (isLandingPage &&
      (currentPageData?.brandNameHeadingEnabled || !!headingText));

  const getPageTitle = (): JSX.Element => {
    if (isLandingPage) {
      return <WelcomeText />;
    } else {
      return (
        <Typography variant="h1">
          {translate(currentPageData?.title || '')}
        </Typography>
      );
    }
  };

  const handleLoginClick = (): void => {
    onLogin();
    handleDrawerOpenChange(false);
  };

  const renderNavSection = (): JSX.Element => (
    <StyledNavSection>
      {!isLoggedIn && (
        <StyledLoginButton onClick={handleLoginClick}>
          <Typography variant="button">{translate('Log_in')}</Typography>
        </StyledLoginButton>
      )}
      <Box className="menu">
        {showLanguageSelector && <LanguageSelector />}
        <IconButton
          data-fd="header-button"
          aria-label={translate('Burger_Menu_Label')}
          aria-haspopup="true"
          onClick={() => handleDrawerOpenChange(true)}
        >
          <StyledBurgerMenuIcon />
        </IconButton>
        <Drawer
          data-fd="menu-drawer"
          open={isDrawerOpen}
          onClose={() => handleDrawerOpenChange(false)}
        >
          <StyledCloseButton
            onClick={() => handleDrawerOpenChange(false)}
            data-fd="close-drawer-button"
            aria-label={translate('Close_navigation_menu')}
          >
            <CloseIcon />
          </StyledCloseButton>
          <StyledMenuList>{menuItems}</StyledMenuList>
        </Drawer>
      </Box>
    </StyledNavSection>
  );

  return (
    <header>
      <StyledBaseGridPolice shouldOverlap={isLandingPage}>
        <StyledHolderGrid container columnGap={3} wrap="nowrap">
          <StyledLogoDiv item sm={getLogoSectionWidth()}>
            <StyledLogoLink data-fd="logo" to="/">
              <StyledLogo alt={translate(logoAlt)} src={logo} />
            </StyledLogoLink>
            {hasTitle && (
              <StyledTitleContainer>{getPageTitle()}</StyledTitleContainer>
            )}
          </StyledLogoDiv>
          <StyledMenuDiv item sm={getNavSectionWidth()}>
            {renderNavSection()}
          </StyledMenuDiv>
        </StyledHolderGrid>
        {children}
      </StyledBaseGridPolice>
    </header>
  );
};
export default BoxedUp;
