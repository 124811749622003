import type React from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  type Theme,
} from '@mui/material/styles';

type ThemeProviderProps = {
  children: React.ReactNode;
  theme: Theme;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
