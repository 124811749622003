import useAbly from '@Src/hooks/useAbly';
import { useEffect, useState } from 'react';
import { useApi } from '@Src/hooks/useApi';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useLogger } from '@Src/providers/LoggerProvider';

// #region Types
type FulfillmentStatus = {
  DeliveryTrackingUrl: string | null;
  Description: string;
  EstimatedCompletionDescription: string | null;
  EstimatedCompletionTime: string;
  EstimatedRemainingCompletionMinutes: number;
  ImageUrl: string;
  IsError: boolean;
  StatusId: FulfillmentStatusId;
  StatusName: string;
  StatusPosition: number;
  TotalStatuses: number;
};

export type FulfillmentStatusId =
  | 'pending'
  | 'accepted'
  | 'preparing'
  | 'on-hold'
  | 'ready-to-collect'
  | 'collected'
  | 'on-the-way'
  | 'arrived-at-location'
  | 'delivered'
  | 'cannot-deliver'
  | 'cancelled'
  | 'completed'
  | 'rejected'
  | 'refunded';

type EventData = {
  orderId: string;
  statusId: FulfillmentStatusId;
};
// #endregion

export const activeOrderStatuses: FulfillmentStatusId[] = [
  'accepted',
  'preparing',
  'on-hold',
  'on-the-way',
  'ready-to-collect',
];

export const completedOrderStatuses: FulfillmentStatusId[] = [
  'delivered',
  'cannot-deliver',
  'cancelled',
  'completed',
  'rejected',
  'refunded',
];

const inFligtOrderStatuses: FulfillmentStatusId[] = [
  ...activeOrderStatuses,
  'pending',
];

// #region Local storage
const getOrderIdKey = (appId: string): string => `flipdish-order-id-${appId}`;

const isActiveOrder = (expires: number): boolean => {
  const now = new Date();
  if (now < new Date(expires)) {
    return true;
  }
  return false;
};

const getOrderIdFromStorage = (appId: string): string | undefined => {
  const orderDetails = localStorage.getItem(getOrderIdKey(appId));
  const [orderId, expiryDate] = orderDetails?.split(',') || [];

  if (!orderId || !expiryDate) {
    return;
  }

  if (isActiveOrder(parseInt(expiryDate))) {
    return orderId;
  }

  removeOrderIdFromStorage(appId);
};

const setOrderIdInStorage = ({
  appId,
  orderId,
  expiresInMins,
}: {
  appId: string;
  orderId: string;
  expiresInMins: 10 | 60;
}): void => {
  if (orderId) {
    const expiryDate = Date.now() + expiresInMins * 60 * 1000;
    localStorage.setItem(getOrderIdKey(appId), `${orderId},${expiryDate}`);
  }
};
// #endregion

const removeOrderIdFromStorage = (appId: string): void => {
  localStorage.removeItem(getOrderIdKey(appId));
};

export const useFulfillmentStatusEvents = (): {
  fulfillmentStatus?: FulfillmentStatus;
  orderCreated?: EventData;
} => {
  const [orderStatus, setOrderStatus] = useState<EventData>();
  const logger = useLogger();
  const [orderCreated, setOrderCreated] = useState<EventData>();
  const [fulfillmentStatus, setFulfillmentStatus] =
    useState<FulfillmentStatus>();

  const { ablyClient, loggedInUserId } = useAbly();
  const { get } = useApi();
  const { appId } = useConfig();

  const getFulfillmentStatus = async (
    orderId: string,
  ): Promise<FulfillmentStatus | undefined> => {
    try {
      const response = await get<FulfillmentStatus>({
        path: '/order/fulfillmentstatus',
        params: { orderId },
      });
      const data = response.data?.Data;
      const statusId = data?.StatusId;
      if (
        statusId &&
        completedOrderStatuses.includes(statusId) &&
        ablyClient &&
        loggedInUserId
      ) {
        const createdChannel = ablyClient.channels.get(
          `${appId}:order:created:${loggedInUserId}`,
        );
        createdChannel.unsubscribe();

        const statusChannel = ablyClient.channels.get(
          `${appId}:order:status:${orderId}`,
        );
        statusChannel.unsubscribe();
      }
      return data;
    } catch (e: any) {
      logger.error('Error fetching fulfillment status', {
        appId,
        orderId,
        message: e.message,
      });
    }
  };

  useEffect(() => {
    // Handle previous order or case were user refreshes
    // or navigates away from the page and back again
    // This is checked from local storage.
    const orderId = getOrderIdFromStorage(appId);
    if (orderId) {
      void (async () => {
        const response = await getFulfillmentStatus(orderId);
        const statusId = response?.StatusId;
        if (!statusId) {
          return;
        }
        if (inFligtOrderStatuses.includes(statusId)) {
          setOrderCreated({
            statusId,
            orderId,
          });
        }
        setFulfillmentStatus(response);
      })();
    } else {
      console.log('No orderId found in local storage');
    }
  }, []);

  useEffect(() => {
    // Subscribe to NEW order events
    if (ablyClient && loggedInUserId) {
      const createdChannel = ablyClient.channels.get(
        `${appId}:order:created:${loggedInUserId}`,
      );
      void createdChannel.subscribe((message) => {
        const { orderId, statusId } = message.data.data as EventData;
        setOrderStatus({ orderId, statusId: 'pending' });
        setOrderCreated({ orderId, statusId });
      });
    }
  }, [ablyClient, loggedInUserId]);

  useEffect(() => {
    // Subscribe to order status events - triggered by new order created event or orderId is in local storage and status is not completed
    if (ablyClient && orderCreated?.orderId) {
      const statusChannel = ablyClient.channels.get(
        `${appId}:order:status:${orderCreated?.orderId}`,
      );
      void statusChannel.subscribe((message) => {
        const { orderId, statusId } = message.data.data as EventData;
        setOrderStatus({ orderId, statusId });
      });
    }
  }, [ablyClient, orderCreated]);

  useEffect(() => {
    const statusId = orderStatus?.statusId;
    const orderId = orderStatus?.orderId;
    void (async () => {
      // Fetch fulfillment status from the API cos the event is missing some data
      if (statusId && orderId) {
        switch (statusId) {
          case 'cancelled':
          case 'cannot-deliver':
          case 'completed':
          case 'delivered':
          case 'rejected':
          case 'refunded': {
            setOrderIdInStorage({
              appId,
              expiresInMins: 10,
              orderId,
            });
            const data = await getFulfillmentStatus(orderId);
            setFulfillmentStatus(data);
            break;
          }
          default: {
            setOrderIdInStorage({
              appId,
              expiresInMins: 60,
              orderId,
            });
            const data = await getFulfillmentStatus(orderId);
            setFulfillmentStatus(data);
            break;
          }
        }
      }
    })();
  }, [orderStatus]);

  return { fulfillmentStatus, orderCreated };
};
