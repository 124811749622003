import { useConfig } from '@Src/providers/ConfigProvider';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';
import { type CollectionStoreSummary } from '@Src/types/collectionStoreTypes';
import { type DeliveryStoreSummary } from '@Src/types/deliveryStoreTypes';
import { useNavigate } from 'react-router-dom';

export const getStoreOrderUrl = ({
  isCollection,
  storeSummary,
  deliveryLocationId,
  shouldForceCollectionStoreUrl,
}: {
  isCollection: boolean;
  storeSummary?: CollectionStoreSummary | DeliveryStoreSummary;
  deliveryLocationId?: number;
  shouldForceCollectionStoreUrl?: boolean;
}): string | undefined => {
  if (storeSummary?.VirtualRestaurantId && storeSummary?.PhysicalRestaurantId) {
    if (isCollection || shouldForceCollectionStoreUrl) {
      return `/order#/restaurant/${storeSummary.VirtualRestaurantId}/collection/${storeSummary.PhysicalRestaurantId}`;
    } else if (!isCollection && deliveryLocationId) {
      return `/order#/restaurant/${storeSummary.VirtualRestaurantId}/delivery/${deliveryLocationId}`;
    }
  }
  return undefined;
};

type NavigateToWebOrderType = {
  isCollection: boolean;
  storeSummary?: CollectionStoreSummary | DeliveryStoreSummary;
  deliveryLocationId?: number;
};

export const useNavigateToWebOrder = (): ((
  x: NavigateToWebOrderType,
) => void) => {
  const enableNavigateToWebOrderFix = useFeatureFlag(
    'enableNavigateToWebOrderFix',
  );
  const { appId, isCustomDomain, vanityUrlSuffix } = useConfig();
  const navigate = useNavigate();
  const navigateToWebOrder = ({
    isCollection,
    storeSummary,
    deliveryLocationId,
  }: NavigateToWebOrderType): void => {
    const orderUrl = getStoreOrderUrl({
      isCollection,
      storeSummary,
      deliveryLocationId,
    });
    if (orderUrl) {
      if (enableNavigateToWebOrderFix) {
        const url = new URL(window.location.href);

        let prefix = `/${appId}`;
        if (isCustomDomain) {
          prefix = '';
        } else if (vanityUrlSuffix) {
          prefix = `/${vanityUrlSuffix}`;
        }

        // Split orderUrl into the path (/order) and the hash (#/restaurant/...)
        const [path, hash] = orderUrl.split('#');
        url.pathname = `${prefix}${path}`;
        url.hash = hash;

        window.location.href = url.toString();
      } else {
        navigate(orderUrl);
      }
    }
  };
  return navigateToWebOrder;
};
