// NOTE: Utils taken from Web order's basket controls functions

const CurrencySymbols = (key: string): string => {
  switch (key) {
    case 'AED':
      return 'د.إ';
    case 'AFN':
      return '؋';
    case 'ALL':
      return 'L';
    case 'AMD':
      return '֏'; // case 'ANG': return 'ƒ'; //case 'AOA': return 'Kz';
    case 'ARS':
      return '$';
    case 'AUD':
      return '$'; // case 'AWG': return 'ƒ';
    case 'AZN':
      return 'ман';
    case 'BAM':
      return 'KM'; // case 'BBD': return '$';
    case 'BDT':
      return '৳';
    case 'BGN':
      return 'лв';
    case 'BHD':
      return '.د.ب'; // case 'BIF': return 'FBu';
    case 'BMD':
      return '$';
    case 'BND':
      return '$';
    case 'BOB':
      return '$b';
    case 'BR':
      return 'R$'; // case 'BSD': return '$';
    case 'BTN':
      return 'Nu.';
    case 'BWP':
      return 'P'; // case 'BYR': return 'Br.';
    case 'BZD':
      return 'BZ$';
    case 'CAD':
      return '$';
    case 'CDF':
      return 'Fr.';
    case 'CHF':
      return 'CHF';
    case 'CLP':
      return '$';
    case 'CNY':
      return '¥';
    case 'COP':
      return '$';
    case 'CRC':
      return '₡';
    case 'CUP':
      return '₱'; // case 'CUC': return '$'; //case 'CVE': return 'Esc';
    case 'CZK':
      return 'Kč';
    case 'DJF':
      return 'Fdj';
    case 'DKK':
      return 'kr';
    case 'DOP':
      return 'RD$';
    case 'DZD':
      return 'د.ج'; // case 'EEK': return 'kr';
    case 'EGP':
      return '£';
    case 'ERN':
      return 'Nfk';
    case 'ETB':
      return 'Br.';
    case 'EUR':
      return '€'; // case 'FJD': return '$'; //case 'FKP': return '£';
    case 'GBP':
      return '£';
    case 'GEL':
      return 'ლ'; // case 'GGP': return '£'; //case 'GHS': return '₵'; //case 'GIP': return '£'; //case 'GMD': return 'D'; //case 'GNF': return 'GFr';
    case 'GTQ':
      return 'Q'; // case 'GYD': return '$';
    case 'HKD':
      return '$';
    case 'HNL':
      return 'L';
    case 'HRK':
      return 'kn';
    case 'HTG':
      return 'G';
    case 'HUF':
      return 'Ft';
    case 'IDR':
      return 'Rp';
    case 'ILS':
      return '₪'; // case 'IMP': return '£';
    case 'INR':
      return '₹';
    case 'IQD':
      return 'ع.د';
    case 'IRR':
      return '﷼';
    case 'ISK':
      return 'kr'; // case 'JEP': return '£';
    case 'JMD':
      return 'J$';
    case 'JOD':
      return 'د.ا';
    case 'JPY':
      return '¥';
    case 'KES':
      return 'Sh';
    case 'KGS':
      return 'лв';
    case 'KHR':
      return '៛'; // case 'KMF': return 'CF'; //case 'KPW': return '₩';
    case 'KRW':
      return '₩';
    case 'KWD':
      return 'د.ك'; // case 'KYD': return '$';
    case 'KZT':
      return '₸';
    case 'LAK':
      return '₭';
    case 'LBP':
      return 'ل.ل';
    case 'LKR':
      return 'රු'; // case 'LRD': return '$'; //case 'LSL': return 'L'; //case 'LTL': return 'Lt'; //case 'LVL': return 'Ls';
    case 'LYD':
      return 'ل.د';
    case 'MAD':
      return 'د.م.';
    case 'MDL':
      return 'L'; // case 'MGA': return 'Ar';
    case 'MKD':
      return 'ден';
    case 'MMK':
      return 'Ks';
    case 'MNT':
      return '₮';
    case 'MOP':
      return 'P'; // case 'MRO': return 'UM'; //case 'MUR': return '₨';
    case 'MVR':
      return '.ރ'; // case 'MWK': return 'MK';
    case 'MXN':
      return '$';
    case 'MYR':
      return 'RM'; // case 'MZN': return 'MT'; //case 'NAD': return '$';
    case 'NGN':
      return '₦';
    case 'NIO':
      return 'C$';
    case 'NOK':
      return 'kr';
    case 'NPR':
      return '₨';
    case 'NZD':
      return '$';
    case 'OMR':
      return 'ر.ع.';
    case 'PAB':
      return 'B/.';
    case 'PEN':
      return 'S/.'; // case 'PGK': return 'K';
    case 'PHP':
      return '₱';
    case 'PKR':
      return '₨';
    case 'PLN':
      return 'zł'; // case 'PRB': return 'p.';
    case 'PYG':
      return '₲';
    case 'QAR':
      return 'ر.ق';
    case 'RON':
      return 'lei';
    case 'RSD':
      return 'Дин.';
    case 'RUB':
      return 'руб';
    case 'RWF':
      return 'R₣';
    case 'SAR':
      return 'ر.س'; // case 'SBD': return '$'; //case 'SCR': return '₨'; //case 'SDG': return '£';
    case 'SEK':
      return 'kr';
    case 'SGD':
      return '$'; // case 'SHP': return '£'; //case 'SLL': return 'Le';
    case 'SOS':
      return 'Sh'; // case 'SRD': return '$'; //case 'SSP': return '£'; //case 'STD': return 'Db'; //case 'SVC': return '$';
    case 'SYP':
      return 'ل.س'; // case 'SZL': return 'L';
    case 'THB':
      return '฿';
    case 'TJS':
      return 'SM';
    case 'TMT':
      return 'm';
    case 'TND':
      return 'د.ت'; // case 'TOP': return 'T$';
    case 'TRY':
      return '₺';
    case 'TTD':
      return 'TT$';
    case 'TWD':
      return 'NT$'; // case 'TZS': return 'Sh';
    case 'UAH':
      return '₴'; // case 'UGX': return 'Sh';
    case 'USD':
      return '$';
    case 'UYU':
      return '$U';
    case 'UZS':
      return 'лв';
    case 'VEF':
      return 'Bs';
    case 'VND':
      return '₫'; // case 'VUV': return 'Vt'; //case 'WST': return 'T';
    case 'XAF':
      return '#'; // case 'XAG': return '#'; //case 'XAU': return '#'; //case 'XBA': return '#'; //case 'XBB': return '#'; //case 'XBC': return '#'; //case 'XBD': return '#';
    case 'XCD':
      return '$';
    case 'XDR':
      return '#'; // case 'XFU': return '#';
    case 'XOF':
      return '#'; // case 'XPD': return '#'; //case 'XPF': return '#'; //case 'XPT': return '#';
    case 'YER':
      return '﷼';
    case 'ZAR':
      return 'R'; // case 'ZMW': return 'ZK'; //case 'ZWD': return '#';//
    default: {
      return '#';
    }
  }
};

export const formatNumber = (
  price: number,
  currency: string,
  lang: string,
  style: 'decimal' | 'currency' | 'percent' | 'unit',
  minimumFractionDigits: number,
  currencyDisplay?: string,
): string => {
  try {
    const formatter = new Intl.NumberFormat(lang || 'en', {
      style,
      currency: currency || 'EUR',
      minimumFractionDigits,
      currencyDisplay,
    });
    return formatter.format(price);
  } catch (e: any) {
    const symbol = CurrencySymbols(currency);
    return `${symbol} ${price}`;
  }
};

export const formatPrice = (
  price: number,
  currency: string,
  lang: string,
  trailingZeros: boolean = true,
  currencyDisplay = 'symbol',
): string => {
  switch (currency) {
    // assuming these edge cases may want symbol currency display
    case 'AED': {
      return (
        CurrencySymbols(currency) +
        formatNumber(price, currency, lang, 'decimal', trailingZeros ? 2 : 0)
      );
    }

    case 'BMD': {
      // Intl.NumberFormat doesn't contain currency symbol... legends says they using '$'
      return formatNumber(
        price,
        'USD',
        lang,
        'currency',
        trailingZeros ? 2 : 0,
      );
    }

    // zero decimal currencies https://stripe.com/docs/currencies#zero-decimal
    // missing currencies we dont support GNF, KMF, MGA, UGX, VUV, XPF but stripe does
    case 'CLP':
    case 'DJF':
    case 'JPY':
    case 'KRW':
    case 'PYG':
    case 'RWF':
    case 'VND':
    case 'XAF':
    case 'XOF': {
      return formatNumber(price, currency, lang, 'currency', 0);
    }

    default: {
      return formatNumber(
        price,
        currency,
        lang,
        'currency',
        trailingZeros ? 2 : 0,
        currencyDisplay,
      );
    }
  }
};
