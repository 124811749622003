import { type SupportedLanguage } from '@Src/middlewares/utils/constants';
import { createContext, useContext } from 'react';

export type ConfigType = {
  appId: string;
  isCustomDomain: boolean;
  supportedLanguages: Array<{ id: SupportedLanguage; name: string }>;
  language: SupportedLanguage;
  vanityUrlSuffix?: string;
  env?: string;
  isLoggedIn?: boolean;
  isPreview?: boolean;
  isEditorPreview?: boolean;
  version?: string;
  isLocal?: boolean;
};

interface ConfigBase {
  config: ConfigType;
}

const ConfigContext = createContext<ConfigBase>({
  config: {
    appId: '',
    isCustomDomain: false,
    supportedLanguages: [],
    language: 'en',
    env: 'production',
  },
});

type ConfigProp = {
  children: JSX.Element;
} & ConfigBase;

export const ConfigProvider = ({
  children,
  config,
}: ConfigProp): JSX.Element => {
  const value = {
    config,
  };
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = (): ConfigType => {
  const { config } = useContext(ConfigContext);

  return config;
};
