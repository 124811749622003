import * as GMaps from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';

export function useGoogleMap(
  mapOptions: google.maps.MapOptions,
  mapRef?: React.RefObject<HTMLElement>,
): [google.maps.Map?] {
  const designSchema = useDesignSchema();
  let googleMapApiKey = '';
  if (designSchema.schemaVersion === 'v2') {
    googleMapApiKey = designSchema.config?.googleMapApiKey || '';
  } else {
    googleMapApiKey = designSchema.map?.googleMapApiKey || '';
  }
  if (!googleMapApiKey) {
    return [];
  }
  const [map, setMap] = useState<google.maps.Map>();
  const { Loader } = GMaps;

  useEffect(() => {
    if (mapRef?.current) {
      if (typeof google !== 'undefined' && google?.maps?.places) {
        setMap(
          new google.maps.Map(mapRef.current, {
            ...mapOptions,
          }),
        );
      } else {
        const loader = new Loader({
          apiKey: googleMapApiKey,
          libraries: ['geometry', 'places', 'maps'],
        });
        loader
          .load()
          .then(() => {
            setMap(
              new google.maps.Map(mapRef.current as HTMLElement, {
                ...mapOptions,
              }),
            );
          })
          .catch(() => {
            console.log('Failed to create map');
          });
      }
    }
  }, [mapRef]);

  return [map];
}
