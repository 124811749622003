import styled from 'styled-components';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import TimeTableEntry from './TimeTableEntry';
import FadeInContainer from '../FadeInContainer';
import { useTranslation } from '@Providers/TranslationProvider';
import { type OpeningHoursType } from '@Src/middlewares/DesignConfigTypes';

const StyledOpeningHoursSection = styled.section<{ img?: string }>`
  background-image: url('${({ img }) => img}') !important;
`;

export const OpeningHours = ({ image }: { image: string }): JSX.Element => {
  const { openingHours: data } = useDesignSchema();
  const { translate } = useTranslation();
  if (!data) {
    return <></>;
  }
  return (
    <StyledOpeningHoursSection
      id="timetable"
      className="section timetable parallax paraOn"
      img={image}
    >
      <FadeInContainer className="container">
        {data.map((oh, index) => {
          const hasPickupHours =
            (oh?.pickupHours?.length || 0) > 0 &&
            oh.pickupHours?.some(
              (pickup: { hours: string }) => pickup?.hours !== 'Closed',
            );

          const hasDeliveryHours =
            (oh?.deliveryHours?.length || 0) > 0 &&
            oh.deliveryHours?.some(
              (delivery: { hours: string }) => delivery?.hours !== 'Closed',
            );

          let entries: OpeningHoursType[] | undefined = [];
          // !hasDeliveryHours default to previous behavior
          if (hasPickupHours || !hasDeliveryHours) {
            entries = oh.pickupHours;
          } else if (hasDeliveryHours) {
            entries = oh.deliveryHours;
          }

          if (!entries) {
            return <></>;
          }
          return (
            <TimeTableEntry
              key={`oh-${index}`}
              title={data.length === 1 ? translate('Opening_Hours') : oh.title}
              entries={entries}
            />
          );
        })}
      </FadeInContainer>
    </StyledOpeningHoursSection>
  );
};

export default OpeningHours;
