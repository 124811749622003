const UUID = function (): string {
  let d = new Date().getTime();

  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

// Generate a UUID
// http://stackoverflow.com/a/8809472/249879
export const DeviceId = function (): string {
  const key = 'flipdish-device-id';
  const uuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  try {
    const deviceId = localStorage.getItem(key);
    try {
      if (deviceId) {
        const parsedDeviceId = JSON.parse(deviceId);
        if (parsedDeviceId?.match(uuid)) {
          return parsedDeviceId;
        }
      }
    } catch (e) {
      if (deviceId?.match(uuid)) {
        return deviceId;
      }
    }

    const newDeviceId = UUID();
    localStorage.setItem(key, JSON.stringify(newDeviceId));
    return newDeviceId;
  } catch (error) {
    console.warn(error);
  }

  // No local storage, make a new device ID each time.
  return UUID();
};
