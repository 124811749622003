import { useEffect, useRef, useState } from 'react';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useGoogleMap } from '../../../hooks/useGoogleMap';
import mapStyles from './defaultMapStyles.json';
import styled from 'styled-components';

const StyledMapDiv = styled.div`
  height: 100%;
`;
const MapSection = (): JSX.Element => {
  const mapRef = useRef(null);
  const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[]>([]);
  const { storeMapData, map: mapData } = useDesignSchema('v1');
  if (!storeMapData || !mapData) {
    return <></>;
  }

  const [map] = useGoogleMap(
    {
      center: {
        lat: storeMapData[0]?.Coordinates?.Latitude,
        lng: storeMapData[0]?.Coordinates?.Longitude,
      },
      mapTypeControl: false,
      scrollwheel: false,
      styles: mapStyles,
      zoom: 17,
    },
    mapRef,
  );

  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      const markers = storeMapData.map((store) => {
        // Add marker
        const markerPosition = new google.maps.LatLng(
          store.Coordinates?.Latitude,
          store.Coordinates?.Longitude,
        );
        if (storeMapData.length > 1) {
          bounds.extend(markerPosition);
        }
        const markerIcon = {
          url: 'https://static.web.flipdish.com/public/images/elixir/pointer-gray.png',
          scaledSize: new google.maps.Size(50, 68),
        };

        const marker = new google.maps.Marker({
          map,
          position: markerPosition,
          icon: markerIcon,
        });

        // Add info window logic
        const contentString = `<div>${store.Address}<br></div>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: store.Address,
        });
        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
        return marker;
      });
      if (markers.length > 1) {
        map.fitBounds(bounds);
      }
      setMapMarkers(markers);
    }

    return () => {
      mapMarkers.forEach((marker) => {
        google.maps.event.clearInstanceListeners(marker);
      });
    };
  }, [map]);
  return (
    <section id="maps">
      <StyledMapDiv id="map" ref={mapRef} />
    </section>
  );
};
export default MapSection;
