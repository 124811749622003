import type { FeatureFlag } from '../middlewares/featureFlag';
import { createContext, useContext } from 'react';

const FeatureFlagContext = createContext<SplitIO.Treatments | undefined>(
  undefined,
);

interface FeatureFlagProp {
  children: JSX.Element;
  features?: SplitIO.Treatments;
}

export const FeatureFlagProvider = ({
  children,
  features,
}: FeatureFlagProp): JSX.Element => (
  <FeatureFlagContext.Provider value={features}>
    {children}
  </FeatureFlagContext.Provider>
);

export const useFeatureFlag = (feature: FeatureFlag): string | undefined => {
  const features = useContext(FeatureFlagContext) || {};
  return features[feature];
};
