import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { useContext } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import '@Components/ui/ScrollingMenuHeader/hideScrollBar.css';
import Card from '@mui/material/Card';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { ReactComponent as QuoteIcon } from '@Src/assets/images/Quote.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import ScrollingMenuHeader from '@Components/ui/ScrollingMenuHeader';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

type StyledContainerProps = {
  ownerState: {
    borderRadius?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  };
};

const StyledContainer = styled('div')<StyledContainerProps>(
  ({ theme, ownerState }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: ownerState.borderRadius,
    paddingTop: ownerState.paddingTop,
    paddingBottom: ownerState.paddingBottom,
    [theme.breakpoints.down('sm')]: {
      paddingTop: ownerState.paddingTop,
      paddingBottom: ownerState.paddingBottom,
    },
  }),
);

const StyledTestimonialsCardWrapper = styled(Grid)`
  height: 100%;
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

type StyledCardProps = {
  ownerState: {
    borderWidth?: string;
  };
};

const StyledCard = styled(Card)<StyledCardProps>`
  border-radius: inherit;
  border-width: ${({ ownerState }) => ownerState.borderWidth || '1px'};
  border-color: ${({ theme }) => theme.palette.secondary.lowContrast};
  text-align: center;
  position: relative;
  width: 440px;
  height: inherit;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 240px;
  }
  min-height: 230px;
  padding: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

const StyledQuoteIcon = styled(QuoteIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.1;
  }
`;

type StyledCardContainerProps = {
  ownerState: {
    borderRadius?: string;
  };
};
const StyledCardContainer = styled('div')<StyledCardContainerProps>`
  position: relative;
  height: 100%;
  border-radius: ${({ ownerState }) => ownerState.borderRadius || '8px'};
  overflow: hidden;
`;

const StyledAuthorText = styled(Typography)`
  z-index: 1;
  color: ${({ theme }) => theme.palette.secondary.body};
`;
type TypographyWithComponent = React.ComponentType<
  TypographyProps<'span', { component: 'span' }>
>;
const StyledTestimonialText: TypographyWithComponent = styled(Typography)`
  z-index: 1;
`;

const StyledText = styled('div')`
  pre,
  p,
  li {
    white-space: pre-wrap;
    text-wrap: inherit;
    font: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
`;
type TestimonialsProps = {
  className?: string;
  borderRadius?: string;
  testimonialBorderRadius?: string;
  testimonialBorderWidth?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
};

const Testimonials: React.FC<TestimonialsProps> = ({
  className = '',
  borderRadius,
  testimonialBorderRadius,
  testimonialBorderWidth,
  paddingTop,
  paddingBottom,
  paddingLeft,
}): JSX.Element => {
  const { translate } = useTranslation();
  const { testimonials } = useDesignSchema('v2');

  if ((testimonials?.data?.length || 0) === 0) {
    return <></>;
  }

  return (
    <StyledContainer
      id="testimonials"
      className={className}
      data-fd="testimonials"
      ownerState={{ borderRadius, paddingBottom, paddingLeft, paddingTop }}
    >
      <ScrollMenu
        Header={() => {
          const {
            scrollPrev,
            scrollNext,
            isFirstItemVisible,
            isLastItemVisible,
          } = useContext(VisibilityContext);
          return (
            <ScrollingMenuHeader
              title={translate(testimonials?.title || '')}
              subTitle={translate(testimonials?.subTitle || '')}
              goBack={() => {
                scrollPrev('smooth', 'nearest');
              }}
              goForward={() => {
                scrollNext('smooth', 'nearest');
              }}
              leftButtonDisabled={isFirstItemVisible}
              rightButtonDisabled={isLastItemVisible}
              maxContainerWidth="none"
            />
          );
        }}
      >
        {testimonials?.data?.map((item, idx: number) => (
          <StyledTestimonialsCardWrapper key={idx}>
            <StyledCardContainer
              ownerState={{ borderRadius: testimonialBorderRadius }}
            >
              <StyledCard
                variant="outlined"
                ownerState={{ borderWidth: testimonialBorderWidth }}
              >
                <StyledTestimonialText component="span" variant="h3xs">
                  <StyledText
                    className="rich-text"
                    dangerouslySetInnerHTML={{
                      __html: translate(item.testimonial || ''),
                    }}
                  />
                </StyledTestimonialText>
                <StyledAuthorText variant="body2">
                  {item.author || translate('Anonymous')}
                </StyledAuthorText>
              </StyledCard>
              <StyledQuoteIcon />
            </StyledCardContainer>
          </StyledTestimonialsCardWrapper>
        )) || []}
      </ScrollMenu>
    </StyledContainer>
  );
};

export default Testimonials;
