import { useConfig } from '@Src/providers/ConfigProvider';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';
import { useLogger } from '@Src/providers/LoggerProvider';
import Cookies from 'js-cookie';

interface ApiResponse<T> {
  error?: boolean;
  errorMessage?: string;
  errorStack?: string;
  data?: {
    Success?: boolean;
    Data?: T;
    UserMessage?: string;
    DeveloperMessage?: string;
    StackTrace: string;
    ReloadRestaurant?: boolean;
    Code?: number;
  };
  headers?: Headers;
}

type StringWithSlashType = `/${string}`;

export type SupportedMethodsType = {
  post: <B, R>({
    path,
  }: {
    path: StringWithSlashType;
    payload?: B;
    suppresCookieHeader?: boolean;
    customHeaders?: Record<string, string>;
    appType?: 'Photon' | 'Web';
  }) => Promise<ApiResponse<R>>;
  get: <R>({
    path,
  }: {
    path: StringWithSlashType;
    suppresCookieHeader?: boolean;
    customHeaders?: Record<string, string>;
    appType?: 'Photon' | 'Web';
    params?: Record<string, string>;
  }) => Promise<ApiResponse<R>>;
};

export const useApi = (): SupportedMethodsType => {
  const logger = useLogger();
  const enableSafariLogin = useFeatureFlag('enableSafariLogin');
  const { appId, version, language, isLocal } = useConfig();
  const genericHeaders = {
    'Content-Type': 'application/json',
    'Flipdish-App-Version': version || '',
    'Flipdish-White-Label-Id': appId,
  };

  const newBaseURL =
    typeof window !== 'undefined' ? `${window.location.origin}/api` : '/api';
  const baseUrl =
    enableSafariLogin && !isLocal ? newBaseURL : 'https://api.flipdish.co';

  return {
    post: async <B, R>({
      path,
      payload,
      suppresCookieHeader,
      customHeaders,
      appType = 'Photon',
    }: {
      path: StringWithSlashType;
      payload?: B;
      suppresCookieHeader?: boolean;
      customHeaders?: Record<string, string>;
      appType?: 'Photon' | 'Web';
    }): Promise<ApiResponse<R>> => {
      let headers: Record<string, string> = {
        ...genericHeaders,
        'Flipdish-App-Type': appType,
      };
      if (!enableSafariLogin) {
        headers = {
          ...headers,
          'X-Flipdish-Token': Cookies?.get('flipdish-token') || '',
          ...(suppresCookieHeader ? { 'Suppress-Auth-Cookie': 'true' } : {}),
        };
      }
      try {
        const data = await fetch(`${baseUrl}${path}`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: customHeaders || headers,
          credentials: enableSafariLogin ? 'include' : undefined,
        });

        const response = await data.json();
        return { data: response, headers: data.headers };
      } catch (e: any) {
        logger.error(`Failed to fetch ${path}`, {
          payload: { payload },
          error: e,
          errorMessage: e.message,
          errorStack: e.stack,
        });
        return {
          error: true,
          errorMessage: e.message,
          errorStack: e.stack,
        };
      }
    },
    get: async <R>({
      path,
      suppresCookieHeader,
      appType = 'Photon',
      customHeaders,
      params = {},
    }: {
      path: StringWithSlashType;
      suppresCookieHeader?: boolean;
      customHeaders?: Record<string, string>;
      appType?: 'Photon' | 'Web';
      params?: Record<string, string>;
    }): Promise<ApiResponse<R>> => {
      let headers: Record<string, string> = {
        ...genericHeaders,
        'Flipdish-Language': language,
        'Flipdish-App-Type': appType,
      };
      if (!enableSafariLogin) {
        headers = {
          ...headers,
          'X-Flipdish-Token': Cookies?.get('flipdish-token') || '',
          ...(suppresCookieHeader ? { 'Suppress-Auth-Cookie': 'true' } : {}),
        };
      }
      const paramsString = new URLSearchParams(params).toString();
      const url = `${baseUrl}${path}${paramsString ? `?${paramsString}` : ''}`;

      try {
        const data = await fetch(url, {
          method: 'GET',
          headers: customHeaders || headers,
          credentials: 'include',
        });

        const response = await data.json();
        return { data: response };
      } catch (e: any) {
        // logger.error(`Failed to fetch ${path}`, {
        //   error: e,
        //   errorMessage: e.message,
        //   errorStack: e.stack,
        // });
        return {
          error: true,
          errorMessage: e.message,
          errorStack: e.stack,
        };
      }
    },
  };
};

export default useApi;
