import { useTranslation } from '@Src/providers/TranslationProvider';
import { useRecaptcha } from '@Src/hooks/useRecaptcha';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useApi } from '@Src/hooks/useApi';
import { TextField } from '@Components/ui/TextField';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConfig } from '@Src/providers/ConfigProvider';
import { validEmailRegex } from '@Src/utility/regex';
import {
  maxEmailLength,
  maxNameLength,
  maxPhoneLength,
  maxSpecialRequestsLength,
} from '../utils';
import MuiButton from '@Components/ui/MuiButton';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { type SubmissionStatus } from '../types';

type ErrorsType = {
  time?: string;
  date?: string;
  name?: string;
  email?: string;
  phone?: string;
  numPeople?: string;
  captchaToken?: string;
  message?: string;
};

const StyledRecaptchaContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const StyledSubmitButton = styled(MuiButton)`
  width: fit-content;
  padding: 20px 32px;
`;

const StyledRecaptchaErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

const StyledButtonContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledButtonText = styled(Typography)`
  text-transform: none;
`;

const StyledTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

type ReservationsProps = {
  daysAhead?: number;
  submissionStatus: SubmissionStatus;
  setFormSubmissionStatus: (status: SubmissionStatus) => void;
};

export const Reservations = ({
  daysAhead,
  submissionStatus,
  setFormSubmissionStatus,
}: ReservationsProps): JSX.Element => {
  const { translate } = useTranslation();
  const { language, appId } = useConfig();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<ErrorsType>({});
  const [minDateString, setMinDateString] = useState<string>('');
  const [maxDateString, setMaxDateString] = useState<string>('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    numPeople: '',
    message: '',
  });

  const {
    isRecaptchaValid,
    handleRecaptchaExpired,
    handleRecaptchaTest,
    recaptchaSiteKey,
  } = useRecaptcha();

  useEffect(() => {
    setIsBrowser(true);
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    setMinDateString(todayString);

    const customerString = isLoggedIn
      ? localStorage.getItem('flipdish-customer')
      : '';
    const parsedCustomer = customerString ? JSON.parse(customerString) : {};

    setFormValues({
      name: parsedCustomer?.CustomerName || '',
      email: parsedCustomer?.EmailAddress || '',
      phone: parsedCustomer?.CustomerPhoneNumberLocalFormatString || '',
      date: todayString,
      numPeople: '2',
      message: '',
      time: '',
    });
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + (daysAhead || 25));
    setMaxDateString(futureDate.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    submissionStatus === 'success' &&
      setFormValues({ ...formValues, time: '', message: '' });
  }, [submissionStatus]);

  useEffect(() => {
    if (isRecaptchaValid) {
      setCaptchaToken(recaptchaRef.current?.getValue() || '');
    } else {
      setCaptchaToken('');
    }
  }, [isRecaptchaValid]);

  const { post } = useApi();

  const validateForm = (): boolean => {
    const errors: ErrorsType = {};
    const numPeople = parseInt(formValues?.numPeople || '0');
    if (!formValues?.date) {
      errors.date = translate('The_Date_field_is_required');
    } else if (formValues?.date < minDateString) {
      errors.date = translate('Date_should_be_in_future');
    } else if (formValues?.date > maxDateString) {
      errors.date = translate('Date_value_too_far_in_future');
    }
    if (!formValues?.time) {
      errors.time = translate('The_Time_field_is_required');
    }
    if (!formValues?.name) {
      errors.name = translate('The_Name_field_is_required');
    } else if (formValues?.name?.length > maxNameLength) {
      errors.name = translate('Value_more_than_maximum_length_of', {
        maximum: maxNameLength,
      });
    }
    if (formValues?.message.length > maxSpecialRequestsLength) {
      errors.message = translate('Value_more_than_maximum_length_of', {
        maximum: maxSpecialRequestsLength,
      });
    }
    if (!formValues?.email) {
      errors.email = translate('The_Email_field_is_required');
    } else if (!validEmailRegex.test(formValues?.email || '')) {
      errors.email = translate('Invalid_email');
    } else if (formValues?.email?.length > maxEmailLength) {
      errors.email = translate('Value_more_than_maximum_length_of', {
        maximum: maxEmailLength,
      });
    }
    if (!formValues?.phone) {
      errors.phone = translate('The_Phone_Number_field_is_required');
    } else if (formValues?.phone.length > maxPhoneLength) {
      errors.phone = translate('Value_more_than_maximum_length_of', {
        maximum: maxPhoneLength,
      });
    }
    if (!formValues?.numPeople) {
      errors.numPeople = translate('The_Party_Size_field_is_required');
    } else if (numPeople < 1) {
      errors.numPeople = translate('The_Number_of_people_field_is_required');
      // TODO: ADD numpeople to editor api
    } else if (numPeople > 14) {
      errors.numPeople = translate(
        'Please_enter_a_value_less_than_or_equal_to_14',
      );
    }
    if (!captchaToken) {
      errors.captchaToken = translate('This_field_is_required');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormFieldChange = (event: {
    target: { name: any; value: string };
  }): void => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value?.trimStart() || '',
    });
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
  }): Promise<void> => {
    setIsSubmitting(true);
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      const formData = {
        Name: formValues.name,
        Phone: formValues.phone,
        Email: formValues.email,
        Message: formValues.message,
        NumberOfPeople: parseInt(formValues.numPeople),
        Date: formValues.date,
        Time: formValues.time,
        ReservationGoogleRecaptchaResponse: captchaToken,
      };
      try {
        if (appId) {
          const response = await post<typeof formData, string>({
            path: `/app/makereservation?panaceaAppNameId=${appId}`,
            payload: formData,
          });
          if (response?.data?.Success === true) {
            setFormSubmissionStatus?.('success');
          } else {
            setFormSubmissionStatus?.('error');
          }
        }
      } catch {
        setFormSubmissionStatus?.('error');
      }
    }
    setIsSubmitting(false);
  };

  return (
    <section id="v2-reservations">
      <form
        className="reservations-form"
        noValidate
        // form doesn't expect promise returned but doesn't use it anyway
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit}
      >
        <Grid container columnSpacing={1.5}>
          <Grid item xs={12} md={6}>
            <StyledTextField
              disabled={isSubmitting}
              name="name"
              data-fd="reservations-name"
              error={!!formErrors?.name}
              helperText={formErrors?.name || ''}
              value={formValues.name}
              label={translate('Name')}
              onChange={handleFormFieldChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              disabled={isSubmitting}
              name="phone"
              data-fd="reservations-phone"
              error={!!formErrors.phone}
              helperText={formErrors.phone || ''}
              value={formValues.phone}
              label={translate('Phone_Number')}
              onChange={handleFormFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              name="email"
              data-fd="reservations-email"
              onChange={handleFormFieldChange}
              error={!!formErrors?.email}
              helperText={formErrors?.email || ''}
              value={formValues.email}
              label={translate('Email')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTextField
              disabled={isSubmitting}
              name="date"
              data-fd="reservations-date"
              onChange={handleFormFieldChange}
              InputProps={{
                inputProps: { min: minDateString, max: maxDateString },
              }}
              error={!!formErrors?.date}
              helperText={
                formErrors?.date ||
                translate('Accepting_reservations_up_to') + ` ${maxDateString}`
              }
              value={formValues?.date}
              type="date"
              label={translate('Date')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTextField
              disabled={isSubmitting}
              name="time"
              data-fd="reservations-time"
              onChange={handleFormFieldChange}
              InputProps={{
                inputProps: { step: 900 },
              }}
              error={!!formErrors?.time}
              helperText={formErrors?.time || ''}
              value={formValues.time}
              type="time"
              label={translate('Time')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTextField
              disabled={isSubmitting}
              name="numPeople"
              data-fd="reservations-num-people"
              onChange={handleFormFieldChange}
              InputProps={{
                inputProps: { min: 1, max: 14 },
              }}
              error={!!formErrors?.numPeople}
              helperText={formErrors?.numPeople || ''}
              value={formValues.numPeople}
              type="number"
              label={translate('Party_Size')}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={isSubmitting}
              error={!!formErrors?.message}
              helperText={formErrors?.message || ''}
              multiline
              name="message"
              data-fd="reservations-message"
              onChange={handleFormFieldChange}
              value={formValues.message}
              placeholder={translate('e.g_window_seat,birthday_etc.')}
              label={translate('Special_Requests')}
            />
          </Grid>
          <Grid item xs={12}>
            {isBrowser && recaptchaSiteKey && (
              <StyledRecaptchaContainer>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  data-fd="reservations-recaptcha"
                  hl={language}
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaTest}
                  onExpired={handleRecaptchaExpired}
                />
                {formErrors.captchaToken && (
                  <StyledRecaptchaErrorText variant="caption">
                    {formErrors.captchaToken}
                  </StyledRecaptchaErrorText>
                )}
              </StyledRecaptchaContainer>
            )}
          </Grid>
        </Grid>
        <StyledButtonContainer>
          <StyledSubmitButton
            loading={isSubmitting}
            disabled={isSubmitting}
            variant="contained"
            type="submit"
          >
            <StyledButtonText variant="button">
              {translate('Request_booking')}
            </StyledButtonText>
          </StyledSubmitButton>
        </StyledButtonContainer>
      </form>
    </section>
  );
};

export default Reservations;
