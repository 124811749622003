import { styled } from '@mui/material';
import { Star, type StarVariant } from './Star';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

const StyledRow = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-around',
  fontSize: '16px',
}));

const StyledStar = styled(Star)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

export type Props = {
  currentStar: number;
  showPendingStar?: boolean;
  totalStars: number;
};

export function LoyaltyProgressBar(props: Props): JSX.Element {
  const { currentStar, showPendingStar = false, totalStars } = props;

  const getStarVariant = (index: number): StarVariant => {
    if (index <= currentStar) {
      return 'filled';
    }

    if (showPendingStar && index === currentStar + 1) {
      return 'pending';
    }

    return 'outlined';
  };

  const renderLoyaltyBar = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];
    // rows limited to 10 stars
    const numberOfRows = Math.ceil(totalStars / 10);

    let starIndex = 1;
    let currentRow = 1;

    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex += 1) {
      const stars: JSX.Element[] = [];
      const currentRowLimit = Math.ceil(
        (totalStars / numberOfRows) * currentRow,
      );

      for (starIndex; starIndex <= currentRowLimit; starIndex += 1) {
        stars.push(
          <StyledStar key={starIndex} variant={getStarVariant(starIndex)} />,
        );
      }

      rows.push(
        <StyledRow
          className={'loyalty-bar-row'}
          data-fd="loyalty-bar-row"
          key={currentRow}
        >
          {stars}
        </StyledRow>,
      );
      currentRow += 1;
    }

    return rows;
  };

  return <StyledContainer>{renderLoyaltyBar()}</StyledContainer>;
}
