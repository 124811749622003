import {
  type DesignSchemaV1Type,
  type DesignSchemaType,
  type DesignSchemaV2Type,
} from '../middlewares/DesignConfigTypes';
import { createContext, useContext } from 'react';

interface SchemaBase {
  schema: DesignSchemaType;
}

const SchemaContext = createContext<SchemaBase>({} as unknown as SchemaBase);

type SchemaProp = {
  children: JSX.Element;
} & SchemaBase;

export const DesignSchemaProvider = ({
  children,
  schema,
}: SchemaProp): JSX.Element => {
  const value = {
    schema,
  };
  return (
    <SchemaContext.Provider value={value}>{children}</SchemaContext.Provider>
  );
};

type VersionType = 'v1' | 'v2';

type ReturnObjectType<T> = T extends 'v1'
  ? DesignSchemaV1Type
  : T extends 'v2'
  ? DesignSchemaV2Type
  : DesignSchemaType;

export const useDesignSchema = <T extends VersionType>(
  version?: T,
): ReturnObjectType<T> => {
  const { schema } = useContext(SchemaContext);
  // @ts-expect-error - this is a hack to make the types work.
  return schema || {};
};
