import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Reorder from '@Components/Widgets/Reorder';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import OrderStatus from '@Components/Widgets/OrderStatus';
import Voucher from '@Components/Widgets/Voucher';
import { OrderSettings } from '@Components/Widgets/OrderSettings';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { NotLoggedInTile } from './NotLoggedInTile';
import LoyaltyProgress from '@Components/Widgets/LoyaltyProgress';

const StyledHero = styled(Grid)<{ image?: string }>(({ theme, image }) => ({
  backgroundColor: theme.palette.primary.main,
  ...(image ? { backgroundImage: `url(${image})` } : {}),
  backgroundSize: 'cover',
  height: '600px',
  borderRadius: theme.boxedUp.borderRadius.mobile,
  display: 'flex',

  [theme.breakpoints.up('sm')]: {
    height: '600px',
    borderRadius: theme.boxedUp.borderRadius.default,
  },
}));

const StyledLandingChildrenWrapper = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  minHeight: '72px',
}));

const StyledHeroWidgetsHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const StyledReorderGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));

const StyledReorderTile = styled(Grid)<{ image?: string }>(
  ({ theme, image }) => ({
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.primary.main,
      ...(image ? { backgroundImage: `url(${image})` } : {}),
      backgroundSize: 'auto 350px',
      backgroundPositionX: 'center',
      height: '600px',
      borderRadius: theme.boxedUp.borderRadius.default,
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  }),
);

const StyledOrderSettingsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '156px',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    minHeight: '120px',
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

type Props = {
  backgroundImages: string[];
  borderWidth?: string;
  secondaryImage?: string;
};

const StyledContainer = styled('div')`
  position: relative;
  overflow: hidden;
`;

export const BoxedUpLanding = (props: Props): JSX.Element => {
  const { backgroundImages, borderWidth, secondaryImage } = props;
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  let image, reorderImage;
  const themePropKey = isMobile ? 'mobile' : 'default';
  const widgetBorderRadius = `${theme.boxedUp.widgetBorderRadius}px`;
  const orderSettingsBorderRadius = `${theme.boxedUp.orderSettings.borderRadius[themePropKey]}px`;
  if (backgroundImages[0]) {
    image = isMobile
      ? `${backgroundImages[0]}?auto=format&fit=crop&h=600&w=700&upscale=true`
      : `${backgroundImages[0]}?auto=format&fit=crop&h=600&w=1200&upscale=true`;
  }
  reorderImage = secondaryImage || backgroundImages[0];
  if (reorderImage) {
    reorderImage = `${reorderImage}?auto=format&fit=crop&h=350`;
  }
  const columnSpacing = isMobile ? 0 : 3;

  return (
    <StyledContainer id="hero" data-fd="landing">
      <Grid container columnSpacing={columnSpacing}>
        <StyledHero item xs={12} md={8} lg={9} image={image}>
          <StyledHeroWidgetsHolder>
            <StyledLandingChildrenWrapper>
              <OrderStatus style={{ borderRadius: widgetBorderRadius }} />
              <Voucher borderRadius={widgetBorderRadius} />
              <LoyaltyProgress borderRadius={widgetBorderRadius} />
            </StyledLandingChildrenWrapper>
            <StyledOrderSettingsContainer>
              <OrderSettings
                borderRadius={orderSettingsBorderRadius}
                borderWidth={borderWidth}
                boxShadow={theme.boxedUp.orderSettings.boxShadow}
                buttonBorderRadius={`${theme.boxedUp.buttonBorderRadius}px`}
                orderButtonSize="lg"
                paddingBottom={`${theme.boxedUp.orderSettings.padding.bottom[themePropKey]}px`}
              />
            </StyledOrderSettingsContainer>
          </StyledHeroWidgetsHolder>
        </StyledHero>
        <StyledReorderGrid item xs={12} md={4} lg={3}>
          <StyledReorderTile image={reorderImage}>
            {isLoggedIn ? (
              <Reorder
                buttonborderradius="18px"
                buttonBorderWidth={`${theme.boxedUp.borderWidth}px`}
                cardBorderWidth={`${theme.boxedUp.borderWidth}px`}
                isVertical={!isMobile}
                cardborderradius={isMobile ? '16px' : '0px'}
                reordertextcolor={theme.palette.text.primary}
                showViewAllOrdersCard={isMobile}
                showNoOrdersMessage
                {...(!isMobile ? { cardWidth: '100%' } : {})}
              />
            ) : (
              <NotLoggedInTile />
            )}
          </StyledReorderTile>
        </StyledReorderGrid>
      </Grid>
    </StyledContainer>
  );
};
