import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { NotFound } from './NotFound';
import { Weborder } from './Weborder';
import LegacyElixir from './LegacyElixir';
import { useCustomPageSchema } from '@Providers/CustomPageSchemaProvider';
import {
  PageType,
  type CustomPageType,
  type V2CustomPageType,
  V2PageType,
  type HqContactPageType,
  type IndexPageType,
} from '@Src/middlewares/CustomPageTypes';
import { EmptyPage } from './LegacyElixir/CustomPages/EmptyPage';
import HqContactPage from './LegacyElixir/CustomPages/HqContactPage';
import AboutPage from './LegacyElixir/CustomPages/AboutPage';
import ContactLocations from './LegacyElixir/ContactLocations';
import { Redirect } from './RoutesUtils';
import PageLayout from './PageLayout';
import ErrorBoundary from './ErorrBoundary';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

import V2Template from './WebsiteV2/Template';
import V2Contact from './WebsiteV2/ContactPage';
import V2AboutPage from './WebsiteV2/AboutUs';
import V2EmptyPage from './WebsiteV2/EmptyPage';
import V2HqContactPage from './WebsiteV2/HqContactPage';

const getCustomPageComponent = (value: CustomPageType): JSX.Element => {
  switch (value.PageType) {
    case PageType.AboutUsPage:
      return <AboutPage data={value} />;
    case PageType.HQContactPage:
      return <HqContactPage data={value} />;
    case PageType.EmptyPage:
    case PageType.EmptyNoHeaderPage:
      return <EmptyPage html={value.html} />;
    case PageType.IndexPage:
      return <LegacyElixir data={value} />;
    default:
      return <NotFound />;
  }
};

const getV2CustomPageComponent = (value: V2CustomPageType): JSX.Element => {
  switch (value.pageType) {
    case V2PageType.EmptyPage:
    case V2PageType.EmptyNoHeaderPage:
      return <V2EmptyPage html={value.html} />;
    case V2PageType.LandingPage:
      return <V2Template data={value} />;
    case V2PageType.ContactPage:
    case V2PageType.ReservationsPage:
      return <V2Contact key={value.pageType} data={value} />;
    case V2PageType.AboutUsPage:
      return <V2AboutPage data={value} />;
    case V2PageType.HQContactPage:
      return <V2HqContactPage data={value as unknown as HqContactPageType} />;
    default:
      return <NotFound />;
  }
};

export const Router = (): JSX.Element => {
  const pagesSchema = useCustomPageSchema();
  const designSchema = useDesignSchema();
  const { redirects = [], schemaVersion } = designSchema;
  const location = useLocation();

  const landingPageData = pagesSchema.index;
  const isWebOrder = location.pathname === '/order';

  useEffect(() => {
    const handleMessage = (e: { data: { scrollToId: string } }): void => {
      const scrollToId = e?.data?.scrollToId;
      scrollToId && window.location.assign(`#${scrollToId}`);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo?.(0, 0);
    }
  }, [location.hash, location.pathname]);

  if (schemaVersion === 'v2') {
    return (
      <ErrorBoundary identifier="base routes v2 template">
        <PageLayout isFullWidth={isWebOrder}>
          <Routes>
            {redirects.map((redirect) => (
              <Route
                key={redirect.from}
                path={redirect.from}
                element={<Redirect to={redirect.to} />}
              />
            ))}
            <Route path="/order" element={<Weborder />} />
            {pagesSchema &&
              Object.entries(pagesSchema).map(([key, value]) => (
                <Route
                  key={key}
                  {...(key === 'index' ? { index: true } : { path: key })}
                  element={getV2CustomPageComponent(
                    value as unknown as V2CustomPageType,
                  )}
                />
              ))}
            {
              /*
              This is a fallback route for the index page, in case we hit /index.
            */
              landingPageData && (
                <Route
                  key="indexRoute"
                  path="index"
                  element={getV2CustomPageComponent(
                    landingPageData as unknown as V2CustomPageType,
                  )}
                />
              )
            }
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PageLayout>
      </ErrorBoundary>
    );
  }

  const v1PageSchema = pagesSchema as Record<string, CustomPageType>;
  return (
    <ErrorBoundary identifier="base routes">
      <PageLayout>
        <Routes>
          {redirects.map((redirect) => (
            <Route
              key={redirect.from}
              path={redirect.from}
              element={<Redirect to={redirect.to} />}
            />
          ))}
          <Route path="/order" element={<Weborder />} />
          {designSchema.locationPageEnabled && (
            <Route path="/contact" element={<ContactLocations />} />
          )}
          {v1PageSchema &&
            Object.entries(v1PageSchema).map(([key, value]) => (
              <Route
                key={key}
                {...(key === 'index' ? { index: true } : { path: key })}
                element={getCustomPageComponent(value)}
              />
            ))}
          {
            /*
              This is a fallback route for the index page, in case we hit /index.
            */
            landingPageData && (
              <Route
                key="indexRoute"
                path="index"
                element={getCustomPageComponent(
                  landingPageData as IndexPageType,
                )}
              />
            )
          }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageLayout>
    </ErrorBoundary>
  );
};
