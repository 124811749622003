import { HorizontalList } from '@Components/ui/HorizontalList.tsx';
import { ReorderCard } from './ReorderCard';
import Card from '@Components/ui/Card';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@Components/ui/Button';
import { ReactComponent as ArrowRight } from '@Src/assets/images/ArrowRight.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { formatPrice } from '@Src/utility/priceUtils';
import { useEffect, useState } from 'react';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import { useConfig } from '../../../providers/ConfigProvider';
import { useApi } from '@Src/hooks/useApi';
import { type OrderType } from './ReorderAPITypes';
import { useTracking } from '@Src/hooks/useTracking';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { Typography } from '@mui/material';
import VerticalReorder from './VerticalReorder';
import { InnerGridPolice } from '@Components/WebsiteV2/BaseGridElements';
import { NoOrders } from './NoOrders';

const StyledWrapper = styled('div')<{
  ownerState: { borderRadius?: string };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: ownerState.borderRadius,

  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
  },
}));

type StyledArrowRightProps = {
  primarycolor: string;
};

const StyledArrowRight = styled(ArrowRight)<StyledArrowRightProps>`
  path {
    fill: ${(props) => props.primarycolor};
  }
`;

const StyledActionButtonCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.secondary.lowContrast};
`;

type StyledButtonProps = {
  primaryColor: string;
};

const StyledButton = styled(Button)<StyledButtonProps>`
  border: 0;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  p {
    color: ${(props) => props.primaryColor};
  }
`;

const StyledSameAgainText = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledReorderContainer = styled(Card)<{
  fontFamily?: string;
  isVertical?: boolean;
}>(({ theme, cardStyles, isVertical }) => ({
  fontFamily: cardStyles?.fontFamily || 'inherit',
  position: 'relative',
  color: theme.palette.text.primary,
  ...(!isVertical
    ? {
        [theme.breakpoints.up('md')]: {
          width: 'calc(100% + 50vw - 570px + 88px)',
        },
        [theme.breakpoints.up('lg')]: {
          width: 'calc(100% + 50vw - 820px + 88px)',
        },

        '& .reorder-container-card-content': {
          padding: 0,
        },
      }
    : {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '100%',
          '& .reorder-container-card-content': {
            paddingBottom: 0,
          },
        },
      }),
}));

const StyledInnerGridPolice = styled(InnerGridPolice)<{
  isVertical?: boolean;
}>(({ isVertical, theme }) => ({
  ...(isVertical
    ? {
        margin: `0 !important`,
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 0,
        },
      }
    : {}),
}));

type ReorderProps = {
  borderradius?: string;
  buttonborderandtextcolor?: string;
  buttonborderradius?: string;
  cardborderradius?: string;
  cardbackgroundcolor?: string;
  cardBorderWidth?: string;
  buttonBorderWidth?: string;
  fontfamily?: string;
  reordertextcolor?: string;
  style?: Record<string, string>;
  allorderscardborder?: string;
  widgetbackgroundcolor?: string;
  textcolor?: string;
  cardWidth?: string;
  isVertical?: boolean;
  showViewAllOrdersCard?: boolean;
  showNoOrdersMessage?: boolean;
};

const Reorder = (props: ReorderProps): JSX.Element => {
  // lowercase props as parsing from html attributes
  const {
    borderradius,
    buttonborderandtextcolor,
    buttonBorderWidth,
    cardbackgroundcolor,
    cardborderradius,
    cardBorderWidth,
    fontfamily,
    buttonborderradius,
    reordertextcolor,
    allorderscardborder,
    widgetbackgroundcolor,
    textcolor,
    cardWidth = '240px',
    style = {},
    isVertical,
    showViewAllOrdersCard = true,
    showNoOrdersMessage,
  } = props;
  const { translate } = useTranslation();
  const [isServer, setIsServer] = useState(true);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { language, appId } = useConfig();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { get } = useApi();
  const { globalStyle } = useDesignSchema('v2');
  const { trackOrderEvent } = useTracking();

  const getReorders = async (): Promise<void> => {
    try {
      const { data } = await get<OrderType[]>({
        path: '/OrderSuggestions/ReOrderList',
        params: {
          count: '6',
        },
      });
      if (data?.Success === true && data?.Data) {
        setOrders(data?.Data);
      }
    } catch {
      // TODO: Handle error
    }
  };

  useEffect(() => {
    if (isLoggedIn && appId) {
      void getReorders();
    }
  }, [isLoggedIn, appId]);

  const theme = useTheme();

  const primary = globalStyle?.colors?.primary || '#000000';

  const buttonTextAndBorderColor = buttonborderandtextcolor || primary;

  const allOrdersCard = (): JSX.Element => {
    const primaryColor = style?.color || buttonTextAndBorderColor;
    return (
      <StyledActionButtonCard
        cardStyles={{
          borderRadius: style?.borderRadius || cardborderradius,
          border: style?.border || allorderscardborder,
          backgroundColor:
            cardbackgroundcolor || theme.palette.background.default,
          borderWidth: cardBorderWidth || '1px',
        }}
        fdKey="all-orders"
        width={cardWidth}
        key="show-all-card"
      >
        <StyledButton
          styles={{
            borderRadius: buttonborderradius,
            ...style,
            backgroundColor: 'transparent',
            border: 'none',
          }}
          fdKey="all-orders-button"
          primaryColor={primaryColor}
          actionText={translate('All_Previous_Orders')}
          action={() => navigate('/order#/history')}
          ariaLabel={translate('All_Previous_Orders_Button')}
          icon={<StyledArrowRight primarycolor={primaryColor} />}
        />
      </StyledActionButtonCard>
    );
  };

  useEffect(() => {
    setIsServer(false);
  }, []);

  const handleReorder = (order: OrderType, index: number): void => {
    trackOrderEvent('Reorder', {
      placement: (index + 1).toString(),
      value: order.TotalAmount?.toString(),
    });
    navigate(`/order#/reorder/${order.OrderId}`);
  };

  const getListItems = (): JSX.Element[] => {
    const ordersToDisplay = orders.length > 5 ? orders.slice(0, 5) : orders;
    const items = ordersToDisplay?.map((order: OrderType, index: number) => {
      if (!order?.OrderId) return <></>;

      const description = order?.MenuItems?.map((item) => item.Name).join(', ');

      return (
        <ReorderCard
          style={style}
          id={order.OrderId}
          key={order.OrderId}
          reorderTextColor={reordertextcolor}
          buttonBorderRadius={buttonborderradius}
          cardBorderWidth={cardBorderWidth}
          buttonBorderWidth={buttonBorderWidth}
          primaryColor={buttonTextAndBorderColor}
          cardBackgroundColor={cardbackgroundcolor}
          cardBorderRadius={cardborderradius}
          action={(): void => handleReorder(order, index)}
          actionText={translate('Reorder')}
          description={description}
          cardWidth={cardWidth}
          price={
            isServer
              ? ''
              : formatPrice(
                  order.TotalAmount,
                  order.Currency?.IsoCode || 'EUR',
                  language,
                  false,
                  'narrowSymbol',
                )
          }
        />
      );
    });
    if (showViewAllOrdersCard && orders.length > 5) {
      items.push(allOrdersCard());
    }
    return items;
  };

  const getReordersList = (): JSX.Element =>
    isVertical ? (
      <VerticalReorder
        borderWidth={cardBorderWidth}
        borderRadius="24px"
        listItems={getListItems()}
      />
    ) : (
      <HorizontalList
        listItems={getListItems()}
        gap={theme.spacing(3)}
      ></HorizontalList>
    );

  if (!showNoOrdersMessage && orders?.length === 0) {
    return <></>;
  }

  return (
    <StyledInnerGridPolice shouldOverFlowMobile isVertical={isVertical}>
      <StyledWrapper ownerState={{ borderRadius: borderradius }}>
        <div style={style}>
          <StyledReorderContainer
            cardStyles={{
              fontFamily: fontfamily,
              backgroundColor: widgetbackgroundcolor,
              textColor: textcolor,
            }}
            isVertical={isVertical}
            fdKey="reorder-container"
            maxWidth={isMobile ? '100%' : 'fit-content'}
          >
            <StyledSameAgainText>
              <Typography variant="body1Medium">
                {translate('Same_Again')}
              </Typography>
            </StyledSameAgainText>

            {orders?.length === 0 ? (
              <NoOrders
                borderWidth={cardBorderWidth}
                borderRadius="24px"
                isVertical={isVertical}
                primaryColor={buttonTextAndBorderColor}
                textColor={reordertextcolor}
                translate={translate}
              />
            ) : (
              getReordersList()
            )}
          </StyledReorderContainer>
        </div>
      </StyledWrapper>
    </StyledInnerGridPolice>
  );
};

export default Reorder;
