import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Footer from '@Components/LegacyElixir/Footer';
import {
  useCustomPageSchema,
  useGetHeaderStateV2,
  useGetHeadernFooterState,
} from '@Providers/CustomPageSchemaProvider';
import debounce from 'lodash.debounce';
import { useConfig } from '@Src/providers/ConfigProvider';
import { PreviewBanner } from './LegacyElixir/Preview';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import Header from './LegacyElixir/Header';
import HeaderV2 from './WebsiteV2/Header';
import FooterV2 from './WebsiteV2/Footer';
import { setIsLoggedIn } from '@Src/slices/userSlice';
import Cookies from 'js-cookie';
import {
  type V2CustomPageType,
  V2PageType,
  type CustomPageType,
  V2Templates,
} from '@Src/middlewares/CustomPageTypes';
import Banner from './WebsiteV2/Banner';
import { styled } from '@mui/material/styles';
import {
  BaseGridPolice,
  StyledBaseContainer,
} from './WebsiteV2/BaseGridElements';
import useApi from '@Src/hooks/useApi';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import {
  SUPPORTED_LANGUAGE,
  type SupportedLanguage,
} from '@Src/middlewares/utils/constants';

const StyledBody = styled('div')`
  min-height: 65vh;
`;

const StyledBaseGridPolice = styled(BaseGridPolice)`
  min-height: 65vh;
`;

const StyledContainer = styled('div')<{ pageStyles?: string }>`
  ${(props) => props.pageStyles || ''}

  .order-online-h2 {
    text-shadow: 1px 1px 7px rgba(150, 150, 150, 1);
  }

  @media only screen and (max-width: 568px) {
    .restAddresses {
      line-height: 0.4;
    }
  }

  #flipdish-menu .has-feedback label ~ .form-control-feedback {
    top: 15px !important;
  }

  #skipNavigation {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;

const StyledContainerV2 = styled('div')<{ pageStyles?: string }>`
  ${({ theme }) =>
    theme.template === V2Templates.boxedUp
      ? {
          backgroundColor: theme.palette.secondary.extraLowContrast,
          paddingTop: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3),
          },
        }
      : { backgroundColor: theme.palette.background.default }}
  ${(props) => props.pageStyles || ''}
`;

const pageWithoutBaseMargin = [
  V2PageType.EmptyNoHeaderPage,
  V2PageType.EmptyPage,
];

export const PageLayout = ({
  children,
  isFullWidth = false,
}: {
  children: JSX.Element | JSX.Element[];
  isFullWidth?: boolean;
}): JSX.Element => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showScrollToTopButton, setScrollToTopButton] =
    useState<boolean>(false);
  // removing `/` from pathname
  // `/index` -> `index`
  const path = location.pathname.substring(1);
  const pathKey = path === '' ? 'index' : path;
  const { header, footer } = useGetHeadernFooterState(pathKey);
  const { get } = useApi();
  const enableSafariLogin = useFeatureFlag('enableSafariLogin');
  const dispatch = useAppDispatch();
  const { header: v2Header, footer: v2Footer } = useGetHeaderStateV2(pathKey);
  const pageSchema = useCustomPageSchema();
  const designSchema = useDesignSchema();
  let pageStyles;
  if (designSchema.schemaVersion === 'v2') {
    pageStyles = (pageSchema[pathKey] as V2CustomPageType)?.pageCss;
  } else {
    pageStyles = (pageSchema[pathKey] as CustomPageType)?.pageStyles;
  }

  const { appId, isPreview, isCustomDomain } = useConfig();

  useEffect(() => {
    document.body.setAttribute('id', pathKey);
    document.body.classList.add(pathKey, 'default');
    if (designSchema.schemaVersion === 'v2') {
      document.body.setAttribute('data-version', 'v2');
      document.body.setAttribute('data-template', designSchema.template);
    }
  }, [pathKey]);

  useEffect(() => {
    if (designSchema.schemaVersion === 'v2') {
      if (enableSafariLogin) {
        void (async () => {
          const response = await get<{ UserId: number }>({
            path: '/Account/AccountDetails',
          });
          if (!response.error) {
            dispatch(setIsLoggedIn(true));
          }
        })();
      } else {
        dispatch(setIsLoggedIn(!!Cookies.get('flipdish-token')));
      }
      setupLanguageCookie();
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // TODO: Investigate why setting this cookie on server side doesnt work in production
  // Setting this cookie client side as a temporary fix
  const setupLanguageCookie = (): void => {
    const value = searchParams.get('lang');
    if (value && SUPPORTED_LANGUAGE.includes(value as SupportedLanguage)) {
      let cookiePath = '/';
      // Need to set cookie path with appId in case of panacea URLs
      if (!isCustomDomain) {
        cookiePath = `/${appId}`;
      }
      const sixMonthsDate = new Date();
      sixMonthsDate.setMonth(sixMonthsDate.getMonth() + 6); // Expires in 6 months
      Cookies?.set('flipdish-customer-language', value, {
        expires: sixMonthsDate,
        path: cookiePath,
      });
    }
  };

  const handleScroll = (): void => {
    handleScrollToTopVisibility(window.pageYOffset);
  };

  const handleScrollToTopVisibility = useCallback(
    debounce((offset: number) => {
      setScrollToTopButton(offset > 7000);
    }, 200),
    [],
  );
  const handleScrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (designSchema.schemaVersion === 'v2') {
    const urlsToShowCTABanner = ['/'];
    Object.entries(
      (pageSchema as Record<string, V2CustomPageType>) || {},
    )?.forEach(([key, value]) => {
      if (
        (value?.pageType as unknown as V2PageType) === V2PageType.LandingPage
      ) {
        urlsToShowCTABanner.push(`/${key}`);
      }
    });
    const pageKey = (pageSchema[pathKey] as V2CustomPageType)?.pageType;
    const isLandingPage = pageKey === V2PageType.LandingPage;
    const showCTABanner =
      isLandingPage || ['/', '/index'].includes(location.pathname);

    const banner = designSchema.banner;
    const StyledContainer =
      pageWithoutBaseMargin.includes(pageKey || V2PageType.LandingPage) ||
      (isLandingPage && V2Templates.classic === designSchema.template)
        ? StyledBody
        : StyledBaseGridPolice;
    const StyledBaseContainerHolder =
      isLandingPage && V2Templates.classic === designSchema.template
        ? 'div'
        : StyledBaseContainer;

    return (
      <StyledContainerV2 pageStyles={pageStyles} id="website-v2">
        {isPreview && <PreviewBanner />}
        {banner?.enabled && showCTABanner && <Banner />}
        {v2Header && <HeaderV2 />}
        <StyledBaseContainerHolder isFullWidth={isFullWidth}>
          <StyledContainer isFullWidth={isFullWidth}>
            {children}
          </StyledContainer>
        </StyledBaseContainerHolder>
        {v2Footer && <FooterV2 />}
      </StyledContainerV2>
    );
  }
  return (
    <StyledContainer pageStyles={pageStyles} id="elixir">
      {isPreview && <PreviewBanner />}
      {header && <Header />}
      <StyledBody>{children}</StyledBody>
      {footer && <Footer />}
      <button
        onClick={handleScrollToTop}
        className={`cd-top ${showScrollToTopButton ? 'cd-is-visible' : ''}`}
      />
    </StyledContainer>
  );
};

export default PageLayout;
