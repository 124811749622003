import { createThemeOptions } from '@Src/styles/theme';
import { type Theme, createTheme, lighten } from '@mui/material/styles';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';
import { getColorSchemeExtended } from '@Src/styles/schemes';
import { type DesignSchemaType } from '@Src/middlewares/DesignConfigTypes';

const defaultTertiaryColor = '#e8dcc6';

export let theme: Theme;

export const initializeTheme = (designSchema: DesignSchemaType): Theme => {
  const template =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.template || V2Templates.dishy
      : undefined;
  const primaryFont =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.globalStyle?.fonts?.primary
      : '';
  const secondaryFont =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.globalStyle?.fonts?.secondary
      : '';

  const primaryColor =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.globalStyle?.colors?.primary || ''
      : designSchema?.appDesignScheme?.Primary;

  const secondaryColor =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.globalStyle?.colors?.secondary || ''
      : designSchema?.appDesignScheme?.Secondary;

  const tertiaryColor =
    designSchema.schemaVersion === 'v2'
      ? designSchema?.globalStyle?.colors?.tertiary || ''
      : '';

  const colorScheme =
    designSchema.schemaVersion === 'v2'
      ? designSchema.globalStyle?.colorScheme
      : undefined;

  const lightestColor = lighten(primaryColor, 0.9);

  const colorSecondary = secondaryColor || lighten(primaryColor, 0.756);
  const colorTertiary = tertiaryColor || defaultTertiaryColor;

  const colorSchemeExtended = getColorSchemeExtended(colorScheme);

  const themeOptions = createThemeOptions({
    colorScheme: colorSchemeExtended,
    lightestColor,
    primaryColor,
    secondaryColor: colorSecondary,
    primaryFont: primaryFont || 'Roboto',
    secondaryFont: secondaryFont || 'sans-serif',
    tertiaryColor: colorTertiary,
    template: template || '',
  });

  theme = createTheme(themeOptions);
  theme = createTheme(theme, {
    palette: {
      tertiary: theme.palette.augmentColor({
        color: {
          main: colorTertiary,
        },
        name: 'tertiary',
      }),
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          root: {
            '.MuiPaper-root': {
              backgroundColor:
                theme.template === V2Templates.boxedUp
                  ? theme.palette.secondary.main
                  : theme.palette.background.default,
              color:
                theme.template === V2Templates.boxedUp
                  ? theme.palette.secondary.contrastText
                  : theme.palette.text.primary,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover,&:focus': {
              backgroundColor: 'transparent !important',
              a: {
                color: theme.palette.primary.main,
              },
            },
            a: {
              color: 'inherit',
              textDecoration: 'none !important',
            },
            span: {
              color: 'inherit',
            },
          },
        },
      },
    },
  });
  return theme;
};
