import { Drawer as MuiDrawer, styled } from '@mui/material';
import { type DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';

export interface DrawerProps extends MuiDrawerProps {
  children: JSX.Element | JSX.Element[];
}

const StyledDrawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    padding: ${({ theme }) => theme.spacing(2)};
    width: 260px;
  }
`;

export function Drawer(props: DrawerProps): JSX.Element {
  const { children, ...rest } = props;
  return (
    <StyledDrawer anchor="right" {...rest}>
      <div>{children}</div>
    </StyledDrawer>
  );
}
