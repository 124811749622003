import { getIpInfo } from '@Src/services/locationService';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';
import { type RootState } from '@Src/store';

export const fetchIpInfo = createAsyncThunk(
  'location/fetchIpInfo',
  async (isLocal: boolean, thunkApi) => {
    try {
      thunkApi.dispatch({
        type: 'location/fetchIpInfo',
      });

      // dont call ipinfo for dev builds
      if (isLocal) {
        const state = thunkApi.getState() as RootState;
        return thunkApi.fulfillWithValue({
          ipInfo: undefined,
          geo: state.location.geo,
        });
      }
      const result = await getIpInfo();
      if (result?.loc) {
        const [lat, lng] = result?.loc?.split(',').map(parseFloat);
        const geo = { Latitude: lat, Longitude: lng };
        return { ipInfo: result, geo };
      } else {
        return thunkApi.rejectWithValue({ error: 'No location found' });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(`fetchIpInfo`, {
        errorMessage: e.message,
        errorStack: e.stack,
      });
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
