import type React from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@mui/material/';
import { type DialogProps as MuiDialogProps } from '@mui/material/Dialog';

export interface DialogProps extends MuiDialogProps {
  actions?: JSX.Element[] | JSX.Element;
  children: React.ReactNode;
  titleComponent?: JSX.Element;
  ariaLabel?: string;
  onCloseDialog?: () => void;
}

const StyledDialogContent = styled(MuiDialogContent)`
  padding: 0;
`;

function Dialog(props: DialogProps): JSX.Element {
  const {
    actions = null,
    ariaLabel = '',
    children,
    onCloseDialog,
    titleComponent,
    ...rest
  } = props;

  return (
    <MuiDialog
      className="fd-dialog"
      aria-label={ariaLabel}
      onClose={onCloseDialog}
      {...rest}
    >
      <StyledDialogContent data-fd="dialog-content">
        {children}
      </StyledDialogContent>
      {actions && (
        <MuiDialogActions data-fd="dialog-actions">{actions}</MuiDialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;
