import { styled } from '@mui/material/styles';
import { ReactComponent as DeliveryAddressIcon } from '@Src/assets/images/location.svg';
import { ReactComponent as StoreAddressIcon } from '@Src/assets/images/store.svg';
import { ReactComponent as EditIcon } from '@Src/assets/images/edit.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import LinkButton from '@Components/ui/LinkButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StoreStatusIcon } from '../StoreStatusIcon';
import { ReactComponent as SearchIcon } from '@Src/assets/images/search.svg';
import { UseCurrentLocationButton } from '../EditOrderSettingsDialog/DeliverySettings/UseCurrentLocationButton';
import { type DeliveryFlowStepType } from '../EditOrderSettingsDialog/DeliverySettings';
import { useTracking } from '@Src/hooks/useTracking';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import {
  type SupportedCountries,
  getPostCodeExample,
  getPostCodeLabel,
} from '@Src/utility/locationUtils';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { selectHasSingleStoreTypeOnly } from '@Src/selectors/stores.selectors';
import MobileAppBadges from '@Components/Widgets/Apps/MobileAppBadges';
import { Hidden, type Theme, useMediaQuery } from '@mui/material';
import MuiButton from '@Components/ui/MuiButton';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';
import { Chip } from '@Components/ui/Chip';

const StyledContentContainer = styled(Grid)<{
  ownerState: {
    isToggleHidden?: boolean;
    borderRadius?: string;
    boxShadow?: string;
    paddingBottom?: string;
    mobileLayout?: boolean;
  };
}>(({ ownerState, theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: ownerState.borderRadius || theme.shape.borderRadius * 2,
  border: '1px solid',
  borderColor: theme.palette.secondary.lowContrast,
  boxShadow:
    ownerState.boxShadow || `0px 12px 36px 0px ${theme.palette.text.primary}26`,
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: ownerState.isToggleHidden ? theme.spacing(-3) : theme.spacing(-4),
  paddingTop: ownerState.isToggleHidden ? theme.spacing(2) : theme.spacing(4),
  paddingRight: theme.spacing(3),
  paddingBottom: ownerState.paddingBottom || theme.spacing(2),
  paddingLeft: theme.spacing(3.5),
  alignItems: 'flex-end',
  ...(ownerState.mobileLayout
    ? {
        flexDirection: 'column',
        alignItems: 'inherit',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
      }
    : {}),
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    alignItems: 'inherit',
    border: 'none',
    flexDirection: 'column',
    marginTop: ownerState.isToggleHidden
      ? theme.spacing(-3.5)
      : theme.spacing(-4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    ...(ownerState.mobileLayout
      ? {
          borderRadius: '0',
        }
      : {}),
  },
}));

const StyledAddressEditContainer = styled('div')`
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  height: 16px;
  width: 16px;
`;

const StyledEditButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  textDecorationStyle: 'dashed',
  textDecorationColor: theme.palette.primary.main,
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(4),
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));

const SyledAddressContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(1)};
`;

const StyledAddressTextContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

const StyledOrderTimeText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.body};
  white-space: nowrap;
`;

const StyledAddressText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0;
`;

const StyledStatusContainer = styled('div')<{ mobileLayout?: boolean }>(
  ({ theme, mobileLayout }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '160px',
    justifyContent: 'center',
    ...(!mobileLayout
      ? {
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
            marginRight: theme.spacing(3),
          },
        }
      : {}),
  }),
);

const StyledDistanceChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1),
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

const StyledDownArrow = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '10px',
  lineHeight: 'normal',
  paddingRight: theme.spacing(1.25),
  paddingTop: theme.spacing(0.25),
  transform: 'rotate(45deg)',
}));

const StyledContainer = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'auto',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '4px',
    paddingTop: theme.spacing(1),
  },
}));

const StyledFulfillmentDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(1),
  },
}));

const StyledStatusAndOrderContainer = styled('div')<{
  ownerState: {
    buttonBorderRadius?: string;
    mobileLayout?: boolean;
  };
}>(({ theme, ownerState }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  marginTop: theme.spacing(2),
  alignItems: 'center',
  gap: theme.spacing(2),

  ...(!ownerState.mobileLayout
    ? {
        [theme.breakpoints.up('sm')]: {
          marginTop: 0,
          flexDirection: 'row',
          gap: 0,
        },
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
      }
    : {}),

  '& a': {
    borderRadius: ownerState.buttonBorderRadius,
  },
}));

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledCollectionStoreAddressIcon = styled(DeliveryAddressIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(0.5),
    flexShrink: 0,
  }),
);

const StyledLocationContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
}));

const StyledDownloadAppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(1.5),
}));

const StyledAppLinksContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  'a:not(:last-of-type)': {
    marginRight: `${theme.spacing(2)} !important`,
  },
}));

const StyledOrderButton = styled(MuiButton)<{
  ownerState: {
    buttonBorderRadius?: string;
    buttonSize?: 'lg' | 'sm';
  };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: ownerState.buttonBorderRadius,
  color: `${theme.palette.primary.contrastText} !important`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textAlign: 'center',
  minWidth: '50px',
  textDecoration: 'none',
  textTransform: 'inherit',
  width: '100%',
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  ...(ownerState.buttonSize === 'lg' && {
    padding: `${theme.spacing(2.5)} ${theme.spacing(1)}`,
  }),
}));

const StyledCollectionAddressContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledCollectionStoreAddressTextContainer = styled('div')(() => ({
  display: '-webkit-box',
  overflow: 'hidden',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));

const StyledCollectButton = styled(StyledOrderButton)(
  ({ theme, ownerState }) => ({
    width: 'auto',
    whiteSpace: 'nowrap',
    ...(ownerState.buttonSize === 'lg' && {
      padding: `${theme.spacing(2.5)} ${theme.spacing(4.5)}`,
    }),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
    },
  }),
);

const StyledLinkButton = styled(LinkButton)<{
  ownerState: {
    buttonSize?: 'lg' | 'sm';
  };
}>(({ theme, ownerState }) => ({
  whiteSpace: 'nowrap',
  ...(ownerState.buttonSize === 'lg' && {
    padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
  }),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const StyledInputButton = styled('button')<{
  ownerState: { borderWidth?: string };
}>(({ ownerState, theme }) => ({
  height: '43px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  borderWidth: ownerState.borderWidth,
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.lowContrast,
  borderRadius: '8px',
  ':hover': {
    cursor: 'pointer',
  },
}));

const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export type OrderSettingDetailsPropsType = {
  borderRadius?: string;
  borderWidth?: string;
  boxShadow?: string;
  buttonBorderRadius?: string;
  collectionStoresLength: number;
  deliveryStoresLength: number;
  distanceKm?: number;
  hasUserDeliveryAddress: boolean;
  hasSelectedDeliveryStore: boolean;
  locationName: string;
  orderButtonSize?: 'lg' | 'sm';
  orderSetting: 'Deliver' | 'Collect';
  orderUrl?: string;
  physicalRestaurantAddress?: string;
  setIsDialogOpen: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      startStep?: DeliveryFlowStepType;
    }>
  >;
  statusMessage: string;
  storeAcceptsPreorders: boolean;
  storeOpen: boolean;
  paddingBottom?: string;
  mobileLayout?: boolean;
};

export function OrderSettingDetails({
  borderRadius,
  borderWidth = '1px',
  boxShadow,
  buttonBorderRadius,
  collectionStoresLength,
  distanceKm,
  hasUserDeliveryAddress,
  hasSelectedDeliveryStore,
  locationName,
  orderButtonSize = 'sm',
  orderSetting,
  orderUrl = '',
  setIsDialogOpen,
  statusMessage,
  storeAcceptsPreorders,
  storeOpen,
  paddingBottom,
  physicalRestaurantAddress,
  mobileLayout,
}: OrderSettingDetailsPropsType): JSX.Element {
  const { translate } = useTranslation();
  const { countryCode, mobileApps } = useDesignSchema('v2');
  const { trackOrderEvent, trackNavigationEvent } = useTracking();
  const fulfillmentTypeCopyUpdateEnabled = useFeatureFlag(
    'enableFulfillmentTypeCopyUpdate',
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const onlyOpenForPreorders = !storeOpen && storeAcceptsPreorders;
  const deliveryOrderButtonText = fulfillmentTypeCopyUpdateEnabled
    ? onlyOpenForPreorders
      ? 'Preorder_for_delivery'
      : 'Order_for_delivery'
    : 'Order_now';
  const collectionOrderButtonText = fulfillmentTypeCopyUpdateEnabled
    ? onlyOpenForPreorders
      ? 'Preorder_for_collection'
      : 'Order_for_collection'
    : 'Order_now';

  const userCountryCode = useAppSelector(
    (state) => state.location.ipInfo?.country,
  ) as SupportedCountries;
  const hasSingleStoreTypeOnly = useAppSelector(selectHasSingleStoreTypeOnly);

  const showEditButton =
    !!(orderSetting === 'Collect' && collectionStoresLength > 1) ||
    orderSetting === 'Deliver';

  const handleEditClick = (isSelectStoreButton?: boolean): void => {
    if (isSelectStoreButton) {
      trackOrderEvent('Pick a Store');
    } else {
      trackNavigationEvent('Edit Order Settings', {
        orderType: orderSetting === 'Collect' ? 'Collection' : 'Delivery',
      });
    }
    setIsDialogOpen({
      isOpen: true,
    });
  };

  const handleViewMenuClick = (): void => {
    trackOrderEvent('Start Order', {
      orderType: orderSetting === 'Collect' ? 'Collection' : 'Delivery',
      section: 'Order Settings',
    });
  };

  const renderFulfillmentDetails = (): JSX.Element => {
    if (orderSetting === 'Collect') {
      if (!physicalRestaurantAddress && !distanceKm) {
        return <></>;
      }
      return (
        <StyledFulfillmentDetails>
          {physicalRestaurantAddress && (
            <StyledCollectionAddressContainer>
              <StyledCollectionStoreAddressIcon data-fd="collection-store-address-icon" />
              <StyledCollectionStoreAddressTextContainer>
                <Typography variant="finePrint1">
                  {physicalRestaurantAddress}
                </Typography>
              </StyledCollectionStoreAddressTextContainer>
            </StyledCollectionAddressContainer>
          )}
          {distanceKm && (
            <StyledDistanceChip>
              <Typography variant="finePrint1">{`${distanceKm}km`}</Typography>
            </StyledDistanceChip>
          )}
        </StyledFulfillmentDetails>
      );
    }
    // TODO: populate with delivery fee once api is provided
    return <></>;
  };

  const renderAppLinks = (): JSX.Element => (
    <Hidden smUp>
      {(mobileApps?.hasAndroid || mobileApps?.hasApple) && (
        <StyledDownloadAppContainer>
          <Typography variant="body2">
            {translate('Or_download_our_app_to_order_now')}
          </Typography>
          <StyledAppLinksContainer>
            <MobileAppBadges />
          </StyledAppLinksContainer>
        </StyledDownloadAppContainer>
      )}
    </Hidden>
  );

  const LocationIcon =
    orderSetting === 'Deliver' ? (
      <DeliveryAddressIcon data-fd="delivery-address-icon" />
    ) : (
      <StoreAddressIcon data-fd="store-address-icon" />
    );

  if (
    orderSetting === 'Deliver' &&
    (!hasUserDeliveryAddress || !hasSelectedDeliveryStore)
  ) {
    return (
      <StyledContentContainer
        container
        ownerState={{
          isToggleHidden: hasSingleStoreTypeOnly,
          borderRadius,
          boxShadow,
          paddingBottom,
          mobileLayout,
        }}
      >
        <Grid item sm={mobileLayout ? 12 : 4}>
          <Typography variant="caption">
            {translate(
              getPostCodeLabel(
                (countryCode as SupportedCountries) || userCountryCode,
              ),
            )}
          </Typography>
          <StyledInputButton
            ownerState={{ borderWidth }}
            aria-label={translate(
              getPostCodeLabel(
                (countryCode as SupportedCountries) || userCountryCode,
              ),
            )}
            onClick={() =>
              setIsDialogOpen({
                isOpen: true,
                startStep: 'addressSearch',
              })
            }
          >
            {userCountryCode ? (
              <StyledPlaceholder variant="body1xs">
                {`${translate('e.g.')} ${getPostCodeExample(
                  (countryCode as SupportedCountries) || userCountryCode,
                )}`}
              </StyledPlaceholder>
            ) : (
              // keeps search icon on right if no placeholder text
              <div />
            )}
            <StyledSearchIcon data-fd="search-icon" />
          </StyledInputButton>
        </Grid>
        <StyledLocationContainer
          item
          display="flex"
          sm={mobileLayout ? 12 : 4}
          alignItems="center"
        >
          <UseCurrentLocationButton
            onLocationRetrieved={() =>
              setIsDialogOpen({
                isOpen: true,
                startStep: 'addressConfirm',
              })
            }
          />
        </StyledLocationContainer>
        <Grid item sm={mobileLayout ? 12 : 4}>
          <StyledOrderButton
            data-fd="order-for-delivery-btn"
            ownerState={{
              buttonBorderRadius,
              buttonSize: orderButtonSize,
            }}
            onClick={() => {
              setIsDialogOpen({
                isOpen: true,
                startStep: 'addressList',
              });
            }}
          >
            {translate(deliveryOrderButtonText)}
          </StyledOrderButton>
        </Grid>
        {renderAppLinks()}
      </StyledContentContainer>
    );
  }
  if (orderSetting === 'Collect' && collectionStoresLength > 1) {
    return (
      <StyledContentContainer
        data-fd="Select Store container"
        ownerState={{
          isToggleHidden: hasSingleStoreTypeOnly,
          borderRadius,
          boxShadow,
          paddingBottom,
          mobileLayout,
        }}
      >
        <div>
          <Typography variant="caption">
            {translate('Select_the_store_to_order_from')}
          </Typography>
          <StyledInputButton
            ownerState={{ borderWidth }}
            aria-label={translate('Select_store')}
            onClick={() => handleEditClick(true)}
          >
            <StyledPlaceholder variant="body1xs">
              {translate('Select_store')}
            </StyledPlaceholder>
            {/* down caret arrow */}
            <StyledDownArrow variant="body1xs">{'\u25E2'}</StyledDownArrow>
          </StyledInputButton>
        </div>
        <StyledCollectButton
          data-fd="order-for-collection-btn"
          ownerState={{
            buttonBorderRadius,
            buttonSize: orderButtonSize,
          }}
          onClick={() => handleEditClick(true)}
        >
          {translate(collectionOrderButtonText)}
        </StyledCollectButton>
        {renderAppLinks()}
      </StyledContentContainer>
    );
  } else {
    const orderButtonText =
      orderSetting === 'Deliver'
        ? deliveryOrderButtonText
        : collectionOrderButtonText;
    return (
      <StyledContentContainer
        ownerState={{
          isToggleHidden: hasSingleStoreTypeOnly,
          borderRadius,
          boxShadow,
          paddingBottom,
          mobileLayout,
        }}
      >
        <StyledContainer>
          <StyledAddressEditContainer onClick={() => handleEditClick(false)}>
            <SyledAddressContainer>
              {LocationIcon}
              <StyledAddressTextContainer>
                <StyledOrderTimeText variant="finePrint1">
                  {translate(orderSetting)} ASAP
                </StyledOrderTimeText>
                <StyledAddressText variant="body2Medium" paragraph>
                  {locationName}
                </StyledAddressText>
              </StyledAddressTextContainer>
            </SyledAddressContainer>
            {showEditButton && (
              <StyledEditButton>
                <StyledEditIcon />
                <Typography variant="body2">{translate('Edit')}</Typography>
              </StyledEditButton>
            )}
          </StyledAddressEditContainer>
          {isMobile && renderFulfillmentDetails()}
        </StyledContainer>

        <StyledStatusAndOrderContainer
          ownerState={{ buttonBorderRadius, mobileLayout }}
        >
          <StyledStatusContainer mobileLayout={mobileLayout}>
            {!isMobile && renderFulfillmentDetails()}
            <StoreStatusIcon
              isOpen={storeOpen}
              acceptPreorders={storeAcceptsPreorders}
              size="12px"
            />
            <Typography variant="finePrint1">{statusMessage}</Typography>
          </StyledStatusContainer>
          <StyledLinkButton
            ownerState={{ buttonSize: orderButtonSize }}
            onClick={handleViewMenuClick}
            label={translate(orderButtonText)}
            href={orderUrl}
          />
        </StyledStatusAndOrderContainer>
        {renderAppLinks()}
      </StyledContentContainer>
    );
  }
}
