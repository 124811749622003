import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link } from '@Components/RoutesUtils';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useConfig } from '@Src/providers/ConfigProvider';
import { HCAPTCHA_KEY } from '@Src/BuildConstants';
import { ReactComponent as CloseIcon } from '@Src/assets/images/Close.svg';
import Dialog from '@Components/ui/Dialog';
import { TextField } from '@Components/ui/TextField';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { logger } from '@Src/utility/loggerService';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { type PhoneNumberConfigType } from '@Src/types/accountTypes';
import { useForm } from 'react-hook-form';
import MuiButton from '@Components/ui/MuiButton';
import useApi from '@Src/hooks/useApi';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';

const StyledDialog = styled(Dialog)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    & .MuiPaper-root {
      width: 480px;
    }
  }
`;
const StyledContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledCloseIcon = styled(IconButton)`
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledHeaderContainer = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

type StyledButtonProps = {
  loading: boolean;
};
const StyledButton = styled(MuiButton)<StyledButtonProps>`
  text-transform: none;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3)}`};
  width: 100%;
`;

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type PhoneNumberDialogPropsType = {
  onClose: () => void;
  onPhoneNumberSubmit: (phoneNumber: string) => void;
};
const PhoneNumberDialog = ({
  onClose,
  onPhoneNumberSubmit,
}: PhoneNumberDialogPropsType): JSX.Element => {
  const [phoneNumberConfig, setPhoneNumberConfig] = useState<
    PhoneNumberConfigType | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneConfigLoading, setPhoneConfigLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const hCaptchaRef = useRef<HCaptcha>(null);
  const { translate } = useTranslation();
  const { appId, language } = useConfig();
  const phoneInputRef = useRef<HTMLInputElement>();
  const coordinates = useAppSelector((state) => state.location.geo);
  const { config } = useDesignSchema();
  const { policyUrls } = config || {};
  const theme = useTheme();
  const { get, post } = useApi();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isNewLoginHeadersEnabled = useFeatureFlag('enableLoginHeaderUpdated');

  const { register, handleSubmit, getValues, setValue } = useForm<{
    phone: string;
  }>({
    mode: 'onChange',
  });

  useEffect(() => {
    setPhoneConfigLoading(true);
    get<{
      Prefix: string;
      AutocompletePhoneNumberLength: number;
    }>({
      path: '/Account/PhoneNumberConfig',
      params: {
        Latitude: coordinates?.Latitude?.toString() || '53.3454362',
        Longitude: coordinates?.Longitude?.toString() || '-6.2616217',
      },
    })
      .then(({ data }) => {
        if (data?.Data?.Prefix) {
          setValue('phone', data?.Data.Prefix);
          setPhoneNumberConfig(data?.Data);
        }
      })
      .catch((error) => {
        logger.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setPhoneConfigLoading(false);
          phoneInputRef?.current?.focus();
        }, 10);
      });
  }, []);

  const isValidPhoneNumber = (phoneNum: string): boolean => {
    const phoneNumber = phoneNum.replace(/\s/g, '');
    const hasPlusPrefix =
      phoneNumber.charAt(0) && phoneNumber.charAt(0) === '+';
    const newPhoneNumber =
      (hasPlusPrefix ? '+' : '') + phoneNumber.replace(/\D/g, '');
    const isNumber = newPhoneNumber.match(/^\+?\d*$/);
    setValue('phone', newPhoneNumber);
    if (!isNumber) {
      return false;
    }
    const { AutocompletePhoneNumberLength, Prefix } = phoneNumberConfig || {
      AutocompletePhoneNumberLength: 12,
      Prefix: '+1',
    };
    if (
      phoneNumber.length === AutocompletePhoneNumberLength &&
      phoneNumber.startsWith(Prefix) &&
      !hasPlusPrefix
    ) {
      handleContinue();
    }
    return true;
  };

  const genericHeaders = {
    'Content-Type': 'application/json',
    'Flipdish-App-Type': isNewLoginHeadersEnabled ? 'Photon' : 'Web',
    'Flipdish-Site-Type': 'photon-v2',
    'Flipdish-White-Label-Id': appId,
  };

  const onHCaptchaVerify = (token: string): void => {
    const headers = {
      ...genericHeaders,
      'h-captcha-response': token,
    };
    const phoneNumber = getValues().phone;
    const payload = {
      DeviceName: window.navigator.userAgent,
      PhoneNumber: phoneNumber,
    };
    post<typeof payload, number>({
      path: '/Account/RequestPhoneLoginCodeSms',
      payload,
      customHeaders: headers,
    })
      .then(({ data }) => {
        if (data?.Success) {
          onPhoneNumberSubmit(phoneNumber);
        } else if (data?.UserMessage) {
          setErrorMessage(data?.UserMessage);
          setValue('phone', '');
        } else {
          setErrorMessage('Something went wrong');
        }
      })
      .catch(() => {
        setErrorMessage('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContinue = (): void => {
    setLoading(true);
    setErrorMessage('');
    // No need to await, this triggers hcaptcha
    // which in turn triggers onHCaptchaVerify
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    hCaptchaRef?.current?.execute({ async: true });
  };

  const renderServiceTerms = (): JSX.Element[] => {
    const sentences = translate('Login_Service_Terms').split('%@');

    const urlLanguageCode = language ? `${language}/` : '';
    const flipdishTerms = `https://www.flipdish.com/${urlLanguageCode}flipdish-consumer-terms-and-conditions/`;
    const termsLinks = [
      {
        key: 'our-terms',
        translationKey: 'Terms_and_conditions',
        linkUrl: policyUrls?.terms || '/terms',
      },
      {
        key: 'flipdish-terms',
        translationKey: 'Terms_and_conditions',
        linkUrl: flipdishTerms,
      },
      {
        key: 'privacy',
        translationKey: 'Privacy_policy',
        linkUrl: policyUrls?.privacy || '/privacy',
      },
    ];

    const serviceTerms = sentences.reduce(
      (fullTermsContent: JSX.Element[], sentence, index) => {
        fullTermsContent.push(<span key={sentence}>{sentence}</span>);

        if (index < termsLinks.length) {
          fullTermsContent.push(
            <StyledLink
              key={termsLinks[index].key}
              to={termsLinks[index].linkUrl}
            >
              {translate(termsLinks[index].translationKey)}
            </StyledLink>,
          );
        }

        return fullTermsContent;
      },
      [],
    );

    return serviceTerms;
  };

  return (
    <StyledDialog
      open
      onCloseDialog={onClose}
      actions={
        <StyledButton
          loading={loading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(handleContinue)}
          disabled={loading}
          variant="contained"
        >
          {translate('Continue')}
        </StyledButton>
      }
      fullScreen={isSmallScreen}
      fullWidth={isSmallScreen}
    >
      <StyledContainer>
        <StyledCloseIcon
          aria-label="Close dialog"
          className="closeIcon"
          data-fd="close-dialog-icon"
          disableRipple
          onClick={onClose}
        >
          <CloseIcon />
        </StyledCloseIcon>
        <StyledHeaderContainer>
          <Typography variant="h2">{translate('Login_Title')}</Typography>
          <Typography variant="body2">{translate('Login_SubTitle')}</Typography>
        </StyledHeaderContainer>
        <form onSubmit={(event) => event.preventDefault()}>
          <HCaptcha
            onVerify={onHCaptchaVerify}
            ref={hCaptchaRef}
            sitekey={HCAPTCHA_KEY}
            size="invisible"
          />
          <StyledTextField
            aria-label="Please enter a phone number"
            autoFocus
            disabled={phoneConfigLoading}
            id="phone"
            name="phone"
            placeholder="e.g. +44775555555"
            type="tel"
            inputRef={phoneInputRef}
            inputProps={{
              ...register('phone', {
                required: false,
                validate: isValidPhoneNumber,
              }),
            }}
            helperText={errorMessage}
            error={errorMessage !== ''}
          />
        </form>
        <Typography variant="caption">{renderServiceTerms()}</Typography>
      </StyledContainer>
    </StyledDialog>
  );
};

export default PhoneNumberDialog;
