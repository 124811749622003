import collectionStoresReducer from '@Src/slices/collectionStoresSlice';
import deliveryAddressesReducer from '@Src/slices/deliveryAddressesSlice';
import deliveryStoresReducer from '@Src/slices/deliveryStoresSlice';
import locationReducer from '@Src/slices/locationSlice';
import loyaltyCampaignsReducer from '@Src/slices/loyaltyCampaignsSlice';
import orderSettingsReducer from '@Src/slices/orderSettingSlice';
import orderUrlReducer from '@Src/slices/orderUrlSlice';
import storeLeadTimesReducer from '@Src/slices/storeLeadTimesSlice';
import userReducer from '@Src/slices/userSlice';
import { configureStore } from './utility/reduxUtils';
import { orderUrlMiddleware } from './middlewares/orderUrlMiddleware';

export const rootReducer = {
  collectionStores: collectionStoresReducer,
  deliveryAddresses: deliveryAddressesReducer,
  deliveryStores: deliveryStoresReducer,
  location: locationReducer,
  loyaltyCampaigns: loyaltyCampaignsReducer,
  orderSettings: orderSettingsReducer,
  orderUrl: orderUrlReducer,
  storeLeadTimes: storeLeadTimesReducer,
  user: userReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(orderUrlMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
