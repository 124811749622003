import { useTranslation } from '@Src/providers/TranslationProvider';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Suspense, useState } from 'react';
import LoginDialog from './LoginDialog';

const StyledButton = styled(Button)`
  text-transform: none;
  &:hover {
    background-color: transparent;
  }
`;

const StyledLogInText = styled(Typography)(({ theme }) => ({
  textDecorationLine: 'underline',
  textDecorationStyle: 'dashed',
  textDecorationColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
}));

const StyledRecentOrdersText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.body,
}));

export const LogInTextButton = (): JSX.Element => {
  const { translate } = useTranslation();

  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const handleLoginClick = (): void => {
    setIsLoginOpen(true);
  };
  return (
    <>
      <StyledButton
        color="secondary"
        variant="text"
        size="small"
        onClick={handleLoginClick}
      >
        <StyledLogInText variant="body2">{translate('Log_in')}</StyledLogInText>
        &nbsp;
        <StyledRecentOrdersText variant="body2">
          {translate('to_view_your_recent_orders')}
        </StyledRecentOrdersText>
      </StyledButton>
      {isLoginOpen && (
        <Suspense fallback={<div>{translate('loading')}</div>}>
          <LoginDialog
            onClose={() => setIsLoginOpen(false)}
            page="Order Settings"
          />
        </Suspense>
      )}
    </>
  );
};
