import { useDesignSchema } from '@Providers/DesignSchemaProvider';

import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { ImgixResponsiveImage } from '@Components/ui/ImgixImage/responsive';
import { WelcomeText } from './WelcomeText';
import Card from '@mui/material/Card';

const StyledWelcomeCard = styled(Card)`
  box-shadow: none;
  border-radius: 0;
  padding: 16px 24px;
  margin-top: 0;
  position: absolute;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 4em;
    width: auto;
  }
`;

const StyledChildrenContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: theme.spacing(6),
  paddingRight: theme.spacing(2),
  overflowX: 'auto',
  position: 'absolute',
  scrollbarWidth: 'none',
  bottom: '28%',
  zIndex: 1200, // login modal is 1300
  '::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    bottom: '15%',
  },
}));

const StyledImageContainer = styled('div')(({ theme }) => ({
  height: '315px',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    height: '400px',
  },
}));

const StyledImgixResponsiveImage = styled(ImgixResponsiveImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

type Props = {
  backgroundImages: string[];
  children?: JSX.Element;
  hideWelcomeMessage?: boolean;
};

type StyledContainerProps = {
  noImages?: boolean;
};

const StyledContainer = styled('div')<StyledContainerProps>`
  background-color: ${({ theme, noImages }) =>
    noImages ? theme.palette.primary.main : 'inherit'};
  height: 400px;
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 315px;
  }
  overflow: hidden;
`;

export const Landing = (props: Props): JSX.Element => {
  const { backgroundImages, children, hideWelcomeMessage } = props;

  const { imageAttributes } = useDesignSchema('v2');
  const noImages = !backgroundImages || backgroundImages.length === 0;

  return (
    <div id="hero" data-fd="landing">
      <StyledContainer noImages={noImages}>
        <Grid container>
          <Grid item xs={12}>
            {!hideWelcomeMessage && (
              <StyledWelcomeCard elevation={3} data-fd={'welcome-card'}>
                <WelcomeText />
              </StyledWelcomeCard>
            )}
            {children && (
              <StyledChildrenContainer>{children}</StyledChildrenContainer>
            )}
          </Grid>
          <Grid item xs={12}>
            {backgroundImages[0] && (
              <StyledImageContainer className="item" key={`image`}>
                <StyledImgixResponsiveImage
                  data-fd={`main-image`}
                  srcDesktop={`${backgroundImages[0]}?w=1440&h=400`}
                  srcMobile={`${backgroundImages[0]}?w=700&h=400`}
                  alt={
                    imageAttributes?.[backgroundImages[0]]?.alt ||
                    `landing page image`
                  }
                />
              </StyledImageContainer>
            )}
          </Grid>
        </Grid>
      </StyledContainer>
    </div>
  );
};

export default Landing;
