import { initAblyClient } from '@Src/config/ably-client';
import type * as Ably from 'ably/promises';
import { useState, useEffect } from 'react';
import { useApi } from './useApi';
import { useAppSelector } from './useAppSelector';
import { useConfig } from '@Src/providers/ConfigProvider';

const useAbly = (): {
  ablyClient: Ably.Realtime | null;
  loggedInUserId: string | null;
} => {
  const [ablyClient, setAblyClient] = useState<Ably.Realtime | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const { appId } = useConfig();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { get } = useApi();

  useEffect(() => {
    if (isLoggedIn && !userId) {
      void (async () => {
        const response = await get<{ UserId: number }>({
          path: '/Account/AccountDetails',
        });
        if (!response.error) {
          const userData = response.data?.Data;
          const userId = userData?.UserId?.toString();
          userId && setUserId(userId);
          try {
            localStorage.setItem('flipdish-customer', JSON.stringify(userData));
          } catch (e) {
            console.error(e);
          }
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    void (async (): Promise<void> => {
      if (userId && !ablyClient) {
        const client = await initAblyClient({ appId, userId });
        setAblyClient(client);
      }
    })();

    return () => {
      if (ablyClient) {
        ablyClient.close();
      }
    };
  }, [userId]);

  return { ablyClient, loggedInUserId: userId };
};

export default useAbly;
