import styled from 'styled-components';
import { useTranslation } from '@Providers/TranslationProvider';

const StyledFromText = styled.i`
  color: #595959;
  font-size: '75%';
`;

type MenuPreviewItemProps = {
  description?: string;
  isFromPrice: boolean;
  price: string;
  title: string;
};
const MenuPreviewItem = ({
  description = '',
  isFromPrice,
  price,
  title,
}: MenuPreviewItemProps): JSX.Element => {
  const { translate } = useTranslation();
  return (
    <li>
      {title}
      <div className="detail">
        {description}
        <span className="price">
          {isFromPrice && <StyledFromText>{translate('from')} </StyledFromText>}
          {price}
        </span>
      </div>
    </li>
  );
};

export default MenuPreviewItem;
