import { type V2ContactPageType } from '@Src/middlewares/CustomPageTypes';
import ContactForms from '.';

export const ContactFormWidget = ({
  contactFormData,
  borderRadius,
  buttonBorderRadius,
  formPaddingLeft,
  formPaddingRight,
  formPaddingBottom,
  formPaddingTop,
  imagePaddingLeft,
  imagePaddingTop,
}: {
  contactFormData: V2ContactPageType;
  borderRadius?: string;
  buttonBorderRadius?: string;
  formPaddingLeft?: string;
  formPaddingRight?: string;
  formPaddingBottom?: string;
  formPaddingTop?: string;
  imagePaddingLeft?: string;
  imagePaddingTop?: string;
}): JSX.Element => (
  <ContactForms
    hasContactForm={true}
    reservationsFormDefaultSelected={false}
    contactFormData={contactFormData}
    borderRadius={borderRadius}
    buttonBorderRadius={buttonBorderRadius}
    formPaddingLeft={formPaddingLeft}
    formPaddingRight={formPaddingRight}
    formPaddingBottom={formPaddingBottom}
    formPaddingTop={formPaddingTop}
    imagePaddingLeft={imagePaddingLeft}
    imagePaddingTop={imagePaddingTop}
  />
);

export default ContactFormWidget;
