import {
  V2Templates,
  type V2AboutUsPageType,
} from '@Src/middlewares/CustomPageTypes';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import Dishy from './dishy';
import Highlight from '../Highlight';
import Gallery from '@Components/Widgets/Gallery';
import { InnerGridPolice } from '../BaseGridElements';

export const AboutUs = ({ data }: { data: V2AboutUsPageType }): JSX.Element => {
  const { template } = useDesignSchema('v2');

  if (!data) {
    return <></>;
  }

  const blocks = data.blocks;
  const blockIds =
    Object.keys(data.blocks)
      ?.filter((id) => !blocks?.[id]?.isHidden)
      ?.sort((a, b) => (blocks?.[a]?.order || 0) - (blocks?.[b]?.order || 0)) ||
    [];

  const blockElements = blockIds?.map((blockId, index) => {
    const isOverFlowing = template === V2Templates.dishy && index !== 0;
    return (
      <Highlight
        isPlainSection={!isOverFlowing}
        orientation={index % 2 === 0 ? 'right' : 'left'}
        hasBackground={index === 0 && !!blocks[blockId]?.description}
        key={index}
        data={blocks[blockId]}
      />
    );
  });

  switch (template) {
    case V2Templates.boxedUp:
      return (
        <>
          {blockElements}
          {data.galleryEnabled && (
            <InnerGridPolice>
              <Gallery />
            </InnerGridPolice>
          )}
        </>
      );
    default:
      return (
        <Dishy blocks={blockElements} data={data}>
          <InnerGridPolice shouldOverFlowMobile>
            {data.galleryEnabled && <Gallery />}
          </InnerGridPolice>
        </Dishy>
      );
  }
};

export default AboutUs;
