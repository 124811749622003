import { styled } from '@mui/material/styles';
import { Fragment } from 'react';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useNavigate } from 'react-router-dom';
import { HorizontalList } from '@Components/ui/HorizontalList.tsx';
import { Button, Typography } from '@mui/material';
import { ReactComponent as ArrowRight } from '@Src/assets/images/ArrowRight.svg';

const StyledContainer = styled('div')<{
  ownerState: { borderWidth?: string; borderRadius?: string };
}>(({ ownerState, theme }) => ({
  marginTop: theme.spacing(1.5),
  borderStyle: 'solid',
  borderWidth: ownerState.borderWidth || '1px',
  borderColor: theme.palette.secondary.lowContrast,
  borderRadius: ownerState.borderRadius,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: 'none',
  '.scroll-list': {
    display: 'block',
  },
  '.reorder-card': {
    border: 'none',
    borderBottomWidth: ownerState.borderWidth,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.lowContrast,
  },
  '.reorder-card:last-child': {
    borderBottom: 'none',
  },
  button: {
    borderWidth: ownerState.borderWidth,
  },
}));

const StyledButtonContainer = styled('div')<{
  ownerState: { borderRadius?: string; borderWidth?: string };
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-style: solid;
  border-top-width: ${({ ownerState }) => ownerState.borderWidth};
  border-top-color: ${({ theme }) => theme.palette.secondary.lowContrast};
`;

const StyledButton = styled(Button)`
  text-transform: none;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 36px;
  width: 36px;
`;

type VerticalReorderProps = {
  listItems: JSX.Element[];
  borderWidth?: string;
  borderRadius?: string;
};
const VerticalReorder = (props: VerticalReorderProps): JSX.Element => {
  const { listItems, borderWidth, borderRadius } = props;
  const { translate } = useTranslation();
  const navigate = useNavigate();
  return (
    <StyledContainer ownerState={{ borderRadius, borderWidth }}>
      <HorizontalList
        height="245px"
        maxHeight="245px"
        gap="none"
        isHorizontal={false}
        listItems={[
          ...(listItems || [<Fragment key="no-items-passed"></Fragment>]),
        ]}
      />
      <StyledButtonContainer ownerState={{ borderRadius, borderWidth }}>
        <StyledButton
          onClick={() => navigate('/order#/history')}
          variant="text"
        >
          <Typography variant="buttonSmall">
            {translate('All_Previous_Orders')}
          </Typography>
          <StyledArrowRight />
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default VerticalReorder;
