import { type AppDispatch, type RootState } from '@Src/store';
import {
  type AnyAction,
  type Middleware,
  type MiddlewareAPI,
} from '@Src/utility/reduxUtils';

import { listenForSetIsCollection } from '@Src/thunks/listenForSetIsCollectionThunks';
import { setIsCollection } from '@Src/slices/orderSettingSlice';

export const orderUrlMiddleware: Middleware =
  (store: MiddlewareAPI<AppDispatch, RootState>) =>
  (next: AppDispatch) =>
  (action: AnyAction) => {
    if (action.type === setIsCollection.type) {
      void store.dispatch(
        listenForSetIsCollection({ isCollection: action.payload }),
      );
    }
    return next(action);
  };
