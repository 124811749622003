import { type ReactElement } from 'react';

const AppStoreBadge = (): ReactElement => (
  <svg
    width="120"
    height="40"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1404_64)">
      <path
        d="M110.1 0H9.5C9.2 0 8.8 0 8.4 0C8.1 0 7.8 0 7.5 0C6.8 0 6.2 0.1 5.5 0.2C4.9 0.3 4.2 0.5 3.6 0.8C3 1.1 2.5 1.5 2 2C1.5 2.5 1.1 3 0.8 3.6C0.5 4.2 0.3 4.9 0.2 5.5C0.1 6.2 0 6.9 0 7.5C0 7.8 0 8.1 0 8.4V31.5C0 31.8 0 32.1 0 32.4C0 33.1 0.1 33.7 0.2 34.4C0.3 35.1 0.5 35.7 0.8 36.3C1.1 37 1.5 37.5 2 38C2.5 38.5 3 38.9 3.6 39.2C4.2 39.5 4.8 39.7 5.5 39.8C6.2 39.9 6.8 40 7.5 40C7.8 40 8.1 40 8.4 40C8.8 40 9.1 40 9.5 40H110.1C110.5 40 110.8 40 111.2 40C111.5 40 111.8 40 112.1 40C112.8 40 113.4 39.9 114.1 39.8C114.8 39.7 115.4 39.5 116 39.2C116.6 38.9 117.1 38.5 117.6 38C118.1 37.5 118.5 37 118.8 36.4C119.1 35.8 119.3 35.2 119.4 34.5C119.5 33.8 119.6 33.2 119.6 32.5C119.6 32.2 119.6 31.9 119.6 31.6C119.6 31.2 119.6 30.9 119.6 30.5V9.5C119.6 9.1 119.6 8.8 119.6 8.4C119.6 8.1 119.6 7.8 119.6 7.5C119.6 6.8 119.5 6.2 119.4 5.5C119.3 4.8 119.1 4.2 118.8 3.6C118.2 2.4 117.2 1.4 116 0.8C115.4 0.5 114.8 0.3 114.1 0.2C113.4 0.1 112.8 0 112.1 0C111.8 0 111.5 0 111.2 0C110.9 0 110.5 0 110.1 0Z"
        fill="black"
      />
      <path
        d="M25 19.9C25 18.1 25.9 16.5 27.5 15.6C26.5 14.2 25 13.4 23.3 13.4C21.6 13.2 19.9 14.4 19 14.4C18.1 14.4 16.7 13.4 15.2 13.4C13.3 13.5 11.5 14.5 10.5 16.2C8.5 19.7 10 24.8 11.9 27.6C12.9 29 14 30.5 15.5 30.5C16.9 30.4 17.5 29.6 19.2 29.6C20.9 29.6 21.4 30.5 22.9 30.5C24.4 30.5 25.4 29.1 26.4 27.7C27.1 26.7 27.6 25.6 28 24.5C26.2 23.7 25 21.9 25 19.9Z"
        fill="white"
      />
      <path
        d="M22.2 11.5C23 10.5 23.5 9.20002 23.4 7.90002C22 8.00002 20.8 8.60002 20 9.60002C19.2 10.6 18.7 11.8 18.8 13.1C20.1 13.1 21.3 12.5 22.2 11.5Z"
        fill="white"
      />
      <path
        d="M42.3 27.1H37.6L36.5 30.5H34.5L39 18.1H41L45.5 30.5H43.5L42.3 27.1ZM38.1 25.6H41.9L40 20.1H39.9L38.1 25.6Z"
        fill="white"
      />
      <path
        d="M55.2 26C55.2 28.8 53.7 30.6 51.4 30.6C50.2 30.7 49.1 30 48.6 29V33.5H46.7V21.5H48.5V23C49.1 22 50.2 21.4 51.4 21.4C53.6 21.3 55.2 23.2 55.2 26ZM53.2 26C53.2 24.2 52.3 23 50.8 23C49.4 23 48.4 24.2 48.4 26C48.4 27.8 49.4 29 50.8 29C52.3 29 53.2 27.8 53.2 26Z"
        fill="white"
      />
      <path
        d="M65.1 26C65.1 28.8 63.6 30.6 61.3 30.6C60.1 30.7 59 30 58.5 29V33.5H56.6V21.5H58.4V23C59 22 60.1 21.4 61.3 21.4C63.6 21.3 65.1 23.2 65.1 26ZM63.2 26C63.2 24.2 62.3 23 60.8 23C59.4 23 58.4 24.2 58.4 26C58.4 27.8 59.4 29 60.8 29C62.3 29 63.2 27.8 63.2 26Z"
        fill="white"
      />
      <path
        d="M71.7 27C71.8 28.2 73 29 74.7 29C76.3 29 77.4 28.2 77.4 27.1C77.4 26.1 76.7 25.6 75.1 25.2L73.5 24.8C71.2 24.2 70.2 23.2 70.2 21.5C70.2 19.4 72.1 17.9 74.7 17.9C77.3 17.9 79.1 19.4 79.2 21.5H77.3C77.2 20.3 76.2 19.5 74.7 19.5C73.2 19.5 72.2 20.3 72.2 21.4C72.2 22.3 72.9 22.8 74.5 23.2L75.9 23.5C78.4 24.1 79.5 25.1 79.5 26.9C79.5 29.2 77.7 30.7 74.7 30.7C71.9 30.7 70.1 29.3 70 27H71.7Z"
        fill="white"
      />
      <path
        d="M83.3 19.3V21.4H85V22.9H83.3V27.9C83.3 28.7 83.6 29 84.4 29C84.6 29 84.8 29 85 29V30.5C84.7 30.6 84.3 30.6 84 30.6C82.2 30.6 81.5 29.9001 81.5 28.2001V23H80.2V21.5H81.5V19.4H83.3V19.3Z"
        fill="white"
      />
      <path
        d="M86.1 26C86.1 23.2 87.8 21.4 90.4 21.4C93 21.4 94.7 23.2 94.7 26C94.7 28.9 93 30.6 90.4 30.6C87.7 30.6 86.1 28.8 86.1 26ZM92.8 26C92.8 24 91.9 22.9 90.4 22.9C88.9 22.9 88 24 88 26C88 28 88.9 29.1 90.4 29.1C91.9 29.1 92.8 27.9 92.8 26Z"
        fill="white"
      />
      <path
        d="M96.2 21.4H98V23C98.2 22 99.2 21.3 100.2 21.4C100.4 21.4 100.6 21.4 100.8 21.5V23.2C100.5 23.1 100.2 23.1 100 23.1C99 23.1 98.1 23.9 98.1 24.9C98.1 25 98.1 25.1 98.1 25.2V30.6H96.2V21.4Z"
        fill="white"
      />
      <path
        d="M109.4 27.8C109.2 29.4 107.5 30.6 105.5 30.6C102.9 30.6 101.2 28.8 101.2 26C101.2 23.2 102.8 21.3 105.4 21.3C107.9 21.3 109.5 23 109.5 25.8V26.4H103.1V26.5C103 27.8 103.9 28.9001 105.2 29.1C105.3 29.1 105.4 29.1 105.5 29.1C106.4 29.2 107.3 28.7 107.6 27.8H109.4ZM103.1 25.1H107.6C107.7 23.9 106.7 22.9 105.5 22.8C105.4 22.8 105.4 22.8 105.3 22.8C104.1 22.8 103.1 23.8001 103.1 25.1Z"
        fill="white"
      />
      <path
        d="M37.8 8.70004C39.3 8.60004 40.5 9.70004 40.6 11.1C40.6 11.3 40.6 11.4 40.6 11.6C40.6 13.5 39.6 14.6 37.8 14.6H35.6V8.60004H37.8V8.70004ZM36.6 13.9H37.7C38.7 14 39.6 13.2 39.7 12.1C39.7 12 39.7 11.8 39.7 11.7C39.8 10.7 39.1 9.70004 38.1 9.60004C38 9.60004 37.9 9.60004 37.7 9.60004H36.6V13.9Z"
        fill="white"
      />
      <path
        d="M41.7 12.4C41.6 11.2 42.4 10.2 43.6 10.1C44.8 10 45.8 10.8 45.9 12C45.9 12.1 45.9 12.3 45.9 12.4C46 13.6 45.2 14.6 44 14.7C42.8 14.8 41.8 14 41.7 12.8C41.7 12.7 41.7 12.6 41.7 12.4ZM45 12.4C45 11.4 44.6 10.9 43.8 10.9C43 10.9 42.6 11.5 42.6 12.4C42.6 13.4 43 14 43.8 14C44.6 14 45 13.4 45 12.4Z"
        fill="white"
      />
      <path
        d="M51.6 14.7H50.7L49.8 11.4H49.7L48.8 14.7H47.9L46.7 10.2H47.6L48.4 13.6H48.5L49.4 10.2H50.3L51.2 13.6H51.3L52.1 10.2H53L51.6 14.7Z"
        fill="white"
      />
      <path
        d="M53.9 10.2H54.8V10.9H54.9C55.1 10.4 55.7 10.1 56.2 10.1C57 10 57.7 10.6 57.8 11.5C57.8 11.6 57.8 11.7 57.8 11.8V14.7H56.9V12C56.9 11.3 56.6 10.9 55.9 10.9C55.3 10.9 54.8 11.3 54.8 11.9C54.8 12 54.8 12 54.8 12.1V14.7H53.9V10.2Z"
        fill="white"
      />
      <path d="M59.1 8.40002H60V14.7H59.1V8.40002Z" fill="white" />
      <path
        d="M61.2 12.4C61.1 11.2 61.9 10.2 63.1 10.1C64.3 10 65.3 10.8 65.4 12C65.4 12.1 65.4 12.3 65.4 12.4C65.5 13.6 64.7 14.6 63.5 14.7C62.3 14.8 61.3 14 61.2 12.8C61.2 12.7 61.2 12.6 61.2 12.4ZM64.6 12.4C64.6 11.4 64.2 10.9 63.4 10.9C62.6 10.9 62.2 11.5 62.2 12.4C62.2 13.4 62.6 14 63.4 14C64.1 14 64.6 13.4 64.6 12.4Z"
        fill="white"
      />
      <path
        d="M66.4 13.4C66.4 12.6 67 12.1 68.1 12.1L69.3 12V11.6C69.3 11.1 69 10.9 68.4 10.9C67.9 10.9 67.6 11.1 67.5 11.4H66.6C66.7 10.6 67.4 10.1 68.4 10.1C69.5 10.1 70.2 10.7 70.2 11.6V14.7H69.3V14.1H69.2C68.9 14.6 68.4 14.8 67.8 14.8C67.1 14.9 66.4 14.3 66.3 13.6C66.4 13.5 66.4 13.5 66.4 13.4ZM69.3 13V12.6L68.2 12.7C67.6 12.7 67.3 13 67.3 13.3C67.3 13.7 67.7 13.9 68.1 13.9C68.7 14.1 69.2 13.7 69.3 13C69.3 13.1 69.3 13.1 69.3 13Z"
        fill="white"
      />
      <path
        d="M71.3 12.4C71.3 11 72 10.1 73.2 10.1C73.8 10.1 74.3 10.4 74.6 10.9H74.7V8.40002H75.6V14.7H74.7V14H74.6C74.3 14.5 73.8 14.8 73.2 14.8C72.1 14.8 71.3 13.9 71.3 12.4ZM72.3 12.4C72.3 13.4 72.8 13.9 73.5 13.9C74.2 13.9 74.7 13.3 74.7 12.4C74.7 11.5 74.2 10.9 73.5 10.9C72.7 10.9 72.3 11.5 72.3 12.4Z"
        fill="white"
      />
      <path
        d="M79.2 12.4C79.1 11.2 79.9 10.2 81.1 10.1C82.3 10 83.3 10.8 83.4 12C83.4 12.1 83.4 12.3 83.4 12.4C83.5 13.6 82.7 14.6 81.5 14.7C80.3 14.8 79.3 14 79.2 12.8C79.2 12.7 79.2 12.6 79.2 12.4ZM82.6 12.4C82.6 11.4 82.2 10.9 81.4 10.9C80.6 10.9 80.2 11.5 80.2 12.4C80.2 13.4 80.6 14 81.4 14C82.1 14 82.6 13.4 82.6 12.4Z"
        fill="white"
      />
      <path
        d="M84.7 10.2H85.6V10.9H85.7C85.9 10.4 86.5 10.1 87 10.1C87.8 10 88.5 10.6 88.6 11.5C88.6 11.6 88.6 11.7 88.6 11.8V14.7H87.7V12C87.7 11.3 87.4 10.9 86.7 10.9C86.1 10.9 85.6 11.3 85.6 11.9C85.6 12 85.6 12 85.6 12.1V14.7H84.7V10.2Z"
        fill="white"
      />
      <path
        d="M93.5 9.10004V10.2H94.5V11H93.5V13.3C93.5 13.8 93.7 14 94.1 14C94.2 14 94.3 14 94.4 14V14.7C94.2 14.7 94.1 14.7 93.9 14.7C92.9 14.7 92.5 14.4 92.5 13.5V11H91.8V10.3H92.5V9.10004H93.5Z"
        fill="white"
      />
      <path
        d="M95.7 8.40002H96.6V10.9H96.7C96.9 10.4 97.5 10 98.1 10.1C98.9 10.1 99.6 10.7 99.7 11.5C99.7 11.6 99.7 11.7 99.7 11.8V14.7H98.8V12C98.8 11.3 98.5 10.9 97.8 10.9C97.2 10.9 96.7 11.3 96.7 11.9C96.7 12 96.7 12 96.7 12.1V14.7H95.8L95.7 8.40002Z"
        fill="white"
      />
      <path
        d="M104.8 13.5C104.6 14.3 103.7 14.9 102.8 14.8C101.7 14.8 100.7 13.9 100.7 12.8C100.7 12.7 100.7 12.6 100.7 12.5C100.5 11.4 101.3 10.3 102.5 10.2C102.6 10.2 102.7 10.2 102.8 10.2C104.1 10.2 104.8 11.1 104.8 12.5V12.8H101.6C101.5 13.5 102 14 102.7 14.1H102.8C103.2 14.2 103.7 13.9 103.9 13.6L104.8 13.5ZM101.6 12H103.9C103.9 11.4 103.5 10.9 102.9 10.8H102.8C102.2 10.9 101.6 11.4 101.6 12Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1404_64">
        <rect width="119.7" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreBadge;
