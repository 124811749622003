import * as GMaps from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { type GoogleMapTools } from '@Src/types/googleMapsTypes';
import { logger } from '@Src/utility/loggerService';

export function useMapTools(): [GoogleMapTools?] {
  const designSchema = useDesignSchema();
  let googleMapApiKey = '';
  if (designSchema.schemaVersion === 'v2') {
    googleMapApiKey = designSchema.config?.googleMapApiKey || '';
  } else {
    googleMapApiKey = designSchema.map?.googleMapApiKey || '';
  }
  if (!googleMapApiKey) {
    return [];
  }
  const [mapTools, setMapTools] = useState<GoogleMapTools | undefined>(
    undefined,
  );
  const { Loader } = GMaps;
  useEffect(() => {
    if (typeof google !== 'undefined' && google?.maps?.places) {
      const newMapTools = {
        AutoComplete: new google.maps.places.AutocompleteService(),
        GeoCoder: new google.maps.Geocoder(),
        Places: new google.maps.places.PlacesService(
          document.createElement('div'),
        ),
      };
      setMapTools(newMapTools);
    } else if (googleMapApiKey) {
      const loader = new Loader({
        apiKey: googleMapApiKey,
        libraries: ['geometry', 'places', 'maps'],
      });
      loader
        .load()
        .then((google) => {
          const newMapTools = {
            AutoComplete: new google.maps.places.AutocompleteService(),
            GeoCoder: new google.maps.Geocoder(),
            Places: new google.maps.places.PlacesService(
              document.createElement('div'),
            ),
          };
          setMapTools(newMapTools);
        })
        .catch(() => {
          logger.log('Failed to load map tools');
        });
    }
  }, []);

  return [mapTools];
}
