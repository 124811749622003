import { styled } from '@mui/material/styles';
import { useTranslation } from '@Src/providers/TranslationProvider';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { LoyaltyProgressBar } from './LoyaltyProgressBar';
import { useEffect } from 'react';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { fetchLoyaltyCampaignProgress } from '@Src/thunks/loyaltyCampaignsThunks';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useApi } from '@Src/hooks/useApi';

// #region styles
const StyledContainer = styled('div')<{
  ownerState: { borderRadius?: string };
}>(({ theme, ownerState }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: ownerState?.borderRadius || '8px',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '72px',
  padding: '8px 8px 8px 12px',
}));

const StyledLoyaltyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium,
}));

const StyledFractionContainer = styled('div')<{
  ownerState: { borderRadius?: string };
}>(({ theme, ownerState }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: ownerState.borderRadius || '8px',
  boxSizing: 'border-box',
  display: 'flex',
  height: '56px',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
  minWidth: '56px',
  paddingTop: theme.spacing(1.5),
}));

const StyledNumberLeft = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '28px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '36px',
  marginBottom: theme.spacing(1.5),
}));

const StyledNumberRight = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '36px',
}));

export const LoyaltyProgress = ({
  borderRadius,
}: {
  borderRadius?: string;
}): JSX.Element => {
  const { translate } = useTranslation();
  const { appId } = useConfig();
  const { get } = useApi();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const loading = useAppSelector((state) => state.loyaltyCampaigns.loading);
  const selectedDeliveryStore = useAppSelector(
    (state) => state.deliveryStores.selectedDeliveryStore,
  );
  const loyaltyCampaigns = useAppSelector(
    (state) => state.loyaltyCampaigns.loyaltyCampaigns,
  );
  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );
  const dispatch = useAppDispatch();
  const activeLoyaltyCampaign =
    !loading && !!loyaltyCampaigns?.length
      ? loyaltyCampaigns[loyaltyCampaigns.length - 1]
      : undefined;

  // Get active loyalty campaigns whenever order setting changes
  useEffect(() => {
    if (isLoggedIn && !loading) {
      void dispatch(fetchLoyaltyCampaignProgress({ get, appId }));
    }
  }, [isLoggedIn, selectedDeliveryStore, isCollection]);

  if (!isLoggedIn || !activeLoyaltyCampaign) {
    return <></>;
  }

  const { CurrentValue: currentValue, RequiredValue: requiredValue } =
    activeLoyaltyCampaign?.RequiredConditions?.[0] || {
      CurrentValue: '0',
      RequiredValue: '0',
    };

  return (
    <StyledContainer ownerState={{ borderRadius }}>
      <div>
        <StyledLoyaltyText variant="body2">
          {translate('Your_loyalty_progress')}
        </StyledLoyaltyText>
        <LoyaltyProgressBar
          currentStar={parseInt(currentValue)}
          totalStars={parseInt(requiredValue)}
        />
      </div>
      <div>
        <StyledFractionContainer ownerState={{ borderRadius }}>
          <StyledNumberLeft variant="body2">{currentValue}</StyledNumberLeft>
          <StyledNumberRight variant="body2">
            /{requiredValue}
          </StyledNumberRight>
        </StyledFractionContainer>
      </div>
    </StyledContainer>
  );
};

export default LoyaltyProgress;
