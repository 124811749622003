import { useTranslation } from '@Providers/TranslationProvider';
import { useDesignSchema } from '@Providers/DesignSchemaProvider';
import { useConfig } from '@Src/providers/ConfigProvider';
import { buildSocialMediaIconList } from '@Components/LegacyElixir/SocialMedia/SocialMedia';
import MobileAppBadges from '@Components/Widgets/Apps/MobileAppBadges';
import Grid from '@mui/material/Grid';
import { StyledLogoLink } from '../Header';
import { ReactComponent as ReturnBanner } from '@Src/assets/images/re-turn-banner.svg';
import Typography from '@mui/material/Typography';
import { useIsMobile } from '@Src/hooks/useIsMobile';
import {
  FlipdishIconTextContainer,
  StyledBadgesContainer,
  StyledBottomLineFooter,
  StyledCookieSettingsButton,
  StyledFlipdishContainer,
  StyledFooter,
  StyledLink,
  StyledLogo,
  StyledMobileAndSocialMediaItem,
  StyledMobileBadgeAndSocialMediaContainer,
  StyledText,
  StyledPoweredByIcon,
  StyledReturnLink,
  StyledSocialMediaList,
  StyledSublink,
  StyledSublinkContainer,
  StyledTopLineFooter,
  SyledLogoSublinkGridItem,
  StyledFooterInnerGridPolice,
  StyledBoxedUpFooter,
} from './styles';
import { V2Templates } from '@Src/middlewares/CustomPageTypes';

const themeName = {
  boxedUp: 'Boxed Up Theme',
  dishy: 'Dishy Theme',
  classic: 'Classic Theme',
};

const BoxedUpFooter = ({
  children,
  ...props
}: {
  children: JSX.Element;
}): JSX.Element => (
  <StyledBoxedUpFooter {...props}>
    <StyledFooterInnerGridPolice>{children}</StyledFooterInnerGridPolice>
  </StyledBoxedUpFooter>
);

const Footer = (): JSX.Element => {
  const {
    socialMedia,
    config,
    imageAttributes,
    name,
    showReturnBanner,
    template,
  } = useDesignSchema('v2');
  const { policyUrls, logo } = config || {};
  const { translate } = useTranslation();
  const isMobile = useIsMobile();
  const { language } = useConfig();

  const logoAlt = logo
    ? imageAttributes?.[logo]?.alt || 'Our_logo'
    : 'Our_logo';

  const handleCookieSettingsClick = (): void => {
    window.fdCc?.edit({
      lang: language,
      restaurantName: name,
      logoUrl: logo,
      cookiePolicyUrl: policyUrls?.cookie || 'cookiepolicy',
    });
  };
  const Footer =
    template === V2Templates.boxedUp ? BoxedUpFooter : StyledFooter;

  const copyrightMessage = translate('Copyright_message').replace(
    '%@',
    themeName[template],
  );

  return (
    <Footer id="footer" className="section" role="contentinfo" data-fd="footer">
      <StyledTopLineFooter container>
        <SyledLogoSublinkGridItem item>
          <StyledLogoLink data-fd="logo" to="/">
            <StyledLogo alt={translate(logoAlt)} src={logo} />
          </StyledLogoLink>

          <StyledSublinkContainer className="footerlink">
            <StyledSublink
              to={policyUrls?.terms ?? '/terms'}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="body2">{translate('Terms')}</Typography>
            </StyledSublink>{' '}
            |{' '}
            {policyUrls?.privacy ? (
              <>
                <StyledSublink
                  to={policyUrls.privacy}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="body2">
                    {' '}
                    {translate('Privacy')}
                  </Typography>
                </StyledSublink>
              </>
            ) : (
              <StyledSublink to="/privacy" target="_blank" rel="noreferrer">
                <Typography variant="body2"> {translate('Privacy')}</Typography>
              </StyledSublink>
            )}{' '}
            |{' '}
            {policyUrls?.cookie ? (
              <StyledSublink
                to={policyUrls.cookie}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body2">
                  {' '}
                  {translate('Cookie_Policy')}
                </Typography>
              </StyledSublink>
            ) : (
              <StyledSublink
                to={'/cookiepolicy'}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body2">
                  {translate('Cookie_Policy')}
                </Typography>
              </StyledSublink>
            )}{' '}
            |{' '}
            <StyledCookieSettingsButton onClick={handleCookieSettingsClick}>
              <Typography variant="body2">
                {translate('Cookie_Settings')}
              </Typography>
            </StyledCookieSettingsButton>
          </StyledSublinkContainer>
        </SyledLogoSublinkGridItem>
        <StyledMobileAndSocialMediaItem item>
          <StyledMobileBadgeAndSocialMediaContainer>
            <StyledBadgesContainer>
              <MobileAppBadges />
            </StyledBadgesContainer>
            <StyledSocialMediaList>
              {buildSocialMediaIconList(socialMedia, true)}
            </StyledSocialMediaList>
          </StyledMobileBadgeAndSocialMediaContainer>
        </StyledMobileAndSocialMediaItem>
        {isMobile && showReturnBanner && (
          <StyledReturnLink
            to="https://re-turn.ie/"
            target="_blank"
            aria-label={translate('Link_to_Return_website')}
            rel="noreferrer"
          >
            <ReturnBanner />
          </StyledReturnLink>
        )}
        <StyledBottomLineFooter item xs={12}>
          <StyledFlipdishContainer>
            <StyledLink
              to="https://www.flipdish.com/"
              target="_blank"
              aria-label={translate('Online_ordering_powered_by_Flipdish')}
              rel="noreferrer"
            >
              <Grid>
                <FlipdishIconTextContainer>
                  <StyledPoweredByIcon />
                  <StyledText
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                    variant="body2Medium"
                  >
                    {translate('Online_ordering_powered_by_Flipdish')}
                  </StyledText>
                </FlipdishIconTextContainer>
                <StyledText
                  sx={{ display: { xs: 'flex', sm: 'none' } }}
                  variant="body2Medium"
                >
                  {translate('Online_ordering_powered_by_Flipdish')}
                </StyledText>
                <StyledText variant="finePrint1">{copyrightMessage}</StyledText>
              </Grid>
            </StyledLink>
          </StyledFlipdishContainer>
          <Grid display="flex" alignItems="center">
            {!isMobile && showReturnBanner && (
              <StyledReturnLink
                to="https://re-turn.ie/"
                target="_blank"
                aria-label={translate('Link_to_Return_website')}
                rel="noreferrer"
              >
                <ReturnBanner />
              </StyledReturnLink>
            )}
          </Grid>
        </StyledBottomLineFooter>
      </StyledTopLineFooter>
    </Footer>
  );
};

export default Footer;
