import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material/styles';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { ReactComponent as HamburgerMenuIcon } from '@Src/assets/images/elixir/hamburger-menu.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { Link } from '@Components/RoutesUtils';
import { useRef } from 'react';
import { Drawer } from '@Components/ui/Drawer';
import { ReactComponent as CloseIcon } from '@Src/assets/images/Close.svg';
import { useConfig } from '@Src/providers/ConfigProvider';
import LanguageSelector from './LanguageSelector';

const StyledAppBar = styled(AppBar)`
  color: ${({ theme }) => theme.palette.text.primary};
  position: relative !important;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 64px;
    .desktop-menu {
      display: none;
      visibility: hidden;
    }
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 88px;
    .mobile-menu {
      display: none;
      visibility: hidden;
    }
  }
  justify-content: center;
  background-color: ${(props) =>
    props.theme.palette.background.default} !important;
  .MuiPopover-paper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledLogo = styled('img')`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 48px;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 72px;
  }
  border-radius: 20%;
`;

const StyledBurgerMenuIcon = styled(HamburgerMenuIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
  width: 24px;
  height: 24px;
`;

export const StyledLogoLink = styled(Link)`
  justify-content: left;
  overflow: visible;
  display: flex;
`;

const Dishy = ({
  menuItems,
  children,
  isDrawerOpen,
  handleDrawerOpenChange,
}: {
  isDrawerOpen: boolean;
  handleDrawerOpenChange: (isOpen: boolean) => void;
  menuItems?: JSX.Element[];
  children?: JSX.Element;
}): JSX.Element => {
  const { config, imageAttributes } = useDesignSchema('v2');
  const { logo } = config || {};
  const { supportedLanguages } = useConfig();
  const showLanguageSelector = supportedLanguages.length > 1;
  const { translate } = useTranslation();
  const ref = useRef(null);
  const logoAlt = logo
    ? imageAttributes?.[logo]?.alt || 'Our_logo'
    : 'Our_logo';

  return (
    <StyledAppBar ref={ref} elevation={0} position="absolute">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <StyledLogoLink data-fd="logo" to="/">
              <StyledLogo alt={translate(logoAlt)} src={logo} />
            </StyledLogoLink>
          </Box>
          {
            <Box
              data-fd="header-desktop-menu"
              className="desktop-menu"
              sx={{ flexGrow: 0, overflow: 'auto', display: { xs: 'flex' } }}
            >
              {menuItems}
              {showLanguageSelector && <LanguageSelector />}
            </Box>
          }
          {
            <Box className="mobile-menu" sx={{ flexGrow: 0 }}>
              {showLanguageSelector && <LanguageSelector />}
              <IconButton
                data-fd="header-mobile-button"
                aria-label={translate('Burger_Menu_Label')}
                aria-haspopup="true"
                onClick={() => handleDrawerOpenChange(true)}
              >
                <StyledBurgerMenuIcon />
              </IconButton>
              <Drawer
                data-fd="menu-drawer"
                open={isDrawerOpen}
                onClose={() => handleDrawerOpenChange(false)}
              >
                <IconButton
                  onClick={() => handleDrawerOpenChange(false)}
                  data-fd="close-drawer-button"
                  aria-label={translate('Close_navigation_menu')}
                >
                  <CloseIcon />
                </IconButton>
                <MenuList>{menuItems}</MenuList>
              </Drawer>
            </Box>
          }
        </Toolbar>
      </Container>
      {children}
    </StyledAppBar>
  );
};
export default Dishy;
