/* eslint-disable no-restricted-imports */
import type { MiddlewareAPI, AnyAction, Middleware } from '@reduxjs/toolkit';
import * as toolkitRaw from '@reduxjs/toolkit';
/**
 * Need to do this because of RTK limitations
 * https://github.com/reduxjs/redux-toolkit/issues/1960#issuecomment-1022277429
 */

const { createSlice, createAsyncThunk, configureStore, createSelector } = ((
  toolkitRaw as any
).default ?? toolkitRaw) as typeof toolkitRaw;

export {
  createSelector,
  createSlice,
  createAsyncThunk,
  configureStore,
  type Middleware,
  type MiddlewareAPI,
  type AnyAction,
};
