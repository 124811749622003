import { type ReactElement } from 'react';

const AppStoreBadge = (): ReactElement => (
  <svg
    width="120"
    height="40"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4846_2945)">
      <path
        d="M110.135 7.92969e-06H9.53468C9.16798 7.92969e-06 8.80568 7.92975e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.17961 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47235 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.304641 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6959 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.145 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53614C119.664 9.16993 119.664 8.80665 119.656 8.44435C119.656 8.13771 119.656 7.83009 119.652 7.52345C119.64 6.85243 119.578 6.18324 119.467 5.52145C119.358 4.85929 119.149 4.21751 118.848 3.61813C118.23 2.4152 117.252 1.43603 116.049 0.818328C115.448 0.515575 114.804 0.304248 114.141 0.191328C113.48 0.0811107 112.811 0.0220157 112.141 0.0145679C111.836 0.00968793 111.523 0.00382793 111.219 0.00187793C110.859 -0.00012207 110.494 -0.00012207 110.135 -0.00012207V7.92969e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.44481 39.125C8.14013 39.125 7.84281 39.1211 7.54052 39.1143C6.9143 39.1061 6.28954 39.0516 5.67138 38.9512C5.09497 38.8519 4.53658 38.6673 4.01464 38.4033C3.49748 38.1415 3.02579 37.7983 2.61764 37.3867C2.20358 36.98 1.85885 36.5082 1.59713 35.9902C1.33252 35.4688 1.14939 34.9099 1.05413 34.333C0.95125 33.7131 0.89559 33.0863 0.887626 32.458C0.881286 32.2471 0.872986 31.5449 0.872986 31.5449V8.44434C0.872986 8.44434 0.881826 7.75293 0.887676 7.5498C0.895302 6.92248 0.950638 6.29665 1.05321 5.67773C1.14864 5.09925 1.33191 4.53875 1.59667 4.01563C1.85743 3.49794 2.20024 3.02586 2.61181 2.61768C3.02291 2.20562 3.49611 1.8606 4.01415 1.59521C4.53489 1.33209 5.09222 1.14873 5.66747 1.05127C6.28766 0.949836 6.91459 0.894996 7.54298 0.88721L8.4453 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.606 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.71 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44481 39.125Z"
        fill="black"
      />
      <path
        d="M24.7689 20.3007C24.7796 19.466 25.0013 18.6477 25.4134 17.9217C25.8254 17.1957 26.4143 16.5858 27.1254 16.1486C26.6737 15.5035 26.0777 14.9725 25.3849 14.598C24.6921 14.2234 23.9215 14.0156 23.1343 13.991C21.455 13.8147 19.8271 14.9958 18.9714 14.9958C18.0991 14.9958 16.7816 14.0085 15.3629 14.0376C14.4452 14.0673 13.5509 14.3341 12.767 14.8122C11.9831 15.2903 11.3364 15.9632 10.89 16.7655C8.95595 20.1139 10.3985 25.035 12.2511 27.7416C13.178 29.0669 14.2613 30.5474 15.6788 30.4949C17.0658 30.4374 17.5839 29.6104 19.2582 29.6104C20.9169 29.6104 21.403 30.4949 22.8492 30.4615C24.3376 30.4374 25.2753 29.1303 26.1697 27.7924C26.8357 26.848 27.3481 25.8043 27.6881 24.6999C26.8234 24.3341 26.0855 23.722 25.5664 22.9397C25.0472 22.1574 24.7699 21.2396 24.7689 20.3007Z"
        fill="white"
      />
      <path
        d="M22.0373 12.2109C22.8488 11.2367 23.2486 9.98451 23.1518 8.72028C21.912 8.8505 20.7667 9.44306 19.9442 10.3799C19.5421 10.8376 19.2341 11.37 19.0378 11.9468C18.8416 12.5235 18.7609 13.1333 18.8005 13.7413C19.4206 13.7477 20.0341 13.6132 20.5948 13.3482C21.1555 13.0831 21.6487 12.6942 22.0373 12.2109Z"
        fill="white"
      />
      <path
        d="M42.3022 27.1396H37.5688L36.4321 30.4961H34.4272L38.9106 18.0781H40.9936L45.477 30.4961H43.438L42.3022 27.1396ZM38.0591 25.5908H41.8111L39.9615 20.1435H39.9097L38.0591 25.5908Z"
        fill="white"
      />
      <path
        d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3813 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.177 22.0316 49.6847 21.7497C50.1923 21.4679 50.7668 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3022 22.9316 50.8569 22.9316C49.437 22.9316 48.4819 24.1621 48.4819 25.9697C48.4819 27.7939 49.437 29.0156 50.8569 29.0156C52.3022 29.0156 53.2495 27.8193 53.2495 25.9697Z"
        fill="white"
      />
      <path
        d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7705 30.621 60.198 30.4883 59.6942 30.2082C59.1905 29.9281 58.7757 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4424H58.395V22.9483H58.4292C58.7201 22.4458 59.1418 22.0316 59.6495 21.7498C60.1571 21.4679 60.7317 21.329 61.312 21.3477C63.6099 21.3477 65.1245 23.1641 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9317 60.8218 22.9317C59.4018 22.9317 58.4468 24.1621 58.4468 25.9697C58.4468 27.794 59.4018 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8194 63.2144 25.9697H63.2144Z"
        fill="white"
      />
      <path
        d="M71.7104 27.0361C71.8481 28.2676 73.0444 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1572C77.3726 26.1933 76.6929 25.6162 75.0835 25.2207L73.4741 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4853C70.1353 19.3427 72.0024 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4853H77.2612C77.1489 20.246 76.1245 19.498 74.6274 19.498C73.1303 19.498 72.1059 20.2548 72.1059 21.3564C72.1059 22.2343 72.7602 22.7509 74.3608 23.1464L75.729 23.4823C78.2768 24.0849 79.3354 25.1083 79.3354 26.9247C79.3354 29.248 77.4848 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7104 27.0361Z"
        fill="white"
      />
      <path
        d="M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4477 29.042C84.6521 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.3729 30.5543 84.0268 30.5478C82.1938 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z"
        fill="white"
      />
      <path
        d="M86.065 25.9697C86.065 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.7261 30.6084 86.065 28.8262 86.065 25.9697ZM92.7603 25.9697C92.7603 24.0156 91.8648 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8648 29.0762 92.7602 27.9316 92.7602 25.9697H92.7603Z"
        fill="white"
      />
      <path
        d="M96.186 21.4424H97.9585V22.9834H98.0015C98.1214 22.5021 98.4034 22.0768 98.8 21.7789C99.1966 21.481 99.6836 21.3287 100.179 21.3476C100.393 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.981 23.043C99.711 23.032 99.4418 23.0796 99.192 23.1825C98.9421 23.2854 98.7175 23.4411 98.5335 23.639C98.3496 23.8369 98.2106 24.0723 98.1262 24.3289C98.0417 24.5856 98.0139 24.8575 98.0444 25.126V30.4961H96.186L96.186 21.4424Z"
        fill="white"
      />
      <path
        d="M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8933 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348V25.1348Z"
        fill="white"
      />
      <path
        d="M35.4277 11.7163C35.4277 9.78124 36.457 8.58593 38.1152 8.58593C38.7129 8.55633 39.2998 8.75254 39.7595 9.13561C40.2192 9.51869 40.518 10.0606 40.5967 10.6538H39.6738C39.5964 10.3023 39.3979 9.98912 39.113 9.76911C38.8281 9.54909 38.4749 9.43621 38.1152 9.45019C37.0439 9.45019 36.374 10.3227 36.374 11.7163C36.374 13.1059 37.0439 13.978 38.1191 13.978C38.4657 14.004 38.8104 13.9084 39.0942 13.7077C39.3779 13.507 39.5829 13.2138 39.6738 12.8784H40.5967C40.5067 13.4575 40.2016 13.9813 39.7423 14.3453C39.2831 14.7094 38.7035 14.8868 38.1191 14.8423C36.461 14.8423 35.4277 13.6474 35.4277 11.7163Z"
        fill="white"
      />
      <path
        d="M41.6104 12.4443C41.582 12.1484 41.6159 11.8499 41.7097 11.5678C41.8036 11.2857 41.9553 11.0264 42.1553 10.8064C42.3552 10.5864 42.5989 10.4107 42.8708 10.2904C43.1426 10.1701 43.4366 10.108 43.7339 10.108C44.0312 10.108 44.3251 10.1701 44.597 10.2904C44.8688 10.4107 45.1126 10.5864 45.3125 10.8064C45.5124 11.0264 45.6642 11.2857 45.758 11.5678C45.8519 11.8499 45.8857 12.1484 45.8574 12.4443C45.8863 12.7406 45.8528 13.0396 45.7592 13.3221C45.6656 13.6046 45.514 13.8644 45.314 14.0848C45.114 14.3052 44.8701 14.4814 44.598 14.6019C44.3258 14.7225 44.0315 14.7847 43.7339 14.7847C43.4363 14.7847 43.1419 14.7225 42.8698 14.6019C42.5977 14.4814 42.3538 14.3052 42.1538 14.0848C41.9538 13.8644 41.8021 13.6046 41.7086 13.3221C41.615 13.0396 41.5815 12.7406 41.6104 12.4443ZM44.9434 12.4443C44.9434 11.4683 44.5049 10.8975 43.7354 10.8975C42.9629 10.8975 42.5284 11.4683 42.5284 12.4444C42.5284 13.4282 42.9629 13.9946 43.7354 13.9946C44.5049 13.9946 44.9434 13.4243 44.9434 12.4443H44.9434Z"
        fill="white"
      />
      <path
        d="M47.0498 10.1948H47.9053V10.9101H47.9717C48.0843 10.6532 48.2743 10.4378 48.5152 10.294C48.7561 10.1502 49.0358 10.0852 49.3154 10.1079C49.5345 10.0914 49.7545 10.1244 49.9591 10.2045C50.1637 10.2846 50.3477 10.4097 50.4974 10.5706C50.647 10.7314 50.7586 10.9239 50.8238 11.1337C50.889 11.3435 50.9062 11.5653 50.874 11.7827V14.6977H49.9854V12.0058C49.9854 11.2822 49.6709 10.9223 49.0137 10.9223C48.8649 10.9154 48.7164 10.9407 48.5784 10.9966C48.4403 11.0524 48.316 11.1374 48.2138 11.2458C48.1117 11.3542 48.0342 11.4834 47.9867 11.6245C47.9392 11.7656 47.9227 11.9154 47.9385 12.0635V14.6977H47.0498V10.1948Z"
        fill="white"
      />
      <path
        d="M53.8945 10.1079C54.9072 10.1079 55.5693 10.5791 55.6562 11.373H54.8037C54.7217 11.0425 54.3984 10.8315 53.8945 10.8315C53.3984 10.8315 53.0215 11.0669 53.0215 11.4185C53.0215 11.6875 53.2491 11.8569 53.7373 11.9688L54.4853 12.1421C55.3418 12.3408 55.7432 12.709 55.7432 13.3706C55.7432 14.2183 54.9532 14.7847 53.8779 14.7847C52.8066 14.7847 52.1084 14.3008 52.0293 13.5029H52.919C52.9956 13.6928 53.1339 13.8515 53.3115 13.9534C53.4891 14.0553 53.6958 14.0946 53.8985 14.0649C54.4522 14.0649 54.8457 13.8169 54.8457 13.457C54.8457 13.1885 54.6348 13.0146 54.1836 12.9072L53.3985 12.7251C52.542 12.5224 52.1455 12.1382 52.1455 11.4683C52.1455 10.6699 52.877 10.1079 53.8945 10.1079Z"
        fill="white"
      />
      <path
        d="M56.8652 9.44189L57.9033 8.01953H58.9453L57.7832 9.44189H56.8652ZM56.957 10.1948H57.8418V14.6978H56.957V10.1948Z"
        fill="white"
      />
      <path
        d="M59.2256 15.1485H60.1348C60.21 15.4751 60.5859 15.6861 61.1856 15.6861C61.9258 15.6861 62.3643 15.3345 62.3643 14.7393V13.875H62.2979C62.1585 14.117 61.9549 14.3156 61.7097 14.4491C61.4644 14.5826 61.187 14.6457 60.9082 14.6314C59.7588 14.6314 59.0479 13.7427 59.0479 12.3946C59.0479 11.0216 59.7666 10.1201 60.917 10.1201C61.2028 10.1102 61.4859 10.179 61.7353 10.319C61.9846 10.459 62.1907 10.6649 62.3311 10.9141H62.4014V10.1949H63.2529V14.7349C63.2529 15.7646 62.4463 16.418 61.1728 16.418C60.0479 16.418 59.3203 15.9136 59.2256 15.1485ZM62.3809 12.3902C62.3809 11.4932 61.917 10.9185 61.1602 10.9185C60.3955 10.9185 59.9658 11.4932 59.9658 12.3902C59.9658 13.2876 60.3955 13.8623 61.1602 13.8623C61.9219 13.8623 62.3809 13.292 62.3809 12.3902Z"
        fill="white"
      />
      <path
        d="M68.4653 14.6978H67.6089V13.9824H67.5386C67.4247 14.242 67.2321 14.4592 66.9879 14.6032C66.7437 14.7472 66.4605 14.8107 66.1782 14.7847C65.9604 14.8001 65.7419 14.7666 65.5387 14.6866C65.3356 14.6066 65.1529 14.4821 65.0041 14.3223C64.8554 14.1625 64.7443 13.9714 64.679 13.763C64.6137 13.5546 64.5959 13.3343 64.6269 13.1182V10.1948H65.5171V12.8867C65.5171 13.6143 65.8101 13.9619 66.4634 13.9619C66.6148 13.9763 66.7676 13.9567 66.9105 13.9045C67.0535 13.8523 67.183 13.7689 67.2895 13.6603C67.3961 13.5517 67.4771 13.4207 67.5266 13.2768C67.5761 13.1329 67.5929 12.9798 67.5757 12.8286V10.1948H68.4653V14.6978Z"
        fill="white"
      />
      <path
        d="M73.689 13.482C73.5683 13.8935 73.3067 14.2495 72.95 14.4876C72.5933 14.7258 72.1642 14.8309 71.7378 14.7847C71.4412 14.7925 71.1464 14.7357 70.8738 14.6182C70.6013 14.5006 70.3577 14.3252 70.1598 14.1041C69.9619 13.8829 69.8145 13.6214 69.7278 13.3376C69.6411 13.0537 69.6172 12.7544 69.6578 12.4605C69.6182 12.1656 69.6426 11.8656 69.7291 11.581C69.8155 11.2963 69.9622 11.0335 70.159 10.8105C70.3559 10.5874 70.5984 10.4092 70.8701 10.288C71.1418 10.1668 71.4364 10.1054 71.7339 10.1079C72.9868 10.1079 73.7427 10.9639 73.7427 12.3779V12.688H70.563V12.7378C70.5491 12.9031 70.5699 13.0694 70.624 13.2262C70.6781 13.3829 70.7644 13.5266 70.8773 13.6481C70.9902 13.7695 71.1272 13.866 71.2796 13.9314C71.432 13.9968 71.5964 14.0297 71.7622 14.0278C71.9748 14.0533 72.1902 14.0151 72.3809 13.9179C72.5717 13.8207 72.7292 13.6689 72.8335 13.4819L73.689 13.482ZM70.563 12.0308H72.8374C72.8486 11.8797 72.8281 11.7279 72.7771 11.5851C72.7262 11.4424 72.6459 11.3119 72.5415 11.2021C72.4371 11.0922 72.3109 11.0054 72.171 10.9472C72.0311 10.8891 71.8805 10.8608 71.729 10.8643C71.5753 10.8624 71.4228 10.8912 71.2804 10.9492C71.138 11.0071 71.0087 11.093 70.9 11.2017C70.7914 11.3104 70.7056 11.4397 70.6477 11.5821C70.5898 11.7245 70.561 11.8771 70.563 12.0308Z"
        fill="white"
      />
      <path
        d="M74.9771 8.43701H75.8657V14.6978H74.9771V8.43701Z"
        fill="white"
      />
      <path
        d="M77.1011 12.4443C77.0728 12.1484 77.1066 11.8499 77.2005 11.5678C77.2943 11.2857 77.4461 11.0264 77.646 10.8064C77.8459 10.5864 78.0897 10.4107 78.3615 10.2904C78.6334 10.1701 78.9273 10.108 79.2246 10.108C79.5219 10.108 79.8159 10.1701 80.0877 10.2904C80.3596 10.4107 80.6033 10.5864 80.8032 10.8064C81.0032 11.0264 81.1549 11.2857 81.2488 11.5678C81.3426 11.8499 81.3765 12.1484 81.3481 12.4443C81.377 12.7406 81.3436 13.0396 81.25 13.3221C81.1564 13.6046 81.0047 13.8644 80.8047 14.0848C80.6047 14.3052 80.3608 14.4814 80.0887 14.6019C79.8166 14.7225 79.5222 14.7847 79.2246 14.7847C78.927 14.7847 78.6327 14.7225 78.3605 14.6019C78.0884 14.4814 77.8445 14.3052 77.6445 14.0848C77.4445 13.8644 77.2929 13.6046 77.1993 13.3221C77.1057 13.0396 77.0722 12.7406 77.1011 12.4443ZM80.4341 12.4443C80.4341 11.4683 79.9956 10.8975 79.2261 10.8975C78.4536 10.8975 78.0191 11.4683 78.0191 12.4444C78.0191 13.4282 78.4536 13.9946 79.2261 13.9946C79.9956 13.9946 80.4341 13.4243 80.4341 12.4443H80.4341Z"
        fill="white"
      />
      <path
        d="M88.7915 13.482C88.6708 13.8935 88.4092 14.2495 88.0525 14.4876C87.6958 14.7258 87.2667 14.8309 86.8403 14.7847C86.5437 14.7925 86.2489 14.7357 85.9764 14.6182C85.7039 14.5006 85.4602 14.3252 85.2623 14.1041C85.0644 13.8829 84.917 13.6214 84.8303 13.3376C84.7436 13.0537 84.7197 12.7544 84.7603 12.4605C84.7208 12.1656 84.7451 11.8656 84.8316 11.581C84.918 11.2963 85.0647 11.0335 85.2616 10.8105C85.4584 10.5874 85.7009 10.4092 85.9726 10.288C86.2443 10.1668 86.5389 10.1054 86.8364 10.1079C88.0894 10.1079 88.8452 10.9639 88.8452 12.3779V12.688H85.6655V12.7378C85.6516 12.9031 85.6724 13.0694 85.7265 13.2262C85.7806 13.3829 85.8669 13.5266 85.9798 13.6481C86.0927 13.7695 86.2298 13.866 86.3822 13.9314C86.5346 13.9968 86.6989 14.0297 86.8648 14.0278C87.0773 14.0533 87.2927 14.0151 87.4835 13.9179C87.6742 13.8207 87.8318 13.6689 87.9361 13.4819L88.7915 13.482ZM85.6655 12.0308H87.94C87.9512 11.8797 87.9307 11.7279 87.8797 11.5851C87.8287 11.4424 87.7485 11.3119 87.6441 11.2021C87.5397 11.0922 87.4135 11.0054 87.2736 10.9472C87.1337 10.8891 86.9831 10.8608 86.8316 10.8643C86.6779 10.8623 86.5254 10.8912 86.383 10.9491C86.2406 11.0071 86.1113 11.0929 86.0026 11.2017C85.8939 11.3104 85.8081 11.4397 85.7502 11.5821C85.6923 11.7245 85.6635 11.8771 85.6655 12.0308H85.6655Z"
        fill="white"
      />
      <path
        d="M90.0386 10.1948H90.894V10.9101H90.9605C91.0731 10.6532 91.2631 10.4378 91.504 10.294C91.7449 10.1502 92.0246 10.0852 92.3042 10.1079C92.5233 10.0914 92.7433 10.1244 92.9479 10.2045C93.1525 10.2846 93.3365 10.4097 93.4861 10.5706C93.6358 10.7314 93.7474 10.9239 93.8126 11.1337C93.8778 11.3435 93.895 11.5653 93.8628 11.7827V14.6977H92.9741V12.0058C92.9741 11.2822 92.6597 10.9223 92.0024 10.9223C91.8537 10.9154 91.7052 10.9407 91.5671 10.9966C91.4291 11.0524 91.3047 11.1374 91.2026 11.2458C91.1005 11.3542 91.023 11.4834 90.9755 11.6245C90.9279 11.7656 90.9115 11.9154 90.9272 12.0635V14.6977H90.0386V10.1948Z"
        fill="white"
      />
      <path
        d="M101.488 13.482C101.367 13.8935 101.105 14.2495 100.749 14.4876C100.392 14.7258 99.963 14.8309 99.5366 14.7847C99.24 14.7925 98.9452 14.7357 98.6727 14.6182C98.4002 14.5006 98.1565 14.3252 97.9586 14.1041C97.7607 13.8829 97.6132 13.6214 97.5266 13.3376C97.4399 13.0537 97.416 12.7544 97.4566 12.4605C97.417 12.1656 97.4414 11.8656 97.5278 11.581C97.6143 11.2963 97.761 11.0335 97.9578 10.8105C98.1547 10.5874 98.3972 10.4092 98.6689 10.288C98.9406 10.1668 99.2352 10.1054 99.5327 10.1079C100.786 10.1079 101.542 10.9639 101.542 12.3779V12.688H98.3618V12.7378C98.3479 12.9031 98.3687 13.0694 98.4228 13.2262C98.4769 13.3829 98.5632 13.5266 98.6761 13.6481C98.789 13.7695 98.9261 13.866 99.0785 13.9314C99.2309 13.9968 99.3952 14.0297 99.5611 14.0278C99.7736 14.0533 99.989 14.0151 100.18 13.9179C100.371 13.8207 100.528 13.6689 100.632 13.4819L101.488 13.482ZM98.3618 12.0308H100.636C100.647 11.8797 100.627 11.7279 100.576 11.5851C100.525 11.4424 100.445 11.3119 100.34 11.2021C100.236 11.0922 100.11 11.0054 99.9698 10.9472C99.8299 10.8891 99.6793 10.8608 99.5278 10.8643C99.3741 10.8624 99.2216 10.8912 99.0792 10.9492C98.9368 11.0071 98.8075 11.093 98.6989 11.2017C98.5902 11.3104 98.5044 11.4398 98.4465 11.5822C98.3886 11.7245 98.3598 11.8771 98.3618 12.0308H98.3618Z"
        fill="white"
      />
      <path
        d="M102.776 8.43701H103.665V14.6978H102.776V8.43701Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4846_2945">
        <rect width="119.664" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreBadge;
