import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

export const ImgixImage = (
  props: React.ImgHTMLAttributes<HTMLImageElement>,
): JSX.Element => {
  const { schemaVersion } = useDesignSchema();
  let { src = '', width } = props;
  if (!src?.includes('w=') && width) {
    src = `${src}?w=${width}`;
  }
  if (schemaVersion === 'v2' && !src?.includes('auto=')) {
    src = `${src}&auto=format`;
  }

  const srcSet = [1, 2, 3]
    .map((n) => `${src}&upscale=true&dpr=${n}&q=${90 / n} ${n}x`)
    .join(', ');
  // allow img element as this wraps all other images
  // eslint-disable-next-line react/forbid-elements
  return <img {...props} srcSet={srcSet} />;
};
