import { styled } from '@mui/material/styles';
import { ReactComponent as PreorderStatusIcon } from '@Src/assets/images/preorder_status.svg';

type IconSize = '12px' | '16px';

const StyledStoreStatusIcon = styled('div')<{
  isOpen: boolean;
  size: IconSize;
}>`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.palette.success.main : theme.palette.warning.main};
`;

const StyledPreorderIcon = styled(PreorderStatusIcon)<{
  size: IconSize;
}>`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.warning.main};
`;

type StoreStatusIconPropsType = {
  isOpen: boolean;
  acceptPreorders: boolean;
  size?: IconSize;
};
export function StoreStatusIcon({
  isOpen,
  acceptPreorders,
  size = '16px',
}: StoreStatusIconPropsType): JSX.Element {
  const testId = isOpen ? 'open-icon' : 'closed-icon';
  if (!isOpen && acceptPreorders) {
    return <StyledPreorderIcon data-fd="preorder-icon" size={size} />;
  }
  return <StyledStoreStatusIcon data-fd={testId} isOpen={isOpen} size={size} />;
}
