import { createContext, useContext } from 'react';

interface TranslationBase {
  translations: Record<string, string>;
}

const TranslationContext = createContext<TranslationBase>(
  {} as unknown as TranslationBase,
);

type TranslationProp = {
  children: JSX.Element;
} & TranslationBase;

export const TranslationProvider = ({
  children,
  translations,
}: TranslationProp): JSX.Element => {
  const value = { translations };
  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = (): {
  translate: (key: string, data?: Record<string, string | number>) => string;
  translations: Record<string, string>;
} => {
  const { translations } = useContext(TranslationContext);

  const translate = (
    key: string,
    data?: Record<string, string | number>,
  ): string => {
    let translation = Object.keys(translations).includes(key)
      ? translations[key]
      : key;
    if (data && translation) {
      Object.keys(data).forEach((key) => {
        translation = translation.replaceAll(`{${key}}`, `${data[key]}`);
      });
    }
    return translation;
  };
  return { translate, translations };
};
