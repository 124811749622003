import { useEffect, useState } from 'react';
import PhoneNumberDialog from './PhoneNumberDialog';
import SmsCodeDialog from './SmsCodeDialog';
import { useTracking } from '@Src/hooks/useTracking';
import { useCurrentPage } from '@Src/hooks/useCurrentPage';
import { useLocation } from 'react-router-dom';

type LoginDialogPropsType = {
  onClose: () => void;
  page?: string;
};
const LoginDialog = ({ onClose, page }: LoginDialogPropsType): JSX.Element => {
  const [phone, setPhone] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { trackNavigationEvent } = useTracking();
  const currentPageData = useCurrentPage();
  const location = useLocation();

  const handlePhoneNumberSubmit = (phoneNumber: string): void => {
    setPhone(phoneNumber);
    setCurrentStep(2);
  };

  useEffect(() => {
    let identifier = page;
    if (!identifier) {
      identifier = currentPageData?.title || location.pathname;
    }
    trackNavigationEvent('Log in', {
      page: identifier,
    });
  }, []);
  return currentStep === 1 ? (
    <PhoneNumberDialog
      onClose={onClose}
      onPhoneNumberSubmit={handlePhoneNumberSubmit}
    />
  ) : (
    <SmsCodeDialog
      onBackClick={() => setCurrentStep(1)}
      onClose={onClose}
      phone={phone}
    />
  );
};

export default LoginDialog;
