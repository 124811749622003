import { SvgIcon } from '@mui/material';

export function StarFilled({
  className,
  color,
  dataFd,
}: {
  className?: string;
  color: string;
  dataFd?: string;
}): JSX.Element {
  return (
    <SvgIcon
      className={className}
      data-fd={dataFd}
      name="StarFilled"
      fontSize="inherit"
    >
      <path
        d="M4.59 23L6.54 14.4961L0 8.77632L8.64 8.01974L12 0L15.36 8.01974L24 8.77632L17.46 14.4961L19.41 23L12 18.4908L4.59 23Z"
        fill={color}
      />
    </SvgIcon>
  );
}
