import { createSlice } from '@Src/utility/reduxUtils';

type OrderSettingsStateType = {
  isCollection: boolean;
};

export const initialState: OrderSettingsStateType = {
  isCollection: false,
};
export const orderSettingsSlice = createSlice({
  name: 'orderSettings',
  initialState,
  reducers: {
    setIsCollection: (
      state,
      action: {
        type: string;
        payload: boolean;
      },
    ) => ({
      ...state,
      isCollection: action.payload,
    }),
  },
});

export const { setIsCollection } = orderSettingsSlice.actions;

export default orderSettingsSlice.reducer;
