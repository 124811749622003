import { ReactComponent as DeliveryIcon } from '@Src/assets/images/delivery_icon.svg';
import { ReactComponent as DeliveryIconDisabled } from '@Src/assets/images/DeliveryDisabled.svg';
import { ReactComponent as CollectionIcon } from '@Src/assets/images/collection.svg';
import { ReactComponent as CollectionIconDisabled } from '@Src/assets/images/CollectionDisabled.svg';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { setIsCollection } from '@Src/slices/orderSettingSlice';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { ChipToggle } from '@Components/ui/ChipToggle';
import { useTheme } from '@mui/material/styles';

type Props = {
  deliveryDisabled?: boolean;
  collectionDisabled?: boolean;
  padding?: string;
  setIsDialogOpen?: ({ isOpen }: { isOpen: boolean }) => void;
  collectionStoresLength?: number;
};

export function OrderTypeToggle({
  padding,
  collectionDisabled = false,
  deliveryDisabled = false,
  setIsDialogOpen,
  collectionStoresLength = 0,
}: Props): JSX.Element {
  const { translate } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );
  const handleOrderTypeChange = (isCollection: boolean): void => {
    void dispatch(setIsCollection(isCollection));
    // open popup by default if collection is selected and more than one collection store
    if (collectionStoresLength > 1 && isCollection && setIsDialogOpen) {
      setIsDialogOpen({ isOpen: isCollection });
    }
  };

  return (
    <ChipToggle
      borderTop={
        theme.palette.secondary.lowContrast
          ? `1px solid ${theme.palette.secondary.lowContrast}`
          : 'none'
      }
      leftDisabled={deliveryDisabled}
      rightDisabled={collectionDisabled}
      padding={padding}
      handleLeftOnClick={() => handleOrderTypeChange(false)}
      leftSelected={!isCollection}
      leftLabel={translate('Deliver')}
      LeftIcon={deliveryDisabled ? DeliveryIconDisabled : DeliveryIcon}
      handleRightOnClick={() => handleOrderTypeChange(true)}
      rightLabel={translate('Collect')}
      RightIcon={collectionDisabled ? CollectionIconDisabled : CollectionIcon}
    />
  );
}
