import { type RootState } from '@Src/store';
import { DeviceId } from '@Src/utility/deviceUtils';
import { logger } from '@Src/utility/loggerService';
import { createAsyncThunk } from '@Src/utility/reduxUtils';
import { type SupportedMethodsType } from '@Src/hooks/useApi';
import { type DeliveryStoreSummary } from '@Src/types/deliveryStoreTypes';

export type FetchDataParamsType = {
  appId: string | null;
  get: SupportedMethodsType['get'];
};
type GetDeliverySummariesParamsType = {
  Latitude: string;
  Longitude: string;
  skip?: string;
  count?: string;
  deviceId?: string;
  deliveryLocationId?: string;
};

export const fetchDeliveryStoreSummaries = createAsyncThunk(
  'deliveryStores/fetchDeliveryStoreSummaries',
  async ({ appId, get }: FetchDataParamsType, thunkApi) => {
    try {
      if (!appId) {
        throw new Error('appId is required');
      }

      const state = thunkApi.getState() as RootState;
      thunkApi.dispatch({
        type: 'deliveryStores/fetchDeliveryStoreSummariesStart',
      });
      const { selectedDeliveryAddress } = state.deliveryAddresses;
      const { geo } = state.location;
      const deviceId = DeviceId();

      let queryParams: GetDeliverySummariesParamsType = {
        Latitude: geo?.Latitude?.toString(),
        Longitude: geo?.Longitude?.toString(),
        skip: '0',
        count: '1000',
        deviceId,
      };

      if (selectedDeliveryAddress) {
        queryParams = {
          ...queryParams,
          Latitude: selectedDeliveryAddress?.Coordinates?.Latitude?.toString(),
          Longitude:
            selectedDeliveryAddress?.Coordinates?.Longitude?.toString(),
          deliveryLocationId:
            selectedDeliveryAddress?.DeliveryLocationId?.toString(),
        };
      }
      // If coordinates are undefined set them to the default coordinates
      if (!queryParams.Latitude) {
        queryParams.Latitude = '-6.2616217';
      }
      if (!queryParams.Longitude) {
        queryParams.Longitude = '53.3454362';
      }

      const url = '/Restaurant/DeliveryRestaurantSummaries';
      const { data } = await get<DeliveryStoreSummary[]>({
        path: url,
        params: queryParams,
        appType: 'Web',
      });

      if (!data?.Data) {
        logger.error(
          '[fetchDeliveryStoreSummaries]: did not return any data for {AppId}',
          {
            url,
            appId,
            params: queryParams,
            code: data?.Code,
            developerMessage: data?.DeveloperMessage,
            success: data?.Success,
            userMessage: data?.UserMessage,
          },
        );
        return thunkApi.rejectWithValue({
          error: `fetchDeliveryStoreSummaries did not return any data: ${
            data?.UserMessage || 'undefined'
          }`,
        });
      }
      return data.Data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(
        '[fetchDeliveryStoreSummaries]: fetching failed for {AppId}',
        {
          appId,
          errorMessage: e.message,
        },
      );
      console.log('error', e);
      return thunkApi.rejectWithValue({ error: e.message });
    }
  },
);
