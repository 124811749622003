import { type OpeningHoursType } from '../middlewares/DesignConfigTypes';

export const getTimeTableEntryRows = (
  entries: OpeningHoursType[],
): OpeningHoursType[][] => {
  let rowSize = 3;
  // only if entries length is 2 or 4 should the row size be 2
  if ([2, 4].includes(entries.length)) {
    rowSize = 2;
  }

  const rows = [] as OpeningHoursType[][];
  for (let i = 0; i < entries.length; i += rowSize) {
    rows.push(entries.slice(i, i + rowSize));
  }
  return rows;
};
