import { styled, useTheme } from '@mui/material/styles';
import MuiAutocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { TextField } from '../TextField';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

export interface AutocompletePropsType {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  filterOptions?: (options: any) => any[];
  getOptionLabel?: (option: any) => string;
  label?: string;
  onChange?: any;
  onInputChange?: any;
  options?: any[];
  placeholder?: string;
  renderOption?: (optionsProps: any, option: any) => JSX.Element;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
}

const StyledPaperComponent = styled(Paper)`
  box-shadow: none;
  border-radius: 0;
`;

const StyledAutoComplete = styled(MuiAutocomplete)`
  & + .MuiAutocomplete-popper .MuiAutocomplete-option {
    border-bottom: '1px solid';
    border-color: ${({ theme }) =>
      theme.palette.secondary.lowContrast} !important;
    display: block;
  }
  .MuiInput-root {
    ${({ theme }) => ({
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    })}
  }
  .MuiAutocomplete-inputRoot {
    padding-right: ${({ theme }) => `${theme.spacing(2)} !important`};
  }
`;

export function Autocomplete(props: AutocompletePropsType): JSX.Element {
  const {
    autoFocus,
    defaultValue = '',
    filterOptions,
    getOptionLabel,
    label = '',
    onChange,
    onInputChange,
    options = [],
    placeholder = '',
    renderOption,
    endIcon,
    startIcon,
  } = props;

  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');

  return (
    <StyledAutoComplete
      autoComplete
      inputValue={inputValue}
      disablePortal
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      freeSolo
      getOptionLabel={getOptionLabel}
      ListboxProps={{
        style: {
          maxHeight: 'unset',
          paddingTop: 0,
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onInputChange(event, newInputValue);
      }}
      onChange={onChange}
      options={options}
      PaperComponent={StyledPaperComponent}
      renderInput={(renderProps) => (
        <TextField
          {...renderProps}
          autoFocus={autoFocus}
          label={label}
          InputProps={{
            ...renderProps.InputProps,
            endAdornment: inputValue !== '' && (
              <IconButton
                onClick={() => {
                  setInputValue('');
                }}
              >
                {endIcon}
              </IconButton>
            ),
            startAdornment: startIcon,
          }}
          inputRef={(input) => input?.focus()}
          placeholder={placeholder}
          variant="standard"
          startIcon={startIcon}
          endIcon={endIcon}
        />
      )}
      renderOption={renderOption}
    />
  );
}
