import { useTranslation } from '@Providers/TranslationProvider';
import { type V2AboutUsPageType } from '@Src/middlewares/CustomPageTypes';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled, type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InnerGridPolice } from '../BaseGridElements';

type Props = {
  data: V2AboutUsPageType;
  blocks?: JSX.Element[];
  children?: JSX.Element | JSX.Element[];
};

const StyledDiv = styled('div')`
  & > div:first-of-type {
    padding-top: 0;
  }
`;

export const Dishy = (props: Props): JSX.Element => {
  const { data, blocks, children } = props;
  const { translate } = useTranslation();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Grid pb={4}>
        <InnerGridPolice>
          <Typography variant={isSm ? 'h2Display' : 'h1'}>
            {translate(data?.title || '')}
          </Typography>
        </InnerGridPolice>
        <StyledDiv>{blocks}</StyledDiv>
      </Grid>
      {children}
    </>
  );
};

export default Dishy;
