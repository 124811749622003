import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from '@Providers/TranslationProvider';
import { useRecaptcha } from '@Src/hooks/useRecaptcha';
import FadeInContainer from '../FadeInContainer';

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useConfig } from '@Src/providers/ConfigProvider';
import { useApi } from '@Src/hooks/useApi';
import { useIsMobile } from '@Src/hooks/useIsMobile';

type StyledMessageProps = {
  messageColor: string;
};

const StyledMessage = styled.h3<StyledMessageProps>`
  color: ${(props) => props.messageColor}!important;
`;

const StyledError = styled.span`
  color: white;
`;
type ErrorsType = {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
  captchaToken?: string;
};

export const Contact = (): JSX.Element => {
  const { language, appId } = useConfig();

  const { translate } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sectionHeadingColor, setSectionHeadingColor] = useState<string>('');

  const [formErrors, setFormErrors] = useState<ErrorsType>({});
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const isMobile = useIsMobile();

  const {
    isRecaptchaValid,
    handleRecaptchaExpired,
    handleRecaptchaTest,
    recaptchaSiteKey,
  } = useRecaptcha();

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    // custom css overrides heading color so need to find new color
    const contactHeaderElement = document.querySelector(
      '#contact .section-title',
    );
    const color =
      contactHeaderElement && getComputedStyle(contactHeaderElement)?.color;

    color && setSectionHeadingColor(color);
  }, []);

  const { post } = useApi();

  const validateForm = (): boolean => {
    const errors: ErrorsType = {};
    if (name.trim() === '') {
      errors.name = translate('This_field_is_required');
    }

    if (email.trim() === '') {
      errors.email = translate('This_field_is_required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = 'Invalid Email';
    }

    if (phone.trim() === '') {
      errors.phone = translate('This_field_is_required');
    }

    if (message.trim() === '') {
      errors.message = translate('This_field_is_required');
    }

    if (captchaToken.trim() === '') {
      errors.captchaToken = translate('This_field_is_required');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    setSubmitClicked(true);

    const isFormValid = validateForm();
    if (isFormValid) {
      const formData = {
        Name: name,
        Phone: phone,
        Email: email,
        Message: message,
        ContactGoogleRecaptchaResponse: captchaToken,
      };

      try {
        setSubmitting(true);
        if (appId) {
          const response = await post<typeof formData, string>({
            path: `/app/contact?panaceaAppNameId=${appId}`,
            payload: formData,
          });
          if (response?.data?.Success === true) {
            setSubmitSuccess(true);
            setSubmitFailure(false);
          } else {
            setSubmitFailure(true);
          }
        }
        setSubmitting(false);
      } catch {
        setSubmitting(false);
        setSubmitFailure(true);
      }
    }
  };

  // dirty but prevents the edge case of setState not updating the field value before validation in onChange
  useEffect(() => {
    submitClicked && validateForm();
  }, [name, phone, email, message]);

  useEffect(() => {
    if (isRecaptchaValid) {
      setCaptchaToken(recaptchaRef.current?.getValue() || '');
    } else {
      setCaptchaToken('');
    }
  }, [isRecaptchaValid]);

  return (
    <section id="contact" className="section contact dark">
      {submitting && (
        <>
          <div
            style={{
              zIndex: 1000,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgb(0, 0, 0)',
              opacity: '0.6',
              cursor: 'wait',
              position: 'absolute',
            }}
          />
          <div
            style={{
              zIndex: 1011,
              padding: '0px',
              margin: '0px',
              width: '30%',
              left: '35%',
              top: '40%',
              position: 'sticky',
              textAlign: 'center',
              color: 'rgb(0, 0, 0)',
              border: '3px solid rgb(170, 170, 170)',
              backgroundColor: 'rgb(255, 255, 255)',
              cursor: 'wait',
            }}
          >
            <h1>{translate('Please_wait')}...</h1>
          </div>
        </>
      )}
      <div className="container">
        <FadeInContainer className="jt_row jt_row-fluid row visible">
          <>
            <div className="col-md-12 jt_col column_container">
              <h2 className="section-title" tabIndex={0}>
                {translate('Contact')}
              </h2>
            </div>
            <div className="col-md-6 col-md-offset-3 jt_col column_container">
              <div className="section-subtitle"></div>
            </div>
            {submitFailure && (
              <div
                className="col-md-12 jt_col column_container"
                id="contact-success"
              >
                <div className="voffset100"></div>

                <div className="voffset40"></div>
                <span
                  aria-live="assertive"
                  className="field-validation-error"
                  style={{ color: 'white' }}
                >
                  <span>{translate('There_has_been_issue')}</span>
                </span>
                <div className="voffset50"></div>
              </div>
            )}
            {!submitSuccess && (
              <form
                className="contact-form"
                id="contact-form"
                noValidate
                // form doesn't expect promise returned but doesn't use it anyway
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
              >
                <div className="col-md-6 jt_col column_container">
                  {formErrors.name && (
                    <StyledError
                      aria-live="assertive"
                      className="field-validation-error"
                    >
                      <span>{formErrors.name}</span>
                    </StyledError>
                  )}
                  <input
                    aria-label="Enter your name"
                    className="text name required text-box single-line"
                    id="Name"
                    name="Name"
                    placeholder={translate('Name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                  {formErrors.phone && (
                    <StyledError
                      aria-live="assertive"
                      className="field-validation-error"
                    >
                      <span>{formErrors.phone}</span>
                    </StyledError>
                  )}
                  <input
                    aria-label="Enter your mobile number"
                    className="text phone required text-box single-line"
                    id="Phone"
                    name="Phone"
                    placeholder={translate('Phone')}
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {formErrors.email && (
                    <StyledError
                      aria-live="assertive"
                      className="field-validation-error"
                    >
                      <span>{formErrors.email}</span>
                    </StyledError>
                  )}
                  <input
                    aria-label="Please enter an email address"
                    className="text email required text-box single-line"
                    id="Email"
                    name="Email"
                    placeholder={translate('Email')}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {isBrowser && recaptchaSiteKey && !isMobile && (
                    <>
                      {formErrors.captchaToken && !isMobile && (
                        <StyledError
                          aria-live="assertive"
                          className="field-validation-error"
                        >
                          <span>{formErrors.captchaToken}</span>
                        </StyledError>
                      )}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        data-fd="reset-password-recaptcha"
                        hl={language}
                        sitekey={recaptchaSiteKey}
                        onChange={handleRecaptchaTest}
                        onExpired={handleRecaptchaExpired}
                      />
                    </>
                  )}
                </div>
                <div className="col-md-6 jt_col column_container">
                  {formErrors.message && (
                    <StyledError
                      aria-live="assertive"
                      className="field-validation-error"
                    >
                      <span>{formErrors.message}</span>
                    </StyledError>
                  )}
                  <textarea
                    id="message"
                    name="message"
                    className="text area"
                    placeholder={translate('Message')}
                    rows={8}
                    aria-label="Write any message in this field"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                {isBrowser && recaptchaSiteKey && isMobile && (
                  <div className="col-md-6 jt_col column_container">
                    {formErrors.captchaToken && (
                      <StyledError
                        aria-live="assertive"
                        className="field-validation-error"
                      >
                        <span>{formErrors.captchaToken}</span>
                      </StyledError>
                    )}
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      data-fd="reset-password-recaptcha"
                      hl={language}
                      sitekey={recaptchaSiteKey}
                      onChange={handleRecaptchaTest}
                      onExpired={handleRecaptchaExpired}
                    />
                  </div>
                )}
                <div className="col-md-4 col-md-offset-4 jt_col column_container">
                  <input
                    id="contact_submit"
                    type="submit"
                    className="button center"
                    value={translate('Submit')}
                  />
                </div>
              </form>
            )}
            {submitSuccess && (
              <div
                className="col-md-12 jt_col column_container"
                id="contact-success"
              >
                <div className="voffset100"></div>

                <div className="voffset40"></div>
                <StyledMessage
                  className="text-center"
                  aria-live="polite"
                  tabIndex={0}
                  messageColor={sectionHeadingColor}
                >
                  {translate('Message_submitted')}
                </StyledMessage>

                <div className="voffset50"></div>
              </div>
            )}

            <div className="voffset100"></div>
          </>
        </FadeInContainer>
        <div className="voffset50"></div>
      </div>
    </section>
  );
};

export default Contact;
