import { useTranslation } from '@Src/providers/TranslationProvider';
import { type AddressAutoCompleteSuggestionDetails } from '@Src/types/googleMapsTypes';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type AddressSuggestionPropsType = {
  option: AddressAutoCompleteSuggestionDetails;
  optionProps: {
    'aria-disabled': boolean;
    'aria-selected': boolean;
    className?: string;
    'data-option-index': number;
    id: string;
    key: string;
    onClick: () => void;
    onMouseMove: () => void;
    onTouchStart: () => void;
    role: string;
    tabIndex: number;
  };
};

const StyledListItem = styled('li')(({ theme }) => ({
  color: theme.palette.text.primary,
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.secondary.lowContrast,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  minHeight: '84px', // maintain uniform height when address line 2 is missing
}));

const StyledSecondaryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.body};
`;

const StyledAddressPartOne = styled(Typography)`
  margin: 0;
`;

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledButtonText = styled(Typography)`
  text-transform: none;
`;

export const AddressSuggestion = (
  props: AddressSuggestionPropsType,
): JSX.Element | null => {
  const { translate } = useTranslation();

  const { option, optionProps } = props;
  if (!option) {
    return null;
  }

  const address: string = option.description;
  const adressPartOne = address.split(', ', 2).join(', ');
  const adressPartTwo = address.split(', ').slice(2).join(', ');

  const optionPropsCopy = { ...optionProps };
  delete optionPropsCopy.className;

  if (option.placeId !== 'customAddressRequired') {
    return (
      <StyledListItem {...optionPropsCopy}>
        <StyledAddressPartOne variant="body1Medium" paragraph>
          {adressPartOne}
        </StyledAddressPartOne>
        <StyledSecondaryText variant="body2">
          {adressPartTwo}
        </StyledSecondaryText>
      </StyledListItem>
    );
  }

  return (
    <StyledButtonContainer container>
      <StyledButton {...optionPropsCopy} fullWidth variant="outlined">
        <StyledButtonText variant="button">
          {translate('Address_not_listed')}
        </StyledButtonText>
      </StyledButton>
    </StyledButtonContainer>
  );
};
