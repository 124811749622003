import { useRef } from 'react';
import { useElementInView } from '../../../hooks/useElementInView';

type FadeInContainerProps = {
  className?: string;
  children: JSX.Element[] | JSX.Element;
};
const FadeInContainer = ({
  className = '',
  children,
}: FadeInContainerProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useElementInView(ref);
  return (
    <div
      className={className}
      ref={ref}
      style={{
        opacity: onScreen ? 1 : 0,
        transition: '1s ease-in',
      }}
    >
      {children}
    </div>
  );
};
export default FadeInContainer;
