import { type Geo } from '@Src/types/locationTypes';

export const isLocationServiceSupported = (): boolean => {
  const { userAgent } = navigator;
  const isAndroid = /Android/i.test(userAgent);
  const isFirefox = /firefox/i.test(userAgent);
  const isChrome = (window as any).chrome;

  if (!navigator.geolocation) {
    return false;
  }

  if (isChrome || isFirefox || isAndroid) {
    return (window as any).isSecureContext;
  }

  return window.location.protocol === 'https:';
};

export const LatLng = (geo: Geo): google.maps.LatLng =>
  new google.maps.LatLng(geo.Latitude, geo.Longitude);

export const ToGeo = (p: google.maps.LatLng): Geo => ({
  Latitude: p.lat(),
  Longitude: p.lng(),
});

export const getPostCodeExample = (countryCode: SupportedCountries): string =>
  postCodes[countryCode] || '';

export type SupportedCountries =
  | 'AU'
  | 'BE'
  | 'BG'
  | 'CA'
  | 'DE'
  | 'ES'
  | 'FR'
  | 'GB'
  | 'IE'
  | 'IT'
  | 'LU'
  | 'MX'
  | 'NL'
  | 'NZ'
  | 'PL'
  | 'PT'
  | 'US';

type PostCodes = {
  [key in SupportedCountries]: string;
};

const postCodes: PostCodes = {
  AU: 'NSW 2000',
  BE: '1930 Zaventem',
  BG: '1000 Sofia',
  CA: 'M5V 3L9',
  DE: '80687',
  ES: '08830',
  FR: '75001',
  GB: 'EC1V 1AZ',
  IE: 'D18 Y2X6',
  IT: '00199',
  LU: '2240 Ville Haute',
  MX: '15620',
  NL: '1078 GA',
  NZ: 'Auckland 2022',
  PL: '02-143',
  PT: '1000-205 Lisboa',
  US: 'NY 10013',
};

export const getPostCodeLabel = (countryCode: SupportedCountries): string => {
  switch (countryCode) {
    case 'IE':
      return 'Enter_your_eircode_or_address';
    case 'US':
      return 'Enter_your_ZIP_code_or_address';
    default:
      return 'Enter_your_postcode_or_address';
  }
};
